import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import styles from "./airportSelectionPopover.module.scss";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import {
  Airport,
  AirportDataTypes,
  BookingDetails,
  iCityDetailsResult,
  LocationTypes,
} from "../../../../types";
import { FormControlLabel } from "@mui/material";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { toastMessage } from "../../../../helpers/toast/toastMessage";
import { getAirports } from "../../../../api/booking";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";
import AutocompleteAirportAddress from "../../../airportAutoCompleteAddress/airportAutoCompleteAddress";
import AirportMultiCityDepartAutoCompleteAddress from "../../../airportMultiCityDepartAutoCompleteAddress/airportMultiCityDepartAutoCompleteAddress";
import AirportMultiCityArrivalAutoCompleteAddress from "../../../airportMultiCityArrivalAutoCompleteAddress/airportMultiCityArrivalAutoCompleteAddress";
import {
  CityAirports,
  iBookingAirportLoaderTypes,
} from "../../../../interface";
import Loader from "../../../reusableComponent/loader/Loader";

interface iProps {
  popoverTrigger: React.ReactNode;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  isPopoverOpen: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  departure: string;
  arrival: string;
  fromCity: string;
  destinationCity: string;
  indexNumber: number;
  arrivalCityLatitude: string;
  arrivalCityLongitude: string;
  departureCityLatitude: string;
  departureCityLongitude: string;
  // airportData: AirportDataTypes[];
  newDepartureCitySearch: boolean;
  newArrivalCitySearch: boolean;
  arrivalCityDetails: string;
  departureCityDetails: string;
  arrivalSelectedLocation: LocationTypes;
  departureSelectedLocation: LocationTypes;
}

export default function AirportSelectionPopover({
  popoverTrigger,
  bookingDetails,
  setBookingDetails,
  isPopoverOpen,
  setIsPopoverOpen,
  departure,
  arrival,
  fromCity,
  destinationCity,
  indexNumber,
  arrivalCityLatitude,
  arrivalCityLongitude,
  departureCityLatitude,
  departureCityLongitude,
  // airportData,
  newDepartureCitySearch,
  newArrivalCitySearch,
  arrivalCityDetails,
  departureCityDetails,
  arrivalSelectedLocation,
  departureSelectedLocation,
}: iProps) {
  const state = useContext(StateContext);
  const { airPorts, setAirPorts }: iGlobalContext = state;
  // const [airPort, setAirPort] = useState<AirportDataTypes[]>([]);

  const updateBookingDetailsWithAirports = (
    data: AirportDataTypes[],
    fromCity: string,
    destinationCity: string,
    indexNumber: number
  ) => {
    if (data?.length > 0) {
      const departureCityAirports = data.find(
        (city: AirportDataTypes) => city.city === fromCity
      )?.airports;
      const arrivalCityAirports = data.find(
        (city: AirportDataTypes) => city.city === destinationCity
      )?.airports;

      // Check and update departure airport only if it's not already set
      if (departureCityAirports && departureCityAirports.length > 0) {
        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          airPort: prevDetails.airPort.map((airPort, idx) => {
            if (idx === indexNumber && !airPort.departure) {
              return {
                ...airPort,
                departure: departureCityAirports[0].code,
                departureAirportFullName: departureCityAirports[0].fullName,
              };
            }
            return airPort;
          }),
        }));
      }
      if (arrivalCityAirports && arrivalCityAirports.length > 0) {
        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          airPort: prevDetails.airPort.map((airPort, idx) => {
            if (idx === indexNumber && !airPort.arrival) {
              return {
                ...airPort,
                arrival: arrivalCityAirports[0].code,
                arrivalAirportFullName: arrivalCityAirports[0].fullName,
              };
            }
            return airPort;
          }),
        }));
      }
    }
  };

  const handleUpdateAirPortData = useCallback(() => {
    // setAirPort(airportData);
    // setAirPorts(airportData);
    updateBookingDetailsWithAirports(
      airPorts,
      fromCity,
      destinationCity,
      indexNumber
    );
  }, [destinationCity, fromCity]);

  useEffect(() => {
    handleUpdateAirPortData();

    const timeoutId = setTimeout(() => {
      handleUpdateAirPortData();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleRadioArrivalChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    airportIndex: number
  ) => {
    const selectedAirportCode = event.target.value;
    const selectedCity = airPorts.find((city: AirportDataTypes) => {
      const selectedCity = newArrivalCitySearch
        ? formatLocation(
            bookingDetails.airPort[indexNumber]?.arrivalSelectedLocation || {}
          )
        : destinationCity;

      return city.city === selectedCity;
    });

    const selectedAirport = selectedCity?.airports.find(
      (airport) => airport.code === selectedAirportCode
    );

    setBookingDetails((prevDetails) => {
      const updatedAirports = [...prevDetails.airPort];
      updatedAirports[airportIndex] = {
        ...updatedAirports[airportIndex],
        arrival: selectedAirportCode,
        arrivalAirportFullName: selectedAirport?.fullName || "",
      };

      return {
        ...prevDetails,
        airPort: updatedAirports,
      };
    });
  };

  const handleRadioDepartureChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    airportIndex: number
  ) => {
    const selectedAirportCode = event.target.value;

    // Find the city and airport details based on airportIndex and the selectedAirportCode
    const selectedCity = airPorts.find((city: AirportDataTypes) => {
      const selectedCity = newDepartureCitySearch
        ? formatLocation(
            bookingDetails.airPort[indexNumber]?.departureSelectedLocation || {}
          )
        : fromCity;
      return city.city === selectedCity;
    });
    const selectedAirport = selectedCity?.airports.find(
      (airport) => airport.code === selectedAirportCode
    );

    setBookingDetails((prevDetails) => {
      const updatedAirports = [...prevDetails.airPort];
      updatedAirports[airportIndex] = {
        ...updatedAirports[airportIndex],
        departure: selectedAirportCode,
        departureAirportFullName: selectedAirport?.fullName || "",
      };

      return {
        ...prevDetails,
        airPort: updatedAirports,
      };
    });
  };

  // function combineCityNames(
  //   selectedChatData: any
  // ): { name: string; latitude: string; longitude: string }[] {
  //   if (!selectedChatData || !selectedChatData.fromCity) {
  //     return [];
  //   }
  //   const fromCity = {
  //     name: selectedChatData.fromCity,
  //     latitude: "",
  //     longitude: "",
  //   };

  //   const extractCityNames =
  //     selectedChatData?.destinationList?.map((i: iCityDetailsResult) => ({
  //       name: i?.cityName?.split(",")[0] || i?.destination_city,
  //       latitude: i?.city_latitude || "",
  //       longitude: i?.city_longitude || "",
  //     })) || [];

  //   return [fromCity, ...extractCityNames];
  // }
  // let fetchAirportController: any = useRef(null);
  // const handleFlightSearch = async () => {
  //   const cities = combineCityNames(selectedChatData);

  //   if (cities.length === 0) {
  //     toastMessage.error("No valid cities selected");
  //     return;
  //   }

  //   setLoading(true);

  //   const requestBody = {
  //     cities: cities,
  //     radius: "100 miles",
  //   };
  //   fetchAirportController.current = new AbortController();
  //   try {
  //     const data = await getAirports(
  //       requestBody,
  //       fetchAirportController.current
  //     );
  //     if (fetchAirportController.current.signal.aborted) {
  //       return null;
  //     }
  //     setAirPorts(data);
  //   } catch (err) {
  //     setAirPorts([]);
  //     toastMessage.error("Failed to fetch flight data");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   if (airPorts?.length === 0) {
  //     handleFlightSearch();
  //   }
  // }, [airPorts]);
  const [loader, setLoader] = useState<iBookingAirportLoaderTypes>({
    arrivalLoader: false,
    departureLoader: false,
  });
  let fetchAirportController: any = useRef(null);
  const removeDuplicateAirports = (response: any) => {
    const uniqueAirports = new Map();

    response.forEach((entry: any) => {
      entry.airports.forEach((airport: any) => {
        const key = `${airport.code}-${entry.city}`;
        if (!uniqueAirports.has(key)) {
          uniqueAirports.set(key, { ...airport, city: entry.city });
        }
      });
    });

    return Array.from(uniqueAirports.values());
  };

  const formatLocation = (data: LocationTypes) => {
    const { city = "", state = "", country = "" } = data || {};

    return [city, state, country].filter(Boolean).join(", ");
  };

  const handleSearchArrivalAirport = async (index: number) => {
    const selectedAirport = bookingDetails.airPort[index];
    if (selectedAirport.departureDate === "") {
      toastMessage.error("Please select a travel date");
      return;
    }

    // Validate arrival city
    const { city, latitude, longitude, countryCode } =
      selectedAirport.arrivalSelectedLocation;

    if (!city) {
      toastMessage.error("Please enter city details");
      return;
    }

    // Prepare the cities array dynamically based on arrivalSelectedLocation
    const cities = [
      {
        name: formatLocation(selectedAirport.arrivalSelectedLocation),
        latitude: latitude ? latitude.toString() : "",
        longitude: longitude ? longitude.toString() : "",
        countryCode: countryCode || "",
      },
    ];

    // Set loader states
    setLoader((prevData) => ({
      ...prevData,
      departureLoader: false,
      arrivalLoader: true,
    }));

    // Prepare request body
    const requestBody = {
      cities: cities,
      radius: "100 miles",
    };

    // API call with AbortController
    fetchAirportController.current = new AbortController();
    try {
      const data = await getAirports(
        requestBody,
        fetchAirportController.current.signal
      );

      if (fetchAirportController.current.signal.aborted) {
        return;
      }

      if (data) {
        setAirPorts((prevData) => [...prevData, ...data]);
        // Optionally update the bookingDetails to reflect that a new search was performed
        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          airPort: prevDetails.airPort.map((airport, i) =>
            i === index ? { ...airport, newArrivalCitySearch: true } : airport
          ),
        }));
      }
    } catch (err) {
      setAirPorts([]);
      console.error("Failed to fetch flight data", err);
    } finally {
      // Reset loader states
      setLoader((prevData) => ({
        ...prevData,
        departureLoader: false,
        arrivalLoader: false,
      }));
    }
  };
  // const prevStateRef = useRef<Record<number, string>>({});
  // useEffect(() => {
  //   bookingDetails.airPort.forEach((airport, index) => {
  //     const { city, latitude, longitude, countryCode } =
  //       airport.arrivalSelectedLocation;

  //     // Create a key for comparison
  //     const currentKey = `${city}-${latitude}-${longitude}-${countryCode}`;

  //     // Check if the current state is different from the previous state
  //     if (prevStateRef.current[index] !== currentKey) {
  //       prevStateRef.current[index] = currentKey; // Update the previous state
  //       if (city && latitude && longitude && countryCode) {
  //         handleSearchArrivalAirport(index);
  //       }
  //     }
  //   });
  // }, [bookingDetails.airPort.map((airport) => airport.arrivalSelectedLocation.city)]);

  const handleSearchDepartureAirport = async (index: number) => {
    const selectedAirport = bookingDetails.airPort[index];

    if (selectedAirport.departureDate === "") {
      toastMessage.error("Please select a travel date");
      return;
    }

    // Validate arrival city
    const { city, latitude, longitude, countryCode } =
      selectedAirport.departureSelectedLocation;
    if (!city) {
      toastMessage.error("Please enter city details");
      return;
    }

    // Prepare the cities array dynamically based on arrivalSelectedLocation
    const cities = [
      {
        name: formatLocation(selectedAirport?.departureSelectedLocation),
        latitude: latitude ? latitude.toString() : "",
        longitude: longitude ? longitude.toString() : "",
        countryCode: countryCode || "",
      },
    ];

    // Set loader states
    setLoader((prevData) => ({
      ...prevData,
      departureLoader: true,
      arrivalLoader: false,
    }));

    // Prepare request body
    const requestBody = {
      cities: cities,
      radius: "100 miles",
    };

    // API call with AbortController
    fetchAirportController.current = new AbortController();
    try {
      const data = await getAirports(
        requestBody,
        fetchAirportController.current.signal
      );

      if (fetchAirportController.current.signal.aborted) {
        return;
      }

      if (data) {
        // const filteredAirports = removeDuplicateAirports(data);
        setAirPorts((prevData) => [...prevData, ...data]);

        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          airPort: prevDetails.airPort.map((airport, i) =>
            i === index ? { ...airport, newDepartureCitySearch: true } : airport
          ),
        }));
      }
    } catch (err) {
      setAirPorts([]);
      console.error("Failed to fetch flight data", err);
    } finally {
      // Reset loader states
      setLoader((prevData) => ({
        ...prevData,
        departureLoader: false,
        arrivalLoader: false,
      }));
    }
  };

  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={
        <div className={styles.mainContainer}>
          <>
            <h1 className={styles.heading}>Departure Airport</h1>

            <div className={styles.input}>
              <AirportMultiCityDepartAutoCompleteAddress
                onPlaceSelected={() => {}}
                setAddress={setBookingDetails}
                address={`${newDepartureCitySearch ? departureCityDetails : fromCity}`}
                indexNumber={indexNumber}
                handleSearchDepartureAirport={() =>
                  handleSearchDepartureAirport(indexNumber)
                }
              />
            </div>

            {loader.departureLoader ? (
              <div className={styles.loader}>
                <Loader />
              </div>
            ) : (
              <>
                {Array.isArray(airPorts) &&
                airPorts
                  .reduce(
                    (uniqueCities: CityAirports[], city: CityAirports) => {
                      if (!uniqueCities.some((c) => c.city === city.city)) {
                        uniqueCities.push(city);
                      }
                      return uniqueCities;
                    },
                    []
                  )
                  .filter((city: AirportDataTypes) => {
                    const selectedCity = newDepartureCitySearch
                      ? formatLocation(
                          bookingDetails.airPort[indexNumber]
                            ?.departureSelectedLocation || {}
                        )
                      : fromCity;

                    return city.city === selectedCity;
                  }).length === 0 ? (
                  <p className={styles.errorMessage}>
                    There are no nearby airports
                  </p>
                ) : (
                  <FormControl className={styles.formControlContainer}>
                    <RadioGroup
                      key={indexNumber}
                      onChange={(e) =>
                        handleRadioDepartureChange(e, indexNumber)
                      }
                      value={departure}>
                      {Array.isArray(airPorts) &&
                        airPorts
                          .reduce(
                            (
                              uniqueCities: CityAirports[],
                              city: CityAirports
                            ) => {
                              if (
                                !uniqueCities.some((c) => c.city === city.city)
                              ) {
                                uniqueCities.push(city);
                              }
                              return uniqueCities;
                            },
                            []
                          )
                          .filter((city: AirportDataTypes) => {
                            const selectedCity = newDepartureCitySearch
                              ? formatLocation(
                                  bookingDetails.airPort[indexNumber]
                                    ?.departureSelectedLocation || {}
                                )
                              : fromCity;
                            return city.city === selectedCity;
                          })
                          .map(
                            (city: AirportDataTypes, airportIndex: number) => (
                              <React.Fragment key={airportIndex}>
                                {city.airports &&
                                  city.airports
                                    .slice(0, 5)
                                    .map((airport: Airport) => (
                                      <FormControlLabel
                                        key={airport.code}
                                        value={airport.code}
                                        control={<CustomRadio />}
                                        label={
                                          <p
                                            className={styles.airportLabel}
                                            style={{
                                              color:
                                                airport.code ===
                                                (bookingDetails?.airPort[
                                                  indexNumber
                                                ]?.departure ?? "")
                                                  ? "#222222"
                                                  : "#696969",
                                            }}>
                                            {airport.code} - {airport.fullName}
                                          </p>
                                        }
                                      />
                                    ))}
                              </React.Fragment>
                            )
                          )}
                    </RadioGroup>
                  </FormControl>
                )}
              </>
            )}

            <h1 className={styles.heading}>Arrival Airport</h1>
            <div className={styles.input}>
              <AirportMultiCityArrivalAutoCompleteAddress
                onPlaceSelected={() => {}}
                setAddress={setBookingDetails}
                address={`${newArrivalCitySearch ? arrivalCityDetails : destinationCity}`}
                indexNumber={indexNumber}
                handleSearchArrivalAirport={() =>
                  handleSearchArrivalAirport(indexNumber)
                }
              />
            </div>
            {loader.arrivalLoader ? (
              <div className={styles.loader}>
                <Loader />
              </div>
            ) : (
              <>
                {Array.isArray(airPorts) &&
                airPorts
                  .reduce(
                    (uniqueCities: CityAirports[], city: CityAirports) => {
                      if (!uniqueCities.some((c) => c.city === city.city)) {
                        uniqueCities.push(city);
                      }
                      return uniqueCities;
                    },
                    []
                  )
                  // .filter((city: AirportDataTypes) => city.city === destinationCity)
                  .filter((city: AirportDataTypes) => {
                    const selectedCity = newArrivalCitySearch
                      ? formatLocation(
                          bookingDetails.airPort[indexNumber]
                            ?.arrivalSelectedLocation || {}
                        )
                      : destinationCity;

                    return city.city === selectedCity;
                  }).length === 0 ? (
                  <p className={styles.errorMessage}>
                    There are no nearby airports
                  </p>
                ) : (
                  <FormControl className={styles.formControlContainer}>
                    <RadioGroup
                      key={indexNumber}
                      name={`radioArrival-${indexNumber}`}
                      onChange={(e) => handleRadioArrivalChange(e, indexNumber)}
                      value={arrival}>
                      {Array.isArray(airPorts) &&
                        airPorts
                          .reduce(
                            (
                              uniqueCities: CityAirports[],
                              city: CityAirports
                            ) => {
                              if (
                                !uniqueCities.some((c) => c.city === city.city)
                              ) {
                                uniqueCities.push(city);
                              }
                              return uniqueCities;
                            },
                            []
                          )
                          .filter((city: AirportDataTypes) => {
                            const selectedCity = newArrivalCitySearch
                              ? formatLocation(
                                  bookingDetails.airPort[indexNumber]
                                    ?.arrivalSelectedLocation || {}
                                )
                              : destinationCity;

                            return city.city === selectedCity;
                          })
                          .map(
                            (city: AirportDataTypes, airportIndex: number) => (
                              <React.Fragment key={airportIndex}>
                                {city.airports &&
                                  city.airports
                                    .slice(0, 5)
                                    .map((airport: Airport) => (
                                      <FormControlLabel
                                        key={airport.code}
                                        value={airport.code}
                                        control={<CustomRadio />}
                                        label={
                                          <p
                                            className={styles.airportLabel}
                                            style={{
                                              color:
                                                airport.code ===
                                                (bookingDetails?.airPort[
                                                  indexNumber
                                                ]?.arrival ?? "")
                                                  ? "#222222"
                                                  : "#696969",
                                            }}>
                                            {airport.code} - {airport.fullName}
                                            <br />
                                            <p className={styles.distance}>
                                              {airport.distance} miles from{" "}
                                              {city.city}
                                            </p>
                                          </p>
                                        }
                                      />
                                    ))}
                              </React.Fragment>
                            )
                          )}
                    </RadioGroup>
                  </FormControl>
                )}
              </>
            )}
          </>
        </div>
      }
      handleApplyButton={() => {
        triggerGAEvent(GA_LABEL_ENUMS.booking_airports_departure_airport_click);
      }}
    />
  );
}

export function CustomRadio(props: RadioProps) {
  return (
    <Radio
      color="default"
      {...props}
      icon={<span className={styles.icon} />}
      checkedIcon={<span className={styles.checkedIcon} />}
    />
  );
}
