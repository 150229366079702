import { Button } from "@mui/material";
import styles from "./search.module.scss";

interface iProps {
  className?: string;
  onClick: () => void;
  disableButton: boolean;
}

function SearchButton({
  className,
  onClick,
  disableButton,
}: iProps): JSX.Element {
  return (
    <div className={`${className} ${styles.main}`}>
      <Button
        fullWidth
        className={`${disableButton ? styles.inActive : styles.searchBtn}`}
        onClick={onClick}
        disabled={disableButton}>
        Search
      </Button>
    </div>
  );
}

export default SearchButton;
