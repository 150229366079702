import styles from "./hotel.module.scss";

interface iProps {
  hotelRating: number;
  reviewCount: number;
  className?: string;
  ratingCardClass?: string;
  ratingClass?: string;
  contentClass?: string;
  reviewTitleClass?: string;
  reviewClass?: string;
  reviewHeading?: string;
  onReviewClick?: () => void;
}

function HotelRating({
  hotelRating,
  reviewCount,
  className = "",
  ratingCardClass = "",
  ratingClass = "",
  contentClass = "",
  reviewTitleClass = "",
  reviewClass = "",
  reviewHeading = "",
  onReviewClick,
}: iProps): JSX.Element {
  return (
    <div className={`${styles.main} ${className}`}>
      {hotelRating < 1 ? (
        <div className={styles.noReviewCard}>
          <div className={styles.disableCard}></div>
          <p className={styles.noReview}>No Reviews Yet</p>
        </div>
      ) : (
        <>
          {hotelRating !== null && (
            <div className={`${styles.ratingCard} ${ratingCardClass}`}>
              <p className={`${styles.rating} ${ratingClass}`}>{hotelRating}</p>
            </div>
          )}
          <div className={`${styles.content} ${contentClass}`}>
            <p className={`${styles.reviewTitle} ${reviewTitleClass}`}>
              {reviewHeading}
            </p>
            {reviewCount !== null && (
              <p
                className={`${styles.review} ${reviewClass}`}
                onClick={onReviewClick}>
                {reviewCount} reviews
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
}

HotelRating.defaultProps = {
  className: "",
  ratingCardClass: "",
  ratingClass: "",
  contentClass: "",
  reviewTitleClass: "",
  reviewClass: "",
  onReviewClick: () => {},
};

export default HotelRating;
