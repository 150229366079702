import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  BookingDetails,
  ModifiedDataTypes,
  TravelJourney,
} from "../../../../types";
import AirPortPicker from "../../inputFields/airportPicker/airportPicker";
import DateRangePicker from "../../inputFields/dateRangePicker/dateRangePicker";
import Duration from "../../inputFields/duration/duration";
import RouteAndClass from "../../inputFields/routeAndClassNew/routeAndClass";
import SearchButton from "../../inputFields/searchButton/searchButton";
import styles from "./flight.module.scss";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import Travelers from "../../inputFields/travelers/travelers";
import PropertyType from "../../inputFields/propertyType/propertyType";
import CityLocation from "../../inputFields/location/location";
import Stepper from "../flightSingleCityInputFields/stepper/stepper";
import { toastMessage } from "../../../../helpers/toast/toastMessage";
interface iProps {
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  searchFlights: () => void;
  searchHotels: () => void;
  getTravelData: () => void;
}

function FlightHotelInputFields({
  bookingDetails,
  setBookingDetails,
  searchFlights,
  searchHotels,
  getTravelData,
}: iProps): JSX.Element {
  const state = useContext(StateContext);
  const { selectedChatData }: iGlobalContext = state;
  const [enableBtn, setEnableBtn] = useState<boolean>(true);
  const previousTravelDataRef = useRef<TravelJourney[] | null>(null);
  const isThisMultiCityTrip =
    selectedChatData?.destinationList?.length > 1 || false;
  function transformData(
    bookingDetails: BookingDetails,
    setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>
  ): void {
    const tripData = isThisMultiCityTrip
      ? bookingDetails.travelJourneyData
      : bookingDetails.travelJourneyData.slice(0, 1);
    const updatedAirPortData = tripData
      .filter(
        (journey, index, self) =>
          index ===
          self?.findIndex(
            (t) => JSON?.stringify(t) === JSON?.stringify(journey)
          )
      )
      .map((journey, index) => {
        return {
          arrival: bookingDetails.airPort[index]?.arrival || "",
          departure: bookingDetails.airPort[index]?.departure || "",
          fromCity: journey.departureCityAddress,
          destinationCity: journey.arrivalCityAddress,
          departureDate: journey.departureDate,
          returnDate: bookingDetails.travelJourneyData[index]?.returnDate || "",
          departureAirportFullName:
            bookingDetails.airPort[index]?.departureAirportFullName || "",
          arrivalAirportFullName:
            bookingDetails.airPort[index]?.arrivalAirportFullName || "",
          arrivalCityLatitude:
            bookingDetails.airPort[index]?.arrivalCityLatitude,
          arrivalCityLongitude:
            bookingDetails.airPort[index]?.arrivalCityLongitude,
          departureCityLatitude:
            bookingDetails.airPort[index]?.departureCityLatitude,
          departureCityLongitude:
            bookingDetails.airPort[index]?.departureCityLongitude,
          noOfDays: journey.totalDays || 0,
          newArrivalCitySearch:
            bookingDetails.airPort[index]?.newArrivalCitySearch,
          newDepartureCitySearch:
            bookingDetails.airPort[index]?.newDepartureCitySearch,
          arrivalCityDetails: bookingDetails.airPort[index]?.arrivalCityDetails,
          departureCityDetails:
            bookingDetails.airPort[index]?.departureCityDetails,
          arrivalSelectedLocation: {
            city:
              bookingDetails?.airPort[index]?.arrivalSelectedLocation?.city ||
              "",
            state:
              bookingDetails?.airPort[index]?.arrivalSelectedLocation?.state ||
              "",
            country:
              bookingDetails?.airPort[index]?.arrivalSelectedLocation
                ?.country || "",
            countryCode:
              bookingDetails?.airPort[index]?.arrivalSelectedLocation
                ?.countryCode || "",
            latitude:
              bookingDetails?.airPort[index]?.arrivalSelectedLocation
                ?.latitude || 0,
            longitude:
              bookingDetails?.airPort[index]?.arrivalSelectedLocation
                ?.longitude || 0,
          },
          departureSelectedLocation: {
            city:
              bookingDetails?.airPort[index]?.departureSelectedLocation?.city ||
              "",
            state:
              bookingDetails?.airPort[index]?.departureSelectedLocation
                ?.state || "",
            country:
              bookingDetails?.airPort[index]?.departureSelectedLocation
                ?.country || "",
            countryCode:
              bookingDetails?.airPort[index]?.departureSelectedLocation
                ?.countryCode || "",
            latitude:
              bookingDetails?.airPort[index]?.departureSelectedLocation
                ?.latitude || 0,
            longitude:
              bookingDetails?.airPort[index]?.departureSelectedLocation
                ?.longitude || 0,
          },
        };
      });
    setBookingDetails((prevBookingDetails) => ({
      ...prevBookingDetails,
      airPort: updatedAirPortData,
    }));
  }

  const checkTravelJourneyData = bookingDetails.travelJourneyData?.some(
    (i) =>
      i.arrivalCityAddress ||
      i.departureCityAddress ||
      i.departureDate ||
      i.flightCheckOut
  );

  const handleUpdateTravelData = useCallback(async () => {
    await getTravelData();
    transformData(bookingDetails, setBookingDetails);
  }, [
    getTravelData,
    checkTravelJourneyData,
    bookingDetails,
    setBookingDetails,
  ]);

  // useEffect(() => {
  //   handleUpdateTravelData();
  // }, [bookingDetails.travelToDate]);

  useEffect(() => {
    const currentData = bookingDetails.travelJourneyData;
    if (
      JSON.stringify(previousTravelDataRef.current) !==
      JSON.stringify(currentData)
    ) {
      handleUpdateTravelData();
    }
    previousTravelDataRef.current = currentData;
  }, [checkTravelJourneyData, bookingDetails.travelJourneyData]);

  const handleFlightHotelInputValidation = useCallback(() => {
    const {
      airPort,
      adultsCount,
      childrenCount,
      seniorsCount,
      travelFromDate,
    } = bookingDetails || {};
    setBookingDetails((prevData) => ({
      ...prevData,
      selectedFlightId: "",
    }));
    // const checkDepartureDate = airPort?.some((flight) => flight?.departureDate);
    const checkDeparture = airPort?.some((flight) => flight.departure);
    const checkArrival = airPort?.some((flight) => flight.arrival);
    if (travelFromDate === "") {
      toastMessage.error("", "Please select a travel date.");
      setEnableBtn(true);
      return;
    }
    if (!checkDeparture) {
      toastMessage.error("", "Please select a departure airport.");
      setEnableBtn(true);
      return;
    }
    if (!checkArrival) {
      toastMessage.error("", "Please select a arrival airport.");
      setEnableBtn(true);
      return;
    }

    const totalAdults = adultsCount + seniorsCount;

    if (totalAdults === 0) {
      toastMessage.error("", "At least 1 adult or senior is required.");
      setEnableBtn(true);
      return;
    }

    if (childrenCount > 0 && totalAdults === 0) {
      toastMessage.error(
        "",
        "A child must be accompanied by at least 1 adult or senior."
      );
      setEnableBtn(true);
      return;
    }

    searchFlights();
    searchHotels();
  }, [bookingDetails, searchFlights, searchHotels]);

  useEffect(() => {
    const {
      airPort,
      adultsCount,
      seniorsCount,
      childrenCount,
      travelFromDate,
      numberOfRoomCount,
    } = bookingDetails || {};

    const isFieldMissing =
      !airPort?.length ||
      airPort.some((flight) => {
        return !flight.departure || !flight.arrival;
      });

    const isTravelDateMissing = !travelFromDate;
    const isRoomCountInvalid = numberOfRoomCount < 1;
    const shouldDisableButton =
      isFieldMissing ||
      isTravelDateMissing ||
      (childrenCount === 1 && adultsCount < 1 && seniorsCount < 1) ||
      (adultsCount < 1 && seniorsCount < 1) ||
      isRoomCountInvalid;

    setEnableBtn(shouldDisableButton);
  }, [bookingDetails]);
  
  return (
    <div className={styles.inputContainer}>
      <div className={styles.newInputCard1}>
        {bookingDetails && (
          <DateRangePicker
            startDate={bookingDetails.travelFromDate}
            endDate={bookingDetails.airportTo}
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            handleUpdateData={() => {
              getTravelData();
              handleUpdateTravelData();
            }}
          />
        )}
        <div className={styles.card3}>
          {bookingDetails && (
            <Duration
              bookingDetails={bookingDetails}
              flight={true}
              hotel={true}
            />
          )}
        </div>
        {bookingDetails && (
          <div className={styles.card1}>
            <Travelers
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              showRoom={true}
            />
          </div>
        )}
      </div>
      <div className={styles.newInputCard1}>
        {bookingDetails && (
          <div className={styles.card4}>
            <PropertyType
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
            />
          </div>
        )}
        {selectedChatData?.destinationList &&
        selectedChatData?.destinationList.length > 1 ? (
          <RouteAndClass
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            tripType={3}
          />
        ) : (
          <div className={styles.locationCard}>
            <CityLocation city={bookingDetails?.citiesInput || ""} />
          </div>
        )}
      </div>
      <div className={styles.newInputCard3}>
        {bookingDetails && (
          <div className={styles.card4}>
            {selectedChatData?.destinationList?.length < 2 && (
              <RouteAndClass
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                tripType={bookingDetails.tripType}
              />
            )}
          </div>
        )}
        {selectedChatData.destinationList &&
          selectedChatData.destinationList.length === 1 && (
            <>
              {bookingDetails?.airPort &&
                bookingDetails?.airPort?.length >= 1 &&
                bookingDetails.airPort.map((i, index) => (
                  <div className={styles.newInputCard2} key={index}>
                    <div className={styles.card2}>
                      <AirPortPicker
                        indexNumber={index}
                        arrival={i.arrival}
                        departure={i.departure}
                        bookingDetails={bookingDetails}
                        setBookingDetails={setBookingDetails}
                        departureAirportFullName={i.departureAirportFullName}
                        arrivalAirportFullName={i.arrivalAirportFullName}
                        handleUpdateData={() => {
                          getTravelData();
                          handleUpdateTravelData();
                        }}
                        arrivalCity={i.destinationCity}
                        departureCity={i.fromCity}
                      />
                    </div>
                  </div>
                ))}

              {/* : (
                <div className={styles.newInputCard2}>
                  <div className={styles.card2}>
                    <AirPortPicker
                      arrival={""}
                      departure={""}
                      bookingDetails={bookingDetails}
                      setBookingDetails={setBookingDetails}
                      fromCity={""}
                      destinationCity={""}
                      handleUpdateData={() => {
                        getTravelData();
                        handleUpdateTravelData();
                      }}
                      arrivalCity={""}
                      departureCity={""}
                    />
                  </div>
                </div>
              )} */}
            </>
          )}
      </div>
      {selectedChatData.destinationList &&
        selectedChatData.destinationList.length > 1 && (
          <div className={styles.stepper}>
            <section className={styles.stepperDetails}>
              {bookingDetails.travelFromDate &&
                bookingDetails.travelToDate &&
                bookingDetails.airPort.length > 1 && (
                  <Stepper
                    steps={bookingDetails.airPort}
                    initialStep={0}
                    finalStep={bookingDetails.airPort.length - 1}
                    showAccomodation={true}
                  />
                )}
            </section>
          </div>
        )}
      <div
        className={`${selectedChatData?.destinationList?.length > 1 ? styles.searchBox : styles.singleCitySearch}`}>
        <SearchButton
          onClick={handleFlightHotelInputValidation}
          disableButton={enableBtn}
        />
      </div>
    </div>
  );
}

export default FlightHotelInputFields;
