import { useContext } from "react";
import styles from "./cardWithShowMore.module.scss";
import DestinationCard from "../../../component/reusableComponent/destinationCard/DestinationCard";
import { Button, Grid, Stack } from "@mui/material";
import Loader from "../../../component/reusableComponent/loader/Loader";
import ChatContent from "../chatContent/ChatContent";
import { APP_CONFIG } from "../../../constant/common";
import { getConfigMessage } from "../../../utils";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { updatedSeeMoreIcon } from "../../../constant";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import Markdown from "react-markdown";

export default function CardWithShowMore({
  onCheckboxChange,
  data,
  enableMultiSelect,
  handleShowMore,
  postText,
}: {
  onCheckboxChange?: any;
  data?: any;
  enableMultiSelect?: any;
  handleShowMore?: any;
  postText?: any;
}) {
  const globalContext = useContext(StateContext);
  const { appConfig }: iGlobalContext = globalContext;

  return (
    <>
      <div className={styles.destinationCardContainer}>
        <Grid container>
          {data.destinationList.map((ele: any, index: number) => (
            <Grid
              key={index}
              xs={6}
              pt={{ xs: 0, md: 1 }}
              p={0.2}
              px={{ xs: 0.2, md: 0.5 }}
            >
              <DestinationCard
                key={index}
                destinationData={ele}
                onClick={() => {}}
                enableMultiSelect={enableMultiSelect}
                onCheckboxChange={() => onCheckboxChange(ele, data)}
                // onClick={() => handleViewItineraryDetails(index)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={styles.showMoreLikeIconContainer}>
        {data?.isLoading ? (
          <Stack py={2}>
            <Loader />
          </Stack>
        ) : (
          <Button
            className={styles.button}
            onClick={() => {
              triggerGAEvent(GA_LABEL_ENUMS.Show_more_click);
              handleShowMore();
            }}
          >
            <img
              src={updatedSeeMoreIcon}
              alt="show more"
              className={styles.buttonIcon}
            />
            SEE MORE PLACES
          </Button>
        )}
      </div>

      {/* <Markdown className={styles.postText}>{ postText}</Markdown> */}
      {postText && (
        <ChatContent
          isUserMessage={false}
          // message={getConfigMessage(
          //   appConfig,
          //   APP_CONFIG.MODIFY_DESTINATION_LIST
          // )}
          message={postText}
          // preText={postText}
          isLoading={false}
        />
      )}
    </>
  );
}
