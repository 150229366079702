import React, { useState, useRef, useEffect } from "react";
import { Libraries, LoadScript } from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "../../constant/environment";
import { AirPort, BookingDetails } from "../../types";
import styles from "./input.module.scss";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { updatedCloseIcon } from "../../constant";

const libraries: Libraries = ["places"];

interface AutocompleteAddressProps {
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<BookingDetails>>;
  indexNumber: number;
  handleSearchArrivalAirport: (index: number) => void;
}

const AirportMultiCityArrivalAutoCompleteAddress: React.FC<
  AutocompleteAddressProps
> = ({
  onPlaceSelected,
  address,
  setAddress,
  indexNumber,
  handleSearchArrivalAirport,
}) => {
  const state = React.useContext(StateContext);
  const { loginCreateAccountState, isMobile }: iGlobalContext = state;
  const [isAddressUpdated, setIsAddressUpdated] = useState(false);
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(
    null
  );
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (scriptLoaded && !autocompleteServiceRef.current) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
      placesServiceRef.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
  }, [scriptLoaded]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAddress((prevDetails) => ({
      ...prevDetails,
      airPort: prevDetails.airPort.map((airport: AirPort, index: number) =>
        index === indexNumber
          ? {
              ...airport,
              arrivalCityDetails: value,
              newArrivalCitySearch: true,
            }
          : airport
      ),
    }));
    if (value && autocompleteServiceRef.current) {
      autocompleteServiceRef.current.getPlacePredictions(
        { input: value },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setPredictions(predictions);
          } else {
            setPredictions([]);
          }
        }
      );
    } else {
      setPredictions([]);
    }
  };

  const handleSelect = (placeId: string) => {
    if (placesServiceRef.current) {
      placesServiceRef.current.getDetails({ placeId }, (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place
        ) {
          // Extract address components
          const addressComponents = place.address_components || [];
          let city = "";
          let state = "";
          let country = "";
          let countryCode = "";

          addressComponents.forEach((component) => {
            const types = component.types;
            if (types.includes("locality")) {
              city = component.long_name;
            }
            if (types.includes("administrative_area_level_1")) {
              state = component.long_name;
            }
            if (types.includes("country")) {
              country = component.long_name;
              countryCode = component.short_name;
            }
          });

          // Extract latitude and longitude
          const latitude = place.geometry?.location?.lat();
          const longitude = place.geometry?.location?.lng();

          // Update the booking details with arrivalCityDetails and selected location
          setAddress((prevDetails) => ({
            ...prevDetails,
            airPort: prevDetails.airPort.map((airport: any, index: number) =>
              index === indexNumber
                ? {
                    ...airport,
                    arrivalCityDetails: place.formatted_address || "",
                    arrivalSelectedLocation: {
                      city: city,
                      state: state,
                      country: country,
                      latitude: latitude
                        ? parseFloat(latitude.toFixed(4))
                        : null,
                      longitude: longitude
                        ? parseFloat(longitude.toFixed(4))
                        : null,
                      countryCode: countryCode,
                    },
                  }
                : airport
            ),
          }));
          setIsAddressUpdated(true);
          // Clear predictions and notify selection
          setPredictions([]);
          onPlaceSelected(place);
        }
      });
    }
  };

  useEffect(() => {
    if (isAddressUpdated) {
      handleSearchArrivalAirport(indexNumber);
      setIsAddressUpdated(false);
    }
  }, [isAddressUpdated, indexNumber]);

  const handleClearInputFields = (indexNumber: number) => {
    setAddress((prevDetails) => ({
      ...prevDetails,

      airPort: prevDetails.airPort.map((airport: any, index: number) =>
        index === indexNumber
          ? {
              ...airport,
              arrivalCityDetails: "",
              arrival: "",
              newArrivalCitySearch: true,
              arrivalAirportFullName: "",
              arrivalSelectedLocation: {
                city: "",
                state: "",
                country: "",
                latitude: 0,
                longitude: 0,
                countryCode: "",
              },
            }
          : airport
      ),
    }));

    // Optionally clear predictions
    setPredictions([]);
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <LoadScript
          googleMapsApiKey={GOOGLE_MAP_API_KEY}
          libraries={libraries}
          onLoad={() => setScriptLoaded(true)}
        />
      </div>

      {scriptLoaded && (
        <div className={styles.inputBox}>
          <div className={styles.inputContainer}>
            {isMobile && loginCreateAccountState.flag === true ? (
              <div className={styles.inputWrapper}>
                <input
                  type="text"
                  value={address}
                  onChange={handleChange}
                  placeholder="Enter city name"
                  className={styles.inputField1}
                />
                {address && (
                  <img
                    src={updatedCloseIcon}
                    alt="Close"
                    className={styles.closeIcon}
                    onClick={() => handleClearInputFields(indexNumber)}
                  />
                )}
              </div>
            ) : (
              <div className={styles.inputWrapper}>
                <input
                  type="text"
                  value={address}
                  onChange={handleChange}
                  placeholder="Enter city name"
                  className={styles.inputField}
                />
                {address && (
                  <img
                    src={updatedCloseIcon}
                    alt="Close"
                    className={styles.closeIcon}
                    onClick={() => handleClearInputFields(indexNumber)}
                  />
                )}
              </div>
            )}
          </div>

          {predictions.length > 0 && (
            <div className={styles.card}>
              {isMobile && loginCreateAccountState.flag === true ? (
                <div className={styles.listCard1}>
                  <ul className={styles.ulList}>
                    {predictions.map((prediction) => (
                      <li
                        key={prediction.place_id}
                        onClick={() => handleSelect(prediction.place_id)}
                        className={styles.lists}>
                        {prediction.description}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className={styles.listCard}>
                  <ul className={styles.ulList}>
                    {predictions.map((prediction) => (
                      <li
                        key={prediction.place_id}
                        onClick={() => handleSelect(prediction.place_id)}
                        className={styles.lists}>
                        {prediction.description}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AirportMultiCityArrivalAutoCompleteAddress;
