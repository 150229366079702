import { Avatar, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { personaliseIcon, ROUTES, updatedCloseIcon } from "../../constant";
import { StateContext } from "../../context/globalContext/context";
import React from "react";
import { iGlobalContext } from "../../context/globalContext/interface";
import { Box } from "@mui/system";
import { DialogSelected } from "../../constant/common";
import EditButton from "./editBtn/editBtn";
import formatPhoneNumber from "../../hooks/phoneFormater";
import Login from "../login/login";
import styles from "./profile.module.scss";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
interface iProps {
  onClick: () => void;
}

function Profile({ onClick }: iProps): JSX.Element {
  const navigate = useNavigate();
  const state = React.useContext(StateContext);
  const {
    userInfo,
    isMobile,
    setLoginCreateAccountState,
    userDetails,
  }: iGlobalContext = state;

  const handleEditAccount = () => {
    triggerGAEvent(GA_LABEL_ENUMS.home_edit_profile_click);
    if (isMobile) {
      setLoginCreateAccountState({
        flag: true,
        page: DialogSelected.EDIT_ACCOUNT,
      });
      onClick();
    } else {
      setLoginCreateAccountState({
        flag: true,
        page: DialogSelected.EDIT_ACCOUNT,
      });
    }
  };
  const handleEditPreference = () => {
    triggerGAEvent(GA_LABEL_ENUMS.home_preferences_click);
    if (isMobile) {
      navigate(ROUTES.PreviewPreference);
      onClick();
    } else {
      setLoginCreateAccountState({
        flag: true,
        page: DialogSelected.EDIT_PREFER,
      });
    }
  };
  return (
    <div className={styles.main}>
      <div
        className={`${userInfo.loggedIn ? styles.container : styles.inActiveContainer}`}>
        <div
          className={styles.closeCard}
          onClick={() => {
            triggerGAEvent(GA_LABEL_ENUMS.side_menu_x_close_button_click);
          }}>
          <div>
            {" "}
            <Login closeDrawer={() => onClick()} />
          </div>
          <Button
            className={styles.closeButton}
            endIcon={
              <img src={updatedCloseIcon} alt="" className={styles.closeIcon} />
            }
            onClick={onClick}>
            Close
          </Button>
        </div>
        {userInfo.loggedIn && (
          <Box className={styles.profileCard}>
            <div className={styles.profileBox}>
              <Avatar
                alt=""
                src={userInfo.profile_pic ? userInfo.profile_pic : ""}
                sx={{
                  width: 40,
                  height: 40,
                }}
                className={styles.avatar}
              />
              {(userDetails || userInfo) && (
                <div className={styles.profileInfo}>
                  {userDetails.name ? (
                    <p className={styles.userName}>
                      {userDetails.name.length < 15
                        ? userDetails.name
                        : userDetails.name.slice(0, 14) + "..."}
                    </p>
                  ) : (
                    <p className={styles.userName}>
                      {userInfo.name.length < 15
                        ? userInfo.name
                        : userInfo.name.slice(0, 14) + "..."}
                    </p>
                  )}
                  {userDetails.email ? (
                    <p className={styles.email}>
                      {userDetails.email.length < 20
                        ? userDetails.email
                        : userDetails.email.slice(0, 19) + "..."}
                    </p>
                  ) : (
                    <p className={styles.email}>
                      {userInfo.email.length < 20
                        ? userInfo.email
                        : userInfo.email.slice(0, 19) + "..."}
                    </p>
                  )}

                  {userDetails.phone ? (
                    <p className={styles.number}>
                      {formatPhoneNumber(userDetails.phone)}
                    </p>
                  ) : (
                    <p className={styles.number}>
                      {formatPhoneNumber(userInfo.phone)}
                    </p>
                  )}
                  {/* <p className={styles.address}>Washington, DC</p> */}
                </div>
              )}
            </div>
            <div className={styles.editCard}>
              {" "}
              <EditButton onClick={handleEditAccount} />
            </div>
          </Box>
        )}
        {isMobile ? (
          <div className={styles.persBtnCard}>
            {/* <Button
              onClick={handleEditPreference}
              variant="text"
              endIcon={
                <img
                  src={personaliseIcon}
                  alt=""
                  className={styles.arrowIcon}
                />
              }>
              <span className={styles.personaliseBtn}>preferences</span>
            </Button> */}
          </div>
        ) : (
          <div className={styles.DeskBtnCard}>
            <Button
              onClick={handleEditPreference}
              variant="text"
              startIcon={
                <img
                  src={personaliseIcon}
                  alt=""
                  className={styles.arrowDeskIcon}
                />
              }>
              <span className={styles.deskBtn}>preferences</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
