import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function saveItineraryDetailsAPI(
  tripName: string,
  id: string,
  userId: string,
  metaData: any,
  status: string | null,
  numberOfDaysSelected: number,
  year: any,
  selectedMonth: any,
  is_admin_created: boolean,
  // hotelTransactionId: string,
  // hotelItinerayId: string,
  // flightTransactionId: string,
  // flightItinerayId: string,
  // startDate: string,
  // endDate: string,
  data?: any
) {
  const URL = `${ENDPOINT}/app/v1/users/itineraries`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  let body = {
    tripName,
    id,
    userId,
    metaData,
    status,
    numberOfDaysSelected,
    year,
    selectedMonth,
    is_admin_created,
    // hotelTransactionId,
    // hotelItinerayId,
    // flightTransactionId,
    // flightItinerayId,
    // startDate,
    // endDate,
    data,
  };
  try {
    if (!userId) return;
    const response = await axios.post(URL, body, config);
    // console.log(response);
    return response?.data;
  } catch (error) {
    // console.log(error);
  }
}
