import React from "react";
import { Stack } from "@mui/material";
import styles from "./editPromptDialog.module.scss";
import cross from "../../../assets/white-close-line.svg";
import CustomButton from "../../reusableComponent/customButton/CustomButton";

export default function EditPromptDialog({
  handleCancel,
  onSubmit,
  prompt = "Hello world",
  onChangePrompt,
}: {
  handleCancel: () => void;
  onSubmit?: () => void;
  prompt?: string;
  onChangePrompt?: (e: any) => void;
}) {
  return (
    <Stack
      zIndex={1}
      sx={{ minHeight: "100dvh" }}
      spacing={6}
      alignItems="center"
      justifyContent="center"
      justifySelf="center"
      alignSelf="center">
      {/* <pre style={{ backgroundColor: "white", wordBreak: 'break-word' }}>
            {JSON.stringify(test, null, 2)}
        </pre> */}

      <Stack
        sx={{ maxWidth: "350px", transform: "translateY(-30px)" }}
        justifyContent="center"
        alignItems="center">
        <h1 style={{ visibility: "hidden", transform: "translateY(-100px)" }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
          laboriosam
        </h1>
        <Stack sx={{ width: "100%" }} px={0.5}>
          <Stack alignSelf="end" mb={1}>
            <img
              onClick={handleCancel}
              style={{ width: "35px", cursor: "pointer" }}
              src={cross}
              alt="cross"
            />
          </Stack>

          <div className={styles.container}>
            <label className={styles.label}>Prompt</label>
            <textarea
              onChange={onChangePrompt}
              value={prompt}
              className={styles.textArea}
              rows={4}
            />
            <div className={styles.button}>
              <CustomButton buttonText="Submit" handleClick={onSubmit} />
            </div>
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
}
