import { ENDPOINT } from "../environment";

// Base API configuration
const apiVersion = "/app/v1/";
const baseUrl = `${ENDPOINT}${apiVersion}`;

// Centralized API configuration
export const ApiConfig = {
  baseUrl,
  getFullUrl: (endpoint: string, params?: Record<string, string | number>) => {
    let resolvedEndpoint = endpoint;
    if (params) {
      Object.keys(params).forEach((key) => {
        resolvedEndpoint = resolvedEndpoint.replace(
          `:${key}`,
          String(params[key])
        );
      });
    }
    return `${baseUrl}${resolvedEndpoint}`;
  },
};

// Endpoints with static and dynamic options
export const endPoints = {
  //booking
  flightAvailability: "flight/check/:encryptedKey-:uniqueId",
  flightDetails: "flight/:encryptedKey-:uniqueId",
  flightSearch: "flight/search",
  hotelSearch: "hotel/search",
  airports: "flight/airports",
  hotelDetail: (hotelId: string) => `hotel/${hotelId}`,
  // preferences
  preference: (userId: string) => `users/preferences?userId=${userId}`,
  updatePreference: "users/preferences",
  categories: "config/categories",
  //user
  userDetails: (userId: string) => `users/${userId}`,
  createUser: "users",
  updateUserDetails: "users",
  deleteUser: (userId: string) => `users/${userId}`,
  //authentication
  sendResetPassword: "users/passwordlink",
  resetPassword: "users/password",
  getUserDetailByToken: (token: string) => `users/detailBytoken?token=${token}`,
  login: "auth/login",
  logout: "auth/logout",
  //itinerary
  getItineraryDetails: (id: string) => `itineraries?id=${id}`,
  getItineraryImageURL: (cityName: string) =>
    `itineraries/getImage?imageName=${cityName}`,
  saveItineraryDetails: "users/itineraries",
  deleteItinerary: (id: string) => `itineraries/delete?id=${id}`,
  createItinerary: "createItenery",
  itinerarySearch: "itineraries/search",
  getItineraryBudget: (cityName: string) => `budget/${cityName}`,
  deleteHistory: (id: string) => `users/history/${id}`,

  //trip related urls
  frequentlyViewedTrips: "itinerariesLatest",
  updateTripPlan: "updateTripPlan",
  getTripPlanForNDay: "planTripFormAi",
  // chat related urls
  chatInteraction: "itineraries/search",

  // prompt related urls
  getPrompt: (key: string) => `itineraries/getPromptbykey?key=${key}`,
  dayPlanningPrompt: "dayplanningprompt",

  //config
  config: "config",

  // fetch countries
  county: "county",
};
