import * as React from "react";
import styles from "./customPopover.module.scss";
import { Button, Popover } from "@mui/material";

interface CustomPopoverProps {
  popoverTrigger?: React.ReactNode;
  popoverContent: React.ReactNode;
  handleApplyButton: () => void;
  isPopoverOpen: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CustomPopover({
  popoverTrigger,
  popoverContent,
  handleApplyButton,
  isPopoverOpen = false,
  setIsPopoverOpen,
}: CustomPopoverProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };  

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div
        className={styles.anchorElement}
        aria-describedby={id}
        onClick={handleClick}>
        {popoverTrigger}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        <div className={styles.mainContainer}>
          {popoverContent}
          <Button
            className={styles.button}
            onClick={() => {
              handleApplyButton();
              handleClose();
            }}>
            APPLY
          </Button>
        </div>
      </Popover>
    </div>
  );
}
