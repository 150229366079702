import React, { ReactNode } from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import styles from "./routeClassPopover.module.scss";
import CustomChip from "../../../reusableComponent/customChip/CustomChip";
import { BookingDetails } from "../../../../types";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";

export default function RouteClassPopover({
  popoverTrigger,
  route,
  setRoute,
  classData,
  setClass,
  bookingDetails,
  setBookingDetails,
  isPopoverOpen,
  setIsPopoverOpen,
}: {
  popoverTrigger: ReactNode;
  route: any;
  setRoute: any;
  classData: any;
  setClass: any;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  isPopoverOpen: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  function handleChangeClass(id: any) {
    triggerGAEvent(GA_LABEL_ENUMS.booking_route_and_class_class_click);
    const updatedClassData = classData.map((ele: any) =>
      ele.id === id
        ? { ...ele, checkedFlag: true }
        : { ...ele, checkedFlag: false }
    );

    const selectedClass = updatedClassData.find((ele: any) => ele.checkedFlag);

    let flightClass = "";
    if (selectedClass) {
      switch (selectedClass.value.trim()) {
        case "Economy":
          flightClass = "E";
          break;
        case "Premium Plus":
          flightClass = "P";
          break;
        case "Business":
          flightClass = "B";
          break;
        case "First Class":
          flightClass = "F";
          break;
        default:
          flightClass = "";
      }
    }

    setClass(updatedClassData);
    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      flightClass,
      update: true,
    }));
  }

  function handleChangeRoute(id: any) {
    const updatedRouteData = route.map((ele: any) =>
      ele.id === id
        ? { ...ele, checkedFlag: true }
        : { ...ele, checkedFlag: false }
    );

    const selectedRoute = updatedRouteData.find((ele: any) => ele.checkedFlag);
    let tripType = 1;
    if (selectedRoute) {
      triggerGAEvent(GA_LABEL_ENUMS.booking_route_and_class_route_click);
      switch (selectedRoute.value.trim()) {
        case "One-Way":
          tripType = 1;
          break;
        case "Round-Trip":
          tripType = 2;
          break;
        case "Multi-City":
          tripType = 3;
          break;
        default:
          tripType = 1;
      }
    }
    setRoute(updatedRouteData);
    setBookingDetails((prevData) => ({
      ...prevData,
      tripType,
      update: true,
    }));
  }

  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={
        <div className={styles.mainContainer}>
          <h1 className={styles.heading}>Route</h1>
          <div className={styles.routeCard}>
            {route?.length > 0 &&
              route.map((ele: any) => (
                <CustomChip
                  key={ele.id}
                  onClick={() => handleChangeRoute(ele.id)}
                  label={ele.value}
                  selected={ele.checkedFlag}
                />
              ))}
            <br />
          </div>
          <p className={styles.info}>This is determined by your itinerary</p>
          <h1 className={styles.heading}>Class</h1>
          <div className={styles.classChipContainer}>
            {classData?.length > 0 &&
              classData.map((ele: any) => (
                <div key={ele.id}>
                  <CustomChip
                    onClick={() => handleChangeClass(ele.id)}
                    label={ele.value}
                    selected={ele.checkedFlag}
                  />
                </div>
              ))}
          </div>
        </div>
      }
      handleApplyButton={() => {
        triggerGAEvent(GA_LABEL_ENUMS.booking_route_and_class_apply_click);
      }}
    />
  );
}
