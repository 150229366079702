export enum WeatherTypes {
  Hot = "hot",
  Rain = "rain",
  Snow = "snow",
  Sleet = "sleet",
  Hail = "hail",
  Drizzle = "drizzle",
  Sunny = "sunny",
  Humidity = "humidity",
  Thunderstorm = "thunderstorm",
  Fog = "fog",
  Cloudy = "cloudy",
  Windy = "windy",
  Hurricane = "hurricane",
  Tornado = "tornado",
  FLight = "flight"
}

export enum NotificationTypes {
  FLight = "flight",
  Weather = "weather"
}
