import React from "react";
import styles from "./aboutProperty.module.scss";

interface iAboutPropertyArr {
  title: string;
  description: string;
}

interface iProps {
  aboutPropertyArr: iAboutPropertyArr[];
}

function AboutProperty(props: iProps): JSX.Element {
  const { aboutPropertyArr = [] } = props;
  return (
    <div className={styles.aboutPropertyContainer}>
      {aboutPropertyArr.map((item, idx) => {
        const { title: heading = "", description = "" } = item || {};
        return (
          <div className={styles.aboutRow} key={idx}>
            <span className={styles.heading}>{heading}</span>
            <span className={styles.description}>{description}</span>
          </div>
        );
      })}
    </div>
  );
}

export default AboutProperty;
