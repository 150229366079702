import React, { useCallback, useContext, useEffect } from "react";
import styles from "./paymentFailure.module.scss";
import { backIcon, bookedSuccesIcon, ROUTES } from "../../constant";
import { TOAST_MESSAGES } from "../../constant/toastMessages";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { ScreenTypes } from "../../enums";
import Navbar from "../../component/desktopLandingPage/navbar/navbar";

export default function PaymentFailure() {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    setFirstScreen,
    setScreen,
    setIsUserNavigatingFromPaymentGateway,
    isMyTripsTheFirstSelectedScreen,
  }: iGlobalContext = state;

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  return (
    <>
      {!isMobile && <Navbar />}
      <div className={styles.successContainer}>
        <div className={styles.closeBtnContainer}>
          <Button
            className={`${styles.columnAlignButton}`}
            onClick={() => {
              // // console.log(window.history);
              if (!isMobile) {
                setIsUserNavigatingFromPaymentGateway(true);
                navigate(ROUTES.Home);
                if (isMyTripsTheFirstSelectedScreen)
                  setFirstScreen(ScreenTypes.MyTrips);
                if (!isMyTripsTheFirstSelectedScreen)
                  setFirstScreen(ScreenTypes.topDestination);
                setScreen(ScreenTypes.TravellerInfo);
              }
              // setActiveTabIndex(3);
              if (isMobile) navigate(ROUTES.TravelerInformation);
            }}
          >
            <img src={backIcon} alt="Close" />
            <p>BACK</p>
          </Button>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.booking}>
            <img src={bookedSuccesIcon} alt="booked" />
            <h1>Payment Failure!</h1>
          </div>
          <p className={styles.informationText}>
            Please check your credit card details, contact your card issuer, or
            try a different payment method.
          </p>
          <p className={styles.informationText}>
            Go back to the previous screen to try again.
          </p>
        </div>
      </div>
    </>
  );
}
