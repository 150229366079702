import { planeEnginesLight, bedFontLight, flightBed } from "../../../constant";
import { ISelectedOptions } from "../../../types";
import styles from "./selected.module.scss";

interface iProps {
  selectedOptions: ISelectedOptions;
  selectTitle?: string;
  flightData?: any;
}

function SelectedOptions({
  selectedOptions,
  selectTitle,
  flightData,
}: iProps): JSX.Element {
  return (
    <div className={styles.main}>
      <section className={styles.container}>
        {selectedOptions.flights && !selectedOptions.hotels && (
          <>
            {flightData && (
              <div className={styles.headerCard}>
                <div className={styles.imgCard}>
                  <img
                    src={planeEnginesLight}
                    alt=""
                    className={styles.imgTag}
                  />
                  <p className={styles.title}>Flights</p>
                </div>
                {selectTitle && (
                  <>
                    {flightData.length > 0 && (
                      <p className={styles.selectPac}>
                        Select a <br />
                        {selectTitle}
                      </p>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
        {selectedOptions.hotels && !selectedOptions.flights && (
          <div className={styles.headerCard}>
            <div className={styles.imgCard}>
              <img src={bedFontLight} alt="" className={styles.imgTag} />
              <p className={styles.title}>Hotels</p>
            </div>
            {selectTitle && (
              <h1 className={styles.selectPac}>
                Select a <br />
                {selectTitle}
              </h1>
            )}
          </div>
        )}
        {selectedOptions.flights && selectedOptions.hotels && (
          <div className={styles.headerCard}>
            <div className={styles.imgCard}>
              <img src={flightBed} alt="" className={styles.imgTag} />
              <p className={styles.title}>Packages</p>
            </div>
            {selectTitle && (
              <p className={styles.selectPac}>
                Select a <br />
                {selectTitle}
              </p>
            )}
          </div>
        )}
      </section>
    </div>
  );
}

export default SelectedOptions;
