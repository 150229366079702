import styles from "./selected.module.scss";
import closeIcon from "../../../assets/close-line.svg";
import SavedItineraryDetail from "../../../routes/savedItineraryDetails/SavedItineraryDetail";
import React from "react";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { iAppConfig } from "../../../types";
import { APP_CONFIG } from "../../../constant/common";

function SelectedDestination(): JSX.Element {
  const state = React.useContext(StateContext);
  const {
    openDetailsSliderFlag,
    setOpenDetailsSliderFlag,
    appConfig,
  }: iGlobalContext = state;
  const handleClose = () => {
    setOpenDetailsSliderFlag(!openDetailsSliderFlag);
  };
  return (
    <div className={styles.main}>
      {openDetailsSliderFlag ? (
        <div className={styles.content}>
          <div className={styles.closeCard}>
            <img
              src={closeIcon}
              alt=""
              className={styles.closeIcon}
              onClick={handleClose}
            />
          </div>
          <SavedItineraryDetail />
        </div>
      ) : (
        <div className={styles.container}>
          <div
            className={styles.constText}
            dangerouslySetInnerHTML={{
              __html:
                appConfig?.length > 0
                  ? appConfig?.filter(
                      (ele: iAppConfig) =>
                        ele.key === APP_CONFIG.HOME_CONSTANT_3
                    )[0]?.value
                  : "",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default SelectedDestination;
