export const itinerary_details_events = {
  itinerary_details_back_click: {
    category: "User navigates back to Itinerary listing page",
    action: "User clicks on back button",
    label: "itinerary-details-back-click",
  },
  itinerary_details_save_click: {
    category: "Save itinerary popup will open",
    action: "User clicks on save button",
    label: "itinerary-details-save-click",
  },
  itinerary_details_save_itinerary_click: {
    category: "Users can save the trip ",
    action: "User clicks on save itinerary button",
    label: "itinerary-details-save-itinerary-click",
  },
  itinerary_details_save_itinerary_Xclose_click: {
    category: "Allows user to close the popup",
    action: "User clicks on x close button",
    label: "itinerary-details-save-itinerary-Xclose-click",
  },
  itinerary_details_share_click: {
    category: "Allows user to share the itinerary",
    action: "User clicks on share button",
    label: "itinerary-details-share-click",
  },

  itinerary_details_book_click: {
    category: "User navigates to booking page",
    action: "User clicks on book button",
    label: "itinerary-details-book-click",
  },
  itinerary_details_modify_activities_click: {
    category: "User can modify the activity",
    action: "User clicks on modify icon",
    label: "itinerary-details-modify-activities-click",
  },

  itinerary_details_activities_done_click: {
    category: "User can update the modified activity",
    action: "User clicks on done icon",
    label: "itinerary-details-activities-done-click",
  },

  itinerary_details_flight_details_accordion_click: {
    category: "It includes all the flight-related(departure and arrival) details",
    action: "User clicks on the flight accordion icon ",
    label: "itinerary-details-flight-details-accordion-click",
  },

  itinerary_details_accordion_click:{
    category: "User clicks on the accordion icon ",
    action: "It includes all the day activity details",
    label: "itinerary-details-accordion-click",

  },
  itinerary_details_input_field_click: {
    category: "User can modify/update the itinerary trip",
    action: "User clicks on prompt input send button",
    label: "itinerary-details-input-field-clickk",
  },
  itinerary_details_input_field_stop_click:{
    category: "User can stops the prompt request",
    action: "User clicks on stop button",
    label: "itinerary-details-input-field-stop-click ",
  },
  itinerary_details_input_field_mic_send_click:{
    category: "User clicks on mic button",
    action: "User record the prompt by using voice recording",
    label: "itinerary-details-input-field-mic-send-click ",
  },
  itinerary_details_input_field_mic_stop_click: {
    category: "User can stop the voice recording",
    action: "User clicks on mic button to stop the search",
    label: "itinerary-details-input-field-mic-stop-click",
  }



  


};
