// Location Data
export const climateData: any = [
    {
        id: 1,
        data: "Rainforest",
        check: false
    },
    {
        id: 2,
        data: "Tropical Savannah",
        check: false
    },
    {
        id: 3,
        data: "Tropical",
        check: false
    },
    {
        id: 4,
        data: "Humid Subtropical",
        check: false
    },
    {
        id: 5,
        data: "Oceanic",
        check: false
    },
    {
        id: 6,
        data: "Mediterranean",
        check: false
    },
    {
        id: 7,
        data: "Desert",
        check: false
    },
    {
        id: 8,
        data: "Steppe (Grassland)",
        check: false
    },
    {
        id: 9,
        data: "Subartic",
        check: false
    },
    {
        id: 10,
        data: "Tundra",
        check: false
    },
    {
        id: 11,
        data: "Mountains",
        check: false
    },
    {
        id: 12,
        data: "Rivers",
        check: false
    }
]

// Population Data
export const populationData: any = [
    {
        id: 1,
        data: 'Big City',
        check: false
    },
    {
        id: 2,
        data: 'Urban',
        check: false
    },
    {
        id: 3,
        data: 'Suburbs',
        check: false
    },
    {
        id: 4,
        data: 'Small City',
        check: false
    },
    {
        id: 5,
        data: 'Village / Hamlet',
        check: false
    },
    {
        id: 6,
        data: 'Rural',
        check: false
    },
    {
        id: 7,
        data: 'Remote',
        check: false
    }
]

// Experience Data

export const experienceData: any = [
    {
        id: 1,
        data: 'Historical Sights',
        check: false
    },
    {
        id: 2,
        data: 'Nightlife',
        check: false
    },
    {
        id: 3,
        data: 'Unique Foods',
        check: false
    },
    {
        id: 4,
        data: 'Hiking',
        check: false
    },
    {
        id: 5,
        data: 'Festivals',
        check: false
    },
    {
        id: 6,
        data: 'Major / Special Events',
        check: false
    },
    {
        id: 7,
        data: 'Biking',
        check: false
    },
    {
        id: 8,
        data: 'Theme Parks',
        check: false
    },
    {
        id: 9,
        data: 'Beaches',
        check: false
    },
    {
        id: 10,
        data: 'Nature',
        check: false
    },
    {
        id: 11,
        data: 'Boating',
        check: false
    },
    {
        id: 12,
        data: 'Fishing',
        check: false
    },
    {
        id: 13,
        data: 'Water Sports',
        check: false
    }
]


export const monthsData = [
    {
        id: 1000,
        data: "Anytime",
        check: true
    },
    {
        id: 3,
        data: "April",
        check: false,
    },
    {
        id: 4,
        data: "May",
        check: false,
    },

    {
        id: 5,
        data: "June",
        check: false,
    },
    {
        id: 6,
        data: "July",
        check: false,
    },
    {
        id: 7,
        data: "August",
        check: false,
    },
    {
        id: 8,
        data: "September",
        check: false,
    },
    {
        id: 9,
        data: "October",
        check: false,
    },
    {
        id: 10,
        data: "November",
        check: false,
    },
    {
        id: 11,
        data: "December",
        check: false,
    },
    {
        id: 0,
        data: "January",
        check: false,
    },
    {
        id: 1,
        data: "February",
        check: false,
    },
    {
        id: 2,
        data: "March",
        check: false,
    },
]

export const accomodationsData = {
    title: "Accomodations",
    tag: [
        {
            id: 1,
            data: "Boutique Hotels",
            check: false
        },
        {
            id: 2,
            data: "Large Hotels",
            check: false
        },
        {
            id: 3,
            data: "Motels",
            check: false
        },
        {
            id: 4,
            data: "Bed & Breakfast",
            check: false
        },
        {
            id: 5,
            data: "Guest Houses",
            check: false
        },
        {
            id: 6,
            data: "Home Stays",
            check: false
        },
        {
            id: 7,
            data: "Resorts",
            check: false
        },
        {
            id: 8,
            data: "All-Inclusive",
            check: false
        },
        {
            id: 9,
            data: "Chalets",
            check: false
        },
        {
            id: 10,
            data: "Hostels",
            check: false
        },
        {
            id: 11,
            data: "Cottages",
            check: false
        },
        {
            id: 12,
            data: "Cabins",
            check: false
        },
        {
            id: 13,
            data: "Tiny Homes",
            check: false
        },
        {
            id: 14,
            data: "Trailers",
            check: false
        },
        {
            id: 15,
            data: "Campers & RVs",
            check: false
        },
        {
            id: 16,
            data: "Tents & Yurts",
            check: false
        },
        {
            id: 17,
            data: "Apartments",
            check: false
        },
        {
            id: 18,
            data: "Condos",
            check: false
        },
        {
            id: 19,
            data: "Extended Stay Hotels",
            check: false
        },
        {
            id: 20,
            data: "Boats",
            check: false
        },
    ]
}

export const foodPreferencesData = {
    title: "Food preferences",
    tag: [
        {
            id: 1,
            data: "Vegetarian",
            check: false
        },
        {
            id: 2,
            data: "Gluten-free",
            check: false
        },
        {
            id: 3,
            data: "Coeliac",
            check: false
        },
        {
            id: 4,
            data: "Dairy-free",
            check: false
        },
        {
            id: 5,
            data: "Lactose-free",
            check: false
        },
        {
            id: 6,
            data: "Vegan",
            check: false
        },
        {
            id: 7,
            data: "Paleo",
            check: false
        },
        {
            id: 8,
            data: "Pescetarian",
            check: false
        },
        {
            id: 9,
            data: "Low FODMAP",
            check: false
        },
        {
            id: 10,
            data: "Keto",
            check: false
        },
        {
            id: 11,
            data: "Halal",
            check: false
        },
        {
            id: 12,
            data: "Kosher",
            check: false
        },
        {
            id: 13,
            data: "Hindu",
            check: false
        },
        {
            id: 14,
            data: "Jain",
            check: false
        },
        {
            id: 15,
            data: "Diabetes",
            check: false
        },
    ]

}

export const budgetData = [
    // {
    //     id: 1,
    //     data: "Low",
    //     symbol: "Low",
    //     check: false
    // },
    {
        id: 2,
        data: "Economic",
        symbol: "Economic",
        check: true
    },
    {
        id: 3,
        data: "Luxury",
        symbol: "Luxury",
        check: false
    },
    {
        id: 4,
        data: "Ultra luxury",
        symbol: "Ultra",
        check: false
    },
]


export const dummyResponse = [
    {
        "cityName": "Marrakech, Morocco",
        "averagePackagePriceForExperiencing": "$2,000",
        "description": "Marrakech is a vibrant city located in the heart of the Sahara Desert. It is a popular destination for tourists looking to experience the beauty of the desert, with its exotic markets, vibrant culture, and stunning architecture.",
        "matchpercentage": "85%"
    },
    {
        "cityName": "Cape Town, South Africa",
        "averagePackagePriceForExperiencing": "$2,500",
        "description": "Cape Town is a vibrant city located on the southern tip of Africa. It is a popular destination for tourists looking to experience the beauty of the African continent, with its stunning beaches, wild safaris, and vibrant culture.",
        "matchpercentage": "90%"
    },
    {
        "cityName": "Dubai, United Arab Emirates",
        "averagePackagePriceForExperiencing": "$3,500",
        "description": "Dubai is a vibrant city located in the United Arab Emirates. It is a popular destination for tourists looking to experience the beauty of the Middle East, with its luxurious hotels, stunning beaches, and vibrant culture.",
        "matchpercentage": "95%"
    }
]





export const Accomodations = [
    "Boutique Hotels",
    "Large Hotels",
    "Motels",
    "Bed & Breakfast",
    "Guest Houses",
    "Home Stays",
    "Resorts",
    "All-Inclusive",
    "Chalets",
    "Hostels",
    "Cottages",
    "Cabins",
    "Tiny Homes",
    "Trailers",
    "Campers & RVs",
    "Tents & Yurts",
    "Apartments",
    "Condos",
    "Extended Stay Hotels",
    "Boats",

]

export const FoodPreferences = [
    "Vegetarian",
    "Gluten-free",
    "Coeliac",
    "Dairy-free",
    "Lactose-free",
    "Vegan",
    "Paleo",
    "Pescetarian",
    "Low FODMAP",
    "Keto",
    "Halal",
    "Kosher",
    "Hindu",
    "Jain",
    "Diabetes",
]


export const tripForNDaysData =
    [
        {
            "day": "Day 1",
            "activities": [
                {
                    "name": "Visit the Dudhsagar Falls",
                    "description": "Explore the majestic Dudhsagar Falls, one of India's tallest waterfalls located in the Bhagwan Mahaveer Wildlife Sanctuary."
                },
                {
                    "name": "Shop at Anjuna Flea Market",
                    "description": "Head to Anjuna beach and explore the vibrant flea market for some local souvenirs and snacks."
                },
                {
                    "name": "Try out Water Sports",
                    "description": "Go for a thrilling ride on a banana boat or jet ski, or try out parasailing and windsurfing in Goa's beautiful waters."
                }
            ]
        },
        {
            "day": "Day 2",
            "activities": [
                {
                    "name": "Visit Old Goa Churches",
                    "description": "Explore the ruins of churches built by Portuguese missionaries during the 16th century, such as Se Cathedral and Basilica of Bom Jesus."
                },
                {
                    "name": "Take a Spice Tour",
                    "description": "Visit a spice farm and learn about the different spices used in Indian cuisine, such as cardamom, coriander, and turmeric."
                },
                {
                    "name": "Relax at Vagator Beach",
                    "description": "Spend some time relaxing at Vagator beach, which is known for its stunning views of the Arabian Sea."
                }
            ]
        },
        {
            "day": "Day 3",
            "activities": [
                {
                    "name": "Explore Panaji City",
                    "description": "Explore Panaji city, which is full of colonial-style buildings, street art, and quaint cafes. Visit some of its popular attractions such as Miramar Beach and Dona Paula View Point."
                },
                {
                    "name": "Take a Dolphin Watching Cruise",
                    "description": "Go on a dolphin watching cruise off the coast of Goa to spot dolphins swimming in their natural habitat. You can also take part in other activities such as fishing or snorkeling during your cruise."
                },
                {
                    "name": "Spend Evening at Baga Beach",
                    "description": "End your trip with an evening stroll along Baga beach. Enjoy delicious seafood dishes from one of its many restaurants while watching the beautiful sunset over the Arabian Sea."
                }
            ]
        }
    ]