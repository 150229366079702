import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function getItinerariesListAPI(
  userId: string,
  filter: "upcoming" | "past" | "cancelled" | "saved" | "current",
  skip: number,
  limit: number
) {
  // const URL = `${ENDPOINT}/app/v1/users/itineraries?userId=${userId}`;
  const URL = `${ENDPOINT}/app/v1/users/itineraries?userId=${userId}&filter=${filter}&skip=${skip}&limit=${limit}`;

  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };

  try {
    if (!userId) return;
    const response = await axios.get(URL, config);
    return response?.data;
  } catch (error) {
    // console.log(error);
  }
}
