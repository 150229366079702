import {
  airConditioningIcon,
  bathIcon,
  cableTvIcon,
  carpetedIcon,
  deskIcon,
  dishwasherIcon,
  freeToileriesIcon,
  heatingIcon,
  kitchennetteIcon,
  landLineIcon,
  microWaveIcon,
  privateBathroomIcon,
  radioIcon,
  refridgeratorIcon,
  roomImg,
  safeDepositIcon,
  showerIcon,
  sofaSeatingIcon,
  teaMakerIcon,
  televisionIcon,
  toiletIcon,
  wakeUpServiceIcon,
  wheelChairIcon,
} from "../../../../constant";
import { iAmenitiesIcons } from "../../../../types";

export const ROOM_DETAILS = {
  roomImages: [roomImg, roomImg],
  sleeps: 4,
  roomName: "Deluxe Room, 1 King Beg",
  area: "26.85",
  roomDescription: "1 King Bed",
  amenities: [
    "Safe Deposit Box",
    "Shower",
    "Television",
    "Landline Phone",
    "Tea / Coffee Maker",
    "Refridgerator",
    "Bath",
    "Heating",
    "Cable TV",
    "Toilet",
    "Dishwasher",
    "Free Toiletries",
    "Kitchenette",
    "Wake Up Service / Alarm",
    "Private Bathroom",
    "Sofa / Seating Area",
    "Radio",
    "Wheelchair Accessible",
    "Desk",
    "Air Conditioning",
    "Microwave",
    "Carpeted",
  ],
};

export const roomAmenitiesIcons: iAmenitiesIcons[] = [
  {
    name: "Safe Deposit Box",
    icon: safeDepositIcon,
  },
  {
    name: "Shower",
    icon: showerIcon,
  },
  {
    name: "Television",
    icon: televisionIcon,
  },
  {
    name: "Landline Phone",
    icon: landLineIcon,
  },
  {
    name: "Tea / Coffee Maker",
    icon: teaMakerIcon,
  },
  {
    name: "Refridgerator",
    icon: refridgeratorIcon,
  },
  {
    name: "Bath",
    icon: bathIcon,
  },
  {
    name: "Heating",
    icon: heatingIcon,
  },
  {
    name: "Cable TV",
    icon: cableTvIcon,
  },
  {
    name: "Toilet",
    icon: toiletIcon,
  },
  {
    name: "Dishwasher",
    icon: dishwasherIcon,
  },
  {
    name: "Free Toiletries",
    icon: freeToileriesIcon,
  },
  {
    name: "Kitchenette",
    icon: kitchennetteIcon,
  },
  {
    name: "Wake Up Service / Alarm",
    icon: wakeUpServiceIcon,
  },
  {
    name: "Private Bathroom",
    icon: privateBathroomIcon,
  },
  {
    name: "Sofa / Seating Area",
    icon: sofaSeatingIcon,
  },
  {
    name: "Radio",
    icon: radioIcon,
  },
  {
    name: "Wheelchair Accessible",
    icon: wheelChairIcon,
  },
  {
    name: "Desk",
    icon: deskIcon,
  },
  {
    name: "Air Conditioning",
    icon: airConditioningIcon,
  },
  {
    name: "Microwave",
    icon: microWaveIcon,
  },
  {
    name: "Carpeted",
    icon: carpetedIcon,
  },
];
