import React from "react";
import CustomDialog from "../../../component/reusableComponent/customDialog/CustomDialog";
import styles from "./messagePopupDialog.module.scss";
import ChatContent from "../../topDestination/chatContent/ChatContent";
import { Dialog } from "@mui/material";

export default function MessagePopupDialog({
  open,
  onClose,
  message = "",
}: {
  open: boolean;
  onClose?: any;
  message: string;
}) {
  return (
    // <CustomDialog open={open} onClose={onClose}>
    //   <div className={styles.dialogContainer}>
    //     <ChatContent message={message} />
    //   </div>
    // </CustomDialog>
    <Dialog
      onClose={onClose}
      open={open}
      className={styles.main}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0,0,0,0.85)",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <div className={styles.container}>
        {/* <span className={styles.title}>Discard Changes?</span> */}
        {message ? (
          <span className={styles.subTitle}>{message}</span>
        ) : (
          <span className={styles.subTitle}>
            {/* Your changes will be lost if
            <br /> you do not save them! */}
              {message}
          </span>
        )}

        <div className={styles.btnCard}>
          <button className={styles.cancelBtn} onClick={onClose}>
            Cancel
          </button>
          {/* <button className={styles.discBtn} onClick={handleDiscard}>
          Discard Changes
        </button> */}
        </div>
      </div>
    </Dialog>
  );
}
