function convertToCustomTime(dateString: string): string {
  const date = new Date(dateString);
  let hours: number = date.getHours();
  let minutes: number = date.getMinutes();

  const ampm: string = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedMinutes: string =
    minutes < 10 ? "0" + minutes : minutes.toString();

  const formattedTime: string = `${hours}:${formattedMinutes}${ampm}`;

  return formattedTime;
}

export default convertToCustomTime;
