import { useContext, useEffect, useState } from "react";
import DialogPopup from "../../reusableComponent/dialogPopup/dialogPopup";
import { Stack } from "@mui/material";
import { outlinedCircleCloseIcon } from "../../../constant";
import FlightPreferences from "./flightPreferences/flightPreferences";
import HotelPreferences from "./hotelPreferences/hotelPreferences";
import styles from "./bookingPreferencesModal.module.scss";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";

interface iProps {
  isOpen: boolean;
  onClose: () => void;
}

function BookingPreferencesModal(props: iProps): JSX.Element {
  const { isOpen = false, onClose } = props;
  const [isFlightsSelected, setIsFlightsSelected] = useState(true);
  const state = useContext(StateContext);
  const { selectedFlightOptions }: iGlobalContext = state;

  useEffect(() => {
    const isFlightsSelected =
      selectedFlightOptions.flights && !selectedFlightOptions.hotels;
    setIsFlightsSelected(isFlightsSelected);
  }, [selectedFlightOptions.flights, selectedFlightOptions.hotels]);

  return (
    <DialogPopup isOpen={isOpen}>
      <Stack
        sx={{
          // minHeight: "100vh",
          mt: { sm: 0, xs: 2 },
          // justifyContent: { sm: "center", md: "center", lg: "center" },
        }}
        spacing={6}
        alignItems="center"
        justifyContent="center">
        <Stack
          sx={{
            width: { xs: "100vw" },
            maxWidth: { md: "450px" },
          }}>
          <Stack
            sx={{ width: "100%" }}
            px={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }}>
            <Stack
              width={"100%"}
              // alignSelf="end"
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexDirection={"row"}
              mb={"12.14px"}
              pr={"40px"}
              pl={"44px"}
              // sx={{
              //   opacity: "0.8",
              // }}
            >
              <p className={styles.modalHeader}>Preferences</p>
              <img
                onClick={onClose}
                style={{ width: "18.69px", cursor: "pointer", opacity: "0.8" }}
                src={outlinedCircleCloseIcon}
                alt="cross"
              />
            </Stack>

            <Stack
              sx={{
                width: "100%",
                // marginBottom: "-20px",
                maxHeight: "80vh",
                overflow: "auto",
                bgcolor: "#f8f8f8",
                borderRadius: "8px",
              }}
              px={{ md: 3.5, sm: 2, xs: 2 }}
              // py={4}
              py={{ md: 4, sm: 2.8, xs: 2 }}>
              <div className={styles.tabContainer}>
                {selectedFlightOptions.flights === true && (
                  <p
                    className={`${styles.tab} ${isFlightsSelected && styles.selectedTab}`}
                    onClick={() => setIsFlightsSelected(true)}>
                    Flights
                  </p>
                )}
                {selectedFlightOptions.hotels === true && (
                  <p
                    className={`${styles.tab} ${!isFlightsSelected && styles.selectedTab}`}
                    onClick={() => setIsFlightsSelected(false)}>
                    Hotels
                  </p>
                )}
              </div>
              <Stack px={1} mt={{ md: 2, sm: 1, xs: 0.8 }} mb={10}>
                {isFlightsSelected ? (
                  <FlightPreferences />
                ) : (
                  <HotelPreferences />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </DialogPopup>
  );
}

export default BookingPreferencesModal;
