import React from "react";
import styles from "./aboutUs.module.scss";
import { WEB_URL } from "../../constant/environment";
import { ROUTES } from "../../constant";

function AboutUs(): JSX.Element {
  return (
    <div className={styles.aboutUsContainer}>
      <iframe src={WEB_URL + ROUTES.AboutUs} title="about us" />
    </div>
  );
}

export default AboutUs;
