import {
  cloudDrizzleLightIcon,
  sunLightIcon,
  cloudBoltIcon,
  cloudBoltMoonLightIcon,
  cloudBoltSunLightIcon,
  cloudHailLightIcon,
  cloudLightIcon,
  cloudShowerHeavyLightIcon,
  cloudLigthsIcon,
  hurricaneLightIcon,
  moonStarsLightIcon,
  raindropsLightIcon,
  snowflakeLightIcon,
  sunCloudLightIcon,
  temperatureSnowLightIcon,
  temperatureSunLightIcon,
  tornadoLightIcon,
  windLightIcon,
  cloudBoltSunLight,

  cloudDrizzleLightWhiteIcon,
  sunLightWhiteIcon,
  cloudBoltWhiteIcon,
  cloudBoltMoonLightWhiteIcon,
  cloudBoltSunLightWhiteIcon,
  cloudHailLightWhiteIcon,
  cloudLightWhiteIcon,
  cloudShowerHeavyLightWhiteIcon,
  cloudLigthsWhiteIcon,
  hurricaneLightWhiteIcon,
  moonStarsLightWhiteIcon,
  raindropsLightWhiteIcon,
  snowflakeLightWhiteIcon,
  sunCloudLightWhiteIcon,
  temperatureSnowLightWhiteIcon,
  temperatureSunLightWhiteIcon,
  tornadoLightWhiteIcon,
  windLightWhiteIcon,
} from "../constant";
import { WeatherIconCode } from "../constant/common";

export function getWeatherIcon(iconCode: string) {
  switch (true) {
    case WeatherIconCode.CloudBolt.includes(iconCode):
      return cloudBoltIcon;
    case WeatherIconCode.CloudBoltMoonLight.includes(iconCode):
      return cloudBoltMoonLightIcon;
    case WeatherIconCode.CloudBoltSunLight.includes(iconCode):
      return cloudBoltSunLightIcon;
    case WeatherIconCode.CloudDrizzleLight.includes(iconCode):
      return cloudDrizzleLightIcon;
    case WeatherIconCode.CloudHailLight.includes(iconCode):
      return cloudHailLightIcon;
    case WeatherIconCode.CloudLight.includes(iconCode):
      return cloudLightIcon;
    case WeatherIconCode.CloudShowerHeavyLight.includes(iconCode):
      return cloudShowerHeavyLightIcon;
    case WeatherIconCode.CloudLigths.includes(iconCode):
      return cloudLigthsIcon;
    case WeatherIconCode.HurricaneLight.includes(iconCode):
      return hurricaneLightIcon;
    case WeatherIconCode.MoonStarsLight.includes(iconCode):
      return moonStarsLightIcon;
    case WeatherIconCode.RaindropsLight.includes(iconCode):
      return raindropsLightIcon;
    case WeatherIconCode.SnowflakeLight.includes(iconCode):
      return snowflakeLightIcon;
    case WeatherIconCode.SunCloudLight.includes(iconCode):
      return sunCloudLightIcon;
    case WeatherIconCode.SunLight.includes(iconCode):
      return sunLightIcon;
    case WeatherIconCode.TemperatureSnowLight.includes(iconCode):
      return temperatureSnowLightIcon;
    case WeatherIconCode.TemperatureSunLight.includes(iconCode):
      return temperatureSunLightIcon;
    case WeatherIconCode.TornadoLight.includes(iconCode):
      return tornadoLightIcon;
    case WeatherIconCode.WindLight.includes(iconCode):
      return windLightIcon;
    default:
      return sunLightIcon;
  }
}

export function getWeatherWhiteIcon(iconCode: string) {
  switch (true) {
    case WeatherIconCode.CloudBolt.includes(iconCode):
      return cloudBoltWhiteIcon;
    case WeatherIconCode.CloudBoltMoonLight.includes(iconCode):
      return cloudBoltMoonLightWhiteIcon;
    case WeatherIconCode.CloudBoltSunLight.includes(iconCode):
      return cloudBoltSunLightWhiteIcon;
    case WeatherIconCode.CloudDrizzleLight.includes(iconCode):
      return cloudDrizzleLightWhiteIcon;
    case WeatherIconCode.CloudHailLight.includes(iconCode):
      return cloudHailLightWhiteIcon;
    case WeatherIconCode.CloudLight.includes(iconCode):
      return cloudLightWhiteIcon;
    case WeatherIconCode.CloudShowerHeavyLight.includes(iconCode):
      return cloudShowerHeavyLightWhiteIcon;
    case WeatherIconCode.CloudLigths.includes(iconCode):
      return cloudLigthsWhiteIcon;
    case WeatherIconCode.HurricaneLight.includes(iconCode):
      return hurricaneLightWhiteIcon;
    case WeatherIconCode.MoonStarsLight.includes(iconCode):
      return moonStarsLightWhiteIcon;
    case WeatherIconCode.RaindropsLight.includes(iconCode):
      return raindropsLightWhiteIcon;
    case WeatherIconCode.SnowflakeLight.includes(iconCode):
      return snowflakeLightWhiteIcon;
    case WeatherIconCode.SunCloudLight.includes(iconCode):
      return sunCloudLightWhiteIcon;
    case WeatherIconCode.SunLight.includes(iconCode):
      return sunLightWhiteIcon;
    case WeatherIconCode.TemperatureSnowLight.includes(iconCode):
      return temperatureSnowLightWhiteIcon;
    case WeatherIconCode.TemperatureSunLight.includes(iconCode):
      return temperatureSunLightWhiteIcon;
    case WeatherIconCode.TornadoLight.includes(iconCode):
      return tornadoLightWhiteIcon;
    case WeatherIconCode.WindLight.includes(iconCode):
      return windLightWhiteIcon;
    default:
      return cloudBoltSunLight;
  }
}

