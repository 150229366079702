import styles from "./desk.module.scss";
import Navbar from "../desktopLandingPage/navbar/navbar";
import DeskTopDestinations from "./deskTopDestinations/deskTopDestinations";
import DesktopMultipleItinerary from "./deskTopMultipleItineraryDetails/deskTopMultipleItineraryDetails";
import BasicTabs from "../sideBar/tabs/tabs";
import EmptyDrawer from "../emptyDrawer/emptyDrawer";

function DeskTopDestination(): JSX.Element {
  return (
    <div className={styles.main}>
      <div className={styles.layout}>
        <Navbar />
        <div className={styles.container}>
          <div className={styles.chat}>
            <BasicTabs />
          </div>
          <div className={styles.topDest}>
            <DeskTopDestinations />
          </div>
          <div className={styles.itineraryDetails}>
            <DesktopMultipleItinerary />
          </div>
        </div>
      </div>
      <EmptyDrawer />
    </div>
  );
}

export default DeskTopDestination;
