import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const BackButtonHandler = ({ onBack }: { onBack: any }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = () => {
      if (onBack) {
        onBack();
      } else {
        navigate(-1);
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      setTimeout(() => {
        window.removeEventListener("popstate", handlePopState);
      }, 5000);
    };
  }, [navigate, onBack]);

  return null;
};

export default BackButtonHandler;
