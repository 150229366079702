import styles from "./selected.module.scss";

interface iProps {
  flight: boolean;
  hotel: boolean;
}

function SelectedInfo({ flight, hotel }: iProps) {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {hotel && !flight && (
          <p className={styles.note}>
            Select a hotel for each city of your trip to continue
          </p>
        )}
        {flight && !hotel && (
          <p className={styles.note}>
            Select a flight for each city of your trip to continue
          </p>
        )}
        {flight && hotel && (
          <p className={styles.note}>
            Select a flight and hotel for each city of your trip to continue
          </p>
        )}
      </div>
    </div>
  );
}

export default SelectedInfo;
