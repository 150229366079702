import React, { useCallback, useEffect } from "react";
import styles from "./dialogPopup.module.scss";

interface iProps {
  className?: string;
  isOpen: boolean;
  children: JSX.Element;
}
function DialogPopup(props: iProps): JSX.Element {
  const { className, isOpen = false, children } = props;
  useEffect(() => {
    if (isOpen)
      document.documentElement.style.setProperty("--hidden--", `hidden`);

    return () => {
      document.documentElement.style.setProperty("--hidden--", `auto`);
    };
  }, [isOpen]);

  if (!isOpen)
    return (
      <div
        className={`${className} ${styles.modal} ${
          isOpen ? styles.isOpen : styles.hide
        }`}
        // onClick={closeModal}
      >
        <div
          className={styles.backdrop}
          onClick={(e) => {
            e.stopPropagation();
            // closeModal();
          }}
        />
        {children}
      </div>
    );
  return (
    <div
      className={`${className} ${styles.modal} ${
        isOpen ? styles.isOpen : styles.hide
      }`}
      onTouchMove={(e) => {
        // // console.log(e);
        // e.preventDefault();
        e.stopPropagation();
      }}
      onDrag={(e) => {
        // console.log(e);
        // e.preventDefault();
        e.stopPropagation();
      }}
      onTouchStart={(e) => {
        // console.log(e);
        // e.preventDefault();
        e.stopPropagation();
      }}
      onScroll={(e) => {
        e.stopPropagation();
      }}
      //   onClick={closeModal}
    >
      {/* <div
        className={styles.backdrop}
        onClick={(e) => {
          e.stopPropagation();
        }}
      /> */}
      {children}
    </div>
  );
}

DialogPopup.defaultProps = {
  className: "",
};

export default DialogPopup;
