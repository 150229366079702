export const about_us_events = {
    about_us_back_button_click: {
      category: "Navigates back from About us page",
      action: "User clicks on back button ",
      label: "about-us-back-button-click ",
    },
    about_us_try_it_now_button_click: {
        category: "Navigates back from About us page",
      action: "User clicks on try it now button ",
      label: "about-us-try-it-now-button-click ",
    }
}