export const preview_preferences_events = {
  preview_preference_load: {
    category: "Preview preference page is loaded",
    action: "User navigates to preview preferences page",
    label: "preview-preference-load",
  },
  preview_preference_close_click: {
    category: "User discarding the changes done in preview preference page",
    action: "User clicks on preview preferences close icon",
    label: "preview-preference-close-click",
  },
  preview_preferences_edit_click: {
    category: "User changing the homecity",
    action: "User clicks on edit icon of the home city",
    label: "preview-preferences-edit-click",
  },
  preview_preferences_save_changes: {
    category: "User saves the changes done in preview preferences page",
    action: "User clicks on save changes button in preview preferences page",
    label: "preview-preferences-save-changes",
  },
};
