import AWS from "aws-sdk";
import { S3_BUCKET_CONFIG } from "../constant/environment";

// const AWS_ACCESS_KEY_ID_ENV = "AKIAQ3EGTCWKPIQFAX57";
// const AWS_SECRET_ACCESS_KEY_ENV = "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";
// const REGION = "us-east-1";
// const BUCKET_NAME = "zenvoya-uploads";

// const AWS_ACCESS_KEY_ID_ENV = "AKIA6ODU2Y3LWIVRKWPD";
// const AWS_SECRET_ACCESS_KEY_ENV = "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";
// const REGION = "us-east-1";
// const BUCKET_NAME = "zenvoya-uploads-dev-test";

const AWS_ACCESS_KEY_ID_ENV = S3_BUCKET_CONFIG.AWS_ACCESS_KEY_ID_ENV;
const AWS_SECRET_ACCESS_KEY_ENV = S3_BUCKET_CONFIG.AWS_SECRET_ACCESS_KEY_ENV;
const REGION = S3_BUCKET_CONFIG.REGION;
const BUCKET_NAME = S3_BUCKET_CONFIG.BUCKET_NAME;

AWS.config.update({
  region: REGION,
  secretAccessKey: AWS_SECRET_ACCESS_KEY_ENV,
  accessKeyId: AWS_ACCESS_KEY_ID_ENV,
});

const s3 = new AWS.S3();

// const uploadValidation = ({
//   uploadType,
//   companyId,
//   userId,
// }: {
//   uploadType?: 'profile' | 'content' | 'profileThumbnail' | 'contentThumbnail';
//   companyId?: string;
//   userId?: string;
// }): boolean => {
//   if (uploadType === 'content' || uploadType === 'contentThumbnail') {
//     if (!companyId) {
//       toaster.error('Company Id required');
//       return false;
//     }
//   }
//   if (uploadType === 'profile' || uploadType === 'profileThumbnail') {
//     if (!userId) {
//       toaster.error('User Id required');
//       return false;
//     }
//   }
//   return true;
// };

export const s3Operations = {
  getObject: (params?: any) => {
    return new Promise((resolve, reject) => {
      s3.getObject({ ...params, Bucket: BUCKET_NAME }, (error, data) => {
        if (error) reject(error.stack);
        else {
          resolve(data);
        }
      });
    });
  },
  getObjects: (params?: any) => {
    return new Promise((resolve, reject) => {
      if (BUCKET_NAME) {
        s3.listObjectsV2({ ...params, Bucket: BUCKET_NAME }, (err, data) => {
          if (err) reject(err.stack);
          else {
            resolve(data);
          }
        });
      }
    });
  },
  upload: ({
    params,
    progress,
    companyId,
    userId,
    uploadType,
    contentType,
  }: {
    params: { Key: string; Body: any };
    progress?: (progress: number) => void;
    companyId?: string;
    userId?: string;
    contentType?: string;
    uploadType?: "profile";
  }) => {
    let Key = params?.Key?.split(" ")?.join("_").toLocaleLowerCase();
    // if (uploadType === 'content' || uploadType === 'contentThumbnail') {
    //   if (!companyId) {
    //     toaster.error('Company Id required');
    //     return;
    //   }
    // }

    // if (uploadType === 'profile' || uploadType === 'profileThumbnail') {
    //   if (!userId) {
    //     toaster.error('User Id required');
    //     return;
    //   }
    // }
    // if (uploadValidation({ uploadType, userId, companyId })) return;
    if (uploadType === "profile") Key = `profile/${Key}`;
    return new Promise((resolve, reject) => {
      if (BUCKET_NAME) {
        s3.upload(
          {
            Bucket: BUCKET_NAME,
            ...params,
            Key,
            ContentType: contentType || params.Body.mimetype,
          },
          (error: any, response: any) => {
            if (error) {
              reject(error);
            } else {
              resolve(response);
            }
          }
        ).on("httpUploadProgress", ({ loaded, total }) => {
          if (progress) progress(Math.round((100 * loaded) / total));
        });
      }
    });
  },
  delete: (params: { Key: string }) => {
    return new Promise((resolve, reject) => {
      if (BUCKET_NAME) {
        s3.deleteObject(
          { Bucket: BUCKET_NAME, ...params },
          (error, response) => {
            if (error) {
              reject(error);
            } else {
              resolve(response);
            }
          }
        );
      }
    });
  },
};
