export const trip_details_events = {
  trip_details_page_load: {
    category: "User loads trip details page",
    action: "User clicks on image card",
    label: "trip-details-page-load",
  },
  trip_details_back_click: {
    category: "User navigates back to today page",
    action: "User clicks on back button",
    label: "trip-details-back-click",
  },
  trip_details_share_click: {
    category: "Users can share/copy the trip details",
    action: "User clicks on share button",
    label: "trip-details-share-click",
  },
  trip_details_new_trip_click: {
    category: "User navigates new trip page",
    action: "User clicks on new trip button",
    label: "trip-details-new-trip-click",
  },
  trip_details_daily_plans_click: {
    category:
      "Tab includes the daily plan details like flight and hotel details",
    action: "User clicks on the daily plans tab ",
    label: "trip-details-daily-plans-click",
  },
  trip_details_daily_plans_flight_accordion_click: {
    category:
      "When the flight accordion clicks, it opens the flight details section",
    action: "User clicks on the flight accordion icon ",
    label: "trip-details-daily-plans-flight-accordion-click",
  },
  trip_details_daily_plans_flight_deatils_accordion_click: {
    category: "It includes all the flight-related details",
    action: "User clicks on the details accordion icon ",
    label: "trip-details-daily-plans-flight-deatils-accordion-click",
  },
  trip_details_daily_plans_flight_collapse_accordion_click: {
    category: "It hides all the flight-related details",
    action: "User clicks on the collapse accordion icon ",
    label: "trip-details-daily-plans-flight-collapse-accordion-click",
  },
  trip_details_daily_plans_hotel_accordion_click: {
    category:
      "When the hotel accordion clicks, it opens the hotel details section",
    action: "User clicks on the hotel accordion icon ",
    label: "trip-details-daily-plans-hotel-accordion-click",
  },
  trip_details_daily_plans_hotel_view_deatils_accordion_click: {
    category: "User navigates hotel details page",
    action: "User clicks on the hotel view details accordion icon ",
    label: "trip-details-daily-plans-hotel-view-deatils-accordion-click",
  },
  trip_details_activities_click: {
    category:
      "Tab includes the daily plan details like flight and hotel details",
    action: "User clicks on the daily plans tab ",
    label: "trip-details-activities-click",
  },
  trip_details_daily_activity_accordion_click: {
    category: "User can view the daily activities ",
    action: "User clicks on the accordion icon ",
    label: "trip-details-daily-activity-accordion-click",
  },
  trip_details_activities_modify_activities_click: {
    category: "User can modify the activity",
    action: "User clicks on modify activity icon",
    label: "trip-details-activities-modify-activities-click",
  },
  trip_details_activities_done_click: {
    category: "User can update the modified activity",
    action: "User clicks on done icon",
    label: "trip-details-activities-done-click",
  },
  trip_details_flights_click: {
    category: "Tab includes the flight details",
    action: "User clicks on the flights tab ",
    label: "trip-details-flights-click",
  },
  trip_details_flights_cancel_option_click: {
    category: "It cancel the options for flight",
    action: "User clicks on the flights cancel option button",
    label: "trip-details-flights-cancel-option-click",
  },
  trip_details_flights_change_flight_click: {
    category: "It allows the user to change flight optionls",
    action: "User clicks on the change flights edit button",
    label: "trip-details-flights-change-flight-click",
  },
  trip_details_flight_details_accordion_click: {
    category: "It includes all the flight-related details",
    action: "User clicks on the details accordion icon ",
    label: "trip-details-flight-details-accordion-click",
  },
  trip_details_flight_collapse_accordion_click: {
    category: "It hides all the flight-related details",
    action: "User clicks on the collapse accordion icon ",
    label: "trip-details-flight-collapse-accordion-click",
  },
  trip_details_flight_traveler_accordion_click: {
    category: "It includes all the traveler information",
    action: "User clicks on the traveler accordion icon ",
    label: "trip-details-flight-traveler-accordion-click",
  },
  trip_details_hotel_accordion_click: {
    category:
      "When the hotel accordion clicks, it opens the hotel details section",
    action: "User clicks on the hotel accordion icon ",
    label: "trip-details-hotel-accordion-click",
  },
  trip_details_hotel_view_deatils_accordion_click: {
    category: "User navigates hotel details page",
    action: "User clicks on the hotel view details accordion icon ",
    label: "trip-details-hotel-view-deatils-accordion-click",
  },
  trip_details_hotel_cancel_option_click: {
    category: "It cancel the options for hotel",
    action: "User clicks on the hotel cancel option button",
    label: "trip-details-hotel-cancel-option-click",
  },
  trip_details_hotel_change_hotel_click: {
    category: "It allows the user to change hotel optionls",
    action: "User clicks on the change hotel edit button",
    label: "trip-details-hotel-change-hotel-click",
  },
};
