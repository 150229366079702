import { ChangeEvent, useContext, useEffect, useState } from "react";
import CustomInput from "./inputField/customInput";
import styles from "./traveler.module.scss";
import BasicSelect from "./customSelect/customSelect";
import CustomCheckbox from "../bookingCheckBox/CustomCheckbox";
import { countryCode, gender, options } from "../../../constant/dummyData";
import BookedSuccessDialog from "../../dialog/bookedSuccessDialog/BookedSuccessDialog";
import toast from "react-hot-toast";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import { emailRegex } from "../../../constant/common";
import { StateContext } from "../../../context/globalContext/context";
import {
  flyerProgramType,
  iGlobalContext,
} from "../../../context/globalContext/interface";
import { AsYouType } from "libphonenumber-js";
import { TOAST_MESSAGES } from "../../../constant/toastMessages";
import PhoneNumberValidationInputField from "../../reusableComponent/phoneNumberValidationInputField/PhoneNumberValidationInputField";
import DobSelector from "./dob-selector/dobSelector";
import { iCountryData } from "../../../interface/common";

const FormDataLocalrmationForma =
  // forwardRef(
  ({
    setShowErrorMessageFlag,
    showErrorMessageFlag,
    frequentFlyerProgram,
    specialAssistance,
    seatsPreference,
    mealsPreference,
    // ref,
    formData,
    travelerInfoArrayDataRef,
    formIndex,
    setTravelerInfoArrayDataRef,
    countriesData,
  }: {
    setShowErrorMessageFlag?: any;
    showErrorMessageFlag?: boolean;
    frequentFlyerProgram: any;
    specialAssistance: any;
    seatsPreference: any;
    mealsPreference: any;
    // ref: any;
    formData: any;
    formIndex: number;
    travelerInfoArrayDataRef: any;
    setTravelerInfoArrayDataRef: any;
    countriesData: iCountryData[];
  }) => {
    // // console.log(specialAssistance, seatsPreference, mealsPreference);

    const globalContext = useContext(StateContext);
    const {
      selectedFlightOptions,
      selectedChatData,
      flightAvailabilityData,
    }: iGlobalContext = globalContext;

    // // console.log({ selectedFlightOptions });
    const { international = false } = flightAvailabilityData || {};
    useEffect(() => {}, [countriesData]);

    const [openBookedSuccessDialog, setOpenBookedSuccessDialog] =
      useState(false);

    // useEffect(() => {
    //   // Interval for saving the data to the form array
    //   let saveFormInterval = setInterval(() => {
    //     if (travelerInfoArrayDataRef.current)
    //       localStorage.setItem(
    //         "_traveler_info_array_state",
    //         JSON.stringify(travelerInfoArrayDataRef.current)
    //       );
    //   }, 2000);
    //   return () => {
    //     clearInterval(saveFormInterval);
    //   };
    // }, []);

    const [formDataLocal, setFormDataLocal] = useState(formData);

    // const [errors, setErrors] = useState({
    //   firstName: "",
    //   lastName: "",
    //   dateOfBirth: "",
    //   gender: "",
    //   email: "",
    // });

    // useEffect(() => {
    //   setFormDataLocal(formData);
    //   return () => {
    //     // console.log("Current Reset", travelerInfoArrayDataRef.current);
    //   };
    // }, []);

    useEffect(() => {
      setTravelerInfoArrayDataRef(
        travelerInfoArrayDataRef.current.map((ele: any, index: number) => {
          if (index === formIndex) {
            // // console.log("Current", formDataLocal);
            return formDataLocal;
          } else return ele;
        })
      );
    }, [formDataLocal]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      // console.log("JUST");
      const { name, checked } = event.target;
      setFormDataLocal((prevState: any) => ({
        ...prevState,
        selectedOptions: {
          // ...prevState.selectedOptions,
          [name]: checked,
        },
      }));
    };

    // const validateFields = () => {
    //   const newErrors = {
    //     firstName: "",
    //     lastName: "",
    //     dateOfBirth: "",
    //     gender: "",
    //     email: "",
    //   };

    //   if (!formDataLocal.firstName.trim()) {
    //     newErrors.firstName = "Please enter your first name";
    //   }
    //   if (!formDataLocal.lastName.trim()) {
    //     newErrors.lastName = "Please enter your last name";
    //   }
    //   if (
    //     !formDataLocal.dateOfBirth.trim() ||
    //     !formDataLocal.dateOfYear.trim() ||
    //     !formDataLocal.dateOfBirthMonth.trim()
    //   ) {
    //     newErrors.dateOfBirth = "Please enter your complete date of birth";
    //   }
    //   if (!formDataLocal.gender.trim()) {
    //     newErrors.gender = "Please select your gender";
    //   }
    //   if (!formDataLocal.email.trim()) {
    //     newErrors.email = "Please enter your email";
    //   }

    //   setErrors(newErrors);

    //   return Object.values(newErrors).every((error) => error === "" && showErrorMessageFlag);
    // };

    // const handleSubmit = () => {
    //   if (validateFields()) {
    //     // console.log("Form submit successfully");
    //     setOpenBookedSuccessDialog(true);
    //   } else {
    //     // console.log("Error");
    //   }
    // };
    // useImperativeHandle(ref, () => ({
    //   handleSubmit,
    // }));

    // useEffect(() => {
    //   const phoneNumber = new AsYouType("US").input(formDataLocal.phoneNumber);
    //   // console.log({ phoneNumber });
    // }, [formDataLocal.phoneNumber]);

    return (
      <div className={styles.main}>
        <BookedSuccessDialog
          open={openBookedSuccessDialog}
          onClose={() => {
            setOpenBookedSuccessDialog(false);
          }}
        />
        <form className={styles.formCard}>
          <div className={styles.nameCard}>
            <CustomInput
              value={formDataLocal.firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                toast.dismiss();
                setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                  ...prevFormData,
                  firstName: e.target.value || "",
                }));
              }}
              inputName="First Name"
              mandate={true}
              width="178px"
              error={formDataLocal.firstName === "" && showErrorMessageFlag}
            />
            <CustomInput
              value={formDataLocal.middleName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                toast.dismiss();
                setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                  ...prevFormData,
                  middleName: e.target.value || "",
                }));
              }}
              inputName="Middle Name / Initial"
              mandate={false}
              placeHolder="(if shown on ID)"
              width="89px"
            />
          </div>
          <div className={styles.nameCard}>
            <CustomInput
              value={formDataLocal.lastName}
              onChange={(e) => {
                toast.dismiss();
                setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                  ...prevFormData,
                  lastName: e.target.value || "",
                }));
              }}
              inputName="Last Name"
              mandate={true}
              width="256px"
              error={formDataLocal.lastName === "" && showErrorMessageFlag}
            />
          </div>

          <div className={styles.dateCard}>
            <BasicSelect
              isPlaceholderTextColorLight
              placeholder="Month"
              options={options}
              value={formDataLocal.dateOfBirthMonth}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                toast.dismiss();
                setFormDataLocal((prevFormData: any) => ({
                  ...prevFormData,
                  dateOfBirthMonth: event.target.value,
                }));
              }}
              width="80px"
              label={"Date of Birth"}
              mandate={true}
              error={
                formDataLocal.dateOfBirthMonth === "" && showErrorMessageFlag
              }
            />
            <CustomInput
              value={formDataLocal.dateOfBirth}
              onChange={(e) => {
                toast.dismiss();
                if (!/^[0-9]*$/.test(e.target.value)) {
                  toastMessage.error(
                    TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate
                      .messageTitle,
                    TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate.message
                  );
                  return;
                }

                if (e.target.value.length > 2 || Number(e.target.value) > 31) {
                  toastMessage.error(
                    TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate
                      .messageTitle,
                    TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate.message
                  );
                  setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                    ...prevFormData,
                    dateOfBirth: e.target.value,
                  }));
                  return;
                }
                setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                  ...prevFormData,
                  dateOfBirth: e.target.value,
                }));
              }}
              placeHolder="DD"
              mandate={false}
              width="40px"
              showRequired={false}
              error={formDataLocal.dateOfBirth === "" && showErrorMessageFlag}
            />
            <CustomInput
              value={formDataLocal.dateOfYear}
              onChange={(e) => {
                const value = e.target.value;
                toast.dismiss();
                if (!/^\d*$/.test(value) || Number(value) > 9999) {
                  toastMessage.error(
                    TOAST_MESSAGES.BOOKING.TravelerInfo.invalidYear
                      .messageTitle,
                    TOAST_MESSAGES.BOOKING.TravelerInfo.invalidYear.message
                  );
                  return;
                }
                setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                  ...prevFormData,
                  dateOfYear: value,
                }));
              }}
              placeHolder="YYYY"
              mandate={false}
              width="52px"
              showRequired={false}
              error={formDataLocal.dateOfYear === "" && showErrorMessageFlag}
            />
            <div className={styles.genderContainer}>
              <BasicSelect
                isPlaceholderTextColorLight
                placeholder="Select"
                options={gender}
                value={formDataLocal.gender}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  toast.dismiss();
                  setFormDataLocal((prevFormData: any) => ({
                    ...prevFormData,
                    gender: event.target.value,
                  }));
                }}
                width="90px"
                label={"Gender"}
                mandate={true}
                error={formDataLocal?.gender === "" && showErrorMessageFlag}
              />
            </div>
          </div>
          <div className={styles.contactInfo}>
            <p className={styles.contactTitle}>Contact Info</p>
            <CustomInput
              value={formDataLocal.email}
              onChange={(e) => {
                toast.dismiss();
                setShowErrorMessageFlag(false);
                setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                  ...prevFormData,
                  email: e.target.value || "",
                }));
              }}
              placeHolder="email"
              mandate={formIndex === 0 ? true : false}
              inputName="EMAIl"
              width="250px"
              error={
                formIndex === 0 &&
                ((formDataLocal.email === "" && showErrorMessageFlag) ||
                  (!emailRegex.test(formDataLocal.email) &&
                    showErrorMessageFlag))
              }
            />
          </div>
          <div className={styles.phoneCard}>
            <BasicSelect
              showNone={false}
              placeholder="United States (+1)"
              // options={countryCode}
              options={countriesData
                ?.map(
                  (country) =>
                    `${country?.name} ${country?.code ? `(+${country?.code})` : ""}`
                )
                ?.sort()}
              selectOptionClass={styles.phoneInput}
              value={formDataLocal.countryCode}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                toast.dismiss();
                setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                  ...prevFormData,
                  countryCode: event.target.value,
                }));
              }}
              width="120px"
              label={"Country Code"}
              mandate={formIndex === 0 ? true : false}
            />
            <PhoneNumberValidationInputField
              countryCode={formDataLocal?.countryCode === "+91" ? "IN" : "US"}
              showOverlayInput={false}
              // overlayValue={new AsYouType(
              //   formDataLocal?.countryCode === "+91" ? "IN" : "US"
              // ).input(formDataLocal.phoneNumber)}
              value={formDataLocal.phoneNumber}
              // value={new AsYouType(
              //   formDataLocal?.countryCode === "+91" ? "IN" : "US"
              // ).input(formDataLocal.phoneNumber)}

              // value={new AsYouType("US").input(formDataLocal.phoneNumber)}
              onChange={(e) => {
                // if (!/^[0-9]*$/.test(e.target.value)) {
                //   toast.dismiss();
                //   toastMessage.error("Please enter valid phone number");
                //   return;
                // }
                // console.clear();
                // console.log("TARGET", e.target.value);

                // console.log("TARGET VE", e.target.value.length);

                setFormDataLocal({
                  ...formDataLocal,
                  phoneNumber: e.target.value,
                });
              }}
              formDataLocal={formDataLocal}
              setFormDataLocal={setFormDataLocal}
              mandate={formIndex === 0 ? true : false}
              inputName="Mobile Phone Number"
              // width="120px"
            />
            {/* <CustomInput
              showOverlayInput={true}
              overlayValue={new AsYouType(
                formDataLocal?.countryCode === "+91" ? "IN" : "US"
              ).input(formDataLocal.phoneNumber)}
              value={formDataLocal.phoneNumber}
              // value={new AsYouType(
              //   formDataLocal?.countryCode === "+91" ? "IN" : "US"
              // ).input(formDataLocal.phoneNumber)}
              // value={new AsYouType("US").input(formDataLocal.phoneNumber)}
              onChange={(e) => {
                // if (!/^[0-9]*$/.test(e.target.value)) {
                //   toast.dismiss();
                //   toastMessage.error("Please enter valid phone number");
                //   return;
                // }
                console.clear();
                // console.log("TARGET", e.target.value);

                // console.log(
                // "TARGET VE",
                //   e.target.value.length
                // );

                // setFormDataLocal({
                //   ...formDataLocal,
                //   phoneNumber: e.target.value,
                // });
                toast.dismiss();
                setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                  ...prevFormData,
                  phoneNumber: e.target.value,
                }));
              }}
              mandate={false}
              inputName="Mobile Phone Number"
              width="142px"
            /> */}
          </div>
          {selectedFlightOptions?.flights && international && (
            <div className={styles.flyProgramCard}>
              <p className={styles.flyTitle}>Travel Document</p>
              <div className={styles.flyBox}>
                <BasicSelect
                  placeholder="Select document type"
                  options={["Passport"]}
                  selectOptionClass={styles.programName}
                  value={formDataLocal.documentType}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    toast.dismiss();
                    setFormDataLocal((prevFormData: any) => ({
                      ...prevFormData,
                      documentType: event.target.value,
                    }));
                  }}
                  width="120px"
                  label={"Document Type"}
                  mandate={true}
                  error={
                    formDataLocal.documentType === "" && showErrorMessageFlag
                  }
                />
                <CustomInput
                  value={formDataLocal.documentNumber}
                  onChange={(e) => {
                    // if (!/^[0-9]*$/.test(e.target.value)) {
                    //   toast.dismiss();
                    //   toastMessage.error(
                    //     TOAST_MESSAGES.BOOKING.TravelerInfo.flyerProgramError
                    //       .messageTitle,
                    //     TOAST_MESSAGES.BOOKING.TravelerInfo.flyerProgramError
                    //       .message
                    //   );
                    //   return;
                    // }
                    // if (e.target.value.length < 10)
                    setFormDataLocal({
                      ...formDataLocal,
                      documentNumber: e.target.value.slice(0, 25),
                    });
                  }}
                  mandate={true}
                  inputName="Document Number"
                  width="150px"
                  error={
                    formDataLocal.documentNumber === "" && showErrorMessageFlag
                  }
                />
              </div>
              <div className={styles.flyBox}>
                <BasicSelect
                  placeholder="Select country"
                  options={countriesData
                    ?.map((country) => country?.name)
                    ?.sort()}
                  selectOptionClass={styles.programName}
                  value={formDataLocal.issuingCountry}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    toast.dismiss();
                    setFormDataLocal((prevFormData: any) => ({
                      ...prevFormData,
                      issuingCountry: event.target.value,
                    }));
                  }}
                  width="140px"
                  label={"Issuing Country"}
                  mandate={false}
                  error={
                    formDataLocal.issuingCountry === "" && showErrorMessageFlag
                  }
                />
                <BasicSelect
                  placeholder="Select nationality"
                  options={countriesData
                    ?.map((country) => country?.name)
                    ?.sort()}
                  selectOptionClass={styles.programName}
                  value={formDataLocal.nationality}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    toast.dismiss();
                    setFormDataLocal((prevFormData: any) => ({
                      ...prevFormData,
                      nationality: event.target.value,
                    }));
                  }}
                  width="140px"
                  label={"Nationality"}
                  mandate={false}
                  error={
                    formDataLocal.nationality === "" && showErrorMessageFlag
                  }
                />
              </div>
              <div className={styles.datesContainer}>
                <DobSelector
                  dobLabel="Issue Date"
                  dateValue={formDataLocal.docIssueDate}
                  monthValue={formDataLocal.docIssueMonth}
                  yearValue={formDataLocal.docIssueYear}
                  onMonthChange={(
                    event: React.ChangeEvent<HTMLSelectElement>
                  ) => {
                    toast.dismiss();
                    setFormDataLocal((prevFormData: any) => ({
                      ...prevFormData,
                      docIssueMonth: event.target.value,
                    }));
                  }}
                  onDateChange={(e) => {
                    toast.dismiss();
                    if (!/^[0-9]*$/.test(e.target.value)) {
                      toastMessage.error(
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate
                          .messageTitle,
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate.message
                      );
                      return;
                    }

                    if (
                      e.target.value.length > 2 ||
                      Number(e.target.value) > 31
                    ) {
                      toastMessage.error(
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate
                          .messageTitle,
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate.message
                      );
                      setFormDataLocal(
                        (prevFormData: typeof formDataLocal) => ({
                          ...prevFormData,
                          docIssueDate: e.target.value,
                        })
                      );
                      return;
                    }
                    setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                      ...prevFormData,
                      docIssueDate: e.target.value,
                    }));
                  }}
                  onYearChange={(e) => {
                    const value = e.target.value;
                    toast.dismiss();
                    if (!/^\d*$/.test(value) || Number(value) > 9999) {
                      toastMessage.error(
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidYear
                          .messageTitle,
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidYear.message
                      );
                      return;
                    }
                    setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                      ...prevFormData,
                      docIssueYear: value,
                    }));
                  }}
                  showErrorMessageFlag={showErrorMessageFlag}
                />
                <DobSelector
                  dobLabel="Expiration Date"
                  dateValue={formDataLocal.docExpiryDate}
                  monthValue={formDataLocal.docExpiryMonth}
                  yearValue={formDataLocal.docExpiryYear}
                  onMonthChange={(
                    event: React.ChangeEvent<HTMLSelectElement>
                  ) => {
                    toast.dismiss();
                    setFormDataLocal((prevFormData: any) => ({
                      ...prevFormData,
                      docExpiryMonth: event.target.value,
                    }));
                  }}
                  onDateChange={(e) => {
                    toast.dismiss();
                    if (!/^[0-9]*$/.test(e.target.value)) {
                      toastMessage.error(
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate
                          .messageTitle,
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate.message
                      );
                      return;
                    }

                    if (
                      e.target.value.length > 2 ||
                      Number(e.target.value) > 31
                    ) {
                      toastMessage.error(
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate
                          .messageTitle,
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate.message
                      );
                      setFormDataLocal(
                        (prevFormData: typeof formDataLocal) => ({
                          ...prevFormData,
                          docExpiryDate: e.target.value,
                        })
                      );
                      return;
                    }
                    setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                      ...prevFormData,
                      docExpiryDate: e.target.value,
                    }));
                  }}
                  onYearChange={(e) => {
                    const value = e.target.value;
                    toast.dismiss();
                    if (!/^\d*$/.test(value) || Number(value) > 9999) {
                      toastMessage.error(
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidYear
                          .messageTitle,
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidYear.message
                      );
                      return;
                    }
                    setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                      ...prevFormData,
                      docExpiryYear: value,
                    }));
                  }}
                  showErrorMessageFlag={showErrorMessageFlag}
                />
              </div>
            </div>
          )}

          {selectedFlightOptions?.flights && (
            <div className={styles.flyProgramCard}>
              <p className={styles.flyTitle}>Frequent Flyer Program</p>
              <div className={styles.flyBox}>
                <BasicSelect
                  placeholder="Select a program"
                  options={
                    frequentFlyerProgram
                      ? [
                          {
                            label: flyerProgramType.none,
                            value: "",
                          },
                          ...frequentFlyerProgram,
                        ]
                      : []
                  }
                  selectOptionClass={styles.programName}
                  value={formDataLocal.programName}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    toast.dismiss();
                    setFormDataLocal((prevFormData: any) => ({
                      ...prevFormData,
                      programName: event.target.value,
                    }));
                  }}
                  width="140px"
                  label={"Program Name"}
                  mandate={false}
                />
                <CustomInput
                  value={formDataLocal.frequentFlyerNumber}
                  onChange={(e) => {
                    // if (!/^[0-9]*$/.test(e.target.value)) {
                    //   toast.dismiss();
                    //   toastMessage.error(
                    //     TOAST_MESSAGES.BOOKING.TravelerInfo.flyerProgramError
                    //       .messageTitle,
                    //     TOAST_MESSAGES.BOOKING.TravelerInfo.flyerProgramError
                    //       .message
                    //   );
                    //   return;
                    // }
                    setFormDataLocal({
                      ...formDataLocal,
                      frequentFlyerNumber: e.target.value,
                    });
                  }}
                  disabled={
                    formDataLocal.programName === "" ||
                    formDataLocal.programName === flyerProgramType.none
                  }
                  mandate={false}
                  inputName="Frequent Flyer Number"
                  width="142px"
                />
              </div>
            </div>
          )}
          {selectedFlightOptions?.flights && (
            <div className={styles.passportCard}>
              <p className={styles.passTitle}>TSA PreCheck Number / PASS ID</p>
              <CustomInput
                value={formDataLocal.passportNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  toast.dismiss();
                  setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                    ...prevFormData,
                    passportNumber: e.target.value,
                  }));
                }}
                mandate={false}
                inputName="TSA PRECHECK NUMBER / PASS ID"
                width="180px"
              />
            </div>
          )}
          {selectedFlightOptions?.flights && (
            <div className={styles.mealCard}>
              <p className={styles.mealTitle}>Meal Preferences</p>
              <BasicSelect
                placeholder="Select meal type"
                options={
                  mealsPreference
                    ? [
                        {
                          label: flyerProgramType.none,
                          value: "",
                        },
                        ...mealsPreference,
                      ]
                    : []
                }
                selectOptionClass={styles.programName}
                value={formDataLocal.mealPref}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  toast.dismiss();
                  setFormDataLocal((prevFormData: any) => ({
                    ...prevFormData,
                    mealPref: event.target.value,
                  }));
                }}
                width="380px"
                label={"MEAL TYPE"}
                mandate={false}
              />
            </div>
          )}
          {selectedFlightOptions?.flights && (
            <div className={styles.specialNeeds}>
              <p className={styles.specialTitle}>Special Travel Needs</p>
              <div className={styles.selectCard}>
                {specialAssistance?.map((i: any, index: number) => (
                  <section key={index} className={styles.checkSection}>
                    <CustomCheckbox
                      label={i.label}
                      name={i.label}
                      handleCheckboxChange={handleChange}
                      selectedOptions={formDataLocal.selectedOptions}
                      travelerForm={true}
                    />
                  </section>
                ))}
              </div>
            </div>
          )}
        </form>
      </div>
    );
  };
// );

export default FormDataLocalrmationForma;
