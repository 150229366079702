import { tost_messages } from "../constant/toastMessages";
import { ApiInfo, ToastMessagesType } from "../types";

const useCustomErrorHandler = () => {
  const handleCustomError = (statusCode: number, apiName: ApiInfo) => {
    if (!statusCode || !apiName) {
      return;
    }
    if (statusCode === 200) {
      return;
    }

    let message = "";
    let title = "Error";

    // Context-specific prefixes
    // const contextPrefix = `${contextType}.failure`;
    const contextPrefix =
      `${apiName}.failure.${statusCode}` as `${string}.failure.${number}`;

    switch (statusCode) {
      // Client-Side
      case 400:
        message =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.message || "Bad Request";
        title =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 401:
        message =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.message || "Unauthorized";
        title =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 402:
        message =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.message || "Please get the access to proceed";
        title =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Access Required!";
        break;
      case 403:
        message =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.message || "Forbidden";
        title =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 404:
        message =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.message || "Not Found";
        title =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;

      //   Server-Side Errors
      case 500:
        message =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.message || "Internal Server Error";
        title =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 501:
        message =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.message || "Not Implemented";
        title =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 502:
        message =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.message || "Bad Gateway";
        title =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 503:
        message =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.message || "Service Unavailable";
        title =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 504:
        message =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.message || "Gateway Timeout";
        title =
          tost_messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;

      default:
        message =
          tost_messages.ApiBreakdown?.message ||
          "An unexpected error occurred.";
        title = tost_messages.ApiBreakdown?.messageTitle || "Unknown Error";
    }

    console.error(`Error (${statusCode}): ${title} - ${message}`);
    // toastMessage.error(title, message);
  };

  return { handleCustomError };
};

export default useCustomErrorHandler;
