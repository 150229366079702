export const home_and_sideBar_events = {
  home_preferences_click: {
    category: "User navigating to preferences page",
    action: "User clicks on preferences button ",
    label: "home-preferences-click",
  },
  home_help_click: {
    category: "User navigating to faq page",
    action: "User clicks on help button",
    label: "home-help-click",
  },
  home_side_bar_close_click: {
    category: "User closes to side menu",
    action: "User clicks on close icon",
    label: "home-side-bar-close-click",
  },
  home_upcoming_trip_click: {
    category: "User clicks on upcoming trip",
    action: "User clicks on upcoming trip card",
    label: "home-upcoming-trip-click",
  },
  home_side_bar_open_click: {
    category: "User clicks to open side menu",
    action: "User clicks on hamburger icon",
    label: "home-side-bar-open-click",
  },
  Itinrary_click: {
    category: "User clicks to view the iterinary",
    action: "User clicks on iterinary card",
    label: "home-itinerary-click",
  },
  Loads_Home_page: {
    // label: GA_LABEL_ENUMS.Loads_Home_page,
    category: "User Entered Home page",
    action: "User loads/enters home page",
    label: "home-load",
  },
  Clicks_Recommended_Trips_card: {
    category: "User clicks on recommended trips cards",
    action: "User clicks on suggested recommended trips cards",
    label: "home-recommended-click",
  },
  Copying_Suggested_Trips: {
    category: "User clicks on suggested trips for trying",
    action: "User copying the text from suggested trips to input field",
    label: "home-suggestedprompt-copy",
  },
  New_Trip_Click: {
    category: "User clicks on new trip button",
    action: "User clicks on new trip",
    label: "home-newtrip-copy",
  },
  Suggested_Trip_Click: {
    category: "User clicks on suggested trips from the response",
    action: "User clicks on iterinary trip card",
    label: "home-destination-click",
  },
  Send_Prompt_click: {
    category: "User sends search prompt",
    action: "User clicks on prompt send button",
    label: "home-send-click",
  },
  Stop_click: {
    category: "User stops the prompt Search",
    action: "User clicks on stop button",
    label: "home-stop-click",
  },
  Show_more_click: {
    category: "User sees more options",
    action: "User clicks on show more button",
    label: "home-showmore-click",
  },
  Logo_Click: {
    category: "User clicks on zenvoya logo to navigate to the home page",
    action: "User clicks on Zenvoya Logo",
    label: "home-logo-click",
  },
  Terms_of_service_click: {
    category: "User clicks on terms of service link",
    action: "User clicks on Terms of service in sidebar",
    label: "menu-tos-click",
  },
  Privacy_polcy_click: {
    category: "User navigates to the privacy policy",
    action: "User clicks on privacy policy button",
    label: "menu-privacy-policy-click",
  },
  Contact_us_click: {
    category:
      "User clicks on contact us from the sidebar to navigate to the form",
    action: "User clicks on Contact Us in the sidebar",
    label: "menu-contactus-click",
  },
  Login_in_click: {
    category: "User clicks on login button to open the popup",
    action: "User clicks on Login button",
    label: "menu-login-click",
  },
  Logout: {
    category: "User logs out",
    action: "User clicks on logout button",
    label: "menu-logout-click",
  },
  My_trips_click: {
    category: "User clicks on my trips from the side bar to open the my trips",
    action: "User clicks on my trips link from the side bar",
    label: "menu-mytrips-click",
  },
  Current_trips_click: {
    category: "User clicks to current trip to open the current trip",
    action: "User clicks on curretn trips link from the side bar",
    label: "menu-currenttrip-click",
  },
  History_click: {
    category: "User clicks from history list to open this trip",
    action:
      "User clicks on any one of the links from the history section from the sidebar",
    label: "menu-history-click",
  },
  History_delete_click: {
    category:
      "User clicks on delete button to delete this trip from the history",
    action:
      "User clicks on delete button on any one of the links from the history section in the sidebar",
    label: "menu-history-delete-click",
  },
};
