interface ObjType {
    id: number,
    data: string,
    check: boolean
}
interface MonthsType {
    id: number,
    data: string,
    numOfDays: string,
    year: string,
    check: boolean
}

// Processing function to create object from a Category
export function getCategoryArrayObj(category: any) {
    const array: ObjType[] = []
    const tag = category.tag
    for (let i = 0; i < tag.length; i++) {
        const obj = {
            id: i,
            data: tag[i],
            check: false
        }
        array.push(obj)
    }
    return {
        title: category.name,
        tag: array
    }
}
