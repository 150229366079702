import { captureException } from "@sentry/react";
import { iChatData, iCityDetailsResult } from "../types";
import { getSelectedOptionsStringArray } from "./recentlyViewTripsItem";
import { v4 } from "uuid";

export function getSelectedOptionsStringArrayFromAllOptions(options: any) {
  if (!options) return "No data";
  let stringArr: any = [];
  for (let i = 0; i < options.length; i++) {
    const ele = options[i]?.tag;
    const selectedOption = getSelectedOptionsStringArray(ele);
    if (selectedOption !== "") stringArr.push(selectedOption);
  }
  let string = stringArr.join(", ");
  // console.log("FREQ", stringArr);
  return string;
}

export function checkAndReturnSingularOrPluralWord(
  number: number,
  word: string
) {
  if (!number) return word;
  else if (number === 1) return number + " " + word;
  else return number + " " + word + "s";
}

// Helper function to join options of string
export function concatenateOptionsToString(array: any) {
  let temp = "";
  const tempArray = array.tag;
  for (let i = 0; i < tempArray.length; i++) {
    if (tempArray[i].check) {
      temp += tempArray[i].data + ", ";
    }
  }
  return temp.substring(0, temp.length - 2);
}

export function extractPlanTripPrompt(
  promptTemplate: string,
  city: string,
  climate: any,
  population: any,
  experiences: any,
  budget: any,
  noOfDays: number | string,
  accomodations: any,
  foodPreferences: any,
  selectedDestFlag: boolean
) {
  let climatePopulationPrompt = "";
  let accomodationsPrompt = "";
  let foodPreferencesPrompt = "";

  if (climate !== "")
    climatePopulationPrompt = ` The itinerary should include places with ${climate} in ${population} areas.`;
  if (selectedDestFlag) climatePopulationPrompt = "";
  if (accomodations !== "")
    accomodationsPrompt = `They would like to stay at (lodging) ${accomodations}.`;
  if (foodPreferences !== "")
    foodPreferencesPrompt = `They prefer ${foodPreferences} food.`;

  const replacements = {
    noOfDays,
    city,
    climatePopulationPrompt,
    accomodationsPrompt,
    foodPreferencesPrompt,
    budget,
    experiences,
  };
  const prompt = replaceWords(promptTemplate, replacements);
  // console.log("PROMPT MODIFIED", replacements, prompt);
  return prompt;
}

function replaceWords(prompt: string, replacements: any) {
  const pattern = /<([^>]+)>/g;
  prompt = prompt.replace(pattern, (match, group) => {
    const replacement = replacements[group];
    return replacement ? replacement : "";
  });
  return prompt;
}

/**
 * check if there are cities or destination list
 */
export function checkIfThereIsDestination(response: any) {
  try {
    // return "JSON_ERROR"
    const element = [
      {
        start: "<recommendationsJsonStart>",
        end: "<recommendationsJsonEnd>",
        singleTrip: false,
      },
      {
        start: "<itineraryJsonStart>",
        end: "<itineraryJsonEnd>",
        singleTrip: true,
      },
    ];
    let results: any = {};

    let startIndex = 0;
    let endIndex = 0;

    for (let i = 0; i < element.length; i++) {
      const ele = element[i];
      if (response?.data?.result.indexOf(ele.start) > 0) {
        startIndex =
          response?.data?.result.indexOf(ele.start) + ele.start.length;
        endIndex = response?.data?.result.indexOf(ele.end);
        if (ele.singleTrip) {
          if (startIndex > 0 && endIndex > 0) {
            let subString = response?.data?.result.substring(
              startIndex,
              endIndex
            );
            results = JSON.parse(subString);
          }
          console.log("HERE IT IS RESULT REAL", results);
          if (JSON.stringify(results) !== "{}") {
            response = {
              ...response,
              data: {
                ...response?.data,
                ...results,
                results: results,
                from: results?.from,
                // numberOfdays: results?.number_of_days,
                numberOfdays:
                  results?.city_wise_itinerary?.reduce(
                    (acc: number, ele: any) =>
                      ele?.city_itinerary?.length + acc,
                    0
                  ) || null,
                preText: response?.data?.result.substring(
                  0,
                  startIndex - ele.start.length
                ),
                postText: response?.data?.result.substring(
                  endIndex + ele.end.length,
                  response?.data?.result.length
                ),
                singleCity: true,
                itinerary_version: results?.itinerary_version || 1,
                // cities: [],
                cities: results?.city_wise_itinerary?.map((ele: any) => ({
                  ...ele,
                  cityName: ele?.city_name + ", " + ele?.city_country,
                  // destination_city: ele?.destination_city,
                  // destination_country: ele?.destination_country,
                  // destination_name: ele?.destination_name,
                  // city_name: ele?.city_name,
                  // city_country: ele?.city_country,
                  // city_country_code: ele?.city_country_code,
                  averagePackagePriceForExperiencing:
                    results[0]?.approximate_trip_cost,
                  description: ele?.city_itinerary_description,
                  city_itinerary: [],
                  tripDetails: ele?.city_itinerary?.map((subEle: any) => ({
                    ...subEle,
                    itinerary_by_time_of_the_day: combineDescriptionsByTime(
                      subEle?.itinerary_by_time_of_the_day
                    ),
                    // itinerary_by_time_of_the_day:
                    //   subEle?.itinerary_by_time_of_the_day.map(
                    //     (subEle_2: any) => ({
                    //       ...subEle_2,
                    //       id: v4(),
                    //     })
                    //   ),
                  })),
                  complete_itinerary_flights_cost: {
                    value: results?.complete_itinerary_flights_cost || "",
                    checkedFlag: true,
                    id: v4(),
                  },
                  complete_itinerary_hotels_cost: {
                    value: results?.complete_itinerary_hotels_cost || "",
                    checkedFlag: true,
                    id: v4(),
                  },
                })),
              },
            };
          }
        } else {
          if (startIndex > 0 && endIndex > 0) {
            let subString = response?.data?.result.substring(
              startIndex,
              endIndex
            );
            results = JSON.parse(subString);
          }
          console.log("HERE IT IS RESULT REAL", results);
          if (JSON.stringify(results) !== "{}") {
            response = {
              ...response,
              data: {
                ...response?.data,
                preText: response?.data?.result.substring(
                  0,
                  startIndex - ele.start.length
                ),
                postText: response?.data?.result.substring(
                  endIndex + ele.end.length,
                  response?.data?.result.length
                ),
                from: results?.from,
                numberOfdays: results?.number_of_days,
                cities: results?.results?.map((ele: any) => ({
                  // cityName: ele.destination_name,
                  ...ele,
                  // destination_name: ele?.destination_name || "",
                  // destination_city: ele?.destination_city || "",
                  averagePackagePriceForExperiencing: ele.approximate_trip_cost,
                  description: ele.destination_description,
                  destination_country: ele?.destination_country || "",
                })),
                singleCity: false,
              },
            };
          }
        }
      }
    }
    console.log("HERE IT IS RESULT Modified 1", response);
    return response;
  } catch (e) {
    captureException({
      e,
      result: response?.data?.result,
    });
    return "JSON_ERROR";
  }
}

function combineDescriptionsByTime(itinerary: any) {
  const result = [];

  // Create a map to hold combined descriptions by time_of_the_day
  const combined: any = {};

  itinerary?.forEach((item: any) => {
    const { time_of_the_day, description, activity_type } = item;

    // If time_of_the_day already exists in the map, combine the descriptions
    if (combined[time_of_the_day]) {
      combined[time_of_the_day].description += `\n\n${description}`;
    } else {
      // If it doesn't exist, create a new entry in the map with a unique id
      combined[time_of_the_day] = {
        id: v4(), // Generate a unique ID
        ...item
        // time_of_the_day,
        // description,
        // activity_type, // Keep the first activity_type (or modify if necessary)
      };
    }
  });

  // Convert the combined map back to an array format
  for (const key in combined) {
    result.push(combined[key]);
  }

  return result;
}

export function scrollToBottom(scrollContainerRef?: any) {
  setTimeout(() => {
    if (scrollContainerRef?.current) {
      scrollContainerRef.current.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        // behavior: 'smooth'
      });
    }
    window.scrollTo({
      top: document.documentElement.scrollHeight,
    });
  }, 10);
}

export function scrollToTop(scrollContainerRef?: any) {
  setTimeout(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        // behavior: 'smooth'
      });
    }
    window.scrollTo({
      top: 0,
    });
  }, 10);
}

export function getTripName(chatData: iChatData) {
  return chatData.destinationList.reduce(
    (acc: string, ele: iCityDetailsResult, index: number) => {
      // console.log({ ele });
      const city =
        ele?.cityName?.split(",")[0] || ele?.city_name || ele?.destination_city;
      if (index === chatData?.destinationList.length - 1) return acc + city;
      else return acc + city + ", ";
    },
    ""
  );
}

/**
 * Add itinerary version
 */

export function getItineraryVersion(
  chatData: iChatData[],
  currentItinerary: any
) {
  if (!chatData || chatData?.length === 0 || !currentItinerary) return "1";
  const reverseChatData = chatData.reverse();
  const currentCity =
    currentItinerary?.city_wise_itinerary[0]?.city_name ||
    currentItinerary?.city_wise_itinerary[0]?.destination_city ||
    "";
  const lastItinerary = reverseChatData?.find(
    (ele: iChatData) =>
      ele?.results?.city_wise_itinerary[0]?.city_name ||
      ele?.results?.city_wise_itinerary[0]?.destination_city === currentCity
  );

  // console.log({lastItinerary}, {currentCity})

  if (!lastItinerary) return "1";
  return String(Number(lastItinerary?.itinerary_version) + 1);
}
