export function formatDate(dateString: string) {
  const date = new Date(dateString);

  // Get month, day, and year
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  const year = date.getUTCFullYear();

  return `${month}/${day}/${year}`;
}

export function formatDateMMddyyyy(dateString: string) {
  const date = new Date(dateString);

  const monthName = date.toLocaleString("default", { month: "short" });
  const dateDay = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${monthName} ${dateDay}, ${year}`;
}

export function sortDatesInAscendingOrder(array: any, dateKey: string) {
  return array.sort(
    (a: any, b: any) =>
      new Date(b[dateKey]).valueOf() - new Date(a[dateKey]).valueOf()
  );
}

export function sortDatesOldtoNew(array: any[], dateKey: string) {
  return array.sort(
    (a, b) => new Date(a[dateKey]).valueOf() - new Date(b[dateKey]).valueOf()
  );
}

// retrun YYYY
export function getYear(dateStr: string) {
  const date = new Date(dateStr);
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${year}`;
}

// return DD YYYY
export function getDDYYYY(dateStr: string) {
  if (!dateStr) return "";
  const date = new Date(dateStr);
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${day}, ${year}`;
}

// Return Aug 19 UTC
export function getMonthDateUTC(timestamp: string): string {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    timeZone: "UTC", // Ensure that it uses UTC time zone
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate.toUpperCase(); // Convert the result to uppercase
}

// Return Aug 19
export function getMonthDate(dateStr: string, capitalize?: boolean) {
  if (!dateStr) return "";
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  };
  if (capitalize) return date.toLocaleDateString("en-US", options);
  else return date.toLocaleDateString("en-US", options).toUpperCase();
}

// return YYYY
export function getYearYYYY(dateStr: string) {
  if (!dateStr) return "";
  return new Date(dateStr).getUTCFullYear();
}

// Return Fri
export function getDayOfWeek(dateStr: string, type?: "long" | "short") {
  if (!type) type = "short";
  if (!dateStr) return "";
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    weekday: type === "long" ? "long" : "short",
  };
  return type === "long"
    ? date.toLocaleDateString("en-US", options)
    : date.toLocaleDateString("en-US", options).toUpperCase();
}

export function getDayOfWeekUTC(dateStr: string, type?: "long" | "short") {
  if (!type) type = "short";
  if (!dateStr) return "";
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    weekday: type === "long" ? "long" : "short",
    timeZone: "UTC",
  };
  return type === "long"
    ? date.toLocaleDateString("en-US", options)
    : date.toLocaleDateString("en-US", options).toUpperCase();
}

// Return true if two dates are same
export function isSameDate(timestamp1: string, timestamp2: string): boolean {
  // const date1 = new Date(timestamp1).toISOString().split('T')[0];
  // const date2 = new Date(timestamp2).toISOString().split('T')[0];

  const date1 = timestamp1.split("-");
  const date2 = timestamp2.split("-");
  // console.log("ISSAME DATE", date1 + " +++ " + date2);
  return (
    date1[0] === date2[0] &&
    date1[1] === date2[1] &&
    date1[2] === date2[2].substring(0, 2)
  );
}

// return time in 12 hours EG: 4:32pm
export function getTwelveHourTimeFormat(dateStr: string) {
  if (!dateStr) return "";
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  return date.toLocaleTimeString("en-US", options);
  // const date = new Date(dateStr);
  // let hours = date.getHours();
  // const minutes = date.getMinutes();
  // const amOrPm = hours >= 12 ? "PM" : "AM";
  // hours = hours % 12;
  // hours = hours ? hours : 12; // If hours is 0, change it to 12
  // const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  // return `${hours}:${formattedMinutes} ${amOrPm}`;
}

// get the time difference
export function getTimeDifference(startDateStr: string, endDateStr: string) {
  if (!startDateStr || !endDateStr) return "";
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate the difference in milliseconds
  const diffInMs = Math.abs(endDate.getTime() - startDate.getTime());

  // Convert milliseconds to hours and minutes
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;

  return `${hours}h ${minutes}m`;
}
