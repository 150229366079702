import React from "react";
import styles from "./step.module.scss";
import { HotelInputTypes } from "../../../../../types";
import CheckInCheckOut from "../../../inputFields/checkInCheckOut/checkInCheckOut";

interface StepperProps {
  steps?: HotelInputTypes[];
  initialStep: number;
  finalStep: number;
}

const Stepper: React.FC<StepperProps> = ({
  steps = [],
  initialStep = 0,
  finalStep = 0,
}) => {
  const [showAllSteps, setShowAllSteps] = React.useState(true);

  const handleViewDetails = () => {
    setShowAllSteps((prev) => !prev);
  };

  if (steps.length === 0) {
    return <div>No data found</div>;
  }
  
  return (
    <div className={styles.main}>
      <div className={styles.stepper}>
        {steps.map((step, index) => {
          // if (index < initialStep || index > finalStep) return null;
          const shouldDisplayStep =
            showAllSteps ||
            index === initialStep ||
            index === finalStep ||
            steps.length <= 2;

          if (!shouldDisplayStep) return null;

          return (
            <React.Fragment key={index}>
              <div className={styles.step}>
                <div className={styles.circle}></div>
                <div className={styles.stepContent}>
                  <p className={styles.stop}>stop {index + 1}</p>
                  <p className={styles.city}>
                    {step.city}, {step.countryFullName}
                  </p>
                  {showAllSteps && (
                    <div className={styles.card}>
                      <div>
                        <CheckInCheckOut
                          checkIn={step.checkIn}
                          checkOut={step.checkOut}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {index < steps.length - 1 && <div className={styles.line} />}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
