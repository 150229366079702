import React, { useContext, useEffect } from "react";
import styles from "./activitiesTabs.module.scss";
import CityTabs from "../../../routes/itineraryDetails/cityTabs/CityTabs";
import { SELECTED_CHAT_DATA_DUMMY } from "./activitiesConstant";
import { iChatData, iCityDetailsResult } from "../../../types";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";

export default function ActivitiesTabs({
  // activitiesData,
  status = "draft",
  startDate = "",
}: {
  // activitiesData: iChatData;
  startDate?: string;
  status?: string;
}) {
  const globalContext = useContext(StateContext);
  const { selectedChatData }: iGlobalContext = globalContext;

  useEffect(() => {
    // console.log("CHANGES");
  }, [selectedChatData]);
  const [showEditActivityFlag, setShowEditActivityFlag] = React.useState(false);

  return (
    <CityTabs
      itineraryDescription={selectedChatData?.complete_itinerary_description}
      itineraryVersion={selectedChatData?.itinerary_version || 0}
      // destinationList={activitiesData?.destinationList?.filter(
      //   (ele: iCityDetailsResult) => ele.checkedFlag
      // )}
      destinationList={selectedChatData?.destinationList?.filter(
        (ele: iCityDetailsResult) => ele.checkedFlag
      )}
      disableInput={false}
      flexDirection={"column-reverse"}
      startDate={startDate}
      showWeatherAdvisory
      showEditActivityFlag={showEditActivityFlag}
      setShowEditActivityFlag={setShowEditActivityFlag}
      savedStatus={status}
      handleHeadingClick={() =>
        triggerGAEvent(
          GA_LABEL_ENUMS.trip_details_daily_activity_accordion_click
        )
      }
      handleEditGAActivity={() =>
        triggerGAEvent(
          GA_LABEL_ENUMS.trip_details_activities_modify_activities_click
        )
      }
      handleEditActivityGADone={() =>
        triggerGAEvent(GA_LABEL_ENUMS.trip_details_activities_done_click)
      }
    />
  );
}
