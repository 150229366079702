import React from "react";
import styles from "./editActivity.module.scss";
import { Button, Dialog, Stack } from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import updateCloseIcon from "../../../assets/updateCloseIcon.svg";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import toast from "react-hot-toast";
import { getDayOfWeek, getMonthDate } from "../../../utils/dateUtils";

export default function EditActivityDialog({
  editingActtivityTimeOfDay,
  onClose,
  open,
  inputValue,
  setInputValue,
  onSaveChanges,
  onDelete,
  activityDate = "",
}: {
    editingActtivityTimeOfDay?: string;
  onClose?: any;
  open: boolean;
  inputValue: string;
  setInputValue: any;
  onSaveChanges?: any;
  onDelete?: any;
  activityDate?: string;
}) {
  function handleInputChange(e: any) {
    // if (e.target.value.length > 160) {
    //   toast.dismiss();
    //   toastMessage.info(
    //     "Information",
    //     "Text has exceed the limit of 160 character!"
    //   );
    //   return;
    // }
    setInputValue(e.target.value);
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      // open={true}
      className={styles.main}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0,0,0,0.85)",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Stack alignSelf="end" mb={2} mr={1.2}>
        <img
          onClick={onClose}
          style={{ width: "25px", cursor: "pointer" }}
          src={updateCloseIcon}
          alt="cross"
        />
      </Stack>
      <Stack
        sx={{
          width: "100%",
          maxHeight: {
            sm: "80vh",
            xs: "80vh",
            md: "calc(100% - 5%)",
            lg: "calc(100% - 5%)",
          },
          overflow: "auto",
          bgcolor: "#f8f8f8",
          borderRadius: "8px",
        }}
      >
        {/* Dialog content */}
        <div className={styles.dialogContainer}>
          <div className={styles.headingContainer}>
            <h1>Edit Activity</h1>
            {activityDate && (
              <p>
                {getDayOfWeek(activityDate)} {","} {getMonthDate(activityDate)}
              </p>
            )}
          </div>
          <div className={styles.midContainer}>
            <h1 >{ editingActtivityTimeOfDay}</h1>
            <TextareaAutosize
              onChange={handleInputChange}
              placeholder={""}
              value={inputValue}
              className={styles.input}
              // className={styles.input}
              minRows={3}
              maxRows={4}
              spellCheck="true"
            />
            {/* <p>160</p> */}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              className={`${styles.button} ${styles.deleteBtn}`}
              onClick={onDelete}
            >
              DELETE
            </Button>
            <Button
              className={`${styles.button} ${styles.saveBtn}`}
              onClick={onSaveChanges}
            >
              SAVE CHANGES
            </Button>
          </div>
        </div>
      </Stack>
    </Dialog>
  );
}
