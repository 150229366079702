import React from "react";
import { planeDepartureRegular } from "../../../../constant";
import CustomAccordion from "../../../reusableComponent/customAccordion/CustomAccordion";
import Stepper from "./stepper/stepper";
import { flightInfoInDetail } from "../../../../routes/booking/dummyFlightData";
import styles from "./flight.module.scss";
import { getDayOfWeek, getMonthDate } from "../../../../utils/dateUtils";

interface iProps {
  flightData?: any;
  flightImage?: any;
  hideExpandIcon?: boolean;
  handleExpandGAEvent?: any;
  handleCloseGAEvent?: any;
}

interface CardInfo {
  flightDetails?: {
    flightName: string;
    flightNumber: string;
    bookingRef: string;
    flightImage: string;
  };
  departure?: {
    departure: string;
    departureTime: string;
    flightTime: string;
  };
  arrival?: {
    arrival: string;
    arrivalTime: string;
    destinationImg: string;
  };
  seats?: {
    seats: string;
    seatNumb: string;
    boardingPasses: boolean;
  };
  layover?: {
    layover: string;
    arrivalTime: string;
  };
}

interface StepData {
  title?: string;
  description?: string;
  departure?: string;
  departureDate?: string;
  ArrivalOnDiffDate?: boolean;
  layOver?: boolean;
  airPortCode?: string;
  airportName?: string;
  cardInfo: CardInfo[];
}

function FlightDetails({
  flightData,
  flightImage,
  hideExpandIcon = true,
  handleExpandGAEvent,
  handleCloseGAEvent,
}: iProps): JSX.Element {
  // const stepperData = flightData[0]?.map((i: any) => i.segments);
  // // console.log("stepperData:-", stepperData);

  const flightDataInDetail = flightInfoInDetail;

  const processFlightData = (flightInfoInDetail: any) => {
    return flightInfoInDetail.data.map((flight: any) => ({
      id: flight.uniqueId,
      accordType: "flight",
      date: "Fri Dec 18",
      fightTo: "Barcelona (BCN)",
      flightFrom: "Washington DC (IAD)",
      stepsData: flight.outbound[0].segments.map((segment: any) => ({
        ArrivalOnDiffDate: false,
        airPortCode: segment.departure,
        airportName: "Barcelona Airport",
        description: "FRI, AUG 14, 2024 at 12:15pm",
        layOver: false,
        title: "BCN  |  Barcelona Airport",
        cardInfo: [
          {
            flightDetails: {
              flightName: "British Airways",
              flightNumber: "Flight BA415",
              Departure: "Departure",
              flightImage:
                "https://zenvoya-uploads-dev-test.s3.amazonaws.com/profile/britishairways.jpg",
            },
            departure: {
              departure: "Departure",
              departureTime: "10:20 PM",
              flightTime: "Flight time: 8h 17m",
            },
          },
        ],
      })),
    }));
  };
  const flightModifiedData = processFlightData(flightDataInDetail);
  const departureAirportCode =
    flightData?.segments?.length > 0 && flightData?.segments[0]?.depAirportCode;
  const departureAirportName =
    flightData?.segments?.length > 0 && flightData?.segments[0]?.depAirportName;
  const arrivalAirportCode =
    flightData?.segments?.length > 0 &&
    flightData?.segments[flightData?.segments?.length - 1]?.arrAirportCode;
  const arrivalAirportName =
    flightData?.segments?.length > 0 &&
    flightData?.segments[flightData?.segments?.length - 1]?.arrAirportName;

  return (
    <div className={styles.main}>
      <div className={styles.flightAccord}>
        <CustomAccordion
          className={styles.customFlightStyles}
          summaryClass={styles.summaryFlightClass}
          accordianDetailsClass={styles.accordFlightDetailsClass}
          defaultExpanded={true}
          hideExpandIcon={hideExpandIcon}
          onChange={(_e, isExpanded) => {
            if (isExpanded) if (handleExpandGAEvent) handleExpandGAEvent();
            if (!isExpanded) if (handleCloseGAEvent) handleCloseGAEvent();
          }}
          headingComponent={
            <div className={styles.headingFlightComp} onClick={() => {}}>
              <div className={styles.accordTitleCard}>
                <p className={styles.dayAndCity}>
                  {getDayOfWeek(
                    flightData?.segments?.length > 0
                      ? flightData?.segments[0]?.departureDate
                      : ""
                  )}{" "}
                  {getMonthDate(
                    flightData?.segments?.length > 0
                      ? flightData?.segments[0]?.departureDate
                      : ""
                  )}
                </p>

                <p className={styles.accordDesc}>
                  {`${departureAirportName} (${departureAirportCode})`} -{" "}
                  {`${arrivalAirportName} (${arrivalAirportCode})`}
                </p>
              </div>
              <img
                src={
                  // i.accordType === "flight"
                  //   ?
                  planeDepartureRegular
                  // : personWalkingLuggage
                }
                alt="plane"
                className={styles.planeDepartureIcon}
              />
            </div>
          }
          bodyComponent={
            <div className={styles.stepper}>
              {/* {flightModifiedData.map((i: any, index: any) => ( */}
              <section
                className={styles.stepperDetails}
                // key={index}
              >
                {/* {i.stepsData && ( */}
                <Stepper
                  // steps={i.stepsData}
                  // initialStep={0}
                  // finalStep={i.stepsData.length - 1}
                  steps={flightData?.segments}
                  stops={flightData?.stops}
                  initialStep={0}
                  finalStep={flightData?.length - 1}
                  flightImage={flightImage}
                />
                {/* )} */}
              </section>
              {/* ))} */}

              <div className={styles.divider} />
            </div>
          }
          zeroMarginOnExpand
          borderBottom
        />
      </div>
    </div>

    // <div className={styles.main}>
    //   <div className={styles.flightAccord}>
    //     {FlightsSearchInfo.filter((item) => item.accordType === "flight").map(
    //       (i, index) => (
    //         <CustomAccordion
    //           className={styles.customFlightStyles}
    //           summaryClass={styles.summaryFlightClass}
    //           accordianDetailsClass={styles.accordFlightDetailsClass}
    //           defaultExpanded={true}
    //           hideExpandIcon={true}
    //           headingComponent={
    //             <div className={styles.headingFlightComp} key={index}>
    //               <div className={styles.accordTitleCard}>
    //                 {i.date && <p className={styles.dayAndCity}>{i.date}</p>}
    //                 {i.flightFrom && (
    //                   <p className={styles.accordDesc}>
    //                     {i.flightFrom} - {i.fightTo}
    //                   </p>
    //                 )}
    //               </div>
    //               {i.accordType && (
    //                 <img
    //                   src={
    //                     i.accordType === "flight"
    //                       ? planeDepartureRegular
    //                       : personWalkingLuggage
    //                   }
    //                   alt="plane"
    //                   className={styles.planeDepartureIcon}
    //                 />
    //               )}
    //             </div>
    //           }
    //           bodyComponent={
    //             <div className={styles.stepper}>
    //               {flightModifiedData.map((i: any, index: any) => (
    //                 <section className={styles.stepperDetails} key={index}>
    //                   {i.stepsData && (
    //                     <Stepper
    //                       steps={i.stepsData}
    //                       initialStep={0}
    //                       finalStep={i.stepsData.length - 1}
    //                     />
    //                   )}
    //                 </section>
    //               ))}

    //               <div className={styles.divider} />
    //             </div>
    //           }
    //           zeroMarginOnExpand
    //           borderBottom
    //         />
    //       )
    //     )}
    //   </div>
    // </div>
  );
}

export default FlightDetails;
