import { endPoints } from "../constant/api_constants/url";
import { fetchApi } from "../utils/apiFunction";
import { API_METHODS } from "../enums";
import { ENDPOINT } from "../constant/environment";
import axios from "axios";

export async function getCategoryAPICall() {
  const URL = `${ENDPOINT}/app/v1/config/categories`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  try {
    const response = await axios.get(URL, config);
    return response?.data.data;
  } catch (error: any) {
    // console.log("ERROR", error);
    // console.log("ERROR", error.message);
    return null;
  }
}

export const getCategory = async (requestBody: any) => {
  const endpoint = endPoints.categories;

  return await fetchApi(endpoint, API_METHODS.GET, requestBody);
};
