import { useState } from "react";
import hotelRoom from "../../../../assets/hotelRoom.png";
import HotelRating from "../hotelRating/hotelRating";
// import { Amenity } from "../../../../types";
import { expandIcon, starSharpSolid } from "../../../../constant";
import ReviewsModal from "../../../hotelDetails/components/reviewsModal/reviewsModal";
import styles from "./hotel.module.scss";
interface iProps {
  star: number;
  hotelName: string;
  hotelLocation: string;
  hotelRating?: number;
  reviewCount?: number;
  Amenity?: string[];
  hotelList: boolean;
  hotelMainImage?: string;
  reviewHeading?: string;
  handleViewDetails?: () => void;
  city?: string;
}

function HotelCard({
  star,
  hotelName,
  hotelLocation,
  hotelRating,
  reviewCount,
  Amenity,
  hotelList,
  hotelMainImage,
  reviewHeading,
  handleViewDetails,
  city,
}: iProps): JSX.Element {
  const [showReviewsModal, setShowReviewsModal] = useState(false);
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.firstCard}>
          <section className={styles.imgBox}>
            <img
              src={hotelMainImage ? hotelMainImage : hotelRoom}
              alt="flight"
              className={`${hotelList ? styles.hotelImg : styles.packageImg}`}
            />
          </section>
        </div>

        <section className={styles.contentCard}>
          <div className={styles.starContainer}>
            {[...Array(star)].map((_, index) => (
              <img
                key={index}
                className={styles.starPropertyImg}
                src={starSharpSolid}
                alt="star icon"
              />
            ))}
            {/* <span className={styles.star}>{star} star property</span> */}
          </div>
          <div className={styles.hotelDetailCard}>
            <div className={styles.hotelNameCard}>
              <p className={styles.hotelName}>{hotelName}</p>
              <p className={styles.location}>
                {hotelLocation}
                {city && `, ${city}`}
              </p>
            </div>
            {/* <div className={styles.addCard}>
              <p className={styles.address}>{hotelLocation}</p>
              <p className={styles.distance}>.5 mi from Center</p>
            </div> */}
          </div>

          {/* <div className={styles.amenitiesCard}>
          {Amenity &&
            Amenity.map((i, index) => (
              <p className={styles.amenities} key={index}>
                {i}
                {index !== Amenity.length - 1 &&
                  "\u00A0\u00A0" + " | " + "\u00A0\u00A0"}
              </p>
            ))}
        </div> */}
        </section>
      </div>
      <section className={styles.secondCard}>
        {reviewCount !== undefined && hotelRating !== undefined && (
          <HotelRating
            hotelRating={hotelRating}
            onReviewClick={() => setShowReviewsModal(true)}
            reviewCount={reviewCount}
            reviewHeading={reviewHeading}
          />
        )}

        <div className={styles.viewBtn} onClick={handleViewDetails}>
          <p className={styles.viewTitle}>VIEW DETAILS</p>{" "}
          <img src={expandIcon} alt="" className={styles.expIcon} />
        </div>
      </section>
      <ReviewsModal
        isOpen={showReviewsModal}
        onClose={() => setShowReviewsModal(false)}
      />
    </div>
  );
}

export default HotelCard;
