import React, { useCallback } from "react";
import ItineraryDetails from "../../routes/itineraryDetails/ItineraryDetails";
import ReorderMultiCityTrip from "../../routes/reorderMultipCityTrip/ReorderMultiCityTrip";
import AdjustTrip from "../../routes/adjustTrip/adjustTrip";
import EmptyCard from "../deskTopDestination/emptyCard/emptyCard";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import PreviewPreference from "../../routes/previewPreference/previewPreference";
import EditAccount from "../../routes/editAccount/EditAccount";
import { ScreenTypes } from "../../enums";
import SelectedDestination from "../desktopLandingPage/selectedDestination/selectedDestination";
import SavedItineraryDetail from "../../routes/savedItineraryDetails/SavedItineraryDetail";
import styles from "./multi.module.scss";
import Booking from "../../routes/booking/booking";
import HotelDetails from "../hotelDetails";
import TripDetailsPage from "../tripDetailsPage/TripDetailsPage";
import MyTrips from "../myTripsPage/myTrips";
import ExploreTripAndAssistance from "../exploreTripAndAssistance/ExploreTripAndAssistance";
import FaqAssistance from "../faqAssistance/FaqAssistance";
import RecommendedTripDetailsPage from "../recommendedTripDetailsPage/RecommendedTripDetailsPage";
import TravelerInformation from "../../routes/travelerInformation/travellerInformation";
import WeatherAdvisory from "../../routes/weatherAdvisory/weatherAdvisory";
import FlightDelay from "../../routes/flightDelay/flightDelay";

function MultiCityTrip(): JSX.Element {
  const state = React.useContext(StateContext);
  const { screen }: iGlobalContext = state;

  const switchScreens = useCallback(
    (param: string) => {
      switch (param) {
        case ScreenTypes.SingleItinerary:
          return <ItineraryDetails />;
        case ScreenTypes.Reorder:
          return <ReorderMultiCityTrip />;
        case ScreenTypes.ItineraryInfo:
          return <ItineraryDetails />;
        case ScreenTypes.Adjust:
          return <AdjustTrip />;
        case ScreenTypes.Edit:
          return <EditAccount />;
        case ScreenTypes.Preference:
          return <PreviewPreference />;
        case ScreenTypes.EmptyPage:
          return <SelectedDestination />;
        case ScreenTypes.SavedItinerary:
          return <RecommendedTripDetailsPage />;
        case ScreenTypes.Booking:
          return <Booking />;
        case ScreenTypes.HotelDetails:
          return <HotelDetails />;
        case ScreenTypes.TripDetails:
          return <TripDetailsPage />;
        case ScreenTypes.SaveTripsList:
          return <MyTrips />;
        case ScreenTypes.ExploreCity:
          return <ExploreTripAndAssistance />;
        case ScreenTypes.FaqAssistance:
          return <FaqAssistance />;
        case ScreenTypes.TravellerInfo:
          return <TravelerInformation />;
        case ScreenTypes.WeatherAdvisory:
          return <WeatherAdvisory />;
        case ScreenTypes.FlightDelay:
          return <FlightDelay />;
        default:
          return <EmptyCard />;
      }
    },
    [screen]
  );

  return <div className={styles.main}>{switchScreens(screen)}</div>;
}

export default MultiCityTrip;
