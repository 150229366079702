import { useCallback } from "react";

const useValidateDate = () => {
  const validateDate = useCallback(
    (dateString: string | null | undefined): string => {
      if (!dateString || typeof dateString !== "string") {
        return "";
      }
      const date = new Date(dateString);
      const today = new Date();

      // Set today's time to midnight for comparison
      today.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);

      // Check if date is today or in the future
      return date >= today ? dateString : "";
    },
    []
  );

  return validateDate;
};

export default useValidateDate;
