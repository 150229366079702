import axios from "axios";
import { ENDPOINT } from "../constant/environment";
import { toastMessage } from "../helpers/toast/toastMessage";
import { API_HEADER } from "../constant/common";
import toast from "react-hot-toast";
import { iHotelRoomBookArgs } from "./hotelDetailsAPI";
import { endPoints } from "../constant/api_constants/url";
import { API_METHODS } from "../enums";
import { fetchApi } from "../utils/apiFunction";
import { iCountryDataResponse } from "../interface/common";
import useCustomErrorHandler from "../hooks/useCustomErrorHandler";

// export const getFlightAvailability = async (
//   encryptedKey: string,
//   uniqueId: string,
//   controller: AbortController
// ) => {
//   const URL = `${ENDPOINT}/app/v1/flight/check/${encryptedKey}-${uniqueId}`;
//   const config = {
//     headers: {
//       "Content-Type": "application/json;charset=UTF-8",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Headers": "*",
//     },
//     signal: controller.signal,
//   };
//   try {
//     const response = await axios.get(URL, config);
//     return response?.data;
//   } catch (error: unknown) {
//     if (error instanceof Error) {
//       console.error("Error fetching flight availability:", error.message);
//       // toastMessage.error("Failed to retrieve flight availability");
//     } else {
//       console.error("Unknown error:", error);
//       toastMessage.error("An unknown error occurred");
//     }
//   }
// };

// --------------------------------------------------------------------------------------------------------------

// export const getFlightAvailability = async (
//   encryptedKey: string,
//   uniqueId: string,
//   controller: AbortController
// ) => {
//   const URL = `${ENDPOINT}/app/v1/flight/check/${encryptedKey}-${uniqueId}`;
//   const config = {
//     headers: {
//       "Content-Type": "application/json;charset=UTF-8",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Headers": "*",
//     },
//     signal: controller.signal,
//   };

//   try {
//     const response = await axios.get(URL, config);
//     if (
//       response?.data?.statusCode === 400 ||
//       response?.data?.statusCode === 404
//     ) {
//       toastMessage.error(
//         TOAST_MESSAGES.BOOKING.Search.FlightAvailabilityFailure.messageTitle,
//         TOAST_MESSAGES.BOOKING.Search.FlightAvailabilityFailure.message
//       );
//     }
//     return response?.data;
//   } catch (error: unknown) {
//     if (error instanceof Error) {
//       if (error?.name === "CanceledError") {
//         console.error("API call was aborted");
//         return;
//       }

//       console.error("Error fetching flight availability:", error.message);
//       toastMessage.error("OOPS!", "Failed to retrieve flight availability");
//     } else {
//       console.error("Unknown error:", error);
//       toastMessage.error("An unknown error occurred");
//     }
//   }
// };

// export const getFlightDetails = async (
//   encryptedKey: string,
//   uniqueId: string
// ) => {
//   const URL = `${ENDPOINT}/app/v1/flight/${encryptedKey}-${uniqueId}`;
//   const config = {
//     headers: {
//       "Content-Type": "application/json;charset=UTF-8",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Headers": "*",
//     },
//   };
//   try {
//     const response = await axios.get(URL, config);
//     return response?.data;
//   } catch (error: unknown) {
//     if (error instanceof Error) {
//       console.error("Error fetching flight details:", error.message);
//       toastMessage.error("Error fetching flight details");
//     } else {
//       console.error("Unknown error:", error);
//       toastMessage.error("An unknown error occurred");
//     }
//   }
// };

// const handleError = (
//   statusCode: number,
//   messageType: "flight" | "hotel" | "booking"
// ) => {
//   if (!statusCode || !messageType) {
//     return;
//   }

//   switch (statusCode) {
//     case 400:
//       if (messageType === "flight") {
//         toastMessage.error(
//           TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.messageTitle,
//           TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.message
//         );
//       } else if (messageType === "hotel") {
//         toastMessage.error(
//           TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
//           TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
//         );
//       }
//       break;
//     case 401:
//       toastMessage.error(
//         TOAST_MESSAGES.MessageFor401.messageTitle,
//         TOAST_MESSAGES.MessageFor401.message
//       );
//       break;
//     case 402:
//       toastMessage.error(
//         TOAST_MESSAGES.MessageFor402.messageTitle,
//         TOAST_MESSAGES.MessageFor402.message
//       );
//       break;
//     case 403:
//       toastMessage.error(
//         TOAST_MESSAGES.MessageFor403.messageTitle,
//         TOAST_MESSAGES.MessageFor403.message
//       );
//       break;
//     case 404:
//       if (messageType === "flight") {
//         toastMessage.error(
//           TOAST_MESSAGES.BOOKING.Search.NoFlightsFound.messageTitle,
//           TOAST_MESSAGES.BOOKING.Search.NoFlightsFound.message
//         );
//       } else if (messageType === "hotel") {
//         toastMessage.error(
//           TOAST_MESSAGES.BOOKING.Search.NoHotelsFound.messageTitle,
//           TOAST_MESSAGES.BOOKING.Search.NoHotelsFound.message
//         );
//       }
//       break;
//     default:
//       toastMessage.error(
//         TOAST_MESSAGES.ApiBreakdown.messageTitle,
//         TOAST_MESSAGES.ApiBreakdown.message
//       );
//   }
// };

// export const searchFlights = async (
//   requestBody: any,
//   controller: AbortController
// ) => {
//   const url = `${ENDPOINT}/app/v1/flight/search`;
//   try {
//     const response = await fetch(url, {
//       method: "POST",
//       signal: controller.signal,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(requestBody),
//     });

//     if (!response.ok) {
//       console.error(`HTTP error! Status: ${response.status}`);
//     }

//     const data = await response.json();
//     if (data.statusCode >= 400 && data.statusCode <= 404) {
//       handleError(data?.statusCode, "flight");
//       return;
//     }
//     return data.data;
//   } catch (error) {
//     console.error("Error searching for flights:", error);
//     // toastMessage.error("Error searching for flights");
//     throw error;
//   }
// };

// export const searchHotels = async (
//   requestBody: any,
//   controller: AbortController
// ) => {
//   const url = `${ENDPOINT}/app/v1/hotel/search`;
//   try {
//     const response = await fetch(url, {
//       method: "POST",
//       signal: controller.signal,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(requestBody),
//     });

//     if (!response.ok) {
//       toastMessage.error(`HTTP error! Status: ${response.status}`);
//     }

//     const data = await response.json();
//     if (data.statusCode >= 400 && data.statusCode <= 404) {
//       handleError(data?.statusCode, "hotel");
//       return;
//     }
//     return data.data;
//   } catch (error) {
//     console.error("Error searching for hotels:", error);
//     // toastMessage.error("Error searching for hotels");
//     throw error;
//   }
// };

export const getAirports = async (
  requestBody: any,
  controller: AbortController
) => {
  const url = `${ENDPOINT}/app/v1/flight/airports`;
  try {
    const response = await fetch(url, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    if (data.statusCode && data.statusCode === 400) {
      console.error("OOPS!", data.message);
      return;
    }
    return data.data;
  } catch (error) {
    console.error("Error searching for airports:", error);
    throw error;
  }
};

// Specific API calls
export const getFlightAvailability = async (
  encryptedKey: string,
  uniqueId: string,
  controller: AbortController
) => {
  return await fetchApi(
    endPoints.flightAvailability,
    API_METHODS.GET,
    null,
    { encryptedKey, uniqueId },
    controller
  );
};

export const getFlightDetails = async (
  encryptedKey: string,
  uniqueId: string,
  controller: AbortController
) => {
  return await fetchApi(
    endPoints.flightDetails,
    API_METHODS.GET,
    null,
    {
      encryptedKey,
      uniqueId,
    },
    controller
  );
};

export const searchFlights = async (
  requestBody: any,
  controller: AbortController
) => {
  return await fetchApi(
    endPoints.flightSearch,
    API_METHODS.POST,
    requestBody,
    null,
    controller
  );
};

export const searchHotels = async (
  requestBody: any,
  controller: AbortController
) => {
  return await fetchApi(
    endPoints.hotelSearch,
    API_METHODS.POST,
    requestBody,
    null,
    controller
  );
};

// export const hotelDetails = async (hotelId: string) => {
//   const endPoint = endPoints.hotelDetail(hotelId);
//   return await fetchApi(endPoint, API_METHODS.GET);
// };

export const getHotelDetails = async (
  hotelId: string,
  controller: AbortController,
  isLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const endpoint = endPoints.hotelDetail(hotelId);

  try {
    if (isLoading) isLoading(true);
    const response = await fetchApi(
      endpoint,
      API_METHODS.GET,
      null,
      null,
      controller
    );
    if (isLoading) isLoading(false);
    return response;
  } catch (error) {
    if (isLoading) isLoading(false);
    return null;
  }
};

export interface iBookHotelAndFlightArgs {
  clientId: string;
  userId: string;
  paymentDetails: PaymentDetails;
  passengerDetails: PassengerDetail[];
  flight?: string;
  hotel?: iHotelRoomBookArgs[];
}

export interface PassengerDetail {
  paxType: string;
  gender?: string;
  leadPax: boolean;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  mobileNumber: string;
  dateOfBirth?: string;
  infantData?: InfantData | null | undefined;
  document?: Document;
  operationalReference?: OperationalReference;
  frequentFlyerinfo?: FrequentFlyerinfo;
  meals?: string[] | null;
}

export interface Document {
  type: string | null;
  number: string | null;
  nationality: string | null;
  issuedCountry: string | null;
  issuedDate: string | null;
  expiryDate: string | null;
}

export interface FrequentFlyerinfo {
  flyerNumber: string;
  frequentFlyerProgram: string;
  airline: string;
}

export interface InfantData {
  title: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  document: Document;
}

export interface OperationalReference {
  specialAssistance: string | null;
}

export interface PaymentDetails {
  successUrl: string;
  cancelUrl: string;
  amount: string;
  currency: string;
  remarks: string;
}

export const bookHotelAndFlight = async (
  args: iBookHotelAndFlightArgs,
  isLoading?: any
): Promise<any> => {
  // const URL = `${ENDPOINT}/app/v1/booking`;
  const URL = `${ENDPOINT}/app/v1/itinerary/prebooking`;
  // const URL = `${ENDPOINT}/app/v1/prebooking`;
  // const URL = `https://inyuqios27.execute-api.us-east-1.amazonaws.com/dev/be/v1/booking`;
  const config = {
    headers: API_HEADER,
    // signal,
  };
  const body = {
    ...args,
  };
  try {
    if (isLoading) isLoading(true);
    const response = await axios.post(URL, body, config);
    if (isLoading) isLoading(false);
    toast.dismiss();
    if (response?.data?.statusCode !== 200)
      throw new Error(response?.data.message);

    return response?.data?.data;
  } catch (error: any) {
    toast.dismiss();
    if (isLoading) isLoading(false);
    toastMessage.error(error.message);
    // console.log(error, "error messagze");
    return null;
  }
};

interface iConfirmBookHotelAndFlightArgs {
  itineraryId: string;
}

export const confirmBookHotelAndFlight = async (
  args: iConfirmBookHotelAndFlightArgs,
  isLoading?: any
) => {
  // const URL = `${ENDPOINT}/app/v1/book`;
  const URL = `${ENDPOINT}/app/v1/itinerary/booking`;
  // const URL = `${ENDPOINT}/app/v1/prebooking`;
  // const URL = `https://inyuqios27.execute-api.us-east-1.amazonaws.com/dev/be/v1/booking`;
  const config = {
    headers: API_HEADER,
    // signal,
  };
  const body = {
    ...args,
  };
  try {
    if (isLoading) isLoading(true);
    const response = await axios.post(URL, body, config);
    if (isLoading) isLoading(false);
    if (response?.data?.statusCode !== 200) {
      throw new Error(response?.data.message);
    }
    return response?.data?.data;
  } catch (error: any) {
    toast.dismiss();
    if (isLoading) isLoading(false);
    // toastMessage.error("OOPS!", "We hit a snag");
    // console.log(error, "error messagze");
    return null;
  }
};
// export const getAirports = async (requestBody: any, controller: AbortController) => {
//   return await fetchApi(endPoints.airports, "POST", requestBody, null, controller);
// };

export const fetchCountries = async (): Promise<iCountryDataResponse> => {
  return await fetchApi(endPoints.county, API_METHODS.GET);
};
