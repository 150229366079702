import * as React from "react";
import styles from "./cityTabs.module.scss";
import { Stack, IconButton, Button } from "@mui/material";
import { iCityDetailsResult } from "../../../types";
import Markdown from "react-markdown";
import {
  doneIcon,
  modifyEditIcon,
  planeDepartureRegular,
} from "../../../constant";
import CustomAccordion from "../../../component/reusableComponent/customAccordion/CustomAccordion";
import ButtonWithIcon from "../../../component/reusableComponent/buttonWithIcon/ButtonWithIcon";
import DestinationCardWithImages from "../../../component/reusableComponent/destinationCardWithImages/DestinationCardWithImages";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import EditActivityDialog from "../../../component/dialog/editAcitvityDialog/EditActivityDialog";
import DiscardChanges from "../../../component/dialog/discardChanges/discardChanges";
import Loader from "../../../component/reusableComponent/loader/Loader";
import { saveItineraryDetailsAPI } from "../../../api/saveItineraryDetails";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import { getConfigMessage } from "../../../utils";
import { APP_CONFIG } from "../../../constant/common";
import toast from "react-hot-toast";
import WeatherWidget from "../../../component/weatherWidget/weatherWidget";
import { iWeatherData } from "../../../component/home/todayLanding/todayLandingPage";
import { getDayOfWeek, getMonthDate } from "../../../utils/dateUtils";
import { ACTIVITY_TYPE_IDENTIFIER } from "../../../enums";
import { getLocalStorageData } from "../../../utils/loginAndLocalStorageutils";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";

export default function CityTabs({
  itineraryDescription = "",
  itineraryVersion = 0,
  destinationList,
  handleEditActivity = () => {},
  disableInput = false,
  isSavedActivityFlag = false,
  flexDirection = "column",
  showWeatherAdvisory = false,
  showPlaneIcon = false,
  startDate = "",

  showEditActivityFlag,
  setShowEditActivityFlag,
  savedStatus = "draft",
  handleHeadingClick,
  handleEditGAActivity,
  handleEditActivityGADone,
}: {
  itineraryDescription?: string;
  destinationList: iCityDetailsResult[];
  itineraryVersion?: string | number;
  handleEditActivity?: any;
  disableInput?: boolean;
  isSavedActivityFlag?: boolean;
  flexDirection?: "column" | "column-reverse";
  showWeatherAdvisory?: boolean;
  showPlaneIcon?: boolean;
  startDate?: string;

  showEditActivityFlag: boolean;
  setShowEditActivityFlag: any;
  savedStatus?: string;
  handleHeadingClick?: any;
  handleEditGAActivity?: any;
  handleEditActivityGADone?: any;
}) {
  // console.log({ startDate });
  // const [showEditActivityFlag, setShowEditActivityFlag] = React.useState(false);
  const [disableButtonFlag, setDisableButtonFlag] = React.useState(false);
  const globalContext = React.useContext(StateContext);
  const {
    selectedChatData,
    setSelectedChatData,
    userDetails,
    appConfig,
    isLoggedIn,
    userInfo,
  }: iGlobalContext = globalContext;
  async function handleShowEditActivity() {
    triggerGAEvent(GA_LABEL_ENUMS.itinerary_details_modify_activities_click);
    if (handleEditGAActivity) handleEditGAActivity();
    setDisableButtonFlag(true);
    // toastMessage.loading("Saving Itinerary as draft");
    const metaData: any = {
      // chatData: chatData,
      selectedChatData: {
        ...selectedChatData,
        isSaved: true,
        prompt: [],
        postText: "",
        preText: "",
      },
    };

    if (!userDetails?.id || !userDetails?.loggedIn) {
      toast.dismiss();
      setDisableButtonFlag(false);
      setShowEditActivityFlag(true);
      // console.log("SETTING DONE");
      localStorage.setItem(
        "_trip_trove_prev_destination_list",
        JSON.stringify(destinationList)
      );
      return;
    }
    const response = await saveItineraryDetailsAPI(
      selectedChatData.tripName || "",
      selectedChatData.id,
      userDetails.id || userInfo.id,
      metaData,
      savedStatus,
      Number(selectedChatData.noOfDays),
      "",
      "",
      userDetails?.role === 1,
      // "",
      // "",
      // "",
      // "",
      // "",
      // "",
      selectedChatData?.results || null
    );
    toast.dismiss();
    if (response?.statusCode === 200) {
      // toastMessage.success("Success", "Your itinerary is saved as draft");
      // setSharedItineraryId(`${response?.data.id}`);
      // setTimeout(next, 500);
      // setTimeout(() => {
      //   if (shareRef.current && shareClick) {
      //     shareRef.current.children[0].click();
      //     setShareClick(false);
      //   }
      // }, 400);
      setShowEditActivityFlag(true);
    } else {
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
    }
    setDisableButtonFlag(false);
    // console.log("SETTING DONE");
    localStorage.setItem(
      "_trip_trove_prev_destination_list",
      JSON.stringify(destinationList)
    );
    // console.log("DONE");
  }

  async function handleEditActivityDone() {
    triggerGAEvent(GA_LABEL_ENUMS.itinerary_details_activities_done_click);
    toast.dismiss();
    if (handleEditActivityGADone) handleEditActivityGADone();
    const prevDestinationList = getLocalStorageData(
      "_trip_trove_prev_destination_list"
    );
    let test = [];
    if (prevDestinationList) test = JSON.parse(prevDestinationList);
    // console.log({ test }, { destinationList });
    if (prevDestinationList === JSON.stringify(destinationList)) {
      setShowEditActivityFlag(false);
      return;
    }

    setDisableButtonFlag(true);
    const metaData: any = {
      // chatData: chatData,
      selectedChatData: {
        ...selectedChatData,
        prompt: [],
        postText: "",
        preText: "",
      },
    };

    if (userDetails?.loggedIn) {
      toast.loading("Saving modified activity...");
      const response = await saveItineraryDetailsAPI(
        selectedChatData.tripName || "",
        selectedChatData.id,
        userDetails.id || userInfo.id,
        metaData,
        savedStatus,
        Number(selectedChatData.noOfDays),
        "",
        "",
        userDetails?.role === 1,
        selectedChatData?.results || null
      );
      if (response?.message === "Itineraries has been saved") {
        toastMessage.success("Success", "Modified activity is saved");
        // setSharedItineraryId(`${response?.data.id}`);
        // setTimeout(next, 500);
        // setTimeout(() => {
        //   if (shareRef.current && shareClick) {
        //     shareRef.current.children[0].click();
        //     setShareClick(false);
        //   }
        // }, 400);
        setTimeout(() => {
          toast.dismiss();
        }, 2000);
        setShowEditActivityFlag(false);
      } else {
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
    } else {
      toastMessage.success("Success", "Activity is modified!");
      setTimeout(() => {
        toast.dismiss();
      }, 2000);
      setShowEditActivityFlag(false);
    }

    setDisableButtonFlag(false);
  }

  /**
   * Edit activity Dialog
   */
  const [openEditActivityDialog, setOpenEditActivityDialog] =
    React.useState(false);
  const [editingActivityValue, setEditingActivityValue] = React.useState("");
  const [editingActtivityTimeOfDay, setEditingActivityTimeOfDay] =
    React.useState("");
  const [prevEditingActivityValue, setPrevEditingActivityValue] =
    React.useState("");
  const [editingActivityId, setEditingActivityId] = React.useState("");
  function handleCloseEditActivityDialog() {
    if (prevEditingActivityValue !== editingActivityValue)
      setOpenDiscardChangesDialog(true);
    setOpenEditActivityDialog(false);
  }

  React.useEffect(() => {
    // console.log(prevEditingActivityValue);
  }, [prevEditingActivityValue, editingActivityValue]);

  const [activityDate, setActivityDate] = React.useState("");
  function handleEditActivityLocal(data: any, date: string) {
    console.log({ data });
    if (date) setActivityDate(date);
    else setActivityDate("");
    // console.log(data);
    handleEditActivity();
    setOpenEditActivityDialog(true);
    setPrevEditingActivityValue(data?.description);
    setEditingActivityValue(data?.description);
    setEditingActivityId(data?.id);
    setEditingActivityTimeOfDay(data?.time_of_the_day);
  }

  function handleEditActivitySaveChanges() {
    setSelectedChatData({
      ...selectedChatData,
      destinationList: selectedChatData.destinationList.map(
        (destinationListEle: iCityDetailsResult) => ({
          ...destinationListEle,
          tripDetails: destinationListEle.tripDetails.map(
            (tripDetailsEle: any) => ({
              ...tripDetailsEle,
              itinerary_by_time_of_the_day:
                tripDetailsEle.itinerary_by_time_of_the_day.map(
                  (itinerary_by_time_of_the_day_ele: any) =>
                    itinerary_by_time_of_the_day_ele.id === editingActivityId
                      ? {
                          ...itinerary_by_time_of_the_day_ele,
                          description: editingActivityValue,
                        }
                      : itinerary_by_time_of_the_day_ele
                ),
            })
          ),
        })
      ),
    });

    setOpenEditActivityDialog(false);
  }

  function handleEditActivityDelete() {
    setSelectedChatData({
      ...selectedChatData,
      destinationList: selectedChatData.destinationList.map(
        (destinationListEle: iCityDetailsResult) => ({
          ...destinationListEle,
          tripDetails: destinationListEle.tripDetails.map(
            (tripDetailsEle: any) => ({
              ...tripDetailsEle,
              itinerary_by_time_of_the_day:
                tripDetailsEle.itinerary_by_time_of_the_day.map(
                  (itinerary_by_time_of_the_day_ele: any) =>
                    itinerary_by_time_of_the_day_ele.id === editingActivityId
                      ? {
                          ...itinerary_by_time_of_the_day_ele,
                          description: "",
                        }
                      : itinerary_by_time_of_the_day_ele
                ),
            })
          ),
        })
      ),
    });

    setOpenEditActivityDialog(false);
  }

  /**
   * Discard changes Dialog
   */
  const [openDiscardChangesDialog, setOpenDiscardChangesDialog] =
    React.useState(false);
  function handleCancelDicard() {
    setOpenEditActivityDialog(true);
    setOpenDiscardChangesDialog(false);
  }
  function handleDicard() {
    setOpenEditActivityDialog(false);
    setOpenDiscardChangesDialog(false);
  }

  const [weatherData, setWeatherData] = React.useState({} as iWeatherData);
  const [cityName, setCityName] = React.useState("Barcelona");

  function isShowingPlaneIcon(array: any) {
    return (
      array?.itinerary_by_time_of_the_day?.some((ele: any) =>
        Object.values(ele).includes(ACTIVITY_TYPE_IDENTIFIER.FlightIcon)
      ) || false
    );
  }

  // console.log("DEST", destinationList);
  if (!destinationList) return <></>;

  return (
    <div className={styles.customTabPanelContainer}>
      <DiscardChanges
        open={openDiscardChangesDialog}
        onClose={handleCancelDicard}
        handleDiscard={handleDicard}
      />
      <EditActivityDialog
        editingActtivityTimeOfDay={editingActtivityTimeOfDay}
        open={openEditActivityDialog}
        inputValue={editingActivityValue}
        setInputValue={setEditingActivityValue}
        onClose={handleCloseEditActivityDialog}
        onSaveChanges={handleEditActivitySaveChanges}
        onDelete={handleEditActivityDelete}
        activityDate={activityDate}
      />

      <div
        className={styles.modifyAndImagesContainer}
        style={{ flexDirection: flexDirection }}
      >
        <div>
          <div className={styles.imageContainer}>
            <DestinationCardWithImages
              itineraryVersion={itineraryVersion}
              showArrowIcon={false}
              destinationList={destinationList}
            />
          </div>
          <Markdown className={styles.cityDescription}>
            {itineraryDescription}
          </Markdown>
        </div>
        {!isSavedActivityFlag && (
          <div className={styles.modifyButtonContainer}>
            {showEditActivityFlag ? (
              <>
                <Button
                  className={styles.doneButton}
                  onClick={handleEditActivityDone}
                >
                  DONE
                  <img src={doneIcon} alt="done" className={styles.doneIcon} />
                </Button>
                <div style={{ height: "35px" }}></div>
              </>
            ) : (
              <ButtonWithIcon
                className={styles.modifyBtn}
                icon={modifyEditIcon}
                text={
                  flexDirection === "column" ? "Modify" : "Modify Activities"
                }
                direction="row-reverse"
                onClick={handleShowEditActivity}
                disable={disableButtonFlag}
              />
            )}
          </div>
        )}
      </div>

      <Stack mx={"-20px"}>
        {/* <pre>{JSON.stringify(destinationList[0]?.tripDetails, null, 2)}</pre> */}
        {destinationList?.map(
          (destinationEle: iCityDetailsResult, firstIndex: number) =>
            destinationEle?.tripDetails?.map((ele: any, index: number) => {
              // const match = ele?.day?.match(/\d+/); // Matches any digit(s) in the string
              // let count = match ? parseInt(match[0], 10) - 1 : 0;
              return (
                <Stack key={index}>
                  <CustomAccordion
                    defaultExpanded={
                      (index === 0 && firstIndex === 0) ||
                      showEditActivityFlag
                    }
                    borderBottom={true}
                    headingComponent={
                      <div
                        className={styles.headingFlightComp}
                        onClick={() => {
                          if (handleHeadingClick) handleHeadingClick();
                        }}
                      >
                        <div>
                          {/* <p className={styles.dayAndCity}>Fri AUG 19</p> */}
                          {/* {ele?.date ? ( */}
                          {startDate !== "" ? (
                            <div className={styles.subHeadingContainer}>
                              <h1>
                                {getDayOfWeek(ele?.date)} &nbsp;{" "}
                                {getMonthDate(ele?.date)}
                                {/* {getDayOfWeek(
                                  String(
                                    new Date(
                                      new Date(startDate).setDate(
                                        new Date(startDate).getUTCDate() + count
                                      )
                                    )
                                  )
                                )}{" "}
                                &nbsp;{" "}
                                {getMonthDate(
                                  String(
                                    new Date(
                                      new Date(startDate).setDate(
                                        new Date(startDate).getUTCDate() + count
                                      )
                                    )
                                  )
                                )} */}
                              </h1>
                            </div>
                          ) : (
                            <div className={styles.subHeadingContainer}>
                              <h1>{ele?.day}</h1>
                              <h1>{ele?.city_name}</h1>
                            </div>
                          )}

                          <p className={styles.accordDesc}>
                            {ele?.day_itinerary_description}
                          </p>
                        </div>

                        {/* {showWeatherAdvisory ? (
                            <WeatherWidget
                              weatherData={weatherData}
                              setWeatherData={setWeatherData}
                              cityName={ele?.city_name || ""}
                              className={styles.weatherWidgetContainer}
                            />
                          ) : (
                            (showPlaneIcon || isShowingPlaneIcon(ele)) && (
                              <img
                                src={planeDepartureRegular}
                                alt="plane"
                                className={styles.planeDepartureIcon}
                              />
                            )
                          )} */}
                        {(showPlaneIcon || isShowingPlaneIcon(ele)) && (
                          <img
                            src={planeDepartureRegular}
                            alt="plane"
                            className={styles.planeDepartureIcon}
                          />
                        )}
                        {showWeatherAdvisory && (
                          <WeatherWidget
                            weatherData={weatherData}
                            setWeatherData={setWeatherData}
                            cityName={ele?.city_name || ""}
                            className={styles.weatherWidgetContainer}
                          />
                        )}
                      </div>
                    }
                    bodyComponent={
                      <Stack pt={1} spacing={1}>
                        {ele?.itinerary_by_time_of_the_day?.map(
                          (subEle: any, index: number) => {
                            return (
                              <div
                                key={index}
                                className={styles.activityContainer}
                              >
                                <div className={styles.timeInADay}>
                                  <h1 className={styles.title}>
                                    {subEle?.time_of_the_day}
                                  </h1>
                                  <Markdown className={styles.description}>
                                    {subEle?.description}
                                  </Markdown>
                                </div>
                                {showEditActivityFlag && (
                                  <IconButton
                                    onClick={() =>
                                      handleEditActivityLocal(subEle, ele?.date)
                                    }
                                    className={styles.iconButton}
                                  >
                                    <img
                                      className={styles.editIcon}
                                      src={modifyEditIcon}
                                      alt="edit"
                                    />
                                  </IconButton>
                                )}
                              </div>
                            );
                          }
                        )}
                      </Stack>
                    }
                  />
                </Stack>
              );
            })
        )}
      </Stack>
      {disableInput && (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
    </div>
  );
}
