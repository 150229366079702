import React from "react";
import styles from "./cardWithCounter.module.scss";
import { iCityDetailsResult } from "../../../types";
import { IconButton } from "@mui/material";
import {
  addIcon,
  deleteIcon,
  dragIcon,
  substractIcon,
} from "../../../constant";

export default function CardWithCounter({
  opacity = 0,
  data,
  index,
  onCheckboxChange,
  showSortIcon = true,
  handleIncrement,
  handleDecrement,
  visibility = "visible",
}: {
  opacity?: number;
  data: iCityDetailsResult;
  index: number;
  onCheckboxChange?: any;
  showSortIcon?: boolean;
  handleIncrement: any;
  handleDecrement: any;
  visibility: "visible" | "hidden";
}) {
  function handleContextMenu(event: any) {
    event.preventDefault();
  }
  return (
    <div className={styles.container}>
      <div className={styles.rowContainer}>
        <div className={styles.cardContainer}>
          <div className={styles.leftContainer}>
            <div
              className={styles.opacityContainer}
              style={{ opacity: opacity }}
            ></div>
            <div className={styles.imageContainer}>
              <img
                onContextMenu={handleContextMenu}
                src={data?.imageURL}
                alt=""
                className={styles.imgTag}
              />
            </div>
            <h3 className={styles.cityName}>{data?.cityName}</h3>
          </div>
          <div className={styles.btnCard}>
            <div className={styles.cartBtn}>
              <IconButton
                className={styles.iconBtnLeft}
                onClick={() => handleDecrement(index)}
              >
                <img
                  src={data.noOfDays === 1 ? deleteIcon : substractIcon}
                  alt=""
                  className={styles.img}
                />
              </IconButton>
              <p className={styles.count}>
                {visibility === "visible" ? data.noOfDays : "0"}
              </p>
              <IconButton
                className={styles.iconBtnRight}
                onClick={() => handleIncrement(index)}
              >
                <img src={addIcon} alt="" className={styles.img} />
              </IconButton>
            </div>
            <p className={styles.days}>{data.noOfDays > 1 ? "Days" : "Day"}</p>
          </div>
        </div>
        {showSortIcon && (
          <div
            className={styles.dragIconContainer}
            style={{ visibility: visibility }}
          >
            <img
              onContextMenu={handleContextMenu}
              src={dragIcon}
              alt=""
              className={styles.dragTag}
            />
          </div>
        )}
      </div>
    </div>
  );
}
