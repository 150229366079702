export const my_trips_events = {
  my_trips_page_load: {
    category: "My trips page loads",
    action: "User navigates to my trips page",
    label: "my-trips-page-load",
  },
  my_trips_upcoming_tab_click: {
    category: "User navigates to upcoming trips",
    action: "User clicks on upcoming button",
    label: "my-trips-upcoming-tab-click",
  },
  my_trips_past_tab_click: {
    category: "my-trips-past-tab-click",
    action: "User navigates to past trips",
    label: "User clicks on past trips button",
  },
  my_trips_cancelled_tab_click: {
    category: "User navigates to cancelled trips",
    action: "User clicks on cancelled trips button",
    label: "my-trips-cancelled-tab-click",
  },
  my_trips_saved_tab_click: {
    category: "User navigates to saved trips",
    action: "User clicks on saved trips tab",
    label: "my-trips-saved-tab-click",
  },
  my_trips_upcoming_trips_card_click: {
    category: "User navigates to trip details of upcoming trips",
    action: "User clicks on upcoming trips card",
    label: "my-trips-upcoming-trips-card-click",
  },
  my_trips_past_trips_card_click: {
    category: "User navigates to trip details of past trips",
    action: "User clicks on past trips card",
    label: "my-trips-past-trips-card-click",
  },
  my_trips_cancelled_trips_card_click: {
    category: "User navigates to trip details of cancelled trips",
    action: "User clicks on cancelled trips card",
    label: "my-trips-cancelled-trips-card-click",
  },
  my_trips_saved_trips_card_click: {
    category: "User navigates to trips details of saved trips",
    action: "User clicks on saved trips card",
    label: "my-trips-saved-trips-card-click",
  },
  my_trips_upcoming_trips_edit_click: {
    category: "User clicks upcoming trips edit icon",
    action: "User clicks on edit icon for upcomings trips card",
    label: "my-trips-upcoming-trips-edit-click",
  },
  my_trips_past_trips_edit_click: {
    category: "User clicks past trips edit icon",
    action: "User clicks on edit icon for past trips card",
    label: "my-trips-past-trips-edit-click",
  },
  my_trips_cancelled_trips_edit_click: {
    category: "User clicks cancelled trips edit icon",
    action: "User clicks on edit icon for cancelled trips card",
    label: "my-trips-cancelled-trips-edit-click",
  },
  my_trips_saved_trips_edit_click: {
    category: "User clicks saved trips edit icon",
    action: "User clicks on edit icon for saved trips card",
    label: "my-trips-saved-trips-edit-click",
  },
  my_trips_past_trips_book_again_click: {
    category: "User clicks to book the past trip",
    action: "User clicks on book again button in past trip card",
    label: "my-trips-past-trips-book-again-click",
  },
  my_trips_cancelled_trips_book_again_click: {
    category: "User clicks to book the cancelled trip",
    action: "User clicks on book again button in cancelled trip card",
    label: "my-trips-cancelled-trips-book-again-click",
  },
  my_trips_saved_trips_book_click: {
    category: "User clicks to book the saved trip",
    action: "User clicks on book button in saved trip card",
    label: "my-trips-saved-trips-book-click",
  },
  edit_trip_name_save_changes: {
    category: "User clicks to edit the trip name",
    action: "User clicks on save changes button in edit trip name popup",
    label: "edit-trip-name-save-changes",
  },
};
