import React from "react";
import updatedEditIcon from "../../../assets/updatedEditIcon.svg";
import styles from "./edit.module.scss";

interface iProps {
  onClick?: () => void;
}

function EditButton({ onClick }: iProps): JSX.Element {
  return (
    <div className={styles.main} onClick={onClick}>
      <p className={styles.editText}>EDIT</p>
      <img src={updatedEditIcon} alt="icon" className={styles.editIcon} />
    </div>
  );
}

export default EditButton;
