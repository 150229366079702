import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";
import styles from "./reOrderComponent.module.scss";
import { ReactSortable } from "react-sortablejs";
import { dragIcon } from "../../../constant";

export default function ReOrderComponent({
  checkboxChildren,
  sortChildren,
  list,
  setList,
}: {
  checkboxChildren: ReactNode,
  sortChildren: ReactNode,
  list: any;
  setList: Dispatch<SetStateAction<any>>;
}) {
  const [display, setDisplay] = useState("flex");
  return (
    <div className={styles.mainContainer}>
      <ReactSortable
        style={{ zIndex: 0, position: "relative" }}
        className={styles.adjustTrip}
        list={list}
        setList={setList}
        dragClass="sortableDrag"
        animation={200}
        easing="ease-out"
        // delayOnTouchOnly={true}
        // delay={80}
        onStart={() => setDisplay("none")}
        onEnd={() => setDisplay("flex")}
      >
        {sortChildren}
      </ReactSortable>

      <div
        className={styles.adjustTrip}
        style={{
          zIndex: 1,
          // top: "72px",
          display: display,
          marginRight: "50px",
          // backgroundColor: "blue"
          // border: "1px solid green",
        }}
      >
        {checkboxChildren}
      </div>
    </div>
  );
}
