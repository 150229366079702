export const flight_select_events = {
  flight_select_page_load: {
    category: "Flight select page load",
    action: "Flight select page loads",
    label: "flight-select-page-load",
  },
  flight_select_back_click: {
    category: "User navigates back to flight delay page",
    action: "User clicks on back button",
    label: "flight-select-back-click",
  },
  flight_select_selected_flight_accordion_click: {
    category:
      "Flight departure and landing details popup will open in the selected flight section",
    action: "User clicks on accordion button",
    label: "flight-select-selected-flight-accordion-click",
  },
  flight_select_selected_flight_details_accordion_click: {
    category: "Flight details will open",
    action: "User clicks on details accordion button",
    label: "flight-select-selected-flight-details-accordion-click",
  },
  flight_select_selected_flight_collapse_accordion_click: {
    category: "Flight details will hide",
    action: "User clicks on collapse accordion button",
    label: "flight-select-selected-flight-collapse-accordion-click",
  },
  flight_select_suggested_changes_your_plan_accordion_click: {
    category: "Suggestions options section will open",
    action:
      "User clicks on accordion button in suggested changes your plan section",
    label: "flight-select-suggested-changes-your-plan-accordion-click",
  },
  flight_select_suggested_changes_your_plan_checkbox_click: {
    category: "Opens the Flight departure and landing details popup ",
    action: "User clicks on check box",
    label: "flight-select-suggested-changes-your-plan-checkbox-click",
  },
  flight_select_confirm_click: {
    category: "User can confirms the selected flight",
    action: "User clicks on confirm button",
    label: "flight-select-confirm-click",
  },
};
