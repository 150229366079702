export function parseResultToJSON(response: any) {
  try {
    // return "JSON_ERROR"
    const element = [
      {
        start: "<restaurantJson>",
        end: "<restaurantJsonEnd>",
      },
      {
        start: "<activities>",
        end: "<activitiesEnd>",
      },
    ];
    let results: any = {};

    let startIndex = 0;
    let endIndex = 0;

    const data = `\n\n + ${response?.data?.result}`;

    for (let i = 0; i < element.length; i++) {
      const ele = element[i];
      if (data.indexOf(ele.start) > 0) {
        startIndex = data.indexOf(ele.start) + ele.start.length;
        endIndex = data.indexOf(ele.end);

        if (startIndex > 0 && endIndex > 0) {
          let subString = data.substring(startIndex, endIndex);
          results = JSON.parse(subString);
          // console.log("PARSE JSON", subString);
        }
      }
    }
    // console.log("PARSE JSON", results);
    return results;
  } catch (e) {
    //   captureException({
    //     e,
    //     result: response?.data?.result,
    //   });
    //   return "JSON_ERROR";
    // console.log("PARSE ERROR", e);
    return null;
  }
}
