import ReactGA from "react-ga4";
import { login_popup_events } from "./login-events";
import { home_and_sideBar_events } from "./home-and-sidebar-events";
import { edit_profile_popup_events } from "./edit-profile-events";
import { my_trips_events } from "./my-trips-events";
import { preview_preferences_events } from "./preview-preference-events";
import { current_trips_events } from "./current-trips-events";
import { flight_delay_events } from "./flight-delay-events";
import { flight_select_events } from "./flight-select-page-events";
import { weather_advisory_events } from "./weather-advisory-events";
import { explore_city_events } from "./explore-city-events";
import { trip_details_events } from "./trip-details-events";
import { new_trip_events } from "./new-trip-events";
import { home_page_events } from "./home-page-events";
import { itinerary_listing_events } from "./itinerary-listing-events";
import { itinerary_details_events } from "./itinerary-details-events";
import { faq_page_events } from "./Faq-page-events";
import { sideBar_navBar_events } from "./sideBar-navBar-events";
import { about_us_events } from "./aboutUs-events";
import { booking_page_events } from "./booking-page-events";
import { traveler_info_page_events } from "./traveler-info-events";

export enum GA_LABEL_ENUMS {
  // home page events
  Loads_Home_page = "Loads_Home_page",
  Clicks_Recommended_Trips_card = "Clicks_Recommended_Trips_card",
  Copying_Suggested_Trips = "Copying_Suggested_Trips",
  New_Trip_Click = "New_Trip_Click",
  Suggested_Trip_Click = "Suggested_Trip_Click",
  Send_Prompt_click = "Send_Prompt_click",
  Stop_click = "Stop_click",
  Show_more_click = "Show_more_click",
  home_upcoming_trip_click = "home_upcoming_trip_click",
  home_side_bar_open_click = "home_side_bar_open_click",
  home_side_bar_close_click = "home_side_bar_close_click",
  home_help_click = "home_help_click",
  home_preferences_click = "home_preferences_click",

  // navbar and side bar events
  Logo_Click = "Logo_Click",
  Terms_of_service_click = "Terms_of_service_click",
  Contact_us_click = "Contact_us_click",
  Login_in_click = "Login_in_click",
  My_trips_click = "My_trips_click",
  Current_trips_click = "Current_trips_click",
  History_click = "History_click",
  History_delete_click = "History_delete_click",
  Privacy_polcy_click = "Privacy_polcy_click",
  Logout = "Logout",
  Itinrary_click = "Itinrary_click",

  // login poup events
  Facebook_login_click = "Facebook_login_click",
  Google_login_click = "Google_login_click",
  Apple_login_click = "Apple_login_click",
  Forgot_password_click = "Forgot_password_click",
  Create_new_account_click = "Create_new_account_click",
  Login_with_email_click = "Login_with_email_click",

  // sign up events
  Sign_up_with_email = "Sign_up_with_email",

  // edit account popup events
  home_edit_profile_click = "home_edit_profile_click",
  home_submit_edit_profile_click = "home_submit_edit_profile_click",
  home_upload_image_click = "home_upload_image_click",
  home_delete_account_click = "home_delete_account_click",
  Profile_yes_delete_account_click = "Profile_yes_delete_account_click",
  Profile_delete_cancel_click = "Profile_delete_cancel_click",

  // my trips page events
  my_trips_page_load = "my_trips_page_load",
  my_trips_upcoming_tab_click = "my_trips_upcoming_tab_click",
  my_trips_past_tab_click = "my_trips_past_tab_click",
  my_trips_cancelled_tab_click = "my_trips_cancelled_tab_click",
  my_trips_saved_tab_click = "my_trips_saved_tab_click",
  my_trips_upcoming_trips_card_click = "my_trips_upcoming_trips_card_click",
  my_trips_past_trips_card_click = "my_trips_past_trips_card_click",
  my_trips_cancelled_trips_card_click = "my_trips_cancelled_trips_card_click",
  my_trips_saved_trips_card_click = "my_trips_saved_trips_card_click",
  my_trips_upcoming_trips_edit_click = "my_trips_upcoming_trips_edit_click",
  my_trips_past_trips_edit_click = "my_trips_past_trips_edit_click",
  my_trips_cancelled_trips_edit_click = "my_trips_cancelled_trips_edit_click",
  my_trips_saved_trips_edit_click = "my_trips_saved_trips_edit_click",
  my_trips_past_trips_book_again_click = "my_trips_past_trips_book_again_click",
  my_trips_cancelled_trips_book_again_click = "my_trips_cancelled_trips_book_again_click",
  my_trips_saved_trips_book_click = "my_trips_saved_trips_book_click",
  edit_trip_name_save_changes = "edit_trip_name_save_changes",

  // preview preferences events
  preview_preference_load = "preview_preference_load",
  preview_preference_close_click = "preview_preference_close_click",
  preview_preferences_edit_click = "preview_preferences_edit_click",
  preview_preferences_save_changes = "preview_preferences_save_changes",

  // current trips/today's page events
  current_trips_page_loads = "current_trips_page_loads",
  today_flight_delay_click = "today_flight_delay_click",
  today_weather_advisory_click = "today_weather_advisory_click",
  today_explore_city_click = "today_explore_city_click",
  today_view_trip_details_click = "today_view_trip_details_click",
  today_zenvoya_assistance_click = "today_zenvoya_assistance_click",

  // flight delay events
  flight_delay_page_load = "flight_delay_page_load",
  flight_delay_back_click = "flight_delay_back_click",
  flight_delay_details_click = "flight_delay_details_click",
  flight_delay_show_more_click = "flight_delay_show_more_click",
  flight_delay_select_click = "flight_delay_select_click",

  // Flight select page events
  flight_select_page_load = "flight_select_page_load",
  flight_select_back_click = "flight_select_back_click",
  flight_select_selected_flight_accordion_click = "flight_select_selected_flight_accordion_click",
  flight_select_selected_flight_details_accordion_click = "flight_select_selected_flight_details_accordion_click",
  flight_select_selected_flight_collapse_accordion_click = "flight_select_selected_flight_collapse_accordion_click",
  flight_select_suggested_changes_your_plan_accordion_click = "flight_select_suggested_changes_your_plan_accordion_click",
  flight_select_suggested_changes_your_plan_checkbox_click = "flight_select_suggested_changes_your_plan_checkbox_click",
  flight_select_confirm_click = "flight_select_confirm_click",

  // weather advisory events
  weather_advisory_page_load = "weather_advisory_page_load",
  weather_advisory_back_click = "weather_advisory_back_click",
  weather_advisory_suggested_changes_your_plan_accordion_click = "weather_advisory_suggested_changes_your_plan_accordion_click",
  weather_advisory_suggested_changes_your_plan_checkbox_click = "weather_advisory_suggested_changes_your_plan_checkbox_click",
  weather_advisory_apply_changes_click = "weather_advisory_apply_changes_click",

  // Explore city evetns
  explore_city_page_load = "explore_city_page_load",
  explore_city_back_click = "explore_city_back_click",
  explore_city_input_click = "explore_city_input_click",
  explore_city_stop_click = "explore_city_stop_click",
  explore_city_mic_send_click = "explore_city_mic_send_click",
  explore_city_mic_stop_click = "explore_city_mic_stop_click",
  explore_city_new_trip_click = "explore_city_new_trip_click",

  //New Trip
  new_trip_input_field_send_click = "new_trip_input_field_send_click",
  new_trip_input_field_stop_click = "new_trip_input_field_stop_click",
  new_trip_input_field_mic_send_click = "new_trip_input_field_mic_send_click",
  new_trip_input_field_mic_stop_click = "new_trip_input_field_mic_stop_click",

  //Home page
  home_zenvoya_logo_click = "home_zenvoya_logo_click",
  home_upcoming_trip_image_card_click = "home_upcoming_trip_image_card_click",
  home_copy_click = "home_copy_click",
  home_input_field_send_click = "home_input_field_send_click",
  home_input_field_stop_click = "home_input_field_stop_click",
  home_input_field_mic_send_click = "home_input_field_mic_send_click",
  home_input_field_mic_stop_click = "home_input_field_mic_stop_click",

  //Itinerary listing page
  top_destination_image_card_click = "top_destination_image_card_click",
  top_destination_input_field_send_click = "top_destination_input_field_send_click",

  //Itinerary details page
  itinerary_details_back_click = "itinerary_details_back_click",
  itinerary_details_save_click = "itinerary_details_save_click",
  itinerary_details_save_itinerary_click = "itinerary_details_save_itinerary_click",
  itinerary_details_save_itinerary_Xclose_click = "itinerary_details_save_itinerary_Xclose_click",
  itinerary_details_share_click = "itinerary_details_share_click",
  itinerary_details_book_click = "itinerary_details_book_click",
  itinerary_details_modify_activities_click = "itinerary_details_modify_activities_click",
  itinerary_details_activities_done_click = "itinerary_details_activities_done_click",
  itinerary_details_flight_details_accordion_click = "itinerary_details_flight_details_accordion_click",
  itinerary_details_accordion_click = "itinerary_details_accordion_click",
  itinerary_details_input_field_click = "itinerary_details_input_field_click",
  itinerary_details_input_field_stop_click = "itinerary_details_input_field_stop_click",
  itinerary_details_input_field_mic_send_click = "itinerary_details_input_field_mic_send_click",
  itinerary_details_input_field_mic_stop_click = " itinerary_details_input_field_mic_stop_click",


//FAQ page
faqs_back_button_click = "faqs_back_button_click",
faqs_back_downward_accordion_click = "faqs_back_downward_accordion_click",
faqs_back_upward_accordion_click = "faqs_back_upward_accordion_click",

//Side bar and nav bar 
side_menu_about_us_click = "side_menu_about_us_click",
side_menu_x_close_button_click = "side_menu_x_close_button_click",


//About-us page
about_us_back_button_click = "about_us_back_button_click",
about_us_try_it_now_button_click  = "about_us_try_it_now_button_click",

//Booking page
booking_back_click = "booking_back_click",
booking_flight_checkbox_click = "booking_flight_checkbox_click",
booking_hotel_checkbox_click = "booking_hotel_checkbox_click",
booking_flight_hotel_checkbox_click = "booking_flight_hotel_checkbox_click",
booking_collapse_accordion_click = "booking_collapse_accordion_click",
booking_downward_accordion_click = "booking_downward_accordion_click",
booking_travel_date_click = "booking_travel_date_click",
booking_travel_date_accordion_click = "booking_travel_date_accordion_click",
booking_travel_date_apply_click = "booking_travel_date_apply_click",
booking_travelers_click = "booking_travelers_click",
booking_travelers_add_remove_traveler_click = "booking_travelers_add_remove_traveler_click",
booking_travelers_add_remove_room_click = "booking_travelers_add_remove_room_click",
booking_travelers_date_apply_click = "booking_travelers_date_apply_click",
booking_property_type_click = "booking_property_type_click",
booking_property_type_star_click = "booking_property_type_star_click",
booking_property_apply_click = "booking_property_apply_click",
booking_route_and_class_click = "booking_route_and_class_click",
booking_route_and_class_route_click = "booking_route_and_class_route_click",
booking_route_and_class_class_click = "booking_route_and_class_class_click",
booking_route_and_class_apply_click = "booking_route_and_class_apply_click",
booking_airports_click = "booking_airports_click",
booking_airports_departure_airport_click = "booking_airports_departure_airport_click",
booking_airports_arrival_airport_click = "booking_airports_arrival_airport_click",
booking_airports_apply_click = "booking_airports_apply_click",
booking_flights_and_hotel_search_click = "booking_flights_and_hotel_search_click",
booking_flights_and_hotel_search_loader_page_click = "booking_flights_and_hotel_search_loader_page_click",
booking_flights_and_hotel_search_loader_page_cancel_click = "booking_flights_and_hotel_search_loader_page_cancel_click",
booking_flights_details_accordion_click = "booking_flights_details_accordion_click",
booking_flights_details_details_accordion_click = "booking_flights_details_details_accordion_click",
booking_flights_details_collapse_accordion_click = "booking_flights_details_collapse_accordion_click",
booking_flights_radio_click = "booking_flights_radio_click",
booking_flights_radio_loader_page_cancel_click = "booking_flights_radio_loader_page_cancel_click",
booking_flights_show_more_click = "booking_flights_show_more_click",
booking_flights_details_loader_page_cancel_click = "booking_flights_details_loader_page_cancel_click",
booking_hotels_view_details_accordion_click ="booking_hotels_view_details_accordion_click",
booking_hotels_select_accordion_click = "booking_hotels_select_accordion_click",
booking_hotels_select_a_room_image_accordion_click =  "booking_hotels_select_a_room_image_accordion_click",
booking_hotels_select_a_room_view_room_details_click = "booking_hotels_select_a_room_view_room_details_click",
booking_hotels_select_a_room_view_room_details_x_button_click = "booking_hotels_select_a_room_view_room_details_x_button_click",
booking_hotels_select_a_room_view_room_details_image_slider_accordion_click = "booking_hotels_select_a_room_view_room_details_image_slider_accordion_click",
booking_hotels_select_a_room_view_room_details_back_button_click = "booking_hotels_select_a_room_view_room_details_back_button_click",

booking_hotels_select_a_room_book_button_click = "booking_hotels_select_a_room_book_button_click",


booking_hotels_select_a_room_x_close_click = "booking_hotels_select_a_room_x_close_click",
booking_flight_hotels_show_more_button_click = "booking_flight_hotels_show_more_button_click",
booking_flight_hotels_continue_button_click = "booking_flight_hotels_continue_button_click",

//Traveler info page
traveler_info_back_button_click = "traveler_info_back_button_click",
traveler_info_downward_accordion_click = "traveler_info_downward_accordion_click",
traveler_info_upward_accordion_click = "traveler_info_upward_accordion_click",
traveler_info_first_name_input_field_click = "traveler_info_first_name_input_field_click",
traveler_info_middle_name_or_initial_input_field_click = "traveler_info_middle_name_or_initial_input_field_click",
traveler_info_last_name_input_field_click = "traveler_info_last_name_input_field_click",
traveler_info_dob_month_dropdown_click = "traveler_info_dob_month_dropdown_click",
traveler_info_dob_day_input_field_click = "traveler_info_dob_day_input_field_click",
traveler_info_dob_year_input_field_click = "traveler_info_dob_year_input_field_click",
traveler_info_gender_dropdown_click = "traveler_info_gender_dropdown_click ",
traveler_info_contact_info_section_email_input_field_click = "traveler_info_contact_info_section_email_input_field_click",
traveler_info_contact_info_section_country_code_dropdown_click = "traveler_info_contact_info_section_country_code_dropdown_click",
traveler_info_contact_info_section_mobile_phone_number_input_field_click = "traveler_info_contact_info_section_mobile_phone_number_input_field_click",traveler_info_frequent_flyer_section_program_name_dropdown_click = "traveler_info_frequent_flyer_section_program_name_dropdown_click",
traveler_info_frequent_flyer_program_section_frequent_flyer_number_input_field_click ="traveler_info_frequent_flyer_program_section_frequent_flyer_number_input_field_click",
traveler_info_tsa_precheck_number_input_field_click = "traveler_info_tsa_precheck_number_input_field_click",
traveler_info_meal_preferences_section_meal_type_dropdown_click = "traveler_info_meal_preferences_section_meal_type_dropdown_click",
traveler_info_special_travel_needs_section_bassinet_request_for_infant_checkbox_click = "traveler_info_special_travel_needs_section_bassinet_request_for_infant_checkbox_click",
traveler_info_special_travel_needs_section_wheel_chair_checkbox_click = "traveler_info_special_travel_needs_section_wheel_chair_checkbox_click",
traveler_info_continue_button_click = "traveler_info_continue_button_click",

traveler_information_x_close_button_click = "traveler_information_x_close_button_click",






  // Trip details page events
  trip_details_page_load = "trip_details_page_load",
  trip_details_back_click = "trip_details_back_click",
  trip_details_share_click = "trip_details_share_click",
  trip_details_new_trip_click = "trip_details_new_trip_click",
  trip_details_daily_plans_click = "trip_details_daily_plans_click",
  trip_details_daily_plans_flight_accordion_click = "trip_details_daily_plans_flight_accordion_click",
  trip_details_daily_plans_flight_deatils_accordion_click = "trip_details_daily_plans_flight_deatils_accordion_click",
  trip_details_daily_plans_flight_collapse_accordion_click = "trip_details_daily_plans_flight_collapse_accordion_click",
  trip_details_daily_plans_hotel_accordion_click = "trip_details_daily_plans_hotel_accordion_click",
  trip_details_daily_plans_hotel_view_deatils_accordion_click = "trip_details_daily_plans_hotel_view_deatils_accordion_click",
  trip_details_activities_click = "trip_details_activities_click",
  trip_details_daily_activity_accordion_click = "trip_details_daily_activity_accordion_click",
  trip_details_activities_modify_activities_click = "trip_details_activities_modify_activities_click",
  trip_details_activities_done_click = "trip_details_activities_done_click",
  trip_details_flights_click = "trip_details_flights_click",
  trip_details_flights_cancel_option_click = "trip_details_flights_cancel_option_click",
  trip_details_flights_change_flight_click = "trip_details_flights_change_flight_click",
  trip_details_flight_details_accordion_click = "trip_details_flight_details_accordion_click",
  trip_details_flight_collapse_accordion_click = "trip_details_flight_collapse_accordion_click",
  trip_details_flight_traveler_accordion_click = "trip_details_flight_traveler_accordion_click",
  trip_details_hotel_accordion_click = "trip_details_hotel_accordion_click",
  trip_details_hotel_view_deatils_accordion_click = "trip_details_hotel_view_deatils_accordion_click",
  trip_details_hotel_cancel_option_click = "trip_details_hotel_cancel_option_click",
  trip_details_hotel_change_hotel_click = "trip_details_hotel_change_hotel_click",
}

export const GA_EVENTS: any = {
  ...home_and_sideBar_events,
  ...login_popup_events,
  ...edit_profile_popup_events,
  // sign up event
  Sign_up_with_email: {
    category: "User signing up with email and password",
    action: "User clicks on create account to sign up with email and password",
    label: "Sign-up-with-email",
  },
  ...sideBar_navBar_events,
  ...home_page_events,
  ...my_trips_events,
  ...preview_preferences_events,
  ...current_trips_events,
  ...flight_delay_events,
  ...flight_select_events,
  ...weather_advisory_events,
  ...explore_city_events,
  ...trip_details_events,
  ...new_trip_events,
  ...itinerary_listing_events,
  ...itinerary_details_events,
  ...faq_page_events,
  ...about_us_events,
  ...booking_page_events,
  ...traveler_info_page_events
};

export const triggerGAEvent = (
  GAlabel: GA_LABEL_ENUMS,
  custom_label?: string
) => {
  //   console.log(GA_EVENTS[GAlabel]?.category, "Google events");
  const { category = "", action = "", label = "" } = GA_EVENTS[GAlabel] || {};
  ReactGA.event({
    category,
    action: label,
    label: action,
  });
};
