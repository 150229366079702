import styles from "./header.module.scss";
import CustomSideBar from "../../customSideBar/customSideBar";

export default function Header() {
  return (
    <div className={styles.mainContainer}>
      <CustomSideBar />
    </div>
  );
}
