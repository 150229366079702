import React from "react";
import { Box, Button } from "@mui/material";
import styles from "./customButton.module.scss";

export default function CustomButton({
  handleClick,
  buttonText,
}: {
  handleClick?: any;
  buttonText?: string;
}) {
  return (
    <Button
      size="small"
      onClick={handleClick}
      sx={{
        padding: "8px 20px",
        boxShadow: "none",
        fontSize: "12px",
        fontWeight: "700",
        color: "secondary.contrastText",
        textTransform: "initial",
        // minWidth: '100px',
        backgroundColor: "secondary.main",
        marginTop: "20px",
        alignSelf: "center",
        ":hover": { bgcolor: "secondary.light" },
      }}
      variant="contained">
      {buttonText}
    </Button>
  );
}
