import React, { ReactNode } from "react";
import styles from "./customDialog.module.scss";
import { Dialog, Stack } from "@mui/material";
import updateCloseIcon from "../../../assets/updateCloseIcon.svg";


export default function CustomDialog({
  open,
  onClose,
  children,
}: {
  open: boolean;
  onClose: any;
  children: ReactNode;
}) {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      // open={true}
      className={styles.main}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0,0,0,0.85)",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Stack alignSelf="end" mb={2} mr={1.2}>
        <img
          onClick={onClose}
          style={{ width: "25px", cursor: "pointer" }}
          src={updateCloseIcon}
          alt="cross"
        />
      </Stack>
      <Stack
        sx={{
          width: "100%",
          maxHeight: {
            sm: "80vh",
            xs: "80vh",
            md: "calc(100% - 5%)",
            lg: "calc(100% - 5%)",
          },
          overflow: "auto",
          bgcolor: "#f8f8f8",
          borderRadius: "8px",
        }}
      >
        {/* Dialog content */}
        {children}
      </Stack>
    </Dialog>
  );
}
