import styles from "./delete.module.scss";
import { iCityDetailsResult } from "../../../types";
import CardWithCounter from "../cardWithCounter/CardWithCounter";

interface iProps {
  sections: iCityDetailsResult[];
  setSections: React.Dispatch<React.SetStateAction<iCityDetailsResult[]>>;
  removedSections: iCityDetailsResult[];
  setRemovedSections: React.Dispatch<
    React.SetStateAction<iCityDetailsResult[]>
  >;
}

function DeletedCart({
  removedSections,
  setSections,
  setRemovedSections,
}: iProps): JSX.Element {
  const handleAddBack = (index: number) => {
    setRemovedSections((prevRemovedSections) => {
      const newRemovedSections = [...prevRemovedSections];
      const itemToAddBack = newRemovedSections.splice(index, 1)[0];
      setSections((prevSections) => [itemToAddBack, ...prevSections]);
      return newRemovedSections;
    });
  };

  return (
    <div className={styles.main}>
      {removedSections.map((i: any, index) => (
        <CardWithCounter
          handleDecrement={() => {}}
          handleIncrement={handleAddBack}
          data={i}
          index={index}
          showSortIcon={true}
          opacity={0.7}
          visibility="hidden"
        />
      ))}
    </div>
  );
}

export default DeletedCart;
