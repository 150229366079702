import React from "react";
import styles from "./step.module.scss";
import { Button } from "@mui/material";
import { expandIcon } from "../../../../../constant";
// import ArrivalOnDifferentDateBtn from "../../arrivalOnDifferentDateBtn/arrivalOnDifferentDateBtn";
import FlightDetails from "../../../flightDetails/flightDetails";
import {
  getDayOfWeek,
  getMonthDate,
  getTimeDifference,
  getTwelveHourTimeFormat,
  getYearYYYY,
  isSameDate,
} from "../../../../../utils/dateUtils";
import DateTimeInputToUTC from "../../../../../hooks/utcDateTimeFormate";

interface StepperProps {
  steps?: any[];
  stops?: any[];
  initialStep: number;
  finalStep: number;
  flightImage?: any;
  onCloseStepperFunc?: any;
  onOpenStepperFunc?: any;
}

const Stepper: React.FC<StepperProps> = ({
  flightImage = "",
  steps = [],
  stops = [],
  initialStep = 0,
  finalStep = 0,
  onCloseStepperFunc,
  onOpenStepperFunc,
}) => {
  const [showAllSteps, setShowAllSteps] = React.useState(false);

  const handleViewDetails = () => {
    if (showAllSteps && onCloseStepperFunc) onCloseStepperFunc();
    if (!showAllSteps && onOpenStepperFunc) onOpenStepperFunc();
    setShowAllSteps((prev) => !prev);
  };

  function isLayover(booleanData: any) {
    return booleanData;
  }

  function addHHMM(time: string) {
    if (typeof time !== "string" || time.split(":").length < 2) return "";
    const splitTime = time.split(":");
    return splitTime[0] + "h " + splitTime[1] + "m";
  }

  function checkIfArrivalIsNextDate(timestamp1: string, timestamp2: string) {
    if (!timestamp1 || !timestamp2) return false;
    const date1 = timestamp1.split("-");
    const date2 = timestamp2.split("-");
    return !(
      date1[0] === date2[0] &&
      date1[1] === date2[1] &&
      date1[2].substring(0, 2) === date2[2].substring(0, 2)
    );
  }

  if (steps.length === 0) {
    return <div>No data found</div>;
  }

  return (
    <div className={styles.main}>
      <div className={styles.stepper}>
        {steps.map((step: any, index: number) => {
          // if (index < initialStep || index > finalStep) return null;
          // console.log(steps);
          // const shouldDisplayStep =
          //   showAllSteps ||
          //   index === initialStep ||
          //   index === finalStep ||
          //   steps.length <= 2;

          // if (!shouldDisplayStep) return null;

          return (
            <React.Fragment key={index}>
              {/* Departure */}
              {(showAllSteps || index === 0) && (
                <div className={styles.step}>
                  <div className={styles.circle}></div>
                  <div className={styles.stepContent}>
                    <p className={styles.title}>
                      <span className={styles.airportCode}>
                        {step?.depAirportCode}{" "}
                        {step?.depAirportName && (
                          <span className={styles.airportName}>
                            | {step?.depAirportName}
                          </span>
                        )}
                      </span>
                    </p>
                    <p
                      className={`${step.layOver ? styles.delayDescription : styles.description}`}
                    >
                      {/* {DateTimeInputToUTC(step?.departureDate)} */}
                      {step?.departureDate &&
                        getDayOfWeek(step?.departureDate, "long") +
                          ", " +
                          getMonthDate(step?.departureDate) +
                          " " +
                          getYearYYYY(step?.departureDate) +
                          " | " +
                          getTwelveHourTimeFormat(step?.departureDate)}
                    </p>
                    {index === initialStep && (
                      <Button
                        endIcon={
                          <img
                            src={expandIcon}
                            alt=""
                            className={`${showAllSteps ? styles.hideIcon : styles.viewIcon}`}
                          />
                        }
                        className={styles.viewDetailsButton}
                        onClick={handleViewDetails}
                      >
                        {showAllSteps ? "Collapse" : "Details"}
                      </Button>
                    )}
                    {showAllSteps && (
                      <div className={styles.card}>
                        <div className={styles.subCard}>
                          <FlightDetails
                            title={step?.marketingAirline}
                            subTitle={`Flight ${step?.operatingAirline}-${step?.flightNumber}`}
                            description={""}
                            imgUrl={step?.airlineImage || flightImage}
                          />
                          <FlightDetails
                            title={"Departure"}
                            subTitle={""}
                            description={`${getTwelveHourTimeFormat(step?.departureDate)} | Flight time: 
                            ${addHHMM(step?.flyDuration) || ""}
                            `}
                            imgUrl={""}
                          />
                          {/* ${getTimeDifference(step?.departureDate, step?.arrivalDate)} */}
                          {/* <FlightDetails
                            title={"Seats"}
                            subTitle={""}
                            description={""}
                            imgUrl={""}
                          /> */}
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`${step.layOver ? styles.dashLine : styles.line}`}
                  ></div>
                </div>
              )}

              {/* Arrival */}
              {(showAllSteps || index !== 0 || steps?.length === 1) && (
                <div className={styles.step}>
                  <div className={styles.circle}></div>
                  <div className={styles.stepContent}>
                    <p className={styles.title}>
                      <span className={styles.airportCode}>
                        {step?.arrAirportCode}{" "}
                        {step?.arrAirportName && (
                          <span className={styles.airportName}>
                            | {step?.arrAirportName}
                          </span>
                        )}
                      </span>
                      {/* <span className={styles.airportCode}>
                        {step?.arrAirportCode}
                      </span>{" "}
                      {step?.arrAirportName && <>| {step?.arrAirportName}</>} */}
                    </p>
                    <p
                      className={`${checkIfArrivalIsNextDate(step?.departureDate, step?.arrivalDate) ? styles.delayDescription : styles.description}`}
                    >
                      {/* {getDayOfWeek(step?.departureDate, "long")}{" "} */}
                      {step?.arrivalDate &&
                        getDayOfWeek(step?.arrivalDate, "long") +
                          ", " +
                          getMonthDate(step?.arrivalDate) +
                          " " +
                          getYearYYYY(step?.arrivalDate) +
                          " | " +
                          getTwelveHourTimeFormat(step?.arrivalDate)}
                      {/* {", "}
                      {getYearYYYY(step?.departureDate)} */}
                    </p>
                    {showAllSteps && (
                      <div className={styles.card}>
                        <div className={styles.subCard}>
                          <FlightDetails
                            title={"Arrival"}
                            subTitle={""}
                            description={getTwelveHourTimeFormat(
                              step?.arrivalDate
                            )}
                            imgUrl={""}
                          />
                          {/* {isLayover(steps?.length - 1 !== index) && (
                          )} */}
                          {isLayover(steps?.length - 1 !== index) && (
                            <FlightDetails
                              title={"Layover"}
                              subTitle={""}
                              description={
                                addHHMM(stops[index]?.layover)
                                // getTimeDifference(
                                // stops[index]?.arrivalDate,
                                // stops[index]?.departureDate
                                // )
                              }
                              imgUrl={""}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`${isLayover(steps?.length - 1 !== index) ? styles.dashLine : styles.line}`}
                    ></div>
                  )}
                </div>
              )}
            </React.Fragment>
            // <React.Fragment key={index}>
            //   <div className={styles.step}>
            //     <div className={styles.circle}></div>
            //     <div className={styles.stepContent}>
            //       <p className={styles.title}>
            //         <span className={styles.airportCode}>
            //           {step.airPortCode}
            //         </span>{" "}
            //         | {step.airportName}
            //       </p>
            //       <p
            //         className={`${step.layOver ? styles.delayDescription : styles.description}`}>
            //         {step.description}
            //       </p>
            //       {index === initialStep && (
            //         <Button
            //           endIcon={
            //             <img
            //               src={expandIcon}
            //               alt=""
            //               className={`${showAllSteps ? styles.hideIcon : styles.viewIcon}`}
            //             />
            //           }
            //           className={styles.viewDetailsButton}
            //           onClick={handleViewDetails}>
            //           {showAllSteps ? "Collapse" : "Details"}
            //         </Button>
            //       )}
            //       {showAllSteps && (
            //         <div className={styles.card}>
            //           {step.cardInfo.map((info, cardIndex) => (
            //             <div key={cardIndex} className={styles.subCard}>
            //               {info.flightDetails && (
            //                 <FlightDetails
            //                   title={
            //                     info.flightDetails.flightName ||
            //                     "Flight Details"
            //                   }
            //                   subTitle={info.flightDetails.flightNumber}
            //                   description={""}
            //                   imgUrl={info.flightDetails.flightImage}
            //                 />
            //               )}
            //               {info.departure && (
            //                 <FlightDetails
            //                   title={info.departure.departure}
            //                   subDescription={info.departure.flightTime}
            //                   description={info.departure.departureTime}
            //                 />
            //               )}
            //               {info.seats && (
            //                 <div className={styles.seatCard}>
            //                   <FlightDetails
            //                     title={info.seats.seats}
            //                     description={info.seats.seatNumb}
            //                   />
            //                   {info.seats.boardingPasses && (
            //                     <Badge
            //                       sx={{
            //                         display: "none",
            //                         "& .MuiBadge-badge": {
            //                           backgroundColor: "#FF3B30",
            //                           color: "white",
            //                           left: "32px",
            //                           top: "-5px",
            //                         },
            //                       }}
            //                       overlap="circular"
            //                       anchorOrigin={{
            //                         vertical: "top",
            //                         horizontal: "left",
            //                       }}
            //                       badgeContent={4}>
            //                       <Button
            //                         startIcon={
            //                           <img
            //                             src={ticketsAirlineRegular}
            //                             alt="boarding"
            //                             className={styles.passIcon}
            //                           />
            //                         }
            //                         className={styles.boardingBtn}>
            //                         Boarding Passes
            //                       </Button>
            //                     </Badge>
            //                   )}
            //                 </div>
            //               )}
            //               {info.arrival && (
            //                 <FlightDetails
            //                   title={info.arrival.arrival}
            //                   description={info.arrival.arrivalTime}
            //                   destinationImg={info.arrival.destinationImg}
            //                 />
            //               )}
            //               {info.layover && (
            //                 <FlightDetails
            //                   title={info.layover.layover}
            //                   description={info.layover.arrivalTime}
            //                 />
            //               )}
            //             </div>
            //           ))}
            //         </div>
            //       )}
            //     </div>
            //     {index < steps.length - 1 && (
            //       <div
            //         className={`${step.layOver ? styles.dashLine : styles.line}`}></div>
            //     )}
            //   </div>
            // </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
