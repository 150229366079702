const FormatInputToUTC = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    timeZone: "UTC",
  };
  const date = new Date(dateString);
  const weekday = date.toLocaleDateString("en-US", {
    weekday: "short",
    timeZone: "UTC",
  });
  const formattedDate = date.toLocaleDateString("en-US", options).toUpperCase();
  return `${weekday} ${formattedDate}`;
};

export default FormatInputToUTC;
