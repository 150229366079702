import { Dispatch, SetStateAction } from "react";
import {
  AirportDataTypes,
  BookingDetails,
  FlightSelectedOptions,
  HotelPagination,
  iAppConfig,
  iChatData,
  showMoreTypes,
  UserInfo,
} from "../../types";

/**
 * User details Interface
 */
export interface iUserDetails {
  id?: string | any;
  name?: string;
  email?: string | any;
  loginType?: string;
  profile_pic?: string;
  displayQuery?: boolean;
  phone?: string | any;
  loggedIn?: boolean;
  preferences?: iTravelPreferences;
  role?: any;
}
interface iHomeCity {
  city?: string;
  state?: string;
  country?: string;
}

interface iTravelPreferences {
  id?: string;
  userId?: string;
  homeCity?: iHomeCity;
  climate?: string[]; // assuming this is an array of strings, adjust if needed
  population?: string[]; // assuming this is an array of strings, adjust if needed
  experiences?: string[];
  travelMonth?: string | null;
  budget?: string;
  duration?: string;
  accommodations?: string[];
  foodPreferences?: string[];
  others?: string | null;
  createdAt?: string;
  updatedAt?: string;
}
/**
 *
 */
export interface iSelectedHotelRoom {
  selectedRoomId: string;
  selectedHotelId: string;
  checkIn: string;
  checkOut: string;
  responseUniqueId: string;
  preBookId: string;
  sessionId: string;
  type: string;
  price: number;
  actualPrice: number;
  feesIncluded: boolean;
  taxIncluded: boolean;
  isBooked: boolean;
}

export interface iSelectedHotelRoomArr {
  checkIn: string;
  checkOut: string;
  city: string;
  selectedHotelRoom: iSelectedHotelRoom | null;
}

export enum flyerProgramType {
  none = "None",
}

export interface iGlobalContext {
  showBookingPreferences: boolean;
  setShowBookingPreferences: React.Dispatch<React.SetStateAction<boolean>>;
  isMyTripsTheFirstSelectedScreen: boolean;
  setIsMyTripsTheFirstSelectedScreen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  flightPreferencesOptions: {
    flightPreferencesScrollValue: number;
    selectedTimings: any;
    isSelectedRefundable: boolean;
    selectedAirlineOptions: any;
  };
  setFlightPreferencesOptions: React.Dispatch<
    React.SetStateAction<{
      flightPreferencesScrollValue: number;
      selectedTimings: any;
      isSelectedRefundable: boolean;
      selectedAirlineOptions: any;
    }>
  >;
  setHotelPreferencesOptions: React.Dispatch<
    React.SetStateAction<{
      isSelectedHotelRefundable: boolean;
      selectedHotelOptions: any;
    }>
  >;
  hotelPreferencesOptions: {
    isSelectedHotelRefundable: boolean;
    selectedHotelOptions: any;
  };
  isUserJustBookedTrip: boolean;
  setIsUserJustBookedTrip: React.Dispatch<React.SetStateAction<boolean>>;
  inputFocus: any;
  setInputFocus: any;
  activeTripDetailsTab: any;
  setActiveTripDetailsTab: any;
  hasMoreTripPagination: any;
  bookedtransIds: any;
  setBookedTransIds: React.Dispatch<any>;
  setHasMoreTripPagination: any;
  shouldUserNavigateBacktoTripDetailsPage: boolean;
  setShouldUserNavigateBackTotripDetailsPage: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsUserNavigatingFromPaymentGateway: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isUserNavigatingFromPaymentGateway: boolean;
  isFetchingAirports: boolean;
  setIsFetchingAirports: React.Dispatch<React.SetStateAction<boolean>>;
  isFetchingHotelDetails: boolean;
  setIsFetchingHotelDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCheckingFlight: React.Dispatch<React.SetStateAction<boolean>>;
  isCheckingFlight: boolean;
  setCheckFlightDetails: React.Dispatch<React.SetStateAction<boolean>>;
  checkFlightDetails: boolean;
  setSelectedHotelRoomArr: React.Dispatch<
    React.SetStateAction<iSelectedHotelRoomArr[]>
  >;
  isFetchingHotels: boolean;
  setIsFetchingHotels: React.Dispatch<React.SetStateAction<boolean>>;
  isFetchingFlights: boolean;
  setIsFetchingFlights: React.Dispatch<React.SetStateAction<boolean>>;
  selectedHotelRoomArr: iSelectedHotelRoomArr[];
  hotelData: any;
  setHotelData: React.Dispatch<any>;
  setSelectedHotelRoom: React.Dispatch<
    React.SetStateAction<iSelectedHotelRoom | null>
  >;
  selectedHotelRoom: iSelectedHotelRoom | null;
  setGetHotelRoomsBody: React.Dispatch<any>;
  getHotelRoomsBody: any;
  hotelDetailsPageState: any;
  setHotelDetailsPageState: React.Dispatch<any>;
  showPropertyDetails: boolean;
  setShowPropertyDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReviews: React.Dispatch<React.SetStateAction<boolean>>;
  showReviewsModal: boolean;
  showSelectRoomModal: boolean;
  setShowSelectRoomModal: React.Dispatch<React.SetStateAction<boolean>>;
  openOverlayScreen: boolean;
  setHotelDetailsPageId: React.Dispatch<React.SetStateAction<string>>;
  hotelDetailsPageId: string;
  setOpenOverlayScreen: Dispatch<SetStateAction<boolean>>;
  deepLinkData: any;
  setDeepLinkData: any;
  tripPlanDataStream: any;
  setTripPlanDataStream: any;
  query: any;
  firstScreen: string;
  setQuery: any;
  dataStream: any;
  setDataStream: any;
  startDestCountry: any;
  setStartDestCountry: any;
  abortTripAPICall: any;
  setAbortTripAPICall: any;
  startingValue: any;
  setStartingValue: any;
  destValue: any;
  setDestValue: any;
  userLocation: any;
  setUserLocation: any;
  nav: any;
  setNav: any;
  climate: any;
  setClimate: any;
  population: any;
  setPopulation: any;
  experience: any;
  setExperience: any;
  data: any;
  setData: any;
  latLong: any;
  setLatLong: any;
  apiResponse: any;
  setApiResponse: any;
  headerBackground: any;
  setHeaderBackground: any;
  zip: any;
  setZip: any;
  firstTwoCategoryState: any;
  setFirstTwoCategoryState: any;
  selectedDestFlag: any;
  setSelectedDestFlag: any;
  accomodations: any;
  setAccomodations: any;
  foodPreferences: any;
  setFoodPreferences: any;
  months: any;
  setMonths: any;
  budget: any;
  setBudget: any;
  loadingScreenState: any;
  setLoadingScreenState: any;
  budgetDurationFlag: any;
  setBudgetDurationFlag: any;
  menuOpenState: any;
  setMenuOpenState: any;
  loginCreateAccountState: any;
  setLoginCreateAccountState: any;
  saveScreenState: any;
  setSaveScreenState: any;
  test: any;
  setTest: any;
  editState: any;
  setEditState: any;
  isLogin: any;
  setIsLogin: any;
  userDetails: iUserDetails;
  setUserDetails: Dispatch<SetStateAction<iUserDetails>>;
  prevNav: any;
  setPrevNav: any;
  internationDest: any;
  setInternationalDest: any;
  prevRoute: any;
  setPrevRoute: any;
  isLoggedIn: any;
  setIsLoggedIn: any;
  sliderValue: any;
  setSliderValue: any;
  signInClick: any;
  setSignInClick: any;
  savedItinerariesList: any;
  setSavedItinerariesList: any;
  itinerayDetails: any;
  setItineraryDetails: any;
  tripForNDays: any;
  setTripForNDays: any;
  addMoreId: any;
  setAddMoreId: any;
  moreButton: any;
  setMoreButton: any;
  expHeight: any;
  setExpHeight: any;
  singleItineraryDetails: any;
  setSingleItineraryDetails: any;
  fetchingPlanTripMessage: any;
  setFetchingPlanTripMessage: any;
  resetPasswordToken: any;
  setResetPasswordToken: any;
  extraMeta: any;
  setExtraMeta: any;
  editViewState: any;
  setEditViewState: any;
  sharedItineraryId: any;
  setSharedItineraryId: any;
  itineraryDetailsLoad: any;
  setItineraryDetailsLoad: any;
  showMoreFlag: any;
  setShowMoreFlag: any;
  metaData: any;
  setMetaData: any;
  shareClick: boolean;
  setShareClick: Dispatch<SetStateAction<boolean>>;
  editExperienceFlag: boolean;
  setEditExperienceFlag: Dispatch<SetStateAction<boolean>>;
  editClimatePopulationFlag: boolean;
  setEditClimatePopulationFlag: Dispatch<SetStateAction<boolean>>;
  isAdmin: boolean;
  setIsAdmin: Dispatch<SetStateAction<boolean>>;
  chatInputText: string;
  setChatInputText: Dispatch<SetStateAction<string>>;
  itineraryDetailsData: any;
  setItineraryDetailsData: any;
  showMoreReqBody: any;
  setShowMoreReqBody: any;
  chatData: iChatData[];
  setChatData: Dispatch<SetStateAction<iChatData[]>>;
  selectedChatData: iChatData;
  setSelectedChatData: Dispatch<SetStateAction<iChatData>>;
  enableMultiSelect: boolean;
  setenableMultiSelect: Dispatch<SetStateAction<boolean>>;

  openDetailsSliderFlag: boolean;
  setOpenDetailsSliderFlag: Dispatch<SetStateAction<boolean>>;
  isMobile: boolean;
  setIsMobile: Dispatch<SetStateAction<boolean>>;
  isItinerarySavedFlag: boolean;
  setIsItinerarySavedFlag: Dispatch<SetStateAction<boolean>>;
  scrollContainerRef: any;
  screen: string;
  setScreen: Dispatch<SetStateAction<string>>;
  setFirstScreen: React.Dispatch<React.SetStateAction<string>>;
  profile: string;
  setProfile: Dispatch<SetStateAction<string>>;
  userInfo: UserInfo;
  setUserInfo: Dispatch<SetStateAction<UserInfo>>;
  bookingDetails: BookingDetails;
  setBookingDetails: Dispatch<SetStateAction<BookingDetails>>;
  showMore: showMoreTypes;
  setShowMore: Dispatch<SetStateAction<showMoreTypes>>;
  flightData: any;
  setFlightData: Dispatch<SetStateAction<any>>;
  isOpenMobileSidebar: boolean;
  setIsOpenMobileSidebar: Dispatch<SetStateAction<boolean>>;
  appConfig: iAppConfig[];
  setAppConfig: Dispatch<SetStateAction<iAppConfig[]>>;
  modifyItineraryPrompt: string;
  setModifyItineraryPrompt: Dispatch<SetStateAction<string>>;
  trigerredListItineraryAPIFlag: boolean;
  setTrigerredListItineraryAPIFlag: Dispatch<SetStateAction<boolean>>;
  // activeTabIndex: number;
  // setActiveTabIndex: Dispatch<SetStateAction<number>>;
  selectedFlightOptions: FlightSelectedOptions;
  setSelectedFlightOptions: Dispatch<SetStateAction<FlightSelectedOptions>>;
  selectedCityListPrompContext: any;
  setSelectedCityListPrompContext: any;
  flightAvailabilityData: any;
  setFlightAvailabilityData: any;
  tripOrItinearyId: string;
  setTripOrItinearyId: Dispatch<SetStateAction<string>>;
  airPorts: AirportDataTypes[];
  setAirPorts: Dispatch<SetStateAction<AirportDataTypes[]>>;
  hotelPagination: HotelPagination[];
  setHotelPagination: Dispatch<SetStateAction<HotelPagination[]>>;
  myTripActiveTab: string;
  setMyTripActiveTab: Dispatch<SetStateAction<string>>;
}
