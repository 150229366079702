import { Radio, RadioProps } from "@mui/material";
import styles from "./custom.module.scss";

export function CustomRadio(props: RadioProps) {
  return (
    <Radio
      // disableRipple
      color="default"
      {...props}
      inputProps={{ type: "radio" }}
      icon={<span className={styles.icon} />}
      checkedIcon={
        <span className={styles.checkedIcon}>
          <span className={styles.selected} />
        </span>
      }
    />
  );
}
