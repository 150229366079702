import styles from "./upcomingTripCard.module.scss";
import { iChatData, iCityDetailsResult } from "../../../types";
import { Skeleton } from "@mui/material";
import { arrowNextIcon } from "../../../constant";
import {
  getDDYYYY,
  getMonthDate,
  getTwelveHourTimeFormat,
  getYear,
} from "../../../utils/dateUtils";
import { getPassengerCount, getTripName } from "../../../utils";

export default function UpcomingTripCard({
  data = {},
  onCardClick,
  showNextIcon = false,
  tripName = "",
}: {
  data?: any;
  onCardClick?: any;
  showNextIcon?: any;
  tripName?: string;
}) {
  const destinationList =
    typeof data?.metaData === "string"
      ? (data?.metaData &&
          JSON.parse(data?.metaData)[0]?.selectedChatData?.destinationList) ||
        []
      : data?.metaData?.selectedChatData?.destinationList || [];
  // console.log({ data });
  const { travellersInfo = {} } = data;

  return (
    <div className={styles.upcomingtripSubContainer}>
      <div className={styles.mainContainer} onClick={onCardClick}>
        {/* {destinationList.length > 1 && ( */}
        <div className={styles.tripDate}>
          {/* <p>AUG 12 - 23, 2024</p> */}
          <p>
            {getMonthDate(data?.startDate)} - {getDDYYYY(data?.endDate)}
          </p>
        </div>
        {/* )} */}

        <div className={styles.imageContainer}>
          {destinationList[destinationList?.length - 1]?.imageURL ? (
            destinationList?.map((ele: iCityDetailsResult) => (
              <img
                src={ele?.imageURL ? ele?.imageURL : "https://error.error"}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/destinationPlaceholder.png";
                  currentTarget.style.objectFit = "cover";
                  currentTarget.style.height = "100%";
                  currentTarget.style.width = `${100 / destinationList.length}%`;
                }}
                style={{ width: `${100 / destinationList.length}%` }}
                className={styles.img}
                // src={ele.imageURL}
                alt="city Image"
              />
            ))
          ) : (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"100%"}
              height={"100%"}
            />
          )}
        </div>

        <div className={styles.placeHeadingContainer}>
          <div className={styles.placeHeadingMobContainer}>
            <h1 className={styles.placeHeadingMob}>
              {data?.tripName ||
                getTripName({ destinationList: destinationList } as iChatData)}
              {/* {destinationList.reduce(
              (acc: any, ele: iCityDetailsResult, index: number) =>
                index === destinationList.length - 1
                  ? acc.substring(0, acc.length - 2) +
                    // " and " +
                    ele.cityName?.split(",")[0]
                  : ele.cityName?.split(",")[0] + ", ",
              ""
            )} */}
              {/* {.cityName?.split(",")[0]} */}
            </h1>

            <h2>
              {/* 2 adults, 2 children */}
              {getPassengerCount(
                travellersInfo?.adultsCount
                  ? Number(travellersInfo?.adultsCount)
                  : 0,
                travellersInfo?.childrenCount
                  ? Number(travellersInfo?.childrenCount)
                  : 0,
                travellersInfo?.seniorsCount
                  ? Number(travellersInfo?.seniorsCount)
                  : 0
              )}
            </h2>
          </div>
          {showNextIcon && (
            <img
              className={styles.nextIcon}
              // src={selectedIcon}
              src={arrowNextIcon}
              alt="selected"
            />
          )}
        </div>
      </div>

      <h1 className={styles.bookedDate}>
        Booked on{" "}
        {getMonthDate(data?.updatedAt) + " " + getYear(data?.updatedAt)} |{" "}
        {getTwelveHourTimeFormat(data?.updatedAt)}
      </h1>
    </div>
  );
}
