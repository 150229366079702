import React, { ReactNode } from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import styles from "./singleDatePickerPopover.module.scss";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { expandIcon } from "../../../../constant";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";

export default function SingleDatePickerPopover({
  popoverTrigger,
  value,
  setValue,
  isPopoverOpen,
  setIsPopoverOpen,
  handleApplyButton,
}: {
  popoverTrigger: ReactNode;
  value: any;
  setValue: any;
  isPopoverOpen: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleApplyButton: () => void;
}) {
  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={
        <div className={styles.mainContainer}>
          <Calendar
            minDate={new Date()}
            onChange={setValue}
            value={value}
            prevLabel={
              <img
                className={styles.prevIcon}
                src={expandIcon}
                alt="Previous"
                onClick={() => {
                  triggerGAEvent(
                    GA_LABEL_ENUMS.booking_travel_date_accordion_click
                  );
                }}
              />
            }
            nextLabel={
              <img
                className={styles.nextIcon}
                src={expandIcon}
                alt="Next"
                onClick={() => {
                  triggerGAEvent(
                    GA_LABEL_ENUMS.booking_travel_date_accordion_click
                  );
                }}
              />
            }
          />
        </div>
      }
      handleApplyButton={handleApplyButton}
    />
  );
}
