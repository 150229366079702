import { ReactNode } from "react";
import { Toaster } from "react-hot-toast";
import { toastMessage } from "../helpers/toast/toastMessage";

interface ToastProviderProps {
  children: ReactNode;
}

const ToastProvider = ({ children }: ToastProviderProps) => (
  <>
    <Toaster position="top-center" reverseOrder={false} />
    {children}
  </>
);

export { ToastProvider, toastMessage };
