import {
  bedFontLight,
  bedWhite,
  planeDepartureRegular,
  planeDepartureRegularWhite,
} from "../../../constant";
import styles from "./flight.module.scss";

interface iProps {
  flight: boolean;
  selected: boolean;
}

function FlightHotelSelect({ flight, selected }: iProps): JSX.Element {
  return (
    <div className={styles.main}>
      <div className={`${selected ? styles.activeCard : styles.card}`}>
        {flight ? (
          <img
            src={selected ? planeDepartureRegularWhite : planeDepartureRegular}
            alt=""
            className={styles.flightHotelIcon}
          />
        ) : (
          <img
            src={selected ? bedWhite : bedFontLight}
            alt=""
            className={styles.flightHotelIcon}
          />
        )}
        {selected ? (
          <p className={styles.select}>Selected</p>
        ) : (
          <p className={styles.notSelect}>select</p>
        )}
      </div>
    </div>
  );
}

export default FlightHotelSelect;
