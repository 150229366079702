export const ActivitiesData = [
  "Historical Sights",
  "Nightlife",
  "Unique Foods",
  "Hiking",
  "Festivals",
  "Major / Special Events",
  "Biking",
  "Theme Parks",
  "Beaches",
  "Boating",
  "Fishing",
  "Water Sports",
];

export const AccommodationData = [
  { id: 1, data: "Boutique Hotels" },
  { id: 2, data: "Large Hotels" },
  { id: 3, data: "Motels" },
  { id: 4, data: "Bed & Breakfast" },
  { id: 5, data: "Guest Houses" },
  { id: 6, data: "Home Stays" },
];

export const FoodPreferData = [
  { id: 1, data: "Vegetarian" },
  { id: 2, data: "Gluten-Free" },
  { id: 3, data: "Coelia" },
  { id: 4, data: "Dairy-free" },
  { id: 5, data: "Lactose-free" },
  { id: 6, data: "Vegan" },
  { id: 7, data: "Paleo" },
  { id: 8, data: "Pescetian" },
  { id: 9, data: "Low FODMAP" },
  { id: 10, data: "Keto" },
  { id: 11, data: "Hala" },
  { id: 12, data: "Kosher" },
  { id: 13, data: "Hindu" },
  { id: 14, data: "Jain" },
  { id: 15, data: "Diabetes" },
];

export const ClimateInfo = [
  { id: 1, data: "Rainforest" },
  { id: 2, data: "Tropical" },
  { id: 3, data: "Mediterranean" },
  { id: 4, data: "Desert" },
  { id: 5, data: "Grassland" },
  { id: 6, data: "Arctic" },
  { id: 7, data: "Mountains" },
  { id: 8, data: "Rivers" },
];

export const PopulationInfo = [
  { id: 1, data: "Big City" },
  { id: 2, data: "Urban" },
  { id: 3, data: "Suburbs" },
  { id: 4, data: "Small City" },
  { id: 5, data: "Village / Hamlet" },
  { id: 6, data: "Rural" },
  { id: 7, data: "Remote" },
];

export const experienceData = [
  { id: 1, data: "Historical Sights" },
  { id: 2, data: "Nightlife" },
  { id: 3, data: "Unique Foods" },
  { id: 4, data: "Hiking" },
  { id: 5, data: "Festivals" },
  { id: 6, data: "Major / Special Events" },
  { id: 7, data: "Biking" },
  { id: 8, data: "Theme Parks" },
  { id: 9, data: "Beaches" },
  { id: 10, data: "Nature" },
  { id: 11, data: "Boating" },
  { id: 12, data: "Fishing" },
  { id: 13, data: "Water Sports" },
];

export const monthsInfo = [
  "Anytime",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "January",
  "February",
  "March",
];
