import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./weather.module.scss";
import { getWeatherDetailsAPI } from "../../api/getWeatherDetailsAPI";
import { getWeatherIcon } from "../../utils/getIconwithCode";
import { iWeatherData } from "../home/todayLanding/todayLandingPage";
interface iProps {
  weatherData: iWeatherData;
  setWeatherData: Dispatch<SetStateAction<iWeatherData>>;
  cityName: string;
  className?: any;
}

function WeatherWidget({
  className = {},
  weatherData,
  setWeatherData,
  cityName,
}: iProps): JSX.Element {
  //   const getIcon = (weatherType: string) => {
  //     switch (weatherType) {
  //       case WeatherTypes.Sunny:
  //         return sunLight;
  //       case WeatherTypes.Cloudy:
  //         return cloudShowers;
  //       case WeatherTypes.Hot:
  //         return cloudBoltSunLight;
  //       default:
  //         return sunLight;
  //     }
  //   };

    return (
      <div className={`${styles.main} ${className}`}>
        <div className={styles.container}>
          <img
            src={
              getWeatherIcon(String(weatherData.iconCode)) ||
              weatherData.iconURL
            }
            // src={weatherData.iconURL}
            alt="weather"
            className={styles.weatherIcon}
          />
          <p className={styles.weather}>
            <span className={styles.high}>H: {weatherData.high || 70}º</span>
            <span className={styles.low}>L: {weatherData.low || 30}º</span>
          </p>
        </div>
      </div>
    );
}

export default WeatherWidget;
