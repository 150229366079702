import { useEffect, useState } from "react";
import {
  planeEnginesWhite,
  outlinedCircleCloseIcon,
  sunLight,
  cloudShowers,
  cloudBoltSunLight,
} from "../../../constant";
import styles from "./delay.module.scss";
import { WeatherTypes } from "../../../enums/weather";
import {
  getWeatherIcon,
  getWeatherWhiteIcon,
} from "../../../utils/getIconwithCode";
import { notType } from "../../../types";

interface iProps {
  notificationType: string;
  title: string;
  notificationDetails: string;
  bgImage: string;
  weatherType: string;
  onClick?: () => void;
  weatherIconCode?: string;
  notification: notType;
  setNotification: React.Dispatch<React.SetStateAction<notType>>;
}

function DelayNotifications(props: iProps): JSX.Element {
  const {
    notificationType,
    title,
    notificationDetails,
    bgImage,
    weatherType,
    onClick,
    weatherIconCode = "1111",
    notification,
    setNotification,
  } = props;
  const [showNotification, setShowNotification] = useState<boolean>(true);
  const handleCloseCard = () => {
    // console.log(notificationType, "notificationType");
    if (notificationType === "flight_delay") {
      setShowNotification(false);
      setNotification({ ...notification, flight: false });
    }
    if (notificationType === "Weather") {
      setShowNotification(false);
      setNotification({ ...notification, weather: false });
    }
  };

  const getIcon = (weatherType: string) => {
    switch (weatherType) {
      case WeatherTypes.Sunny:
        return sunLight;
      case WeatherTypes.Cloudy:
        return cloudShowers;
      case WeatherTypes.FLight:
        return planeEnginesWhite;
      case WeatherTypes.Hot:
        return cloudBoltSunLight;
      default:
        return sunLight;
    }
  };
  return (
    <div
      className={`${showNotification ? styles.main : styles.hideMain}`}
      onClick={onClick}
    >
      <div
        className={`${notificationType === "flight_delay" ? styles.flightContainer : styles.weatherContainer}`}
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className={styles.closeCard}>
          <img
            src={outlinedCircleCloseIcon}
            alt="close"
            className={styles.closeIcon}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleCloseCard();
            }}
          />
        </div>

        <section className={styles.contentCard}>
          <img
            src={
              notificationType === "flight_delay"
                ? planeEnginesWhite
                : getWeatherWhiteIcon(String(weatherIconCode))
            }
            alt="icon"
            className={styles.planeIcon}
          />
          <div className={styles.descCard}>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.desc}>{notificationDetails}</p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default DelayNotifications;
