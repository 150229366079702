export const REVIEWS_CONSTANTS = [
  {
    reviewHeading: "Excellent staff and comfortable rooms!",
    reviewStarRatings: 10,
    createdAt: "Aug 12, 2024",
    description:
      "The hotel exceeded our expectations! The staff went above and beyond, making our stay truly memorable. The rooms were spacious, clean, and incredibly comfortable. Every detail was thoughtfully considered. A perfect blend of luxury and warmth. Highly recommend!",
    complaint: null,
    userName: "Suparna S.",
  },
  {
    reviewHeading: "Great customer service, but …",
    reviewStarRatings: 6,
    createdAt: "Aug 9, 2024",
    description:
      "The hotel offers exceptional service with attentive staff who go above and beyond. The rooms are cozy and well-maintained, providing a comfortable stay. ",
    complaint:
      "The amenities are limited, and the decor feels a bit dated. Overall, a pleasant experience with room for improvement.",
    userName: "Michael F.",
  },
  {
    reviewHeading: "Spend your money somewhere else!",
    reviewStarRatings: 2,
    createdAt: "Jul 19, 2024",
    description: "",
    complaint:
      "The hotel was a huge letdown. The room smelled musty, and the bed was uncomfortable. The staff was rude, and the service was slow. The bathroom was dirty with broken fixtures. Noise from the street kept us up all night. Definitely not worth the price.",
    userName: "Jennifer M.",
  },
  {
    reviewHeading: "Excellent Service!",
    reviewStarRatings: 10,
    createdAt: "December 12, 2023",
    description:
      "Exceptional service! The staff went above and beyond to make our stay unforgettable. From personalized concierge assistance to prompt room service, every detail was perfect. Highly recommend!",
  },
  {
    reviewHeading: "Enjoyed our stay but … ",
    reviewStarRatings: 5,
    createdAt: "December 12, 2023",
    description:
      "Exceptional service! The staff went above and beyond to make our stay unforgettable. From personalized concierge assistance to prompt room service, every detail was perfect. Highly recommend!",
  },
  {
    reviewHeading: "Excellent Service!",
    reviewStarRatings: 10,
    createdAt: "December 12, 2023",
    description:
      "Exceptional service! The staff went above and beyond to make our stay unforgettable. From personalized concierge assistance to prompt room service, every detail was perfect. Highly recommend!",
  },
  {
    reviewHeading: "Enjoyed our stay but … ",
    reviewStarRatings: 6,
    createdAt: "December 12, 2023",
    description:
      "Exceptional service! The staff went above and beyond to make our stay unforgettable. From personalized concierge assistance to prompt room service, every detail was perfect. Highly recommend!",
  },
];
