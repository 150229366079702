export const itinerary_listing_events = {
    top_destination_image_card_click: {
      category: "User navigates itinerary details page",
      action: "User clicks on image card",
      label: "top-destination-image-card-click",
    },
    top_destination_input_field_send_click : {
        category: "User can re-sends the search prompt",
      action: "User clicks on prompt input send button",
      label: "top-destination-input-field-click",
    },
}