import { useContext, useEffect, useState } from "react";
import FaqAccord from "../../component/faqAccordion/faqAccordion";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { FaqItem } from "../../types";
import { Button } from "@mui/material";
import { backIcon } from "../../constant";
import { useNavigate } from "react-router-dom";
import styles from "./faqs.module.scss";
import { GA_LABEL_ENUMS, triggerGAEvent } from "../../constant/google-analytics/GAEvents";

function FaqAccordion(): JSX.Element {
  const state = useContext(StateContext);
  const { appConfig, isMobile }: iGlobalContext = state;
  const [faqData, setFaqData] = useState<FaqItem[]>([]);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const sortedData = faqData?.sort((a, b) => a.key.localeCompare(b.key));

  useEffect(() => {
    const filterFaqCategory = (data: any) => {
      return data.filter(
        (item: { configCategory: string }) =>
          item.configCategory === "faqCategory"
      );
    };

    setFaqData(filterFaqCategory(appConfig));
  }, [appConfig]);

  const handleAccordionChange = (index: number, isExpanded: boolean) => {
    setExpandedIndex(isExpanded ? index : null);
  };
  const navigate = useNavigate();
  function handleBackButton() {
    triggerGAEvent(GA_LABEL_ENUMS.faqs_back_button_click);

    if (isMobile) window.history.back();
    else {
      navigate("/");
    }
    // if (checkIfThereIsChatContext(chatData)) navigate(ROUTES.TopDestination);
    // else navigate("/");
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.closeBtnContainer}>
          <Button
            className={`${styles.columnAlignButton}`}
            onClick={handleBackButton}>
            <img src={backIcon} alt="Close" />
            <p>BACK</p>
          </Button>
        </div>
        <p className={styles.title}>Frequently Asked Questions</p>
        {sortedData &&
          sortedData.map((i: FaqItem, index: number) => {
            const [heading, ...bodyArray] = (i.value || "").split("\n");
            const body = bodyArray.join("\n");
            const accordionHeading = heading || "FAQ not available";
            const accordionDescription = body || "No Description";

            return (
              <div className={styles.accordion} key={index}>
                <FaqAccord
                  headingComponent={
                    <p className={styles.qus}>{accordionHeading}</p>
                  }
                  bodyComponent={
                    <p className={styles.ans}>{accordionDescription}</p>
                  }
                  defaultExpanded={expandedIndex === index}
                  className={styles.accordion}
                  onChange={(isExpanded: boolean) =>
                    handleAccordionChange(index, isExpanded)
                  }
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default FaqAccordion;
