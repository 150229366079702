import Navbar from "./navbar/navbar";
import RecommendedTrips from "./recommendedTrips/recommendedTrips";
import SelectedDestination from "./selectedDestination/selectedDestination";
import styles from "./desk.module.scss";
import DesktopMultipleItinerary from "../deskTopDestination/deskTopMultipleItineraryDetails/deskTopMultipleItineraryDetails";
import { useContext } from "react";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import BasicTabs from "../sideBar/tabs/tabs";
import EmptyDrawer from "../emptyDrawer/emptyDrawer";
import DesktopSelectedScreenPage from "../deskTopDestination/DeskTopSelectedScreenPage/desktopSelectedScreenPage";
import NewTrip from "../../routes/newTrip/newTrip";

function DesktopLandingPage(): JSX.Element {
  const context = useContext(StateContext);
  const { screen, firstScreen, isMobile }: iGlobalContext = context;
  // console.log("HERE HERE HERE");

  if (isMobile)
    return <NewTrip />

  return (
    <div className={styles.main}>
      <div className={styles.layout}>
        <div className={styles.nav}>
          <Navbar />
        </div>

        <div className={styles.container}>
          <div className={styles.chat}>
            {/* <ChatHistory /> */}
            <BasicTabs />
          </div>
          <div className={styles.topDest}>
            {firstScreen === "" ? (
              <>
                {/* <RecommendedTrips /> */}
                <NewTrip />
              </>
            ) : (
              <DesktopSelectedScreenPage />
            )}
          </div>
          <div className={styles.itineraryDetails}>
            {screen === "" ? (
              <SelectedDestination />
            ) : (
              <DesktopMultipleItinerary />
            )}
          </div>
        </div>
      </div>
      <EmptyDrawer />
    </div>
  );
}

export default DesktopLandingPage;
