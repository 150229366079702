import React, { useState } from "react";
import styles from "./checkboxDestinationCard.module.scss";
import destination from "../../../assets/destination-1.jpg";
import dragIcon from "../../../assets/draggable.svg";
import { checkedIcon, unCheckedIcon } from "../../../constant";
import { iCityDetailsResult } from "../../../types";

export default function CheckboxDestinationCard({
  opacity = 0,
  data,
  index,
  onCheckboxChange,
  showStop = true,
  showSortIcon = true,
}: {
  opacity?: number;
  data: iCityDetailsResult;
  index: number;
  onCheckboxChange?: any;
  showStop?: boolean;
  showSortIcon?: boolean;
}) {
  const [checked, setChecked] = useState(false);
  function handleContextMenu(event: any) {
    event.preventDefault();
  }
  return (
    <div className={styles.container}>
      {showStop && <h3 className={styles.stopText}>STOP {index + 1}</h3>}
      <div className={styles.rowContainer}>
        <label
          className={styles.cardContainer}
          // onClick={onCheckboxChange}
        >
          <div
            className={styles.opacityContainer}
            style={{ opacity: opacity }}
          ></div>
          <input
            type="checkbox"
            style={{ display: "none" }}
            id={String(index)}
            checked={data?.checkedFlag}
            onChange={onCheckboxChange}
            // onClick={onCheckboxChange}
            // onTouchStart={onCheckboxChange}
          />
          <div className={styles.leftContainer}>
            <div className={styles.imageContainer}>
              <img
                onContextMenu={handleContextMenu}
                src={data?.imageURL}
                alt=""
                className={styles.imgTag}
              />
            </div>
            <h3 className={styles.cityName}>{data?.cityName}</h3>
          </div>
          <img
            onContextMenu={handleContextMenu}
            className={styles.checkbox}
            src={data?.checkedFlag ? checkedIcon : unCheckedIcon}
            alt="checkbox"
          />
        </label>
        {showSortIcon && (
          <div className={styles.dragIconContainer}>
            <img
              onContextMenu={handleContextMenu}
              src={dragIcon}
              alt=""
              className={styles.dragTag}
            />
          </div>
        )}
      </div>
    </div>
  );
}
