import { useState } from "react";
import { BookingDetails } from "../../../../types";
import TravelerPopover from "../../bookingPopover/travelerPopover/TravelerPopover";
import styles from "./traveler.module.scss";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";

interface iProps {
  className?: string;
  showRoom: boolean;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
}

type CountType =
  | "adultsCount"
  | "childrenCount"
  | "seniorsCount"
  | "numberOfRoomCount";

function Travelers(props: iProps): JSX.Element {
  const { className, bookingDetails, setBookingDetails, showRoom } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const setCount = (
    type: CountType
  ): React.Dispatch<React.SetStateAction<number>> => {
    return (value) => {
      setBookingDetails((prevDetails) => ({
        ...prevDetails,
        [type]: typeof value === "function" ? value(prevDetails[type]) : value,
        update: true,
      }));
    };
  };

  const setAdultsCount = setCount("adultsCount");
  const setChildrenCount = setCount("childrenCount");
  const setSeniorsCount = setCount("seniorsCount");
  const setNumberOfRoomCount = setCount("numberOfRoomCount");

  const checkCounts = (bookingDetails: BookingDetails) => {
    const { adultsCount, childrenCount, seniorsCount, numberOfRoomCount } =
      bookingDetails;

    const baseCondition =
      adultsCount >= 1 || childrenCount > 1 || seniorsCount >= 1;

    if (showRoom) {
      if (numberOfRoomCount > 1) {
        return adultsCount >= 1 || seniorsCount >= 1;
      }
      return baseCondition && numberOfRoomCount >= 1;
    }

    return baseCondition;
  };

  const checkData = checkCounts(bookingDetails);

  return (
    <div
      className={`${className} ${styles.main}`}
      onClick={() => {
        triggerGAEvent(GA_LABEL_ENUMS.booking_travelers_click);
      }}>
      <TravelerPopover
        adultsCount={bookingDetails.adultsCount}
        setAdultsCount={setAdultsCount}
        childrenCount={bookingDetails.childrenCount}
        setChildrenCount={setChildrenCount}
        seniorsCount={bookingDetails.seniorsCount}
        setSeniorsCount={setSeniorsCount}
        numberOfRoomCount={bookingDetails.numberOfRoomCount}
        setNumberOfRoomCount={setNumberOfRoomCount}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        popoverTrigger={
          <div className={styles.container}>
            <p className={styles.label}>Travelerss</p>
            <div
              className={`${isPopoverOpen ? styles.activeTravelBtn : styles.travelBtn}`}>
              <div className={styles.travelCard}>
                {bookingDetails?.adultsCount > 0 ? (
                  <p className={styles.btnName}>
                    {`${bookingDetails.adultsCount} ${bookingDetails.adultsCount === 1 ? "Adult" : "Adults"}`}
                  </p>
                ) : (
                  <p className={styles.btnName}></p>
                )}
                {bookingDetails?.childrenCount > 0 && (
                  <p className={styles.btnName}>
                    {`${bookingDetails.adultsCount > 0 ? ", " : ""}${bookingDetails.childrenCount} ${bookingDetails.childrenCount === 1 ? "Child" : "Children"}`}
                  </p>
                )}
                {bookingDetails?.seniorsCount > 0 && (
                  <p className={styles.btnName}>
                    {`${bookingDetails.childrenCount > 0 || bookingDetails.adultsCount > 0 ? ", " : ""}${bookingDetails.seniorsCount} ${bookingDetails.seniorsCount === 1 ? "Senior" : "Seniors"}`}
                  </p>
                )}
              </div>
              {showRoom && (
                <div className={styles.roomBox}>
                  <div className={styles.roomCard}>
                    <p className={styles.room}>
                      {bookingDetails.numberOfRoomCount > 0
                        ? bookingDetails.numberOfRoomCount
                        : ""}
                    </p>
                    <p className={styles.room}>
                      {bookingDetails.numberOfRoomCount > 1 ? "ROOMS" : "ROOM"}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        }
        showRoom={showRoom}
      />
    </div>
  );
}

export default Travelers;
