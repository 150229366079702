import MultiCityTrip from "../../multiCityTrip/multiCityTrip";
import styles from "./desk.module.scss";

function DesktopMultipleItinerary(): JSX.Element {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <MultiCityTrip />
      </div>
    </div>
  );
}

export default DesktopMultipleItinerary;
