import axios from "axios";
import { WEATHER_API } from "../constant/environment";

const API_KEY = "32a5bb7b9aa1126387e06acad817149e";
const API_URL_CURRENT = `https://api.openweathermap.org/data/2.5/weather?appid=${API_KEY}&units=metric`;

export async function getWeatherDetailsAPI(city: string) {
  // try {
  //   const response = await axios.get(API_URL_CURRENT + "&q=" + city);
  //     // console.log(response?.data);
  //     const data = {
  //         temp: response?.data?.main?.temp,
  //         high: response?.data?.main?.temp_max,
  //         low: response?.data?.main?.temp_min,
  //         description: response?.data?.weather[0]?.description,
  //         iconURL: `https://openweathermap.org/img/wn/${response?.data?.weather[0]?.icon}@2x.png`
  //     }
  //     return data
  // } catch {
  //   return null;
  // }

  const API_URL = `https://api.weatherapi.com/v1/forecast.json?key=${WEATHER_API.API_KEY}&q=${city}&days=1&aqi=no&alerts=yes`;

  try {
    const response: any = await axios.get(API_URL);
    // console.log(response?.data?.forecast?.forecastday[0]?.day?.avgtemp_c)
    if (response?.status !== 200) return null;

    // Access the weather details from the response
    const weatherData = {
      temp: response?.data?.forecast?.forecastday[0]?.day?.avgtemp_c,
      high: response?.data?.forecast?.forecastday[0]?.day?.maxtemp_c,
      low: response?.data?.forecast?.forecastday[0]?.day?.mintemp_c,
      condition: response?.data?.forecast?.forecastday[0]?.day?.condition?.text,
      iconCode: response?.data?.forecast?.forecastday[0]?.day?.condition?.code,
      iconURL: `https:${response?.data?.forecast?.forecastday[0]?.day?.condition?.icon}`,
      alerts: response?.data?.alerts?.alert || [],
      alertsMessage: response?.data?.alerts?.alert[0]?.headline || "",
      advisoryMessage: response?.data?.forecast?.forecastday[0]?.day?.condition?.text,
    };

    // console.log("WEATHER", response);

    return weatherData;
  } catch (error) {
    console.error("Error fetching weather details:", error);
    return null;
    // throw error;
  }
}
