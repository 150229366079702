import { MY_TRIP_TABS } from "../component/myTripsPage/myTripsConstants";

export interface iCityDetailsResult {
  // complete_itinerary_description?: string;
  // city_wise_itinerary?: iCityItinerary[];
  complete_itinerary_flights_cost: {
    value: string;
    checkedFlag: boolean;
    id: string;
  };
  complete_itinerary_hotels_cost: {
    value: string;
    checkedFlag: boolean;
    id: string;
  };
  averageCostAccommodation: string;
  averageCostOfFood: string;
  averagePackagePriceForExperiencing: string;
  checkedFlag: boolean;
  cityName: string;
  city_country_code: string;
  city_latitude: string;
  city_longitude: string;
  description: string;
  hotelName: string;
  imageURL: string;
  matchPercentage: string;
  id: string;
  minimum_cost_for_tour: string;
  locations: iCityDetailsResult[];
  tripDetails: any[];
  noOfDays: number;
  city_country?: string;
  city_name: string;
  city_state: string;
  destination_city?: string;
  destination_country?: string;
  destination_name?: string;
  activity_type?: string;
  time_of_the_day?: string;
}

export interface iTripDetails {
  city_name: string;
  day: string;
  day_itinerary_description: string;
  itinerary_by_time_of_the_day: iItineraryByTimeOfTheDay[];
}

export interface iItineraryByTimeOfTheDay {
  id: string;
  description: string;
  time_of_the_day: string;
  activity_type: string;
}

export interface iMyTripDetails {
  tripDate: string;
  travellersInfo: string;
  tripType: MY_TRIP_TABS;
  bookedOnDate: string;
  type?: string;
  tripsArr: iCityDetailsResult[];
}

/**
 * Chat data Interface
 */
export interface iChatData {
  id: string;
  results?: any;
  itineraryPromptContext?: any;
  complete_itinerary_description?: string;
  city_wise_itinerary?: iCityItinerary[];
  tripName?: string;
  number_of_adults?: string;
  number_of_children?: string;
  number_of_senior_citizens?: string;
  international_trip?: boolean;
  airlines?: string;
  hotels?: string;
  start_date?: string;
  flight_day_part?: string;
  hotel_star_ratings?: string;
  flight_refundable?: string;
  hotel_refundable?: string;
  number_of_stops?: string;
  prompt: [];
  message: string;
  preText?: string;
  postText?: string;
  isUserMessage: boolean;
  isLoading: boolean;
  destinationList: iCityDetailsResult[];
  // singleSelectedDestination: iCityDetailsResult;
  singleSelectedDestination: boolean;
  noOfDays?: number;
  numberOfdays?: number;
  fromCity?: string;
  from?: string;
  from_latitude?: string;
  from_longitude?: string;
  from_country_code?: string;
  city_state?: string;
  itinerary_version?: number | string;
  iteneryInput?: any;
  isSaved?: boolean;
  isShared?: boolean;
  flight_cabin_class?: string;
  number_of_rooms?: string;
  departure_airport?: string;
  arrival_airport?: string;
}
interface iCityItinerary {
  city_name: string;
  city_country: string;
  city_itinerary_description: string;
  city_itinerary: iDayItinerary[];
}

interface iDayItinerary {
  day: string;
  day_itinerary_description: string;
  itinerary_by_time_of_the_day: iTimeOfDayItinerary[];
}

interface iTimeOfDayItinerary {
  time_of_the_day: string;
  description: string;
}

/**
 *
 */
export interface iselectedChatData {
  chatDataId: string;

  city_country?: string;
  city_name?: string;

  averageCostAccommodation: string;
  averageCostOfFood: string;
  averagePackagePriceForExperiencing: string;
  checkedFlag: boolean;
  cityName: string;
  description: string;
  hotelName: string;
  imageURL: string;
  matchPercentage: string;
  id: string;
  minimum_cost_for_tour: string;
  locations: iCityDetailsResult[];

  countryName: string;
  detailsPrompt: string;
  // tripDetails: iTripForNDays[],
  tripDetails: any[];
  budgetEstimates: {
    flight: string;
    hotels: string;
    cars: string;
  };
}

export interface UpdateStateTypes {
  city: boolean;
  climate: boolean;
  population: boolean;
  exp: boolean;
  duration: boolean;
}

export interface UserInfo {
  id: string;
  name: string;
  email: string;
  phone: string;
  profile_pic: string | null;
  loggedIn: boolean;
  role: number;
}

export interface iAppConfig {
  id: string;
  key: string;
  value: string;
  description: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface FaqDataObject {
  id: string;
  key: string;
  value: string;
  configCategory: string;
  description: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface FaqItem {
  id: string;
  key: string;
  value: string;
  configCategory: string;
}

export interface ISelectedOptions {
  flights: boolean;
  hotels: boolean;
}

export interface Amenity {
  id: number;
  breakFast: string;
  pool: string;
  wifi: string;
}

export interface iAmenitiesIcons {
  name: string;
  icon: string;
}

export enum ToastType {
  SUCCESS,
  ERROR,
  WARNING,
  INFO,
}

export interface ToastMessage {
  type: ToastType;
  message: string;
}

export type ToastMessages = {
  [key: string]: ToastMessage | ToastMessages;
};

export type ApiInfo =
  | "flight.search"
  | "flight.availability"
  | "flight.detail"
  | "hotel.search"
  | "hotel.detail"
  | "user.detail"
  | "user.update"
  | "user.create"
  | "user.login"
  | "user.logout"
  | "preference.details"
  | "preference.update"
  | "airport.search"
  | "category.details"
  | "config.details"
  | "prompt.details"
  | "dayplanning.details"
  | "reset.password"
  | "reset.passwordlink"
  | "user.sendpasswordlink"
  | "delete.history"
  | "viewfrequenttrip"
  | "view.history"
  | "itinerary.details"
  | "itinerary.list"
  | "recommended.list"
  | "weather.details"
  | "saveitinerary.details"
  | "delete.itinerary";

export type ApiErrorKey = `${
  | "flight.search"
  | "flight.availability"
  | "flight.detail"
  | "hotel.search"
  | "hotel.detail"
  | "user.detail"
  | "user.update"
  | "user.create"
  | "user.login"
  | "user.logout"
  | "preference.details"
  | "preference.update"
  | "airport.search"
  | "category.details"
  | "config.details"
  | "prompt.details"
  | "dayplanning.details"
  | "reset.password"
  | "reset.passwordlink"
  | "user.sendpasswordlink"
  | "airport.search"
  | "delete.history"
  | "viewfrequenttrip"
  | "view.history"
  | "itinerary.details"
  | "itinerary.list"
  | "recommended.list"
  | "weather.details"
  | "delete.itinerary"
  | "saveitinerary.details"}.failure.${400 | 401 | 402 | 403 | 404 | 500 | 501 | 502 | 503 | 504}`;

export type ToastMessagesType = {
  [key in ApiErrorKey]?: { message: string; messageTitle: string };
} & {
  ApiBreakdown: { message: string; messageTitle: string };
};
