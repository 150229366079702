import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./hotelDetails.module.scss";
import { Button } from "@mui/material";
import { backIcon, starSharpSolid } from "../../constant";
import { amenitiesIcons } from "./hotelDetailsConstants";
import ImageSlider from "./components/imageSlider/imageSlider";
import HotelRating from "../booking/hotelBooking/hotelRating/hotelRating";
import CustomAccordion from "../reusableComponent/customAccordion/CustomAccordion";
import Amenities from "./components/amenities/amenities";
import AboutProperty from "./components/aboutProperty/aboutProperty";
// import ReviewsModal from "./components/reviewsModal/reviewsModal";
// import PropertyDetailsModal from "./components/propertyDetailsModal/propertyDetailsModal";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import Loader from "../reusableComponent/loader/Loader";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { ScreenTypes } from "../../enums";
import { getHotelDetails } from "../../api/booking";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";

function HotelDetails(): JSX.Element {
  const globalContext = useContext(StateContext);
  const {
    shouldUserNavigateBacktoTripDetailsPage,
    // setActiveTabIndex,
    hotelDetailsPageId,
    setScreen,
    setShowReviews,
    setShowPropertyDetails,
    setHotelDetailsPageState,
    isMobile,
    isFetchingHotelDetails,
    setIsFetchingHotelDetails,
  }: iGlobalContext = globalContext;
  // const [isLoading, setIsLoading] = useState(false);
  const [hotelDetails, setHotelDetails] = useState<any>(null);
  const { handleCustomError } = useCustomErrorHandler();
  // const {
  //   // hotelImages,
  //   // hotelStar,
  //   // hotelName,
  //   // rating,
  //   // reviews,
  //   // landMark,
  //   // amenities = [],
  //   // aboutProperty = [],
  // } = HOTEL_DETAILS;

  const {
    stars: hotelStar,
    name: hotelName,
    hotelImages = [],
    address,
    city: place,
    hotelMainImage,
    rating,
    reviewCount,
    landMark = "",
    reviewHeading = "",
    hotelAmenities = [],
    aboutProperty = [],
  } = hotelDetails || {};

  let hotelDetailsFetchingController: any = useRef(null);

  const fetchHotelDetails = useCallback(async () => {
    if (!hotelDetailsPageId) {
      toastMessage.error("There's no hotel id");
      return;
    }
    // const response = await fetch(`https://example.com/hotel/${hotelId}`);
    hotelDetailsFetchingController.current = new AbortController();
    const hotelData = await getHotelDetails(
      hotelDetailsPageId,
      hotelDetailsFetchingController.current,
      setIsFetchingHotelDetails
    );
    handleCustomError(hotelData?.statusCode, "hotel.detail");
    const response = hotelData?.data;
    if (response?.data) {
      // console.log(response?.data);
      if (!response?.data.name)
        toastMessage.error("There's no clear information regarding the hotel");
      setHotelDetails(response?.data);
      setHotelDetailsPageState(response?.data);
    }
    if (!response) {
      toastMessage.error("OOPS!", "Unable to fetch hotel details");
    }
  }, [hotelDetailsPageId, setHotelDetailsPageState, setIsFetchingHotelDetails]);

  useEffect(() => {
    fetchHotelDetails();
  }, [fetchHotelDetails]);

  // const [showSelectRoomModal, setShowSelectRoomModal] = useState(false);

  if (isFetchingHotelDetails) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  if (!isFetchingHotelDetails && !hotelDetails) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={styles.hotelDetailsContainer}>
      <div className={styles.closeBtnContainer}>
        <Button
          className={`${styles.columnAlignButton}`}
          onClick={() => {
            // // console.log(window.history);
            if (!isMobile) {
              if (shouldUserNavigateBacktoTripDetailsPage) {
                setScreen(ScreenTypes.TripDetails);
                return;
              }
              setScreen(ScreenTypes.Booking);
            }
            // setActiveTabIndex(3);
            if (isMobile) window.history.back();
          }}
        >
          <img src={backIcon} alt="Close" />
          <p>BACK</p>
        </Button>
      </div>
      <div className={styles.horizontalScrollContainer}>
        {hotelImages.length > 0 && (
          <ImageSlider
            imagesArr={[
              hotelMainImage,
              ...hotelImages.map((item: any) => item.url),
            ]}
          />
        )}
      </div>
      <div className={styles.hotelDescriptionContainer}>
        <section className={styles.contentCard}>
          {hotelStar && (
            <div className={styles.starContainer}>
              <img
                className={styles.starPropertyImg}
                src={starSharpSolid}
                alt="star icon"
              />
              <span className={styles.star}>{hotelStar} star property</span>
            </div>
          )}
          <p className={styles.hotelName}>{hotelName}</p>
          <p className={styles.location}>
            {/* {place ? place : ""} &nbsp; {address ? `|` : ""} &nbsp;{" "} */}
            {address ? `${address}, ` : ""}
            {place ? place : ""}
          </p>
          {landMark && (
            <p
              className={styles.landMark}
              // onClick={() => setShowSelectRoomModal(true)}
            >
              {landMark}
            </p>
          )}
          <HotelRating
            className={styles.ratingsSection}
            ratingCardClass={styles.ratingCardClass}
            ratingClass={styles.ratingClass}
            hotelRating={rating}
            reviewCount={reviewCount}
            reviewHeading={reviewHeading}
            contentClass={styles.contentClass}
            reviewClass={styles.reviewClass}
            reviewTitleClass={styles.reviewTitleClass}
            onReviewClick={() => setShowReviews(true)}
          />
        </section>
      </div>
      {hotelAmenities.length > 0 && (
        <CustomAccordion
          className={styles.customStyles}
          summaryClass={styles.summaryClass}
          accordianDetailsClass={styles.accordianDetailsClass}
          defaultExpanded={false}
          headingComponent={<span className={styles.heading}>Amenities</span>}
          bodyComponent={
            <Amenities
              aminitiesArr={hotelAmenities.slice(0, 8)}
              showViewPropAmenities
              amenitiesIconsArr={amenitiesIcons}
              onClickPropertyDetails={() => setShowPropertyDetails(true)}
            />
          }
          zeroMarginOnExpand
          borderBottom
        />
      )}
      {aboutProperty.length > 0 && (
        <CustomAccordion
          className={styles.customStyles}
          summaryClass={styles.summaryClass}
          defaultExpanded={false}
          headingComponent={
            <span className={styles.heading}>About the Property</span>
          }
          bodyComponent={<AboutProperty aboutPropertyArr={aboutProperty} />}
          zeroMarginOnExpand
          borderBottom
        />
      )}

      {/* <SelectRoomModal
        isOpen={showSelectRoomModal}
        onClose={() => setShowSelectRoomModal(false)}
      /> */}
    </div>
  );
}

export default HotelDetails;
