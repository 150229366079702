import styles from "./landing.module.scss";
import vecteezyTravelDestination from "../../../assets/vecteezyTravelDestination.png";

function BookingLandingPage(): JSX.Element {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.content}>
          <img
            src={vecteezyTravelDestination}
            alt="place"
            className={styles.landingImg}
          />
          <section className={styles.descCard}>
            <p className={styles.title}>
              Add your dates and number of travelers to see fares here
            </p>
            <div className={styles.description}>
              <span className={styles.star}>*</span>
              <p className={styles.desc}>
                Fares can not be purchased through zenvoya yet.
                <br /> Prices shown are for reference only.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default BookingLandingPage;
