import { useContext } from "react";
import { BookingDetails } from "../../../../types";
import styles from "./duration.module.scss";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import FormatInputToUTC from "../../../../hooks/utcDateFormater";

interface iProps {
  bookingDetails: BookingDetails;
  hotel: boolean;
  flight: boolean;
}

function Duration({ bookingDetails, hotel, flight }: iProps): JSX.Element {
  const state = useContext(StateContext);
  const { selectedChatData }: iGlobalContext = state;

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {bookingDetails.travelFromDate === "" ? (
          <p className={styles.title}>Duration</p>
        ) : (
          <p className={styles.title}>{flight ? "RETURN" : "CHECK OUT"}</p>
        )}
        <div className={styles.durationCard}>
          <p className={styles.desc}>
            {bookingDetails.travelToDate
              ? FormatInputToUTC(bookingDetails.travelToDate)
              : ""}
          </p>
          {selectedChatData.noOfDays ? (
            <>
              {flight && (
                <p className={styles.days}>
                  {selectedChatData.noOfDays}{" "}
                  {selectedChatData.noOfDays > 1 ? "DAYS" : "DAY"}
                </p>
              )}
              {hotel && (
                <p className={styles.days}>
                  {selectedChatData.noOfDays - 1}{" "}
                  {selectedChatData.noOfDays > 1 ? "NIGHTS" : "NIGHT"}
                </p>
              )}
            </>
          ) : (
            <p className={styles.days}></p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Duration;
