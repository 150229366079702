import styles from "./customChip.module.scss";
import { Chip } from "@mui/material";

export default function CustomChip({
  onClick,
  label,
  key = 1,
  selected,
}: {
  onClick?: any;
  label: string;
  key?: any;
  selected: boolean;
}) {
  return (
    <Chip
      label={label}
      size="medium"
      variant="outlined"
      onClick={onClick}
      key={key}
      className={selected ? styles.activeChip : styles.chip}
      style={{
        backgroundColor: selected === true ? "#D8EFFF" : "#ffff",
      }}
    />
  );
}
