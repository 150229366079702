import React, { useContext } from "react";
import styles from "./empty.module.scss";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { APP_CONFIG } from "../../../constant/common";
import { iAppConfig } from "../../../types";

function EmptyCard(): JSX.Element {
  const globalContext = useContext(StateContext);
  const { appConfig }: iGlobalContext = globalContext;
  return (
    <div className={styles.main}>
      <div
        className={styles.constText}
        dangerouslySetInnerHTML={{
          __html:
            appConfig?.length > 0
              ? appConfig?.filter(
                  (ele: iAppConfig) => ele.key === APP_CONFIG.HOME_CONSTANT_2
                )[0]?.value
              : "",
        }}
      />
    
    </div>
  );
}
export default EmptyCard;
