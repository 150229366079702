import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { expandIcon } from "../../constant";
import styles from "./faq.module.scss";
import { GA_LABEL_ENUMS, triggerGAEvent } from "../../constant/google-analytics/GAEvents";

export default function FaqAccord({
  headingComponent,
  bodyComponent,
  defaultExpanded = false,
  className,
  onChange,
}: {
  headingComponent: React.ReactNode;
  bodyComponent: React.ReactNode;
  defaultExpanded?: boolean;
  className?: string;
  onChange: (isExpanded: boolean) => void;
}) {
  const [expan, setExpan] = useState(defaultExpanded);

  const handleChange = (_event: React.SyntheticEvent, isExpanded: boolean) => {
    triggerGAEvent(GA_LABEL_ENUMS.faqs_back_downward_accordion_click);

    setExpan(isExpanded);
    onChange(isExpanded);
  };

  const summaryRef = useRef(null);

  useEffect(() => {
    const summaries = document.querySelectorAll(`.${styles.summary}`);
    if (summaries.length > 0) {
      summaries[summaries.length - 1].classList.add(styles.lastSummary);
    }
  }, []);

  useEffect(() => {
    setExpan(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <Accordion
      expanded={expan}
      className={`${className} ${expan ? styles.activeMainContainer : styles.mainContainer}`}
      onChange={handleChange}>
      <AccordionSummary
        expandIcon={<img src={expandIcon} className={styles.expandIcon} />}
        className={`${expan ? styles.activeSummary : styles.summary}`}
        ref={summaryRef}>
        {headingComponent}
      </AccordionSummary>
      <AccordionDetails
        className={styles.desc}
        sx={{ pt: "0px !important", pb: "0px !important" }}>
        {bodyComponent}
      </AccordionDetails>
    </Accordion>
  );
}
