import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./tripDetailsPage.module.scss";
import TabsContainer from "../reusableComponent/tabsContainer/tabsContainer";
// import {
//   TRIP_DETAILS_TABS,
//   trip_details_tabs,
// } from "./tripDetailsPageConstant";
import DailyTabs from "./dailyTabs/DailyTabs";
import ActivitiesTabs from "./activitiesTabs/ActivitiesTabs";
import FlightsTabs from "./flightsTabs/FlightsTabs";
import HotelsTabs from "./hotelsTabs/HotelsTabs";
import { backIcon, shareIcon } from "../../constant";
import { Button } from "@mui/material";
import TripDetailsHeader from "./tripDetailsHeader/TripDetailsHeader";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { getItineraryDetailsAPI } from "../../api/getItineraryDetails";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Loader from "../reusableComponent/loader/Loader";
import { getBookedFlightDetailsAPI } from "../../api/booking/getBookedFlightDetailsAPI";
import { RWebShare } from "react-web-share";
import { APP_URL } from "../../constant/environment";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import { TRIP_DETAILS_TABS } from "./tripDetailsPageConstant";
import UserDetails from "../booking/flightBooking/details/details";
import { getBookedFlightAndHotelSummaryAPI } from "../../api/booking/getBookedFlightAndHotelSummary";
import { toastMessage } from "../../utils/ToastProvider";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import NewLoader from "../reusableComponent/newLoader/NewLoader";
import { getBookedTravelerInfoDetails } from "../../api/booking/getBookedTravelerInfoDetails";
import { getBookedPaymentDetails } from "../../api/booking/getBookedPaymentDetails";

export default function TripDetailsPage() {
  const globalContext = useContext(StateContext);
  const {
    prevRoute,
    setScreen,
    // activeTabIndex,
    setFirstScreen,
    // setActiveTabIndex,
    isMobile,
    tripOrItinearyId,
    setTripOrItinearyId,
    setPrevRoute,
    selectedChatData,
    setSelectedChatData,
    activeTripDetailsTab,
    setActiveTripDetailsTab,
    setIsUserNavigatingFromPaymentGateway,
    userDetails,
  }: iGlobalContext = globalContext;

  const [tabs, setTabs] = useState([
    "DAILY PLANS",
    "ACTIVITIES",
    "FLIGHTS",
    "HOTELS",
  ]);

  // const [activeTripDetailsTab, setActiveTripDetailsTab] = useState(tabs[activeTabIndex]);
  const [dayWiseItinerary, setdayWiseItinerary] = useState([]);
  const [flightSummary, setFlightSummary] = useState(null);
  const [hotelSummary, setHotelSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { handleCustomError } = useCustomErrorHandler();
  const [tripDetails, setTripDetails] = useState({
    data: null,
    flightSummary: null,
    dayWiseItinerary: null,
    hotelSummary: null,
  } as any);

  const [paymentDetails, setPaymentDetails] = useState({} as any);

  useEffect(() => {
    triggerGAEvent(GA_LABEL_ENUMS.trip_details_page_load);
    return () => {
      setPrevRoute("/");
    };
  }, []);

  useEffect(() => {
    setIsUserNavigatingFromPaymentGateway(false);
    let tabs = ["DAILY PLANS", "ACTIVITIES"];
    if (flightSummary) tabs.push("FLIGHTS");
    if (hotelSummary) tabs.push("HOTELS");
    setTabs(tabs);

    console.log({ flightSummary }, { hotelSummary });
  }, [isLoading, tripDetails, flightSummary, hotelSummary]);

  const navigate = useNavigate();
  const renderTripCard = useCallback(() => {
    switch (activeTripDetailsTab) {
      case TRIP_DETAILS_TABS.DAILY_PLANS: {
        return (
          <DailyTabs
            // dayWiseItinerary={dayWiseItinerary}
            startDate={tripDetails?.data?.startDate}
            flightDetails={flightSummary}
            hotelDetails={hotelSummary}
          />
        );
      }
      case TRIP_DETAILS_TABS.ACTIVITIES: {
        return (
          <ActivitiesTabs
            // activitiesData={
            //   JSON.parse(tripDetails?.data?.metaData)[0]?.selectedChatData
            // }
            // activitiesData={selectedChatData}
            startDate={tripDetails?.data?.startDate}
            status={tripDetails?.data?.status || "draft"}
          />
        );
      }
      case TRIP_DETAILS_TABS.FLIGHTS: {
        return (
          <FlightsTabs
            flightDetails={flightSummary}
            travellersInfo={tripDetails?.passengerDetails}
            flightPayment={paymentDetails?.flights}
          />
        );
      }
      case TRIP_DETAILS_TABS.HOTELS: {
        return (
          <HotelsTabs
            hotelSummary={hotelSummary}
            hotelPayment={paymentDetails?.hotels}
          />
        );
      }
      default:
        return (
          <DailyTabs
            // dayWiseItinerary={dayWiseItinerary}
            startDate={tripDetails?.data?.startDate}
            flightDetails={flightSummary}
            hotelDetails={hotelSummary}
          />
        );
    }
  }, [
    activeTripDetailsTab,
    flightSummary,
    hotelSummary,
    tripDetails?.data?.startDate,
    tripDetails?.data?.status,
    tripDetails?.data?.travellersInfo,
    paymentDetails,
  ]);

  function handleBackButton() {
    triggerGAEvent(GA_LABEL_ENUMS.trip_details_back_click);
    if (isMobile) {
      // if (prevRoute) navigate(prevRoute);
      // else navigate("/");
      window.history.back();
    }
    // navigate("/today")
    setScreen("");
    // setFirstScreen("");
  }

  function handleShare() {
    triggerGAEvent(GA_LABEL_ENUMS.trip_details_share_click);
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     setActiveTabIndex(0);
  //   }, 500);
  // }, []);

  /**
   * Getting the trip details
   */
  const getItineraryDetails = async () => {
    setIsLoading(true);
    const itineraryDetailsResponse =
      await getItineraryDetailsAPI(tripOrItinearyId);
    if (itineraryDetailsResponse?.statusCode !== 200) {
      handleCustomError(
        itineraryDetailsResponse.statusCode,
        "itinerary.details"
      );
      return;
    }
    setTripDetails((prev: any) => ({
      ...prev,
      data: itineraryDetailsResponse?.data,
    }));
    if (itineraryDetailsResponse?.data)
      setSelectedChatData(
        typeof itineraryDetailsResponse?.data?.metaData === "string"
          ? JSON.parse(itineraryDetailsResponse?.data?.metaData)[0]
              ?.selectedChatData
          : itineraryDetailsResponse?.data?.metaData?.selectedChatData
      );

    // Getting Flight and hotel summary
    const itineraryId = itineraryDetailsResponse?.data?.itineraryId || "";
    const summaryPayload = {
      userId: userDetails?.id || "",
      itineraryID: itineraryId,
    };

    const summaryResponse =
      await getBookedFlightAndHotelSummaryAPI(summaryPayload);

    if (summaryResponse?.statusCode !== 200) {
      toastMessage.error("OOPS!", "Flight and hotel data not available!");
      setIsLoading(false);
      return;
    }
    setTripDetails((prev: any) => ({
      ...prev,
      hotelSummary: summaryResponse?.data?.hotel?.bookingInfo,
      // flightSummary: summaryResponse?.data?.flight,
      itineraryData: summaryResponse?.data?.itineraryData,
    }));

    // Getting flight Details
    const transactionIds =
      summaryResponse?.data?.flight?.map((item: any) => item?.transactionId) ||
      [];
    const flightPayload = {
      userId: 1234,
      itineraryId: itineraryId,
      transactionId: transactionIds,
    };
    const flightDetailsResponse =
      await getBookedFlightDetailsAPI(flightPayload);
    if (flightDetailsResponse?.statusCode === 200) {
      console.log({ flightDetailsResponse });
      setTripDetails((prev: any) => ({
        ...prev,
        flightSummary: flightDetailsResponse?.data?.data,
      }));
    }
    // const hotelPayload = {
    //   itineraryID: response?.data?.hotelItinerayId,
    //   transactionId: response?.data?.hotelTransactionId,
    // };
    // const hotelsSummaryResponse = await getBookedFlightDetailsAPI(hotelPayload);
    // if (hotelsSummaryResponse?.statusCode === 200) {
    //   setTripDetails((prev: any) => ({
    //     ...prev,
    //     hotelSummary: hotelsSummaryResponse?.data?.data?.bookingInfo,
    //   }));
    // }

    // Getting Traveler info details
    const travelerDetailsResponse = await getBookedTravelerInfoDetails({
      itineraryId: itineraryId,
    });
    if (travelerDetailsResponse?.statusCode === 200) {
      console.log(
        { travelerDetailsResponse },
        travelerDetailsResponse?.data?.passengerDetails
      );
      setTripDetails((prev: any) => ({
        ...prev,
        passengerDetails: travelerDetailsResponse?.data?.passengerDetails,
      }));
    }

    // Getting Payment Details
    const paymentDetailsResponse = await getBookedPaymentDetails({
      itineraryId: itineraryId,
    });
    if (paymentDetailsResponse?.statusCode === 200) {
      console.log({ paymentDetailsResponse });
      setPaymentDetails(paymentDetailsResponse?.data);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    getItineraryDetails();
  }, [tripOrItinearyId]);

  useEffect(() => {
    console.log({ tripDetails });
  }, [tripDetails]);

  useEffect(() => {
    if (tripDetails?.data?.metaData) {
      let tempList: any = [];
      const list =
        typeof tripDetails?.data?.metaData === "string"
          ? JSON.parse(tripDetails?.data?.metaData)[0]?.selectedChatData
              ?.destinationList
          : tripDetails?.data?.metaData?.selectedChatData?.destinationList;
      for (let i = 0; i < list?.length; i++) {
        // console.log("list", list[i]?.tripDetails);
        tempList = [...tempList, ...list[i]?.tripDetails];
      }
      setdayWiseItinerary(tempList);
      // let tripDetailss = JSON.parse(tripDetails?.metaData)[0]?.selectedChatData
      //   ?.destinationList[0]?.tripDetails;
      // // console.log({ tripDetailss });
      setFlightSummary(
        tripDetails?.flightSummary?.length > 0
          ? tripDetails?.flightSummary
          : null
      );
      setHotelSummary(
        tripDetails?.hotelSummary?.length > 0 ? tripDetails?.hotelSummary : null
      );
    }
  }, [tripDetails]);

  /**
   * Side effect to listen if it is a shared trip and has the itinerary id append to the URL
   */
  const [isSharedTrip, setIsSharedTrip] = useState(false);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data = urlParams.get("id");
    if (data) {
      const formattedData = decodeURIComponent(data)
        .replace(/\+/g, " ")
        .replace(/=/g, "")
        .replace(/\?/g, "");
      setTripOrItinearyId(formattedData);
      setIsSharedTrip(true);
    }
  }, []);

  // const handleSetActiveTab = useCallback(
  //   (item: string) => {
  //     if (item === TRIP_DETAILS_TABS.DAILY_PLANS)
  //       triggerGAEvent(GA_LABEL_ENUMS.trip_details_daily_plans_click);
  //     if (item === TRIP_DETAILS_TABS.ACTIVITIES)
  //       triggerGAEvent(GA_LABEL_ENUMS.trip_details_activities_click);
  //     if (item === TRIP_DETAILS_TABS.FLIGHTS)
  //       triggerGAEvent(GA_LABEL_ENUMS.trip_details_flights_click);
  //     if (item === TRIP_DETAILS_TABS.HOTELS)
  //       triggerGAEvent(GA_LABEL_ENUMS.trip_details_hotel_accordion_click);
  //     setActiveTab(item);
  //   },
  //   [setActiveTab]
  // );

  if (JSON.stringify(tripDetails) === "{}" || isLoading)
    return (
      <NewLoader
        text="Please wait"
        // className={styles.loaderMain}
        // cancelButtonClassName={styles.cancelBtn}
        isOpen={true}
        onClose={() => {
          if (isMobile) window?.history?.back();
          else setScreen("");
        }}
      />
    );

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <div
          className={styles.buttonContainer}
          style={{ marginBottom: isSharedTrip ? "0px" : "-10px" }}
        >
          <Button
            className={`${styles.columnAlignButton}`}
            onClick={handleBackButton}
          >
            <img src={backIcon} alt="Close" />
            <p>BACK</p>
          </Button>
          {/* <Button className={`${styles.rowAlignButton}`} onClick={handleShare}>
            <img className={styles.iconButton} src={shareIcon} alt="share" />
            SHARE
          </Button> */}

          {!isSharedTrip && (
            <RWebShare
              data={{
                url: `${APP_URL}trip-details?=${tripOrItinearyId}`,
                title: "Zenvoya",
              }}
              onClick={handleShare}
            >
              <Button
                className={`${styles.rowAlignButton}`}
                onClick={handleShare}
              >
                <img
                  className={styles.iconButton}
                  src={shareIcon}
                  alt="share"
                />
                SHARE
              </Button>
            </RWebShare>
          )}
        </div>
        <TripDetailsHeader
          travellersInfo={tripDetails?.data?.travellersInfo}
          tripName={tripDetails?.data?.tripName}
          itineraryNumber={tripDetails?.itineraryData?.itineraryId}
          startDate={tripDetails?.data?.startDate}
          endDate={tripDetails?.data?.endDate}
          bookedDate={tripDetails?.itineraryData?.bookedOn}
        />
      </div>
      <TabsContainer
        className={styles.tabsClass}
        activeTab={activeTripDetailsTab}
        setActiveTab={setActiveTripDetailsTab}
        tabsArr={tabs}
      />
      {renderTripCard()}
    </div>
  );
}
