import { useNavigate } from "react-router-dom";
import styles from "./flight.module.scss";
import { planeDepartureRegular, ROUTES } from "../../constant";
import WeatherFlightDelayHeader from "../../component/weatherFlightDelayHeader/weatherFlightDelayHeader";
import { Button } from "@mui/material";
import Stepper from "../../component/booking/stepper/stepper";
import CustomAccordion from "../../component/reusableComponent/customAccordion/CustomAccordion";
import WeatherWidget from "../../component/weatherWidget/weatherWidget";
import { useCallback, useEffect, useMemo, useState } from "react";
import { iWeatherData } from "../../component/home/todayLanding/todayLandingPage";
import {
  citiesDataInterface,
  FlightNewData,
  FlightWeatherDelayData,
  stepsData,
} from "../../constant/dummyData";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
// import FlightDetails from "../../component/booking/flightDetails/flightDetails";

interface HighlightCitiesProps {
  text: string;
}

const HighlightCities: React.FC<HighlightCitiesProps> = ({ text }) => {
  const highlightedText = useMemo(() => {
    return citiesDataInterface.reduce((acc, city) => {
      const regex = new RegExp(`\\b${city}\\b`, "g");
      return acc.replace(
        regex,
        `<span style="color: #007aff; font-weight: bold;">${city}</span>`
      );
    }, text);
  }, [text]);

  return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

function FlightSelect(): JSX.Element {
  const navigate = useNavigate();

  const [weatherData, setWeatherData] = useState({} as iWeatherData);
  const [cityName, setCityName] = useState("Barcelona");
  const [selectedCards, setSelectedCards] = useState<[number, number][]>([]);

  useEffect(() => {
    triggerGAEvent(GA_LABEL_ENUMS.flight_select_page_load);
    const urlParams = new URLSearchParams(window.location.search);
    const city = urlParams.get("city");
    if (city) {
      const formattedCity = decodeURIComponent(city).replace(/\+|=|\?/g, " ");
      setCityName(formattedCity.trim());
    }
  }, []);
  const handleCheckboxChange = (outerIndex: number, innerIndex: number) => {
    triggerGAEvent(
      GA_LABEL_ENUMS.flight_select_suggested_changes_your_plan_checkbox_click
    );
    setSelectedCards((prevSelectedCards) => {
      const index = prevSelectedCards.findIndex(
        ([o, i]) => o === outerIndex && i === innerIndex
      );

      if (index > -1) {
        return prevSelectedCards.filter((_, i) => i !== index);
      } else {
        return [...prevSelectedCards, [outerIndex, innerIndex]];
      }
    });
  };

  const handleConfirm = useCallback(() => {
    triggerGAEvent(GA_LABEL_ENUMS.flight_select_confirm_click);
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {FlightNewData && (
          <WeatherFlightDelayHeader
            bgImage={FlightNewData.bgImage}
            title={FlightNewData.title}
            description={FlightNewData.description}
            advisoryType={"flight"}
            bgColor={FlightNewData.bgColor}
            handleBackButton={() => {
              triggerGAEvent(GA_LABEL_ENUMS.flight_select_back_click);
              navigate(ROUTES.FlightDelay);
            }}
          />
        )}
        <div className={styles.subContainer}>
          <div className={styles.selectedFlightCard}>
            <p className={styles.selectedFlight}>Selected Flight</p>
          </div>
          <div className={styles.flightAccord}>
            <CustomAccordion
              className={styles.customFlightStyles}
              summaryClass={styles.summaryFlightClass}
              accordianDetailsClass={styles.accordFlightDetailsClass}
              defaultExpanded={true}
              onChange={(_a, isOpen) => {
                if (isOpen) {
                  triggerGAEvent(
                    GA_LABEL_ENUMS.flight_select_selected_flight_accordion_click
                  );
                }
                if (!isOpen)
                  triggerGAEvent(
                    GA_LABEL_ENUMS.flight_select_selected_flight_collapse_accordion_click
                  );
              }}
              headingComponent={
                <div className={styles.headingFlightComp}>
                  <div className={styles.accordTitleCard}>
                    <p className={styles.dayAndCity}>Fri AUG 19</p>
                    <p className={styles.accordDesc}>
                      Barcelona (BCN) - London (LHR)
                    </p>
                  </div>
                  <img
                    src={planeDepartureRegular}
                    alt="plane"
                    className={styles.planeDepartureIcon}
                  />
                </div>
              }
              bodyComponent={
                <div className={styles.stepper}>
                  {stepsData.map((i, index) => (
                    <section className={styles.stepperDetails} key={index}>
                      {i.stepsData && (
                        <Stepper
                          steps={i.stepsData}
                          initialStep={0}
                          finalStep={i.stepsData.length - 1}
                        />
                      )}
                    </section>
                  ))}
                  <div className={styles.divider} />
                </div>
              }
              zeroMarginOnExpand
              borderBottom
            />
          </div>

          <div className={styles.titleCard}>
            <p className={styles.title}>Suggested Changes to Your Plans</p>
            <p className={styles.desc}>Tap below to accept suggested changes</p>
          </div>

          {FlightWeatherDelayData.map((item, outerIndex) => (
            <div className={styles.accord} key={outerIndex}>
              <CustomAccordion
                className={styles.customStyles}
                summaryClass={styles.summaryClass}
                accordianDetailsClass={styles.accordianDetailsClass}
                defaultExpanded={true}
                onChange={(_a, isOpen) => {
                  if (isOpen) {
                    triggerGAEvent(
                      GA_LABEL_ENUMS.flight_select_suggested_changes_your_plan_accordion_click
                    );
                  }
                  if (!isOpen)
                    triggerGAEvent(
                      GA_LABEL_ENUMS.flight_select_selected_flight_collapse_accordion_click
                    );
                }}
                headingComponent={
                  <div className={styles.headingComp}>
                    <div className={styles.accordTitleCard}>
                      <p className={styles.dayAndCity}>
                        {item.date}{" "}
                        <span className={styles.city}>{item.cityName}</span>
                      </p>
                      <p className={styles.accordDesc}>{item.cityHighlights}</p>
                    </div>
                    <div className={styles.widgets}>
                      {item.flight && (
                        <img
                          src={planeDepartureRegular}
                          alt=""
                          className={styles.flightFly}
                        />
                      )}

                      <WeatherWidget
                        weatherData={weatherData}
                        setWeatherData={setWeatherData}
                        cityName={cityName}
                      />
                    </div>
                  </div>
                }
                bodyComponent={
                  <div className={styles.accordContent}>
                    {item.dayItinerary.map((itinerary, innerIndex) => (
                      <div
                        className={styles.accordContentCard}
                        key={innerIndex}
                      >
                        {itinerary.new ? (
                          <div className={styles.newCard}>
                            <img
                              src={planeDepartureRegular}
                              alt=""
                              className={styles.newFlight}
                            />
                            <p className={styles.new}>new</p>
                          </div>
                        ) : (
                          <section className={styles.inputBox}>
                            <input
                              type="checkbox"
                              checked={selectedCards.some(
                                ([o, i]) => o === outerIndex && i === innerIndex
                              )}
                              className={styles.customCheckbox}
                              onChange={() =>
                                handleCheckboxChange(outerIndex, innerIndex)
                              }
                            />
                            <span className={styles.checkmark}></span>
                          </section>
                        )}
                        <div
                          className={`${styles.dayWiseItineraryCard} ${
                            selectedCards.some(
                              ([o, i]) => o === outerIndex && i === innerIndex
                            )
                              ? styles.activeBorder
                              : ""
                          }`}
                        >
                          <p className={styles.morning}>{itinerary.title}</p>
                          {itinerary.planModifications && (
                            <ul className={styles.newList}>
                              {itinerary?.planModifications?.map(
                                (item, index) => (
                                  <li key={index} className={styles.listItem}>
                                    {item}
                                  </li>
                                )
                              )}
                            </ul>
                          )}
                          <p className={styles.description}>
                            <HighlightCities text={itinerary.description} />
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                }
                zeroMarginOnExpand
                borderBottom
              />
            </div>
          ))}

          <div className={styles.btnCard}>
            <Button
              className={styles.continueBtn}
              onClick={handleConfirm}
              fullWidth
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlightSelect;
