import styles from "./adjust.module.scss";
import dragIcon from "../../../assets/draggable.svg";
import { Button, IconButton } from "@mui/material";
import { ButtonSizes, ButtonVariants } from "../../../enums";
import deleteIcon from "../../../assets/delete-bin-6-line.svg";
import PlusIcon from "../../../assets/plus.svg";
import substract from "../../../assets/subtract.svg";
import { useState } from "react";
import { adjustTripTypes } from "../../../types/adjust";
import { ReactSortable } from "react-sortablejs";
import { iCityDetailsResult } from "../../../types";
import CardWithCounter from "../cardWithCounter/CardWithCounter";
import ReOrderComponent from "../../reusableComponent/reOrderComponent/ReOrderComponent";

interface iProps {
  sections: iCityDetailsResult[];
  setSections: React.Dispatch<React.SetStateAction<iCityDetailsResult[]>>;
  removedSections: iCityDetailsResult[];
  setRemovedSections: React.Dispatch<
    React.SetStateAction<iCityDetailsResult[]>
  >;
}

function AdjustCart({
  sections,
  setSections,
  setRemovedSections,
}: iProps): JSX.Element {
  const [draggedIndex, setDraggedIndex] = useState(null);

  // const handleDragStart = (index: any) => {
  //   setDraggedIndex(index);
  // };

  // const handleDragOver = (index: any) => (event: any) => {
  //   event.preventDefault();
  //   if (draggedIndex === null || draggedIndex === index) return;

  //   const items = [...sections];
  //   const [draggedItem] = items.splice(draggedIndex, 1);
  //   items.splice(index, 0, draggedItem);

  //   setSections(items);
  //   setDraggedIndex(index);
  // };

  // const handleDragEnd = () => {
  //   setDraggedIndex(null);
  // };

  const handleAddCount = (index: any) => {
    setSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[index].noOfDays += 1;
      return newSections;
    });
  };

  const handleDeleteCount = (index: number) => {
    setSections((prevSections) => {
      const newSections = [...prevSections];
      if (newSections[index].noOfDays > 1) {
        newSections[index].noOfDays -= 1;
      } else {
        const removedItem = newSections.splice(index, 1)[0];
        setRemovedSections((prevRemovedSections) => [
          ...prevRemovedSections,
          removedItem,
        ]);
      }
      return newSections;
    });
  };

  return (
    <div className={styles.main}>
      <ReOrderComponent
        list={sections}
        setList={setSections}
        sortChildren={sections.map((i: any, index) => (
          <div key={index}>
            <CardWithCounter
              handleDecrement={handleDeleteCount}
              handleIncrement={handleAddCount}
              data={i}
              index={index}
              visibility="visible"
            />
          </div>
        ))}
        checkboxChildren={sections.map((i: any, index) => (
          <div key={index}>
            <CardWithCounter
              handleDecrement={handleDeleteCount}
              handleIncrement={handleAddCount}
              data={i}
              index={index}
              showSortIcon={false}
              visibility="visible"
            />
          </div>
        ))}
      />
    </div>
  );
}

export default AdjustCart;
