import React, { Dispatch, SetStateAction } from "react";
import styles from "./budget.module.scss";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { BudgetTypes } from "../../enums";
import { styled } from "@mui/system";

interface iProps {
  budget?: string;
  setBudget?: Dispatch<SetStateAction<BudgetTypes>>;
  setIsFormUpdated: Dispatch<SetStateAction<boolean>>;
}

function Budget({ budget, setBudget, setIsFormUpdated }: iProps): JSX.Element {
  const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "#A4D1F0",
      color: "#222222",
      fontWeight: "600",
      fontFamily: "Lato",
      textTransform: "none",
      paddingLeft: "20px",
      paddingRight: "20px",
      borderColor: "#A4D1F0",
      borderWidth: "2px",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  }));

  const FirstToggleButton = styled(StyledToggleButton)(({ theme }) => ({
    borderTopLeftRadius: "50px",
    borderBottomLeftRadius: "50px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textTransform: "none",
    fontWeight: "600",
    borderWidth: "2px",
    borderColor: "#A4D1F0",
    fontFamily: "Lato",
  }));

  const LastToggleButton = styled(StyledToggleButton)(({ theme }) => ({
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textTransform: "none",
    fontWeight: "600",
    borderColor: "#A4D1F0",
    borderWidth: "2px",
    fontFamily: "Lato",
  }));

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment: BudgetTypes | null
  ) => {
    if (newAlignment !== null && typeof setBudget === "function") {
      setBudget(newAlignment);
      setIsFormUpdated(true);
    }
  };
  return (
    <div className={styles.main}>
      <div className={styles.titleCard}>
        <span className={styles.title}>Budget (per traveler)</span>
      </div>
      <div className={styles.optionCard}>
        <ToggleButtonGroup
          size="small"
          value={budget}
          exclusive
          onChange={handleChange}>
          <FirstToggleButton value={BudgetTypes.Economy}>
            Economy
          </FirstToggleButton>
          <StyledToggleButton
            value={BudgetTypes.MidRange}
            sx={{
              textTransform: "none",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontWeight: "600",
              borderColor: "#A4D1F0",
              borderWidth: "2px",
              fontFamily: "Lato",
            }}>
            Mid-range
          </StyledToggleButton>
          <LastToggleButton value={BudgetTypes.Luxury}>Luxury</LastToggleButton>
        </ToggleButtonGroup>
        <div className={styles.priceCard}>
          <span className={styles.btn1}>{"< $1000"}</span>
          <span className={styles.btn2}>{"$1000-$2000"}</span>
          <span className={styles.btn3}>{"> $2000"}</span>
        </div>
      </div>
    </div>
  );
}

export default Budget;
