import { IconButton, Stack, Typography } from "@mui/material";
import back from "../../../assets/blue-arrow-right-line.svg";
import BackButtonHandler from "../../BackButtonHandler";
import Loader from "../loader/Loader";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { useContext } from "react";

export default function OverlayWithCancelBtn({
  handleCancel = () => {},
}: {
  handleCancel?: any;
}) {
  const globalContext = useContext(StateContext);
  const { openOverlayScreen, setOpenOverlayScreen }: iGlobalContext =
    globalContext;
  // Function to cancel Search Itinerary
  function onCancel() {
    setOpenOverlayScreen(false);
  }

  if (openOverlayScreen)
    return (
      <Stack position="fixed" zIndex={9999} top={0} left={0} right={0}>
        <Stack
          bgcolor="black"
          sx={{ minHeight: "100vh", opacity: "0.8" }}
          spacing={6}
          alignItems="center"
          justifyContent="center"
        >
          <BackButtonHandler
            onBack={() => {
              handleCancel();
              onCancel();
            }}
          />
          <Stack justifyContent="center" alignItems="center">
            <Loader />
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              handleCancel();
              onCancel();
            }}
            pr={2.2}
          >
            <IconButton>
              <img src={back} style={{ width: "35px" }} alt="Back"></img>
            </IconButton>
            <Typography
              variant="body2"
              fontSize={12}
              color="white"
              sx={{ cursor: "pointer" }}
            >
              Cancel request
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  return <></>;
}
