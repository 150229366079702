import React, { useEffect, useState } from "react";
import { Button, Stack, TextField } from "@mui/material";
import { outlinedCircleCloseIcon } from "../../../../constant";
import DialogPopup from "../../../reusableComponent/dialogPopup/dialogPopup";
import styles from "./editTripModal.module.scss";
import MyTripCard from "../myTripsCard/myTripsCard";
// import { upcomingDummyTripData } from "../../../../constant/dummyData";
// import { upcomingDummyTripDataArr } from "../../myTripsConstants";
import { updateItineraryNameAPI } from "../../../../api/saveOrUpdatingItinerary/updateItineraryNameAPI";
import { toastMessage } from "../../../../helpers/toast/toastMessage";
import toast from "react-hot-toast";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";

interface iProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  trip: any;
  setIsTripNameEdited?: any;
  setTripName?: any;
  tripName?: any;
}

function EditTripModal(props: iProps): JSX.Element {
  const {
    className = "",
    isOpen = false,
    onClose,
    trip,
    setIsTripNameEdited,
    tripName,
    setTripName,
  } = props;
  const [destinationList, setDestinationList] = useState(null);

  useEffect(() => {
    if (trip) {
      const tempDestList =
        typeof trip?.metaData === "string"
          ? JSON.parse(trip?.metaData)[0]?.selectedChatData?.destinationList
          : trip?.metaData?.selectedChatData?.destinationList;
      setDestinationList(tempDestList);
      setTripName(trip?.tripName);
    }
  }, [trip]);

  useEffect(() => {}, [destinationList]);

  async function handleSaveChanges() {
    // console.log({ trip }, destinationList, "CURRENT");
    if (!tripName) {
      toast.dismiss();
      return toastMessage.error("", "Please enter trip name!");
    }
    toast.loading("Updating trip name...");
    const response = await updateItineraryNameAPI({
      ...trip,
      tripName: tripName,
      metaData:
        typeof trip?.metaData === "string"
          ? JSON.parse(trip?.metaData)[0]
          : trip?.metaData,
    });
    toast.dismiss();
    if (!response) {
      toastMessage.error("OOPS!", "Updating trip name failed!");
      return;
    }
    triggerGAEvent(GA_LABEL_ENUMS.edit_trip_name_save_changes);
    setIsTripNameEdited((prev: any) => !prev);
    toastMessage.success("SUCCESS", "Trip name is updated!");
    setTimeout(() => {
      toast.dismiss();
      console.log("CLEAR");
    }, 2000);
    onClose();
  }

  return (
    <DialogPopup isOpen={isOpen}>
      <Stack
        sx={{
          // minHeight: "100vh",
          // mt: { sm: 0, xs: 2 },
          // justifyContent: { sm: "center", md: "center", lg: "center" },
          mb: "50px",
        }}
        spacing={6}
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          sx={{
            width: { xs: "340px" },
            maxWidth: { md: "450px" },
          }}
        >
          <Stack
            sx={{ width: "100%" }}
            px={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }}
          >
            <Stack
              alignSelf="end"
              mb={"17.14px"}
              mr={"10.04px"}
              sx={{
                opacity: "0.8",
              }}
            >
              <img
                onClick={onClose}
                style={{ width: "18.69px", cursor: "pointer" }}
                src={outlinedCircleCloseIcon}
                alt="cross"
              />
            </Stack>

            <Stack
              sx={{
                width: "100%",
                // marginBottom: "-20px",
                maxHeight: "80vh",
                overflow: "auto",
                bgcolor: "#f8f8f8",
                borderRadius: "8px",
              }}
            >
              <Stack pt={4} pb={0} pl={0} pr={0} spacing={3}>
                <div className={`${className} ${styles.editTripContainer}`}>
                  <p className={styles.heading}>Edit Trip Name</p>

                  <div className={styles.tripCard}>
                    <MyTripCard
                      className={styles.card}
                      headingContainerClassName={
                        styles.headingContainerClassName
                      }
                      headingClassName={styles.headingClassName}
                      travellersClassName={styles.travellersClassName}
                      trip={trip}
                      onCardClick={() => {}}
                      // showNextIcon={true}
                    />
                  </div>
                  <div className={styles.tripNameContainer}>
                    <Stack
                      position="relative"
                      alignSelf="start"
                      sx={{ width: "100%" }}
                    >
                      <h2
                        style={{
                          fontSize: "10.5px",
                          marginBottom: "6px",
                          color: "#5F6A70",
                          fontWeight: "500",
                          fontFamily: "Lato",
                          opacity: "0.9",
                          textTransform: "uppercase",
                          letterSpacing: "0.3px",
                        }}
                      >
                        new trip name
                      </h2>
                      <TextField
                        type="text"
                        size="small"
                        placeholder="Enter name here"
                        value={tripName}
                        onChange={(e: any) => {
                          setTripName(e.target.value);
                          //   setEmailError("");
                        }}
                        sx={{
                          "& .MuiOutlinedInput-input": {
                            paddingLeft: "7.55px",
                          },
                        }}
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "100%",
                            fontSize: "11.21px",
                            backgroundColor: "white",
                            color: "#696969",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: "11.21px",
                            // paddingLeft: "7.47px",
                          },
                        }}
                        // onKeyDown={handleEmailKeyDown}
                        // error={!!emailError}
                        // helperText={emailError}
                      />
                    </Stack>
                  </div>

                  <Button
                    variant="contained"
                    //   endIcon={<img src={next} alt="next" />}
                    sx={{
                      height: 80,
                      bgcolor: "#FCB400",
                      border: "none",
                      width: "100%",
                      borderRadius: "0px",
                      boxShadow: "none",
                      outline: "none",
                      "&:hover": {
                        bgcolor: "#FCB400",
                      },
                    }}
                    onClick={handleSaveChanges}
                  >
                    <h5
                      style={{
                        color: "#222222",
                        fontWeight: "600",
                        fontSize: "18px",
                        fontFamily: "lato",
                      }}
                    >
                      SAVE CHANGES
                    </h5>
                  </Button>
                </div>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </DialogPopup>
  );
}

EditTripModal.defaultProps = {
  className: "",
};

export default EditTripModal;
