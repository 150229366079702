import { Button } from "@mui/material";
import { arrowRight, arrowRightLineWhite } from "../../constant";
import styles from "./back.module.scss";

interface iProps {
  onClick: () => void;
  className?: string;
  iconColor?: string;
  backBtnStyle?: string;
}

function BackButton({
  onClick,
  className,
  iconColor,
  backBtnStyle,
}: iProps): JSX.Element {
  return (
    <div className={`${className} ${styles.main}`}>
      <Button
        startIcon={
          <img
            src={iconColor === "white" ? arrowRightLineWhite : arrowRight}
            alt=""
            className={styles.backIcon}
          />
        }
        className={`${backBtnStyle} ${styles.backBtn}`}
        onClick={onClick}>
        Back
      </Button>
    </div>
  );
}

BackButton.defaultProps = {
  className: "",
  iconColor: "",
  ratingClass: "",
  backBtnStyle: "",
  onClick: () => {},
};

export default BackButton;
