import React from "react";
import { cloudBoltSunLight, planeEnginesWhite } from "../../constant";
import BackButton from "../backBtn/backBtn";
import styles from "./weather.module.scss";
import { NotificationTypes } from "../../enums/weather";

interface iProps {
  className?: string;
  bgImage: string;
  description?: string;
  title?: string;
  advisoryType?: "weather" | "flight";
  handleBackButton?: () => void;
  bgColor: string;
}

function WeatherFlightDelayHeader(props: iProps): JSX.Element {
  const {
    className = "",
    bgImage,
    description = "",
    title = "",
    advisoryType = "weather",
    bgColor,
    handleBackButton = () => {},
  } = props;
  // const headerClass =
  //   advisoryType === NotificationTypes.Weather
  //     ? styles.weatherHead
  //     : styles.flightHead;
  const IconSelect =
    advisoryType === NotificationTypes.Weather
      ? cloudBoltSunLight
      : planeEnginesWhite;

  const getCardClass = (bgColor: string) => {
    switch (bgColor) {
      case "red":
        return styles.flightRedHead;
      case "blue":
        return styles.flightBlueHead;
      case "white":
        return styles.weatherHead;
      default:
        return styles.weatherHead;
    }
  };

  return (
    <div className={`${className} ${styles.main}`}>
      <div className={styles.container}>
        <header
          className={getCardClass(bgColor)}
          style={{ backgroundImage: `url(${bgImage})` }}>
          <BackButton
            onClick={handleBackButton}
            iconColor="white"
            backBtnStyle={styles.backBtn}
          />
          <section className={styles.contentCard}>
            <img src={IconSelect} alt="icon" className={styles.planeIcon} />
            <div className={styles.descCard}>
              <h1 className={styles.title}>{title}</h1>
              <p className={styles.desc}>{description}</p>
            </div>
          </section>
        </header>
      </div>
    </div>
  );
}

WeatherFlightDelayHeader.defaultProps = {
  className: "",
  bgImage: "",
  description: "",
  title: "",
  advisoryType: "",
  handleBackButton: () => {},
};

export default React.memo(WeatherFlightDelayHeader);
