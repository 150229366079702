import React from "react";
import { FormControlLabel, styled, Switch, SwitchProps } from "@mui/material";

interface iProps {
  isSelectedRefundable: boolean;
  handleRefundFilter: (event: any, checked: boolean) => void;
}

function ToggleSwitchComponent(props: iProps) {
  const { handleRefundFilter, isSelectedRefundable } = props;

  const ToggleSwitch = styled((props: SwitchProps) => (
    <Switch
      //   focusVisibleClassName=".Mui-focusVisible"
      //   inputProps={{ "aria-label": "controlled" }}
      //   disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translate(16px, -1px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          // backgroundColor: "#65C466",
          border: "1.2px solid #A4D1F0",
          backgroundColor: "#D8EFFF",
          opacity: 1,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
    },
    "& .MuiSwitch-thumb": {
      // boxSizing: "border-box",

      height: 24,
      width: 24,
      backgroundColor: "#ffff",
      border: "1.3px solid #696969",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "#D8D8D8",
      },
      "&::before": {
        display: "none",
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  return (
    <FormControlLabel
      control={
        <ToggleSwitch
          sx={{ m: 1 }}
          checked={isSelectedRefundable}
          onChange={handleRefundFilter}
        />
      }
      label="Refundable"
      sx={{
        "& .MuiFormControlLabel-label": {
          fontSize: { md: "12px", sm: "10px", xs: "10px" },
          fontFamily: "Lato",
        },
      }}
    />
  );
}

export default ToggleSwitchComponent;
