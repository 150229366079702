import { iSelectedHotelRoomArr } from "../context/globalContext/interface";

export {};

export function getSelectedOptionsStringArray(options: any) {
  if (!options) return "No data";
  let string = "";
  for (let i = 0; i < options.length; i++) {
    const ele = options[i];
    if (ele.check) {
      string += ele.data + ", ";
    }
  }
  if (string !== "") return string.substring(0, string.length - 2);
  else return "";
}

export const isThisHotelRoomSelected = (
  savedHotelArr: iSelectedHotelRoomArr[],
  currentHotelList: any
): boolean => {
  return currentHotelList?.hotels
    ?.map((eachHotel: any) => eachHotel?.hotelId)
    .includes(
      savedHotelArr?.find(
        (eachCityHotel: iSelectedHotelRoomArr) =>
          eachCityHotel?.checkIn === currentHotelList?.checkIn &&
          eachCityHotel?.checkOut === currentHotelList?.checkOut &&
          eachCityHotel?.city === currentHotelList?.city
      )?.selectedHotelRoom?.selectedHotelId
    );
};
