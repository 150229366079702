import { Button } from "@mui/material";
import CustomAccordion from "../../component/reusableComponent/customAccordion/CustomAccordion";
import { TodayItineraryData, WeatherDelayData } from "../../constant/dummyData";
import WeatherWidget from "../../component/weatherWidget/weatherWidget";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { iWeatherData } from "../../component/home/todayLanding/todayLandingPage";
import { useNavigate } from "react-router-dom";
import WeatherFlightDelayHeader from "../../component/weatherFlightDelayHeader/weatherFlightDelayHeader";
import styles from "./weather.module.scss";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";

interface HighlightCitiesProps {
  text: string;
}

const HighlightCities: React.FC<HighlightCitiesProps> = ({ text }) => {
  const highlightedText = useMemo(() => {
    const cities = [
      "Gothic Quarter",
      "Barcelona",
      "Plaça Reial",
      "La Rambla",
      "Boqueria Market",
      "Plaça de Catalunya",
    ];
    return cities.reduce((acc, city) => {
      const regex = new RegExp(`\\b${city}\\b`, "g");
      return acc.replace(
        regex,
        `<span style="color: #007aff; font-weight: bold;">${city}</span>`
      );
    }, text);
  }, [text]);

  return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

const WeatherAdvisory: React.FC = () => {
  const [weatherData, setWeatherData] = useState({} as iWeatherData);
  const [cityName, setCityName] = useState(TodayItineraryData.cityName);
  const [selectedCards, setSelectedCards] = useState<[number, number][]>([]);
  const navigate = useNavigate();
  let bgImage =
    "https://zenvoya-uploads-dev-test.s3.amazonaws.com/profile/newimage.jpg";
  useEffect(() => {
    triggerGAEvent(GA_LABEL_ENUMS.weather_advisory_page_load);
    const urlParams = new URLSearchParams(window.location.search);
    const city = urlParams.get("city");
    if (city) {
      const formattedCity = decodeURIComponent(city).replace(/\+|=|\?/g, " ");
      setCityName(formattedCity.trim());
    }
  }, []);

  const handleCheckboxChange = (outerIndex: number, innerIndex: number) => {
    triggerGAEvent(
      GA_LABEL_ENUMS.weather_advisory_suggested_changes_your_plan_checkbox_click
    );
    setSelectedCards((prevSelectedCards) =>
      prevSelectedCards.some(([o, i]) => o === outerIndex && i === innerIndex)
        ? prevSelectedCards.filter(
            ([o, i]) => !(o === outerIndex && i === innerIndex)
          )
        : [...prevSelectedCards, [outerIndex, innerIndex]]
    );
  };

  const globalContext = useContext(StateContext);
  const { isMobile = false, setScreen }: iGlobalContext = globalContext || {};

  const handleApplyChanges = useCallback(() => {
    triggerGAEvent(GA_LABEL_ENUMS.weather_advisory_apply_changes_click);
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <WeatherFlightDelayHeader
          bgImage={bgImage}
          title={"Weather Advisory"}
          description="Add plain language description of error and how to recover from
                it here, using up to 100 characters."
          advisoryType="weather"
          bgColor="white"
          handleBackButton={() => {
            triggerGAEvent(GA_LABEL_ENUMS.weather_advisory_back_click);
            if (isMobile) navigate("/today");
            if (!isMobile) setScreen("");
          }}
        />
        <div className={styles.titleCard}>
          <p className={styles.title}>Suggested Changes to Your Plans</p>
          <p className={styles.desc}>Tap below to accept suggested changes</p>
        </div>
        {WeatherDelayData.map((item, outerIndex) => (
          <div className={styles.accord} key={outerIndex}>
            <CustomAccordion
              className={styles.customStyles}
              summaryClass={styles.summaryClass}
              accordianDetailsClass={styles.accordianDetailsClass}
              defaultExpanded={false}
              onChange={(_a, _isOpen) => {
                triggerGAEvent(
                  GA_LABEL_ENUMS.weather_advisory_suggested_changes_your_plan_accordion_click
                );
              }}
              headingComponent={
                <div className={styles.headingComp}>
                  <div className={styles.accordTitleCard}>
                    <p className={styles.dayAndCity}>
                      MON AUG 13 <span className={styles.city}>BARCELONA</span>
                    </p>
                    <p className={styles.accordDesc}>
                      Explore the Gothic Quarter and city center.
                    </p>
                  </div>
                  <WeatherWidget
                    weatherData={weatherData}
                    setWeatherData={setWeatherData}
                    cityName={cityName}
                  />
                </div>
              }
              bodyComponent={
                <div className={styles.accordContent}>
                  {item.dayItinerary.map((itinerary, innerIndex) => (
                    <div className={styles.accordContentCard} key={innerIndex}>
                      <section className={styles.inputBox}>
                        <input
                          type="checkbox"
                          className={styles.customCheckbox}
                          onChange={() =>
                            handleCheckboxChange(outerIndex, innerIndex)
                          }
                        />
                        <span
                          className={`${itinerary.removed ? styles.hideCheck : styles.checkmark}`}
                        ></span>
                      </section>
                      <div
                        className={`${itinerary.removed ? styles.dayWiseItineraryCardRemoveBorder : styles.dayWiseItineraryCard} ${
                          selectedCards.some(
                            ([o, i]) => o === outerIndex && i === innerIndex
                          )
                            ? styles.activeBorder
                            : ""
                        }`}
                      >
                        <p className={styles.morning}>{itinerary.title}</p>
                        <p className={styles.description}>
                          <HighlightCities text={itinerary.description} />
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              }
              zeroMarginOnExpand
              borderBottom
            />
          </div>
        ))}
        <div className={styles.btnCard}>
          <Button
            className={styles.continueBtn}
            onClick={handleApplyChanges}
            fullWidth
          >
            Apply Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WeatherAdvisory;
