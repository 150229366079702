import { endPoints } from "../constant/api_constants/url";
import { API_METHODS } from "../enums";
import { fetchApi } from "../utils/apiFunction";

export const createUser = async (requestBody: any): Promise<any> => {
  const endpoint = endPoints.createUser;

  return await fetchApi(endpoint, API_METHODS.POST, requestBody);
};

export const getUserDetails = async (userId: string) => {
  const endpoint = endPoints.userDetails(userId);

  return await fetchApi(endpoint, API_METHODS.GET);
};

export const updateUserDetails = async (requestBody: any): Promise<any> => {
  const endpoint = endPoints.updateUserDetails;

  return await fetchApi(endpoint, API_METHODS.PUT, requestBody);
};

export const deleteUser = async (
  userId: string,
  requestBody: any,
): Promise<any> => {
  const endpoint = endPoints.deleteUser(userId);

  return await fetchApi(endpoint, API_METHODS.DELETE, requestBody);
};
