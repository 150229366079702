import React from "react";
import updatedEditIcon from "../../assets/updatedEditIcon.svg";
import styles from "./edit.module.scss";

interface iProps {
  onClick?: () => void;
}

function EditButton({ onClick }: iProps): JSX.Element {
  return (
    <div className={styles.main}>
      <img
        src={updatedEditIcon}
        alt="icon"
        onClick={onClick}
        className={styles.editIcon}
      />
      <p className={styles.editText}>EDIT</p>
    </div>
  );
}

export default EditButton;
