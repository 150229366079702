import { styled } from "@mui/material/styles";
import { Box, Slider } from "@mui/material";
import styles from "./slide.module.scss";
import { Dispatch, SetStateAction } from "react";

interface iProps {
  scrollValue: number;
  setScrollValue: (value: number) => void;
  setIsFormUpdated: Dispatch<SetStateAction<boolean>>;
}

function CustomSlider({
  scrollValue,
  setScrollValue,
  setIsFormUpdated,
}: iProps): JSX.Element {
  const CustomStyledSlider = styled(Slider)({
    color: "#A4D1F0",
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
      backgroundColor: "#A4D1F0",
    },
    "& .MuiSlider-rail": {
      backgroundColor: "#5f6a70", // Color for the slider rail (if needed)
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#ffff",
      border: "1.5px solid #5f6a70",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "#D8D8D8",
      },
      "&::before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      display: "none",
    },
  });

  const handleChange = (_event: Event, newValue: number | number[]) => {
    setScrollValue(newValue as number);
    setIsFormUpdated(true);
  };

  return (
    <div className={styles.main}>
      <Box maxWidth={300} minWidth={220}>
        <CustomStyledSlider
          valueLabelDisplay="auto"
          aria-label="slider"
          defaultValue={20}
          onChange={handleChange}
          value={scrollValue}
          min={1}
          max={30}
        />
      </Box>
      <div className={styles.dayCard}>
        <span className={styles.days}>{scrollValue} days</span>
      </div>
    </div>
  );
}

export default CustomSlider;
