export const flight_delay_events = {
  flight_delay_page_load: {
    category: "Flight delay page loads",
    action: "Flight delay page loads",
    label: "flight-delay-page-load",
  },
  flight_delay_back_click: {
    category: "User navigates back to today page",
    action: "User clicks on back button",
    label: "flight-delay-back-click",
  },
  flight_delay_details_click: {
    category: "Details popup will open",
    action: "User clicks on details button",
    label: "flight-delay-details-click",
  },
  flight_delay_show_more_click: {
    category: "User sees more flight options",
    action: "User clicks on show more button",
    label: "flight-delay-show-more-click",
  },
  flight_delay_select_click: {
    category: "User navigates to flight select page",
    action: "User clicks on select button",
    label: "flight-delay-select-click",
  },
};
