import { useEffect, useRef } from "react";

export function useRefLocalStorage<T>(
  key: string,
  initialValue: T | (() => T)
) {
  const isClient = typeof window !== "undefined"; // Check for client-side

  // Function to get the initial value from localStorage or fall back to initialValue
  const getInitialValue = () => {
    if (isClient) {
      const storedValue = localStorage.getItem(key);
      if (storedValue != null) {
        try {
          return JSON.parse(storedValue); // Parse stored value
        } catch (e) {
          console.error("Error parsing localStorage value", e);
        }
      }
    }
    return typeof initialValue === "function"
      ? (initialValue as () => T)()
      : initialValue; // Fallback to initialValue
  };

  // Initialize ref with the value from localStorage or initialValue
  const ref = useRef<T>(initialValue || getInitialValue());

  // Effect to make sure that localStorage sync happens after ref updates
  useEffect(() => {
    if (isClient && ref.current) {
      localStorage.setItem(key, JSON.stringify(ref.current)); // Sync ref to localStorage
    }
  }, [key, ref.current]); // Dependency on ref.current

  const setRefValue = (newValue: T) => {
    ref.current = newValue;
    if (isClient) {
      localStorage.setItem(key, JSON.stringify(newValue)); // Update localStorage
    }
  };

  return [ref, setRefValue] as [typeof ref, typeof setRefValue];
}
