export const traveler_info_page_events = {
    traveler_info_back_button_click: {
      category: "User navigates back to booking page",
      action: "User clicks on the back button",
      label: "traveler-info-back-button-click",
    },
    traveler_info_downward_accordion_click:{
        category: "Traveler info dropdown will display with required input fields",
        action: "User clicks on the downward accordion",
        label: "traveler-info-downward-accordion-click",  
    },
    traveler_info_upward_accordion_click:{
        category: "Traveler info dropdown will get close",
        action: "User clicks on the upward accordion",
        label: "traveler-info-upward-accordion-click",  
    },
    traveler_info_first_name_input_field_click:{
        category: "User can enter the first-name in the input field",
        action: "User clicks on first-name input field",
        label: "traveler-info-first-name-input-field-click",  
    },
    traveler_info_middle_name_or_initial_input_field_click:{
        category: "User can enter the middle-name/initial in the input field",
        action: "User clicks on middle-name input field",
        label: "traveler-info-middle-name-or-initial-input-field-click",  
    },
    traveler_info_last_name_input_field_click:{
        category: "User can enter the last-name in the input field",
        action: "User clicks on last-name input field",
        label: "traveler-info-last-name-input-field-click",   
    },
    traveler_info_dob_month_dropdown_click:{
        category: "Start selecting the month from the dropdown",
        action: "User clicks on DOB's month drop down",
        label: "traveler-info-dob-month-dropdown-click",   
    },
    traveler_info_dob_year_input_field_click:{
        category: "User can enter the year data in the input field",
        action: "User clicks on DOB's year input field",
        label: "traveler-info-dob-year-input-field-click",  
    },
    traveler_info_gender_dropdown_click:{
        category: "Start select the gender from the dropdown",
        action: "User clicks on gender drop down",
        label: "traveler-info-dob-year-input-field-click",  
    },
    traveler_info_contact_info_section_email_input_field_click :{
        category: "User can enter the email id in the input field",
        action: "User clicks on email input field",
        label: "traveler-info-contact-info-section-email-input-field-click", 
    },
    traveler_info_contact_info_section_country_code_dropdown_click:{
        category: "Start select the country code from the dropdown",
        action: "User clicks on country code drop down",
        label: "traveler-info-contact-info-section-country-code-dropdown-click", 
    },
    traveler_info_contact_info_section_mobile_phone_number_input_field_click:{
        category: "User can enter the mobile phone number in the input field",
        action: "User clicks on mobile phone number input field",
        label: "traveler-info-contact-info-section-mobile-phone-number-input-field-click", 
    },
    traveler_info_frequent_flyer_section_program_name_dropdown_click:{
        category: "Start select the program name from the dropdown",
        action: "User clicks on program name drop down",
        label: "traveler-info-frequent-flyer-section-program-name-dropdown-click", 
    },
    traveler_info_frequent_flyer_program_section_frequent_flyer_number_input_field_click :{
        category: "User can enter the frequent flyer number in the input field",
        action: "User clicks on frequent flyer number input field",
        label: "traveler-info-frequent-flyer-program-section-frequent-flyer-number-input-field-click", 
    },
    traveler_info_tsa_precheck_number_input_field_click : {
        category: "User can enter the TSA PreCheck number in the input field",
        action: "User clicks on the TSA PreCheck number input field",
        label: "traveler-info-tsa-precheck-number-input-field-click", 
    },
    traveler_info_meal_preferences_section_meal_type_dropdown_click: {
        category: "Start select the meal type from the dropdown",
        action: "User clicks on meal type drop down",
        label: "traveler-info-meal-preferences-section-meal-type-dropdown-click", 
    },
    traveler_info_special_travel_needs_section_bassinet_request_for_infant_checkbox_click :{
        category: "User can select the bassinet request for infant checkbox",
        action: "User clicks on bassinet request for infant checkbox",
        label: "traveler-info-special-travel-needs-section-bassinet-request-for-infant-checkbox-click", 
    },
    traveler_info_special_travel_needs_section_wheel_chair_checkbox_click : {
        category: "User can select the wheel chair checkbox",
        action: "User clicks on wheel chair checkbox",
        label: "traveler-info-special-travel-needs-section-wheel-chair-checkbox-click",
    },
    traveler_info_continue_button_click: {
        category: "After filling all the data continue button",
        action: "User clicks on continue button",
        label: "traveler-info-continue-button-click",
    },
    traveler_information_x_close_button_click :{
        category: "It will navigate to my trip page",
        action: "User clicks on view itinerary button",
        label: "traveler-information-view-itinerary-button-click",
    }

}