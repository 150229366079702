import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HistoryTab from "../history/history";
import {
  // arrowIcon,
  // calenderIcon,
  newTripIcon,
  ROUTES,
  // slidersRegular,
  updatedNewTrip,
} from "../../../constant";
import { useNavigate } from "react-router-dom";
import SaveTrips from "../../savedTrips/savedTrips";
import { Itineraries } from "../../../types";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { Button } from "@mui/material";
import { getItinerariesListAPI } from "../../../api/getSaveItinerariesList";
import { sortDatesInAscendingOrder } from "../../../utils/dateUtils";
// import toast from "react-hot-toast";
import styles from "./tab.module.scss";
import { ScreenTypes } from "../../../enums";
import { desktopSidebarTabs } from "./desktopTabs";
import { getHistoryAPICall } from "../../../api/getHistory";
import { getConfigMessage } from "../../../utils";
import { APP_CONFIG } from "../../../constant/common";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import { iCurrentTripContext } from "../../../context/currentTripContext/currentTripContextInterface";
import { useCurrentTripContext } from "../../../context/currentTripContext/currentTripContext";
import { useResetState } from "../../../utils/resetBookingData";
import { TERMS_AND_CONTACT_ARR } from "../../../constant/dummyData";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import Loader from "../../reusableComponent/loader/Loader";
import useCustomErrorHandler from "../../../hooks/useCustomErrorHandler";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface iProps {
  isOpenMobileSidebar?: boolean;
  setIsOpenMobileSidebar?: Dispatch<SetStateAction<boolean>>;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box
          sx={{
            pl: 2.3,
            pt: 1,
            pr: 2,
            overflow: "hidden",
            backgroundColor: "#ffff",
          }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  setIsOpenMobileSidebar,
  isOpenMobileSidebar,
}: iProps) {
  const [value, setValue] = useState<number>(1);
  const [tripCount, setTripCount] = useState<number>(0);
  const navigate = useNavigate();
  const [listItinery, setListItinery] = useState<Itineraries | null>(null);
  const [todayHistory, setTodayHistory] = useState([]);
  const [yesterdayHistory, setYesterdayHistory] = useState([]);
  const [oneWeekAgoHistory, setOneWeekAgoHistory] = useState([]);
  const [lastOneMonth, setLastOneMonth] = useState([]);
  const globalContext = useContext(StateContext);
  const {
    isMobile,
    setScreen,
    userInfo,
    trigerredListItineraryAPIFlag,
    setTrigerredListItineraryAPIFlag,
    userDetails,
    setFirstScreen,
    firstScreen,
    appConfig,
    setMyTripActiveTab,
  }: iGlobalContext = globalContext;
  const { handleCustomError } = useCustomErrorHandler();
  const { currentTripData }: iCurrentTripContext = useCurrentTripContext();

  /**
   * Handling tabs and switching between tabs
   */
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const resetBookingValues = useResetState();
  const handleNav = () => {
    triggerGAEvent(GA_LABEL_ENUMS.New_Trip_Click);
    navigate(ROUTES.Home);
    setScreen("");
    setFirstScreen(ScreenTypes.NewTrip);
    resetBookingValues();
  };
  useEffect(() => {
    setValue(0);
    setTimeout(() => {
      if (isMobile) setValue(0);
      else {
        const selectedScreenIndexValue = desktopSidebarTabs.findIndex(
          (sideNavItem) => sideNavItem.enumType === firstScreen
        );
        if (selectedScreenIndexValue >= 0) {
          setValue(selectedScreenIndexValue);
          return;
        }
        // if(firstScreen === )
        setValue(desktopSidebarTabs.length);
      }
    }, 500);
  }, [firstScreen, isMobile]);

  const handleLastTab = () => {
    if (setIsOpenMobileSidebar) setIsOpenMobileSidebar(false);
    navigate(ROUTES.NewTrip);
    setValue(value);
  };

  /**
   * Save trip login
   */
  const [isLoadingItineraryList, setIsLoadingItineraryList] = useState(false);
  async function getListItineries() {
    try {
      setIsLoadingItineraryList(true);
      // setTripCount(0)
      const response = await getItinerariesListAPI(
        userDetails.id || userInfo.id,
        "saved",
        0,
        10
      );
      if (response?.statusCode !== 200) {
        handleCustomError(response?.statusCode, "itinerary.list");
        return null;
      }
      setIsLoadingItineraryList(false);
      if (response && response?.data) {
        setListItinery(sortDatesInAscendingOrder(response?.data, "createdAt"));
        setTripCount(response?.data.length);
        // console.log(response?.data.length, "response");
      } else {
        throw new Error("No response data");
      }
    } catch (error) {
      toastMessage.error("OOPS!", "Something went wrong!");
    }
    setTrigerredListItineraryAPIFlag(false);
  }

  /**
   * React component
   */
  if (!userInfo.loggedIn && !isMobile)
    return (
      <div className={styles.main}>
        <Button className={styles.headBox} onClick={handleNav}>
          <p className={styles.title}>New Trip</p>
          <img src={newTripIcon} alt="" className={styles.editLogo} />
        </Button>
        <Tabs
          orientation={"vertical"}
          value={value}
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "none",
            "& .MuiTab-root": {
              color: "#8F8F8F",
              fontSize: 11,
              fontFamily: "Lato",
              fontWeight: "600",
              marginTop: "0px",
              minHeight: "10px",
              paddingTop: "8px",
              paddingBottom: "8px",
              textAlign: "left",
            },
            "& .Mui-selected": {
              color: "#47174C !important",
              fontWeight: "800",
            },
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}>
          {TERMS_AND_CONTACT_ARR.filter((item) => {
            if (userInfo.loggedIn) {
              return true;
            }
            if (!userInfo.loggedIn && item.id === 1) return false;
            return true;
          }).map((item) => {
            return (
              <a
                style={{ textDecoration: "none", color: "#8F8F8F" }}
                href={item.link}
                key={item.id}
                rel="noreferrer"
                target={item.id !== 0 ? "_blank" : "_self"}
                onClick={() => {
                  triggerGAEvent(
                    item.title === "Terms of Service"
                      ? GA_LABEL_ENUMS.Terms_of_service_click
                      : GA_LABEL_ENUMS.Contact_us_click
                  );
                }}>
                <Tab
                  disableTouchRipple
                  label={item.title}
                  // {...a11yProps(desktopSidebarTabs.length)}
                  sx={{
                    // mx: "20px",
                    ml: "3px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    opacity: 1,
                    //  borderTop: "0.5px solid #979797"
                  }}
                  // onClick={() => {
                  //   setValue(desktopSidebarTabs.length);
                  //   // setScreen(ScreenTypes.SaveTripsList)
                  // }}
                />
              </a>
            );
          })}
        </Tabs>
      </div>
    );

  return (
    <div className={styles.main}>
      {!isMobile && (
        <Button className={styles.headBox} onClick={handleNav}>
          <p className={styles.title}>New Trip</p>
          <img src={newTripIcon} alt="" className={styles.editLogo} />
        </Button>
      )}

      <Box className={styles.box}>
        {isMobile ? (
          <Tabs
            orientation={"horizontal"}
            value={value}
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "none",
              "& .MuiTab-root": {
                color: "#8F8F8F",
                fontSize: 12,
                fontFamily: "Lato",
                fontWeight: "600",
                marginLeft: "10px",
              },
              "& .Mui-selected": {
                color: "#47174C !important",
                fontWeight: "700",
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}>
            <Tab
              label="HISTORY"
              {...a11yProps(0)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
              onClick={() => setValue(0)}
            />
            <Tab
              icon={
                <img
                  src={updatedNewTrip}
                  alt=""
                  style={{ width: "auto", height: "20px" }}
                />
              }
              label="New Trip"
              iconPosition="start"
              {...a11yProps(2)}
              sx={{ alignSelf: "flex-end", mr: 0.5 }}
              onClick={handleLastTab}
            />
          </Tabs>
        ) : (
          <Tabs
            orientation={"vertical"}
            value={value}
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "none",
              "& .MuiTab-root": {
                color: "#8F8F8F",
                fontSize: 11,
                fontFamily: "Lato",
                fontWeight: "600",
                // marginTop: "10px",
                marginTop: "0px",
                // minHeight: "10px",
                paddingTop: "8px",
                paddingBottom: "8px",
                textAlign: "left",
                // minHeight: "60px",
              },
              "& .Mui-selected": {
                color: "#47174C !important",
                fontWeight: "800",
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}>
            {desktopSidebarTabs.map((data, idx) => {
              if (
                !(
                  JSON.stringify(currentTripData) === "{}" &&
                  data?.title === "Current Trip"
                )
              )
                return (
                  <Tab
                    disableTouchRipple
                    icon={
                      <img
                        src={data?.icon}
                        alt=""
                        style={{
                          width: data.icon ? "auto" : "15px",
                          height: "15px",
                        }}
                      />
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setScreen("");
                      setMyTripActiveTab("UPCOMING");
                      resetBookingValues();
                      if (data.title === "Current Trip") {
                        triggerGAEvent(GA_LABEL_ENUMS.Current_trips_click);
                        setFirstScreen(ScreenTypes.CurrentTrip);
                      }
                      if (data.title === "My Trips") {
                        triggerGAEvent(GA_LABEL_ENUMS.My_trips_click);
                        setFirstScreen(ScreenTypes.MyTrips);
                      }
                      if (data.title === "For you") {
                        triggerGAEvent(GA_LABEL_ENUMS.My_trips_click);
                        setFirstScreen(ScreenTypes.ForYou);
                      }
                      if (data.title === "preferences")
                        setFirstScreen(ScreenTypes.Preferences);
                      if (data.title === "HELP")
                        setFirstScreen(ScreenTypes.help);
                      setValue(idx);
                    }}
                    iconPosition="start"
                    label={data.title}
                    {...a11yProps(idx)}
                    sx={{
                      mx: "20px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      height: "20px !important",
                      padding: 0,
                      marginTop: "40px",
                      minHeight: "35px",
                      visibility:
                        data.title !== "Current Trip"
                          ? "visible"
                          : JSON.stringify(currentTripData) === "{}"
                            ? "hidden"
                            : "visible",
                    }}
                  />
                );
            })}
            <div>
              {TERMS_AND_CONTACT_ARR.filter((item) => {
                if (userInfo.loggedIn) {
                  return true;
                }
                if (!userInfo.loggedIn && item.id === 1) return false;
                return true;
              }).map((item) => {
                return (
                  <a
                    style={{
                      textDecoration: "none",
                      color: "#8F8F8F !important",
                    }}
                    href={item.link}
                    key={item.id}
                    rel="noreferrer"
                    target={item.id !== 0 ? "_blank" : "_self"}
                    onClick={() => {
                      triggerGAEvent(
                        item.title === "Terms of Service"
                          ? GA_LABEL_ENUMS.Terms_of_service_click
                          : GA_LABEL_ENUMS.Contact_us_click
                      );
                    }}>
                    <Tab
                      disableTouchRipple
                      label={item.title}
                      // {...a11yProps(desktopSidebarTabs.length)}
                      sx={{
                        // mx: "20px",
                        ml: "3px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        fontWeight: "900",
                        opacity: 1,
                        minHeight: "10px",
                        //  borderTop: "0.5px solid #979797"
                      }}
                      // onClick={() => {
                      //   setValue(desktopSidebarTabs.length);
                      //   // setScreen(ScreenTypes.SaveTripsList)
                      // }}
                    />
                  </a>
                );
              })}
            </div>

            <Tab
              disableTouchRipple
              label="HISTORY"
              // {...a11yProps(desktopSidebarTabs.length)}
              sx={{
                // mx: "20px",
                mb: "15px",
                ml: "3px",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                minHeight: "10px",
                //  borderTop: "0.5px solid #979797"
              }}
              onClick={() => {
                setValue(desktopSidebarTabs.length);
                // setScreen(ScreenTypes.SaveTripsList)
              }}
            />
          </Tabs>
        )}
      </Box>
      {isMobile ? (
        <>
          <CustomTabPanel value={value} index={isMobile ? 0 : 1}>
            <HistoryTab
              // todayHistory={todayHistory}
              // setTodayHistory={setTodayHistory}
              // yesterdayHistory={yesterdayHistory}
              // setYesterdayHistory={setYesterdayHistory}
              // oneWeekAgoHistory={oneWeekAgoHistory}
              // setOneWeekAgoHistory={setOneWeekAgoHistory}
              // lastOneMonth={lastOneMonth}
              // setLastOneMonth={setLastOneMonth}
              isOpenMobileSidebar={isOpenMobileSidebar}
              setIsOpenMobileSidebar={setIsOpenMobileSidebar}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={isMobile ? 1 : 0}>
            <SaveTrips
              value={value}
              setTripCount={setTripCount}
              setIsOpenMobileSidebar={setIsOpenMobileSidebar}
              tripCount={tripCount}
              listItinery={listItinery}
              setListItinery={setListItinery}
              isLoadingItineraryList={isLoadingItineraryList}
              setIsLoadingItineraryList={setIsLoadingItineraryList}
            />
          </CustomTabPanel>
        </>
      ) : (
        <>
          {Array.from({ length: desktopSidebarTabs.length + 1 }).map(
            (item, idx) => (
              <CustomTabPanel value={value} index={idx}>
                <HistoryTab
                  // todayHistory={todayHistory}
                  // setTodayHistory={setTodayHistory}
                  // yesterdayHistory={yesterdayHistory}
                  // setYesterdayHistory={setYesterdayHistory}
                  // oneWeekAgoHistory={oneWeekAgoHistory}
                  // setOneWeekAgoHistory={setOneWeekAgoHistory}
                  // lastOneMonth={lastOneMonth}
                  // setLastOneMonth={setLastOneMonth}
                  isOpenMobileSidebar={isOpenMobileSidebar}
                  setIsOpenMobileSidebar={setIsOpenMobileSidebar}
                />
              </CustomTabPanel>
            )
          )}
        </>
      )}
    </div>
  );
}
