import { useContext, useEffect } from "react";
import {
  // ROUTES,
  // SENDING_CHAT_MESSAGE,
  // VIEW_RECOMMENDED_TRIPS,
  zenvoyaChatIcon,
  scrollRightBtnIcon,
  avatarIcon,
  // zenvolyaNewLogo,
  // ChatData,
} from "../../constant";
import InputWithMic from "../../component/reusableComponent/inputWithMic/InputWithMic";
import { iGlobalContext } from "../../context/globalContext/interface";
import { StateContext } from "../../context/globalContext/context";
import DestinationCard from "../../component/reusableComponent/destinationCard/DestinationCard";
// import { getFrequentlyViewTripsApi } from "../../api/getFrequentlyViewTrips";
// import SpeechRecognition from "react-speech-recognition";
// import toast from "react-hot-toast";
// import { useNavigate } from "react-router-dom";
// import { chatInteractionAPI } from "../../api/chatInteractionAPI";
// import { useSessionStorage } from "../../hooks/useSessionStorage";
// import { v4 } from "uuid";
import { iAppConfig, iCityDetailsResult } from "../../types";
// import { getItineraryImageURL } from "../../api/getItineraryImageURL";
// import {
//   checkIfThereIsDestination,
//   getConfigMessage,
//   scrollToBottom,
// } from "../../utils";
import Loader from "../../component/reusableComponent/loader/Loader";
import DesktopLandingPage from "../../component/desktopLandingPage/DesktopLandingPage";
// import { RESPONSE_DUMMY } from "../../constant/responseDummy";
import InitialChat from "../../component/desktopLandingPage/initialChat/initialChat";
// import ReactGA from "react-ga4";
import { APP_CONFIG } from "../../constant/common";
// import { toastMessage } from "../../helpers/toast/toastMessage";
import styles from "./recommendedTrips.module.scss";
// import { localStorageName } from "../../enums";
import Prompts from "../../component/prompts/prompts";
// import { getUserRecommendedTripBasedOnPreferencesAPI } from "../../api/getUserRecommendTripBasedOnPreferences";
import { iRecommendedTripContext } from "../../context/recommendedTripContext/recommendedTripContextInterface";
import { useRecommendedTripContext } from "../../context/recommendedTripContext/recommendedTripContext";
// import DesktopMultipleItinerary from "../../component/deskTopDestination/deskTopMultipleItineraryDetails/deskTopMultipleItineraryDetails";
// import DestinationCardWithImages from "../../component/reusableComponent/destinationCardWithImages/DestinationCardWithImages";
// import UpcomingTripCard from "../../component/reusableComponent/upcomingTripCard/UpcomingTripCard";
// import { upcomingDummyTripData } from "../../constant/dummyData";
import UpcomingTripCarousel from "../../component/desktopLandingPage/upcomingTripCarousel/UpcomingTripCarousel";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";

export default function RecommendedTrips() {
  const context = useContext(StateContext);
  const {
    chatInputText,
    setChatInputText,
    // setShowMoreFlag,
    // setApiResponse,
    isMobile,
    // setOpenOverlayScreen,
    // setChatData,
    // setSharedItineraryId,
    // setIsItinerarySavedFlag,
    // setenableMultiSelect,
    // scrollContainerRef,
    userDetails,
    appConfig,
    // setSelectedChatData,
    // setPrevRoute,
    chatData,
    // isLoggedIn,
    inputFocus,
  }: iGlobalContext = context;
  const {
    frequentlyViewTrips,
    index,
    handleScrollLeft,
    sliderRef,
    handleViewItineraryDetails,
    handleScrollRight,
    disableInput,
    handleCreateItinerary,
    handleCancel,
    setDisableInput,
    upcomingTripArr,
  }: iRecommendedTripContext = useRecommendedTripContext();
  // const navigate = useNavigate();
  // // console.log("HERE, HERE< HERE");

  useEffect(() => {
    triggerGAEvent(GA_LABEL_ENUMS.Loads_Home_page);
  }, []);

  return (
    <div>
      {/* {isMobile ? ( */}
        <div
          className={`${inputFocus && styles.superContainerFocus} ${styles.superContainer}`}
        >
          {/* <p style={{ visibility: "hidden" }}>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Reiciendis, id!
          </p> */}
          <div className={styles.upcomingtripContainer}>
            {userDetails?.loggedIn && upcomingTripArr?.length > 0 && (
              <>
                <h1 className={styles.heading}>Upcoming Trips</h1>
                <UpcomingTripCarousel carouselArr={upcomingTripArr} />
                {/* <div className={styles.upcomingtripSubContainer}>
                  <UpcomingTripCard
                    destinationList={upcomingDummyTripData}
                    onCardClick={() => {
                      setPrevRoute(ROUTES.Home);
                      navigate(ROUTES.TripDetails);
                    }}
                  />
                  <h1 className={styles.bookedDate}>
                    Booked on JUNE 8 2024 | 4:37pm
                  </h1>
                </div> */}
              </>
            )}
          </div>
          {/* <img className={styles.logo} alt="logo" src={zenvolyaNewLogo} /> */}
          <div className={styles.midSectionContainer}>
            {/* <div className={styles.horizontalScrollContainer}>
              {frequentlyViewTrips.length > 0 && (
                <div className={styles.recommendedTripsContainer}>
                  <h1 className={styles.recommendedTripsHeading}>
                    Recommended Trips
                  </h1>
                  {index.first > 0 && (
                    <div
                      onClick={handleScrollLeft}
                      className={styles.leftBtnContainer}
                    >
                      <img
                        className={styles.leftBtn}
                        src={scrollRightBtnIcon}
                        alt="left btn"
                      />
                    </div>
                  )}

                  <div className={styles.scrollContainer} ref={sliderRef}>
                    {frequentlyViewTrips.length &&
                      frequentlyViewTrips.map((ele: any, index: number) => (
                        <DestinationCard
                          recommendedTripCard
                          width="calc(50vw - 7px)"
                          key={index}
                          destinationData={
                            // userDetails?.loggedIn
                            //   ?
                            // ele?.destinationList[0]
                            // : JSON.parse(
                            //     ele.metaData
                            //   )[0]?.selectedChatData?.destinationList?.filter(
                            //     (ele: iCityDetailsResult) => ele.checkedFlag
                            //   )[0]
                            JSON.parse(
                              ele.metaData
                            )[0]?.selectedChatData?.destinationList?.filter(
                              (ele: iCityDetailsResult) => ele.checkedFlag
                            )[0]
                          }
                          onClick={() => handleViewItineraryDetails(ele)}
                          selectable={false}
                        />
                      ))}
                  </div>
                  <div
                    onClick={handleScrollRight}
                    className={styles.rightBtnContainer}
                  >
                    <img
                      className={styles.rightBtn}
                      src={scrollRightBtnIcon}
                      alt="right btn"
                    />
                  </div>
                </div>
              )}
            </div> */}
            <div className={styles.contentContainer}>
              <div className={styles.sectionContainer}>
                <div className={styles.systemChatIcon}>
                  <img
                    className={styles.chatIcon}
                    alt="chat logo"
                    // src={zenvoyaChatIcon}
                    src={avatarIcon}
                  />
                  <h3 className={styles.heading}>zenvoya</h3>
                </div>
                <div
                  className={styles.constText}
                  dangerouslySetInnerHTML={{
                    __html:
                      appConfig?.length > 0
                        ? appConfig?.filter(
                            (ele: iAppConfig) =>
                              ele.key === APP_CONFIG.HOME_CONSTANT_2
                          )[0]?.value
                        : "",
                  }}
                />
              </div>
              {disableInput && (
                <div className={styles.chatLoaderContainer}>
                  <div className={styles.chatLoaderSubContainer}>
                    <InitialChat
                      message={chatInputText || chatData[0]?.message}
                    />
                  </div>
                </div>
              )}
              {disableInput && <Loader />}
              {!disableInput && <Prompts setValue={setChatInputText} />}
            </div>
          </div>
          <div
            className={
              inputFocus ? styles.inputContainerFocus : styles.inputContainer
            }
          >
            <InputWithMic
              onStopRecording={handleCreateItinerary}
              onSend={handleCreateItinerary}
              value={chatInputText}
              setValue={setChatInputText}
              // handleClick={() => scrollHandler(testRef)}
              disable={disableInput}
              handleCancel={handleCancel}
              setDisableInput={setDisableInput}
              disableSendBtn={chatInputText === "" ? true : false}
            />
          </div>
        </div>
      {/* ) : (
        <>
          <DesktopLandingPage />
        </>
      )} */}
    </div>
  );
}
