import copyIcon from "../../assets/copyIcon.svg";
import { toastMessage } from "../../helpers/toast/toastMessage";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { FaqItem } from "../../types";
import styles from "./promopt.module.scss";
import CustomHorizontalScrollComponent from "../reusableComponent/customHorizontalScrollContainer/CustomHorizontalScrollComponent";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";

interface iProps {
  setValue: Dispatch<SetStateAction<string>>;
}

function Prompts({ setValue }: iProps): JSX.Element {
  const context = useContext(StateContext);

  const { appConfig }: iGlobalContext = context;

  const [promptData, setPromptData] = useState<FaqItem[]>([]);
  const sortedData =
    promptData && promptData?.sort((a, b) => a.key.localeCompare(b.key));
  useEffect(() => {
    const filterFaqCategory = (data: any) => {
      return data.filter(
        (item: { configCategory: string }) =>
          item.configCategory === "promptTemplate"
      );
    };

    setPromptData(filterFaqCategory(appConfig));
  }, [appConfig]);

  const [copiedText, setCopiedText] = useState<string | null>(null);

  const handleCopy = (title: string, description: string) => {
    const textToCopy = `${title} ${description}`;
    if (textToCopy === copiedText) {
      return;
    }

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          triggerGAEvent(GA_LABEL_ENUMS.Copying_Suggested_Trips);
          triggerGAEvent(GA_LABEL_ENUMS.home_copy_click);
          toastMessage.success("Text copied to clipboard");
          setCopiedText(textToCopy);
          setValue(textToCopy);
        })
        .catch((err) => {
          toastMessage.error("Error copying text: ", err);
          fallbackCopyTextToClipboard(textToCopy, setCopiedText);
        });
    } else {
      fallbackCopyTextToClipboard(textToCopy, setCopiedText);
    }
  };

  const fallbackCopyTextToClipboard = (
    text: string,
    setValue: (value: string) => void
  ) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      toastMessage.success("Text copied to clipboard");
      setValue(text);
    } catch (err) {
      console.error("Fallback: Error copying text: ", err);
    }
    document.body.removeChild(textArea);
  };

  return (
    <div className={styles.main}>
      {sortedData?.length > 0 && (
        <p className={styles.title}>Here are some trips to try:</p>
      )}
      <section>
        <CustomHorizontalScrollComponent className={styles.container}>
          {sortedData &&
            sortedData.map((i: FaqItem, index: number) => {
              if (!i.value) {
                return null;
              }

              const [heading, ...bodyArray] = i.value.split("\n");
              const body = bodyArray.join("\n");
              const promptTitle = heading || "";
              const promptDescription = body || "";

              return (
                <div className={styles.contentCard} key={index}>
                  <div className={styles.card}>
                    <p className={styles.cartTitle}>
                      {promptTitle.length > 30
                        ? promptTitle.substring(0, 30) + "..."
                        : promptTitle}
                    </p>
                    <div className={styles.descCard}>
                      <section className={styles.descBox}>
                        <span className={styles.desc}>{promptDescription}</span>
                      </section>
                      <img
                        src={copyIcon}
                        alt=""
                        className={styles.copyIcon}
                        onClick={() =>
                          handleCopy(promptTitle, promptDescription)
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </CustomHorizontalScrollComponent>
      </section>
    </div>
  );
}

export default Prompts;
