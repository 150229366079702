export const booking_page_events = {
  booking_back_click: {
    category: "User clicks on back button",
    action: "User navigates back to itinerary details page",
    label: "booking-back-click",
  },

  booking_flight_checkbox_click: {
    category:
      "Only flights will listing if user selects only the flight option",
    action: "User clicks on flight checkbox",
    label: "booking-flight-checkbox-click ",
  },
  booking_hotel_checkbox_click: {
    category: "Only hotels will listing if user selects only the flight option",
    action: "User clicks on hotel checkbox",
    label: "booking-hotel-checkbox-click",
  },
  booking_flight_hotel_checkbox_click: {
    category:
      "Both flights and hotels will be listed if user selects both the checkbox",
    action: "User clicks on the flight and hotel checkboxs",
    label: "booking-flight-hotel-checkbox-click",
  },

  booking_collapse_accordion_click: {
    category: "Will hide/close all the input fields section",
    action: "User clicks on collapse accordion icon",
    label: "booking-collapse-accordion-click",
  },
  booking_downward_accordion_click: {
    category: "Will open the all input fields section",
    action: "User clicks on downward accordion icon",
    label: "booking-downward-accordion-click",
  },
  booking_travel_date_click: {
    category: "Will open the calendar dropdown on clicking on input field",
    action: "User clicks on travel date input field",
    label: "booking-travel-date-click",
  },
  booking_travel_date_accordion_click: {
    category:
      "User could able to see the calendar in month wise and select the date",
    action: "User clicks on left/right accordion",
    label: "booking-travel-date-accordion-click",
  },
  booking_travel_date_apply_click: {
    category:
      "User can select the travel starting date by clicking on apply button",
    action: "User clicks on apply button",
    label: "booking-travel-date-apply-click",
  },
  booking_travelers_click: {
    category: "Will open the traveler popup on clicking on the input field",
    action: "User clicks on the traveler input field",
    label: "booking-travelers-click",
  },
  booking_travelers_add_remove_traveler_click: {
    category:
      "It allows users to provide information about the total number of travelers  ",
    action: "User clicks on apply (+) or remove (-) buttons",
    label: "booking-travelers-add-remove-traveler-click",
  },
  booking_travelers_add_remove_room_click: {
    category:
      "It allows users to provide information about the total number of rooms required ",
    action: "User clicks on apply (+) or remove (-) buttons",
    label: "booking-travelers-add-remove-room-click",
  },
  booking_travelers_date_apply_click: {
    category:
      "User can select the travelers and room by clicking on apply button",
    action: "User clicks on apply button",

    label: "booking-travelers-date-apply-click",
  },
  booking_property_type_click: {
    category:
      "Will open the property type popup on clicking on the input field",
    action: "User clicks on the property type input field",

    label: "booking-property-type-click",
  },
  booking_property_type_star_click: {
    category: "User can select any of the property types star ",
    action: "User clicks on property type star button",

    label: "booking-property-type-star-click",
  },
  booking_property_apply_click: {
    category: "Which helps in sorting the hotel listing ",
    action: "User clicks on apply button",
    label: "booking-property-apply-click",
  },
  booking_route_and_class_click: {
    category:
      "Will open the route and class popup on clicking on the input field",
    action: "User clicks on the route and class input field",
    label: "booking-route-and-class-click",
  },
  booking_route_and_class_route_click: {
    category: "User can select any one of the route type",
    action: "User clicks on any route type button",
    label: "booking-route-and-class-route-click",
  },
  booking_route_and_class_class_click: {
    category: "User can select any one of the class type",
    action: "User clicks on any class type button",
    label: "booking-route-and-class-class-click",
  },
  booking_route_and_class_apply_click: {
    category: "Which helps in sorting the flight listing ",
    action: "User clicks on apply button",
    label: "booking-route-and-class-apply-click",
  },
  booking_airports_click: {
    category:
      "Will open the airport's list options popup on clicking on the input field",
    action: "User clicks on the airports input field",
    label: "booking-airports-click",
  },
  booking_airports_departure_airport_click: {
    category:
      "Allows user to change the departure airport apart from the auto-selected airport",
    action: "User clicks radio apply button",
    label: "booking-airports-departure-airport-click",
  },
  booking_airports_arrival_airport_click: {
    category:
      "Allows user to change the arrival airport apart from the auto-selected airport",
    action: "User clicks radio apply button",
    label: "booking-airports-arrival-airport-click",
  },
  booking_airports_apply_click: {
    category: "Which helps in selecting the airport listing ",
    action: "User clicks on apply button",
    label: "booking-airports-apply-click",
  },
  booking_flights_and_hotel_search_click: {
    category: "Which helps in sorting the flights and hotel listing ",
    action: "User clicks on search button",
    label: "booking-flights-and-hotel-search-click",
  },
  booking_flights_and_hotel_search_loader_page_click: {
    category: "Loader page will display",
    action: "User clicks on flights and hotel search button",
    label: "booking-flights-and-hotel-search-loader-page-click",
  },
  booking_flights_and_hotel_search_loader_page_cancel_click: {
    category:
      "User can stop/cancel the search process by clicking on cancel button ",
    action: "User clicks on cancel button",
    label: "booking-flights-and-hotel-search-loader-page-cancel-click",
  },
  booking_flights_details_accordion_click: {
    category:
      " The loader page will display and after a few seconds flight details will display in a dropdown",
    action: "User clicks on flight details accordion",
    label: "booking-flights-details-accordion-click",
  },
  booking_flights_details_details_accordion_click: {
    category:
      "The loader page will display and after a few seconds flight details will display in a dropdown",
    action: "User clicks on flight details accordion",
    label: "booking-flights-details-accordion-click ",
  },
  booking_flights_details_collapse_accordion_click: {
    category: "Flight details page will hide",
    action: "User clicks on collapse accordion",
    label: "booking-flights-details-collapse-accordion-click",
  },
  booking_flights_radio_click: {
    category:
      "Will check the flight availability and if flight is available then selects the flight",
    action: "User clicks on radio button",
    label: "booking-flights-radio-click",
  },
  booking_flights_radio_loader_page_cancel_click: {
    category:
      "User can stop/cancel the flight availabilty check process by clicking on cancel button ",
    action: "User clicks on cancel button",
    label: "booking-flights-radio-loader-page-cancel-click",
  },
  booking_flights_show_more_click: {
    category: "Displays more flights option on clicking on show more",
    action: "User clicks on show more button",
    label: "booking-flights-show-more-click",
  },
  booking_flights_details_loader_page_cancel_click: {
    category:
      "User can stop/cancel the fetching process by clicking on cancel button ",
    action: "User clicks on cancel button",
    label: "booking-flights-details-loader-page-cancel-click",
  },
  booking_hotels_view_details_accordion_click: {
    category:
      "The loader page will display and after a few seconds it fetch the hotel details and display in a new page",
    action: "User clicks on hotel view details accordion",
    label: "booking-hotels-view-details-accordion-click",
  },
  booking_hotels_select_accordion_click: {
    category:
      "Initially fetching room rates loader page will display, then select a room popup will display",
    action: "User clicks on select button",
    label: "booking-hotels-select-accordion-click",
  },
  booking_hotels_select_a_room_image_accordion_click: {
    category: "User can view different images of the room",
    action: "The user clicks on the accordion icon present over the image ",
    label: "booking-hotels-select-a-room-image-accordion-click",
  },
  booking_hotels_select_a_room_view_room_details_click: {
    category: "Opens a room details popup which includes amenities list",
    action: "User clicks on the view room details eye button",
    label: "booking-hotels-select-a-room-view-room-details-click",
  },
  booking_hotels_select_a_room_view_room_details_x_button_click: {
    category: "Will close the room details popup",
    action: "User clicks on X close button",
    label: "booking-hotels-select-a-room-view-room-details-x-button-click",
  },
  booking_hotels_select_a_room_view_room_details_image_slider_accordion_click: {
    category: "User can view different images of the room",
    action: "The user clicks on the accordion icon present over the image ",
    label:
      "booking-hotels-select-a-room-view-room-details-image-slider-accordion-click",
  },
  booking_hotels_select_a_room_view_room_details_back_button_click: {
    category: "User will go back to the select a room page",
    action: "User clicks on back button",
    label: "booking-hotels-select-a-room-view-room-details-back-button-click",
  },
  booking_hotels_select_a_room_book_button_click: {
    category: "Will list few more rooms from the particular room",
    action: "User clicks on show more button",
    label: "booking-hotels-select-a-room-show-more-button-click",
  },
  booking_hotels_select_a_room_x_close_click: {
    category: "Will close the select a room popup",
    action: "User clicks on X close button",
    label: "booking-hotels-select-a-room-x-close-click",
  },
  booking_flight_hotels_show_more_button_click: {
    category: "Will list a few more hotels from the particular area",
    action: "User clicks on show more button",
    label: "booking-flight-hotels-show-more-button-click",
  },
  booking_flight_hotels_continue_button_click: {
    category: "User navigates to travel info page",
    action: "User clicks on continue button",
    label: "booking-flight-hotels-continue-button-click",
  },
};
