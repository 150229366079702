import { Dispatch, SetStateAction } from "react";
import { IconButton } from "@mui/material";
import { plusIcon } from "../../../../../constant";
import RemoveIcon from "@mui/icons-material/Remove";
import { toastMessage } from "../../../../../helpers/toast/toastMessage";
import styles from "./bookingCounter.module.scss";
import { GA_LABEL_ENUMS, triggerGAEvent } from "../../../../../constant/google-analytics/GAEvents";

export default function BookingCounter({
  heading1,
  heading2,
  count,
  setCount,
  roomType,
  category,
}: {
  heading1: string;
  heading2: string;
  count: {
    adultCount: number;
    childCount: number;
    seniorCount: number;
    roomCount: number;
  };
  setCount: Dispatch<
    SetStateAction<{
      adultCount: number;
      childCount: number;
      seniorCount: number;
      roomCount: number;
    }>
  >;
  roomType: boolean;
  category: "adult" | "child" | "senior" | "room";
}) {
  function handleDecrement() {
  
    setCount((prev) => ({
      ...prev,
      [`${category}Count`]:
        prev[`${category}Count`] > 0 ? prev[`${category}Count`] - 1 : 0,
    }));
  }

  function handleIncrement() {
  
    if (roomType && category === "room") {
      setCount((prev) => {
        if (prev.roomCount < 4) {
          return { ...prev, roomCount: prev.roomCount + 1 };
        } else {
          toastMessage.error("", "You can select up to 4 rooms");
          return prev;
        }
      });
    } else {
      setCount((prev) => ({
        ...prev,
        [`${category}Count`]: prev[`${category}Count`] + 1,
      }));
    }
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.counterTitleContainer}>
        <h1>{heading1}</h1>
        <p>{heading2}</p>
      </div>
      <div className={styles.counterContainer}>
        <IconButton
          disabled={count[`${category}Count`] <= 0}
          className={`${
            count[`${category}Count`] <= 0
              ? styles.inActiveButton
              : styles.button
          }`}
          onClick={() => {
            if (count[`${category}Count`] > 0) {
              handleDecrement();
            }
          }}>
          <RemoveIcon className={styles.buttonIcon} />
        </IconButton>
        <h1
          className={`${
            count[`${category}Count`] > 0 ? styles.count : styles.inActiveCount
          }`}>
          {count[`${category}Count`]}
        </h1>
        <IconButton className={styles.button} onClick={handleIncrement}>
          <img src={plusIcon} alt="plus" className={styles.buttonIcon} />
        </IconButton>
      </div>
    </div>
  );
}
