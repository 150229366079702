import React from "react";
import {
  seatArlineRegular,
  planeArrivalRegular,
  hourglassHalfRegular,
  planeDepartureRegular,
} from "../../../constant";
import styles from "./flight.module.scss";

interface iProps {
  title: string;
  subTitle?: string;
  description: string | React.ReactNode;
  subDescription?: string;
  imgUrl?: string;
  destinationImg?: string;
}

function FlightDetails(props: iProps): JSX.Element {
  const {
    title,
    subTitle,
    description,
    subDescription,
    imgUrl,
    destinationImg,
  } = props;

  const switchIcons = (title: string) => {
    switch (title) {
      case "Departure":
        return planeDepartureRegular;
      case "Seats":
        return seatArlineRegular;
      case "Arrival":
        return planeArrivalRegular;
      case "Layover":
        return hourglassHalfRegular;
      default:
        return;
    }
  };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <section
          className={`${title === "Layover" ? styles.layCard : styles.contentCard}`}>
          {imgUrl ? (
            <img src={imgUrl} alt="" className={styles.imgIcon} />
          ) : (
            <div className={styles.iconCard}>
              <img src={switchIcons(title)} alt="" className={styles.icon} />
            </div>
          )}
          <div className={styles.detailsCard}>
            <p className={styles.title}>
              {title && title}
              {subTitle && (
                <span className={styles.subTitle}> &nbsp;&nbsp; <b>|</b> &nbsp;&nbsp; {subTitle}</span>
              )}
            </p>{" "}
            <p className={styles.desc}>
              {description}
              {subDescription && <span> &nbsp;&nbsp; <b>|</b> &nbsp;&nbsp; {subDescription}</span>}
            </p>
          </div>
        </section>
        {destinationImg && (
          <img
            src={destinationImg}
            alt="destination"
            className={styles.destinationImg}
          />
        )}
      </div>
    </div>
  );
}

export default FlightDetails;
