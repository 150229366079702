import { useContext } from "react";
import styles from "./flight.module.scss";
import { StateContext } from "../../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../../context/globalContext/interface";
import AirPortPicker from "../../airPortPicker/airPortPicker";
import { planeDepartureRegular } from "../../../../../constant";
import DateInputToUTC from "../../../../../hooks/utcDateFormate";
import { LocationTypes } from "../../../../../types";

interface iProps {
  departureDate: string;
  arrival: string;
  departure: string;
  fromCity: string;
  destinationCity: string;
  returnDate: string;
  showAccomodation: boolean;
  indexNumber: number;
  arrivalCityLatitude: string;
  arrivalCityLongitude: string;
  departureCityLatitude: string;
  departureCityLongitude: string;
  departureAirportFullName: string;
  arrivalAirportFullName: string;
  hotelStayCount: number;
  newArrivalCitySearch: boolean;
  newDepartureCitySearch: boolean;
  arrivalCityDetails: string;
  departureCityDetails: string;
  arrivalSelectedLocation: LocationTypes;
  departureSelectedLocation: LocationTypes;
}

function FlightHotelDetails({
  departureDate,
  arrival,
  departure,
  fromCity,
  destinationCity,
  showAccomodation,
  indexNumber,
  returnDate,
  arrivalCityLatitude,
  arrivalCityLongitude,
  departureCityLatitude,
  departureCityLongitude,
  arrivalAirportFullName,
  departureAirportFullName,
  hotelStayCount,
  newArrivalCitySearch,
  newDepartureCitySearch,
  arrivalCityDetails,
  departureCityDetails,
  arrivalSelectedLocation,
  departureSelectedLocation,
}: iProps): JSX.Element {
  const state = useContext(StateContext);
  const { bookingDetails, setBookingDetails }: iGlobalContext = state;
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.flightHotelCard}>
          <div className={styles.flightCard}>
            <p className={styles.departure}>Departure</p>
            <section className={styles.departCard}>
              <img
                src={planeDepartureRegular}
                alt="flight"
                className={styles.flightImg}
              />
              {departureDate && (
                <p className={styles.departDate}>
                  {DateInputToUTC(departureDate)}
                </p>
              )}
            </section>
          </div>
          {showAccomodation && hotelStayCount > 0 && (
            <div className={styles.hotelCard}>
              <p className={styles.accomodation}>Accomodations</p>
              <p className={styles.nights}>{hotelStayCount} Nights</p>
            </div>
          )}
        </div>

        <p className={styles.dateInfo}>
          Dates are based on Travel Date above, and your itinerary
        </p>

        <div className={styles.card2}>
          <div key={indexNumber}>
            <AirPortPicker
              key={indexNumber}
              arrival={arrival}
              departure={departure}
              fromCity={fromCity}
              destinationCity={destinationCity}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              className={styles.travelTitle}
              hideTitle={false}
              indexNumber={indexNumber}
              arrivalCityLatitude={arrivalCityLatitude}
              arrivalCityLongitude={arrivalCityLongitude}
              departureCityLatitude={departureCityLatitude}
              departureCityLongitude={departureCityLongitude}
              arrivalAirportFullName={arrivalAirportFullName}
              departureAirportFullName={departureAirportFullName}
              newDepartureCitySearch={newDepartureCitySearch}
              newArrivalCitySearch={newArrivalCitySearch}
              arrivalCityDetails={arrivalCityDetails}
              departureCityDetails={departureCityDetails}
              arrivalSelectedLocation={arrivalSelectedLocation}
              departureSelectedLocation={departureSelectedLocation}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlightHotelDetails;
