import React from "react";
import styles from "./amenities.module.scss";
import { eyeLight } from "../../../../constant";
import { iAmenitiesIcons } from "../../../../types";

interface iProps {
  className?: string;
  amenityItemClassName?: string;
  aminitiesArr: string[];
  showViewPropAmenities: boolean;
  amenitiesIconsArr: iAmenitiesIcons[];
  onClickPropertyDetails?: () => void;
  amenityListConainerClass?: string;
}

function Amenities(props: iProps): JSX.Element {
  const {
    aminitiesArr = [],
    showViewPropAmenities = false,
    amenitiesIconsArr = [],
    className = "",
    amenityItemClassName = "",
    amenityListConainerClass = "",
    onClickPropertyDetails,
  } = props;
  return (
    <div className={`${styles.amenitiesContainer} ${className}`}>
      <div
        className={`${styles.amenitiesListContainer} ${amenityListConainerClass}`}
      >
        {aminitiesArr.map((item, idx) => {
          const foundAmenity = amenitiesIconsArr.find(
            (amenity) =>
              amenity.name.toLocaleLowerCase() === item.toLocaleLowerCase()
          );
          const { icon: amenityIcon } = foundAmenity || {};
          return (
            <div className={styles.amenityRow} key={idx}>
              {amenityIcon && (
                <img
                  src={amenityIcon}
                  alt="icon"
                  className={styles.amenityIcon}
                />
              )}
              <span className={`${styles.itemName} ${amenityItemClassName}`}>
                {item}
              </span>
            </div>
          );
        })}
      </div>
      {showViewPropAmenities && (
        <div
          className={styles.amenitiesFooter}
          onClick={onClickPropertyDetails}
        >
          VIEW PROPERTY AMENITIES
          <img src={eyeLight} alt="icon" className={styles.amenityFooterIcon} />
        </div>
      )}
    </div>
  );
}

Amenities.defaultProps = {
  className: "",
  amenityItemClassName: "",
  onClickPropertyDetails: () => {},
  amenityListConainerClass: "",
};

export default Amenities;
