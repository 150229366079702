export const faq_page_events = {
    faqs_back_button_click: {
      category: "Navigates back from FAQ page",
      action: "User clicks on back button ",
      label: "faqs-back-button-click",
    },
    faqs_back_downward_accordion_click :{
        category: "Which opens the selected FAQ and answer",
        action: "User clicks on downward accordion",
        label: "faqs-back-downward-accordion-click", 
    },
    faqs_back_upward_accordion_click :{
        category: "Which closes the selected FAQ and answer",
        action: "User clicks on upward accordion",
        label: "faqs-back-upward-accordion-click", 
    }
}