import {
  airConditioningIcon,
  businessCenterIcon,
  fitnessCenterIcon,
  freeWifiIcon,
  hotelDetailsImg,
  noSmookingIcon,
  onSiteParkingIcon,
  poolIcon,
  restaurantIcon,
} from "../../constant";

export const HOTEL_IMAGES_DATA = [1, 2, 3, 4, 5];

export const HOTEL_DETAILS = {
  hotelImages: [hotelDetailsImg, "img2"],
  hotelStar: 5,
  hotelName: "Hotel Catalan - Ciutadella",
  place: "Sant Marti",
  address: "1297 via Las ramblas, Barcelona",
  landMark: "0.5mi from Center",
  ratings: 9.1,
  ratingDescription: "Exceptional",
  reviews: 2058,
  amenities: [
    "Free WIFI",
    "Air Conditioning",
    "Non-smoking Rooms",
    "Pool",
    "Restaurant",
    "Business Center",
    "On-site Parking",
    "Fitness Center",
  ],
  aboutProperty: [
    {
      heading: "Luxurious Accomodations",
      description:
        "Nestled in the vibrant heart of Barcelona, our luxury hotel offers an unparalleled experience for discerning travelers. Each room and suite is a masterpiece of design, blending modern sophistication with timeless elegance. Guests can choose from a variety of accommodations, ranging from deluxe rooms with stunning city views to opulent penthouse suites featuring private terraces. Every detail has been meticulously crafted to ensure maximum comfort, with plush bedding, high-end furnishings, and state-of-the-art amenities. En-suite marble bathrooms come equipped with rain showers and premium toiletries, creating a sanctuary of relaxation. The hotel also offers exclusive services such as private butlers, in-room dining, and personalized concierge assistance to cater to every guest's need.",
    },
    {
      heading: "Convenient Shopping",
      description:
        "Located just steps away from some of Barcelona's most prestigious shopping destinations, our hotel is a haven for fashion enthusiasts and luxury shoppers. The iconic Passeig de Gràcia, known for its high-end boutiques and designer stores, is within walking distance. Here, guests can explore flagship stores of renowned brands such as Gucci, Chanel, and Louis Vuitton, as well as discover unique local designers and artisanal shops. The hotel also provides a personalized shopping service, offering expert advice and exclusive access to private showrooms. After a day of retail therapy, guests can return to the hotel to relax and rejuvenate, knowing their purchases will be carefully handled and delivered to their rooms.",
    },
    {
      heading: "Great Restaurants",
      description:
        "Barcelona is a gastronomic paradise, and our hotel is no exception, offering an array of dining experiences that cater to all palates. The on-site Michelin-starred restaurant is a culinary jewel, where acclaimed chefs create innovative dishes using the finest local ingredients. Guests can indulge in a gourmet tasting menu or savor à la carte options that showcase the best of Catalan and Mediterranean cuisine. For a more casual dining experience, the hotel’s stylish bistro offers a selection of international favorites and Spanish tapas, perfect for a relaxed meal. Additionally, the rooftop bar provides breathtaking views of the city skyline, where guests can enjoy expertly crafted cocktails and a selection of fine wines, making it an ideal spot for pre-dinner drinks or a nightcap.",
    },
    {
      heading: "Nightlife",
      description:
        "When the sun sets, Barcelona’s vibrant nightlife comes alive, and our hotel places guests at the epicenter of it all. From chic cocktail lounges to bustling nightclubs, the city offers a plethora of options to suit every mood and style. The hotel’s own exclusive lounge is a sophisticated venue where guests can unwind with a signature drink while listening to live music or DJ sets. For those looking to explore further, the famous El Born and Gothic Quarter neighborhoods are just a short walk away, offering a diverse array of bars, clubs, and entertainment venues. Whether you prefer an intimate jazz bar or a high-energy dance club, our concierge team can provide recommendations and secure VIP access, ensuring an unforgettable night out in Barcelona.",
    },
  ],
};

export const HOTEL_DETAILS_DUMMYDATA = {
  message: "Hotel search request processed successfully.",
  data: {
    status: "success",
    data: {
      hotelId: "lp1eb86",
      name: "Econo Lodge London",
      address: "105 Melcon Hill Dr",
      city: "London",
      zip: "40741",
      country: "US",
      latitude: 37.14472,
      longitude: -84.10807,
      stars: 2,
      rating: 5.3,
      reviewCount: 74,
      reviewHeading: "Exceptional",
      hotelMainImage: "https://snaphotelapi.com/hotels/169796642.jpg",
      hotelImages: [
        {
          url: "https://snaphotelapi.com/hotels/hd/169796637.jpg",
          description: "hotel reception",
        },
        {
          url: "https://snaphotelapi.com/hotels/hd/240248349.jpg",
          description: "hotel room",
        },
      ],

      hotelAmenities: [
        "WiFi available",
        "Parking",
        "Free Parking",
        "Non-smoking rooms",
        "Air conditioning",
        "Swimming pool",
        "24-hour front desk",
        "Heating",
        "Pets allowed",
        "Lift / Elevator",
        "Fax/photocopying",
        "Facilities for disabled guests",
        "Hot tub/Jacuzzi",
        "Private parking",
        "Daily housekeeping",
        "Designated smoking area",
        "Accessible parking",
        "Shared lounge/TV area",
        "Wheelchair accessible",
        "Heated pool",
        "Vending machine - drinks",
        "Indoor pool",
        "Toilet with grab rails",
        "Vending machine - snacks",
        "Rooftop pool",
        "Shallow end",
        "Staff adhere to local safety protocols",

        "Laundry washed per local authority guidelines",
        "Guest rooms disinfected between stays",
        "Cleaning standards that are effective against Coronavirus",
        "Physical distancing rules followed",
        "Hand sanitizer in guest room and key areas",
        "Cashless payment available",
        "First aid kit available",
        "Sanitized tableware & silverware",
        "Contactless check-in/check-out",
        "Invoice provided",
        "Guests can opt-out any cleaning services during stay",
        "Smoke alarms",
        "Shared stationery like menus, pens are removed",
        "Guest room sealed after cleaning",
        "Property cleaned by professional cleaning companies",
        "Physical distancing in dining areas",
        "CCTV outside property",
        "CCTV in common areas",
        "Access to health care professionals",
        "Screens / Barriers between staff and guests for safety",
        "Face masks for guests available",
        "Delivered food - securely covered",
        "Key card access",
        "Mobile app for room service",
      ],
      aboutProperty: [
        {
          title: "Luxurious Accommodations",
          description:
            "Nestled in the vibrant heart of Barcelona, our luxury hotel offers an unparalleled experience for discerning travelers. Each room and suite is a masterpiece of design, blending modern sophistication with timeless elegance. Guests can choose from a variety of accommodations, ranging from deluxe rooms with stunning city views to opulent penthouse suites featuring private terraces. Every detail has been meticulously crafted to ensure maximum comfort, with plush bedding, high-end furnishings, and state-of-the-art amenities. En-suite marble bathrooms come equipped with rain showers and premium toiletries, creating a sanctuary of relaxation. The hotel also offers exclusive services such as private butlers, in-room dining, and personalized concierge assistance to cater to every guest's need.",
        },
        {
          title: "Convenient Shopping",
          description:
            "Located just steps away from some of Barcelona's most prestigious shopping destinations, our hotel is a haven for fashion enthusiasts and luxury shoppers. The iconic Passeig de Gracia, known for its high-end boutiques and designer stores, is within walking distance. Here, guests can explore flagship stores of renowned brands such as Gucci, Chanel, and Louis Vuitton, as well as discover unique local designers and artisanal shops. The hotel also provides a personalized shopping service, offering expert advice and exclusive access to private showrooms. After a day of retail therapy, guests can return to the hotel to relax and rejuvenate, knowing their purchases will be carefully handled and delivered to their rooms.",
        },
        {
          title: "Great restaurants",
          description:
            "Barcelona is a gastronomic paradise, and our hotel is no exception, offering an array of dining experiences that cater to all palates. The on-site Michelin-starred restaurant is a culinary jewel, where acclaimed chefs create innovative dishes using the finest local ingredients. Guests can indulge in a gourmet tasting menu or savor à la carte options that showcase the best of Catalan and Mediterranean cuisine. For a more casual dining experience, the hotel's stylish bistro offers a selection of international favorites and Spanish tapas, perfect for a relaxed meal. Additionally, the rooftop bar provides breathtaking views of the city skyline, where guests can enjoy expertly crafted cocktails and a selection of fine wines, making it an ideal spot for pre-dinner drinks or a nightcap.",
        },
        {
          title: "Night life",
          description:
            "When the sun sets, Barcelona's vibrant nightlife comes alive, and our hotel places guests at the epicenter of it all. From chic cocktail lounges to bustling nightclubs, the city offers a plethora of options to suit every mood and style. The hotel's own exclusive lounge is a sophisticated venue where guests can unwind with a signature drink while listening to live music or DJ sets. For those looking to explore further, the famous El Born and Gothic Quarter neighborhoods are just a short walk away, offering a diverse array of bars, clubs, and entertainment venues. Whether you prefer an intimate jazz bar or a high-energy dance club, our concierge team can provide recommendations and secure VIP access, ensuring an unforgettable night out in Barcelona.",
        },
      ],
    },
  },
};

export const amenitiesIcons = [
  {
    name: "Non-smoking Rooms",
    icon: noSmookingIcon,
  },
  {
    name: "Business Center",
    icon: businessCenterIcon,
  },
  {
    name: "On-site Parking",
    icon: onSiteParkingIcon,
  },
  {
    name: "Parking",
    icon: onSiteParkingIcon,
  },
  {
    name: "Free Parking",
    icon: onSiteParkingIcon,
  },
  {
    name: "Fitness Center",
    icon: fitnessCenterIcon,
  },
  {
    name: "Pool",
    icon: poolIcon,
  },
  {
    name: "Swimming Pool",
    icon: poolIcon,
  },
  {
    name: "Restaurant",
    icon: restaurantIcon,
  },
  {
    name: "Air Conditioning",
    icon: airConditioningIcon,
  },
  {
    name: "Free WIFI",
    icon: freeWifiIcon,
  },
  {
    name: "WiFi available",
    icon: freeWifiIcon,
  },
];
