import axios from "axios";
import { ENDPOINT } from "../constant/environment";
import { endPoints } from "../constant/api_constants/url";
import { fetchApi } from "../utils/apiFunction";
import { API_METHODS } from "../enums";

export async function deleteHistoryAPI(id: string) {
  const URL = `${ENDPOINT}/app/v1/users/history/${id}`;

  if (id === "" || id === undefined) return;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  // let body = {
  //     email: email,
  //     password: password
  // }
  try {
    // console.log(URL);
    const response = await axios.delete(URL, config);
    // console.log(response);
    return response?.data;
  } catch (error: any) {
    if (error.response && error.response.status === 409) {
      // console.log("ERROR STATUS", error.response.status);
      // console.log("ERROR", error.response);
    }
    // console.log(error);
  }
}

export const deleteHistory = async (id: string, requestBody: any) => {
  const endpoint = endPoints.deleteHistory(id);

  return await fetchApi(endpoint, API_METHODS.DELETE, requestBody);
};
