export const home_page_events = {
  home_zenvoya_logo_click: {
    category: "User navigates back to home page",
    action: "On any page user can click on Zenvoya logo",
    label: "home-zenvoya-logo-click",
  },
  home_upcoming_trip_image_card_click: {
    category: "User navigates to trip details page",
    action: "User clicks on image card",
    label: "home-upcoming-trip-image-card-click ",
  },
  home_copy_click: {
    category:
      "It allows the user to copy the prompt present over the prompt card",
    action: "User clicks on copy icon",
    label: "home-copy-click",
  },
  home_input_field_send_click: {
    category: "User sends the search prompt",
    action: "User clicks on prompt input send button",
    label: "home-input-field-click",
  },
  home_input_field_stop_click: {
    category: "User stops the prompt request",
    action: "User clicks on stop button",
    label: "home-input-field-stop-click ",
  },
  home_input_field_mic_send_click: {
    category: "User record the prompt by using voice recording",
    action: "User clicks on mic button",
    label: "home-input-field-mic-send-click ",
  },

  home_input_field_mic_stop_click: {
    category: "User can stop the voice recording",
    action: "User clicks on mic button to stop the search",
    label: "home-input-field-mic-stop-click ",
  },
};
