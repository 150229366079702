export const login_popup_events = {
  Facebook_login_click: {
    category: "User loging in with facebook",
    action: "User loging in with facebook",
    label: "Facebook-login-click",
  },
  Google_login_click: {
    category: "User loging in with google",
    action: "User loging in with google",
    label: "Google-login-click",
  },
  Apple_login_click: {
    category: "User loging in with apple",
    action: "User clicks on login with apple button",
    label: "Apple-login-click",
  },
  Forgot_password_click: {
    category: "User wants navigates to forgot password popup",
    action: "User clicks on forgot password button",
    label: "Forgot-password-click",
  },
  Create_new_account_click: {
    category: "User wants to signup or create a new account",
    action: "User clicks on 'create one now' button",
    label: "Create-new-account-click",
  },
  Login_with_email_click: {
    category: "User loging in with email and password",
    action: "User clicks on 'Log In' button",
    label: "Login-with-email-click",
  },
};
