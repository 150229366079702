export const new_trip_events = {
  new_trip_input_field_send_click: {
    category: "User sends the search prompt",
    action: "User clicks on prompt input send button",
    label: "new-trip-input-field-send-click",
  },
  new_trip_input_field_stop_click: {
    category: "User stops the prompt request",
    action: "User clicks on stop button",
    label: "new-trip-input-field-stop-click ",
  },
  new_trip_input_field_mic_send_click: {
    category: "User record the prompt by using voice recording",
    action: "User clicks on mic button",
    label: "new-trip-input-field-mic-send-click",
  },

  new_trip_input_field_mic_stop_click  :{
    category: "User can stop the voice recording",
    action: "User clicks on mic button to stop the search",
    label: "new-trip-input-field-mic-stop-click ",
  }
};
