import Cookies from "js-cookie";
import { endPoints } from "../constant/api_constants/url";
import { API_METHODS } from "../enums";
import { fetchApi } from "../utils/apiFunction";
import { environment } from "../constant/environment";

export const sendResetPasswordLink = async (payload: any): Promise<any> => {
  const endpoint = endPoints.sendResetPassword;
  return await fetchApi(endpoint, API_METHODS.POST, payload);
};

export const resetPassword = async (payload: any): Promise<any> => {
  const endpoint = endPoints.resetPassword;
  return await fetchApi(endpoint, API_METHODS.POST, payload);
};

export const getUserDetailsByToken = async (payload: any): Promise<any> => {
  const endpoint = endPoints.getUserDetailByToken(payload);
  return await fetchApi(endpoint, API_METHODS.GET, payload);
};

// export const logout = async (payload: any): Promise<any> => {
//   const endpoint = endPoints.logout;
//   return await fetchApi(endpoint, API_METHODS.POST, payload);
// };

export const logout = async (payload: any): Promise<any> => {
  const endpoint = endPoints.logout;
  try {
    const response = await fetchApi(endpoint, API_METHODS.POST, {
      userId: payload,
    });

    // Remove cookies in this function as well
    Cookies.remove(`${environment}userData`, {
      path: "/",
      domain: ".zenvoya.ai",
    });

    return response;
  } catch (error) {
    console.error("Logout API error:", error);
    throw error;
  }
};

export const login = async (payload: any): Promise<any> => {
  const endpoint = endPoints.login;
  return await fetchApi(endpoint, API_METHODS.POST, payload);
};
