import React from "react";
import styles from "./step.module.scss";
import { Badge, Button } from "@mui/material";
import { expandIcon, ticketsAirlineRegular } from "../../../constant";
import FlightDetails from "../flightDetails/flightDetails";
import { CardInfo } from "../../../types";

interface StepData {
  title: string;
  description: string;
  ArrivalOnDiffDate: boolean;
  layOver: boolean;
  airPortCode?: string;
  airportName?: string;
  cardInfo: CardInfo[];
}

interface StepperProps {
  steps?: StepData[];
  initialStep: number;
  finalStep: number;
}

const Stepper: React.FC<StepperProps> = ({
  steps = [],
  initialStep = 0,
  finalStep = 0,
}) => {
  const [showAllSteps, setShowAllSteps] = React.useState(false);

  const handleViewDetails = () => {
    setShowAllSteps((prev) => !prev);
  };

  if (steps.length === 0) {
    return <div>No data found</div>;
  }

  return (
    <div className={styles.main}>
      <div className={styles.stepper}>
        {steps.map((step, index) => {
          // if (index < initialStep || index > finalStep) return null;
          const shouldDisplayStep =
            showAllSteps ||
            index === initialStep ||
            index === finalStep ||
            steps.length <= 2;

          if (!shouldDisplayStep) return null;

          return (
            <React.Fragment key={index}>
              <div className={styles.step}>
                <div className={styles.circle}></div>
                <div className={styles.stepContent}>
                  <p className={styles.title}>
                    <span className={styles.airportCode}>
                      {step.airPortCode}
                    </span>{" "}
                    | {step.airportName}
                  </p>
                  <p
                    className={`${step.layOver ? styles.delayDescription : styles.description}`}>
                    {step.description}
                  </p>
                  {index === initialStep && (
                    <Button
                      endIcon={
                        <img
                          src={expandIcon}
                          alt=""
                          className={`${showAllSteps ? styles.hideIcon : styles.viewIcon}`}
                        />
                      }
                      className={styles.viewDetailsButton}
                      onClick={handleViewDetails}>
                      {showAllSteps ? "Collapse" : "Details"}
                    </Button>
                  )}
                  {showAllSteps && (
                    <div className={styles.card}>
                      {step.cardInfo.map((info, cardIndex) => (
                        <div key={cardIndex}>
                          {info.flightDetails && (
                            <FlightDetails
                              title={
                                info.flightDetails.flightName ||
                                "Flight Details"
                              }
                              subTitle={info.flightDetails.flightNumber}
                              description={""}
                              imgUrl={info.flightDetails.flightImage}
                            />
                          )}
                          {info.departure && (
                            <FlightDetails
                              title={info.departure.departure}
                              subDescription={info.departure.flightTime}
                              description={info.departure.departureTime}
                            />
                          )}
                          {info.seats && (
                            <div className={styles.seatCard}>
                              <FlightDetails
                                title={info.seats.seats}
                                description={info.seats.seatNumb}
                              />
                              {info.seats.boardingPasses && (
                                <Badge
                                  sx={{
                                    display: "none",
                                    "& .MuiBadge-badge": {
                                      backgroundColor: "#FF3B30",
                                      color: "white",
                                      left: "32px",
                                      top: "-5px",
                                    },
                                  }}
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  badgeContent={4}>
                                  <Button
                                    startIcon={
                                      <img
                                        src={ticketsAirlineRegular}
                                        alt="boarding"
                                        className={styles.passIcon}
                                      />
                                    }
                                    className={styles.boardingBtn}>
                                    Boarding Passes
                                  </Button>
                                </Badge>
                              )}
                            </div>
                          )}
                          {info.arrival && (
                            <FlightDetails
                              title={info.arrival.arrival}
                              description={info.arrival.arrivalTime}
                              destinationImg={info.arrival.destinationImg}
                            />
                          )}
                          {info.layover && (
                            <FlightDetails
                              title={info.layover.layover}
                              description={info.layover.arrivalTime}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {index < steps.length - 1 && (
                  <div
                    className={`${step.layOver ? styles.dashLine : styles.line}`}></div>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
