function formatPhoneNumber(value: string | null | undefined) {
  if (!value) {
    return "";
  }

  const numericValue = value.replace(/\D/g, "");

  let formattedValue = numericValue;

  if (numericValue.length > 3 && numericValue.length <= 6) {
    formattedValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
  } else if (numericValue.length > 6) {
    formattedValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
  } else if (numericValue.length > 3) {
    formattedValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)}`;
  } else if (numericValue.length > 0) {
    formattedValue = `(${numericValue}`;
  }

  return formattedValue;
}

export default formatPhoneNumber;
