import React from "react";
import styles from "./empty.module.scss";

function EmptyDrawer(): JSX.Element {
  return (
    <div className={styles.container}>
      <div className={styles.emptyNav}></div>
      <div className={styles.emptyDraw}></div>
    </div>
  );
}

export default EmptyDrawer;
