import { toastMessage } from "../helpers/toast/toastMessage";

export async function apiFailedTryAgain(apiCall: any) {
  let count = 0;
  let response = await apiCall();
  if (response) return response;
  while (!response) {
    response = await apiCall();
    if (response) {
      return response;
    }
    count++;
    if (count === 5) {
      toastMessage.error("API failed please try again!");
      return null;
    }
  }
}

export async function apiCallWithNRetries(
  apiFunc: () => any,
  retries: number,
  delay: number
) {
  return new Promise((resolve, reject) => {
    const attempt = async (attemptNumber: number) => {
      try {
        const response = await apiFunc();
        // console.log(response, "response");
        if (response?.data?.statusCode === 200) {
          resolve(response);
          return;
        }
        throw new Error(response?.data.message);
      } catch (error) {
        if (attemptNumber < retries) {
          // console.log(
          //   `Attempt ${attemptNumber} failed, retrying in ${delay} seconds`
          // );
          setTimeout(attempt, delay * 1000, attemptNumber + 1);
        } else {
          reject(error);
        }
      }
    };
    attempt(1);
  });
}

export const wait = (seconds: number, data: any) =>
  new Promise((resolve, reject) => setTimeout(() => resolve(data), seconds));
