export const current_trips_events = {
  current_trips_page_loads: {
    category: "User loads Current trip page",
    action: "User clicks on Current trip button from the side bar",
    label: "current-trips-page-loads",
  },
  today_flight_delay_click: {
    category: "User navigates to flight delay screen",
    action: "User clicks on flight delay container",
    label: "today-flight-delay-click",
  },
  today_weather_advisory_click: {
    category: "User navigating to weather advisory screen",
    action: "User clicks on weather advisory section",
    label: "today-weather-advisory-click",
  },
  today_explore_city_click: {
    category: "User navigates to explore city page",
    action: "User clicks on explore city button",
    label: "today-explore-city-click",
  },
  today_view_trip_details_click: {
    category: "User navigates to trip details page",
    action: "The user clicks on view trip details image card",
    label: "today-view-trip-details-click",
  },
  today_zenvoya_assistance_click: {
    category: "User navigates to assistance page",
    action: "The user clicks on Zenvoya assistance button",
    label: "today-zenvoya-assistance-click",
  },
};
