import styles from "./discount.module.scss";

interface iProps {
  percentage?: string;
  cost?: string;
}

function DiscountCard({ percentage, cost }: iProps): JSX.Element {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.discountBtn}>
          <p className={styles.discount}>{percentage}% off</p>
        </div>
        <div className={styles.costBtn}>
          <p className={styles.cost}>${cost}</p>
        </div>
      </div>
    </div>
  );
}

export default DiscountCard;
