export const edit_profile_popup_events = {
  home_edit_profile_click: {
    category: "User opens edit popup",
    action: "User clicks on edit icon",
    label: "home-edit-profile-click",
  },
  home_submit_edit_profile_click: {
    category: "User updates the profile",
    action: "User clicks on edit profile popup save changes button",
    label: "home-submit-edit-profile-click",
  },
  home_upload_image_click: {
    category: "User uploading the profile pic",
    action: "User clicks on tap to upload button",
    label: "home-upload-image-click",
  },
  home_delete_account_click: {
    category: "User opens delete account popup",
    action: "User clicks delete account button in edit profile popup",
    label: "home-delete-account-click",
  },
  Profile_yes_delete_account_click: {
    category: "User deletes account",
    action: "User clicks on Yes delete account button",
    label: "Profile-yes-delete-account-click",
  },
  Profile_delete_cancel_click: {
    category: "User cancels account deletion",
    action: "User clicks on cancel button from delete account ",
    label: "Profile-delete-cancel-click",
  },
};
