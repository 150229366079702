import styles from "./noData.module.scss";

interface iProps {
  message: string;
}

function NoData({ message }: iProps): JSX.Element {
  return (
    <div className={styles.main}>
      <p className={styles.message}>{message}</p>
    </div>
  );
}

export default NoData;
