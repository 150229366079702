import React, { useContext, useState } from "react";
import { Button, Stack, TextField } from "@mui/material";
import updateCloseIcon from "../../../assets/updateCloseIcon.svg";
import { StateContext } from "../../../context/globalContext/context";
import { APP_CONFIG, emailRegex } from "../../../constant/common";
import styles from "./forget.module.scss";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { getConfigMessage } from "../../../utils";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import { TOAST_MESSAGES } from "../../../constant/toastMessages";
import toast from "react-hot-toast";
import { sendResetPasswordLink } from "../../../api/authentication";
import { sendTokenApiResponse } from "../../../interface";
import { APP_URL } from "../../../constant/environment";

export default function ForgetPasswordDialog() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Context
  const state = useContext(StateContext);
  const {
    setLoginCreateAccountState,
    loginCreateAccountState,
    appConfig,
    setSignInClick,
    setIsFetchingAirports,
  }: iGlobalContext = state;

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = useState<string>("");

  function handleCancel() {
    setSignInClick(false);
    setIsFetchingAirports(false);
    setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    // console.log(loginCreateAccountState);
  }

  async function handleSendResetPasswordLink() {
    try {
      if (!emailRegex.test(email)) {
        setEmailError(
          TOAST_MESSAGES.AUTHENTICATION.forgetPasswordInvalidEmail.message
        );
        return;
      }
      const formattedAppUrl = APP_URL.endsWith("/")
        ? APP_URL.slice(0, -1)
        : APP_URL;
      const payload = {
        email: email.toLowerCase().trim(),
        redirectUrl: formattedAppUrl,
      };
      const response: sendTokenApiResponse =
        await sendResetPasswordLink(payload);
      triggerGAEvent(GA_LABEL_ENUMS.Forgot_password_click);
      if (response.statusCode === 200) {
        toastMessage.success(
          TOAST_MESSAGES.AUTHENTICATION.forgetPasswordEmailSentSuccess
            .messageTitle,
          TOAST_MESSAGES.AUTHENTICATION.forgetPasswordEmailSentSuccess.message
        );
        setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
      } else {
        toastMessage.error("OOPS!", response?.message);
      }
    } catch (error) {
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
    }
  }

  const handleEmailKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!emailRegex.test(email)) {
        setEmailError(
          TOAST_MESSAGES.AUTHENTICATION.forgetPasswordInvalidEmail.message
        );
      } else {
        setEmailError("");
        handleSendResetPasswordLink();
      }
    }
  };
  return (
    <Stack className={styles.main}>
      <Stack className={styles.container}>
        <Stack
          px={{ xs: 3, sm: 3, md: 3, lg: 0, xl: 0 }}
          className={styles.subContainer}>
          <Stack className={styles.card}>
            <img
              onClick={handleCancel}
              src={updateCloseIcon}
              alt="cross"
              className={styles.closeIcon}
            />
          </Stack>

          <Stack
            sx={{
              width: "100%",
              maxHeight: {
                sm: "80vh",
                xs: "80vh",
                md: "calc(100% - 5%)",
                lg: "calc(100% - 5%)",
              },
              overflow: "auto",
              bgcolor: "#f8f8f8",
              borderRadius: "8px",
            }}>
            <Stack padding={3} spacing={3}>
              <Stack spacing={2} sx={{ width: "100%", pl: 2, pr: 2 }}>
                <Stack
                  position="relative"
                  alignSelf="start"
                  sx={{ width: "100%", height: "65px" }}>
                  <h2
                    style={{
                      fontSize: "10px",
                      marginBottom: "6px",
                      color: "#5F6A70",
                      fontWeight: "500",
                      fontFamily: "Lato",
                      opacity: "0.9",
                    }}>
                    YOUR EMAIL ADDRESS
                  </h2>
                  <TextField
                    type="text"
                    size="small"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e: any) => {
                      setEmail(e.target.value);
                      setEmailError("");
                      toast.dismiss();
                    }}
                    InputProps={{
                      style: {
                        height: "40px",
                        width: "100%",
                        fontSize: "12px",
                        backgroundColor: "white",
                        color: "#696969",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onKeyDown={handleEmailKeyDown}
                    error={!!emailError}
                    helperText={emailError}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Button
              variant="contained"
              sx={{
                height: 80,
                bgcolor: "#FCB400",
                border: "none",
                borderRadius: "0px",
                boxShadow: "none",
                outline: "none",
                "&:hover": {
                  bgcolor: "#FCB400",
                },
              }}
              onClick={handleSendResetPasswordLink}>
              <h5
                style={{
                  color: "#222222",
                  fontWeight: "600",
                  fontSize: "18px",
                  fontFamily: "lato",
                }}>
                Send
              </h5>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
