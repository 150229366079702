import React from "react";
import BasicSelect from "../customSelect/customSelect";
import { options } from "../../../../constant/dummyData";
import CustomInput from "../inputField/customInput";
import styles from "./dobSelector.module.scss";

interface iProps {
  showErrorMessageFlag: boolean | undefined;
  dobLabel: string;
  monthValue: any;
  yearValue: any;
  dateValue: any;
  onMonthChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onYearChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function DobSelector(props: iProps): JSX.Element {
  const {
    showErrorMessageFlag = false,
    dobLabel = "",
    dateValue,
    monthValue,
    yearValue,
    onMonthChange,
    onDateChange,
    onYearChange,
  } = props;
  return (
    <div className={styles.dobSelectorContainer}>
      <BasicSelect
        isPlaceholderTextColorLight
        placeholder="Month"
        options={options}
        value={monthValue}
        onChange={onMonthChange}
        width="80px"
        label={dobLabel}
        mandate={true}
        error={monthValue === "" && showErrorMessageFlag}
      />
      <CustomInput
        value={dateValue}
        onChange={onDateChange}
        placeHolder="DD"
        mandate={false}
        width="40px"
        showRequired={false}
        error={dateValue === "" && showErrorMessageFlag}
      />
      <CustomInput
        value={yearValue}
        onChange={onYearChange}
        placeHolder="YYYY"
        mandate={false}
        width="52px"
        showRequired={false}
        error={yearValue === "" && showErrorMessageFlag}
      />
    </div>
  );
}

export default DobSelector;
