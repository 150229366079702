import { useEffect, useState } from "react";
import styles from "./property.module.scss";
import PropertyPopoverPopover from "../../bookingPopover/propertySelectionPopover/propertySelectionPopover";
import { BookingDetails } from "../../../../types";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";

interface iProps {
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
}

function PropertyType({
  bookingDetails,
  setBookingDetails,
}: iProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<number[]>([]);

  const handleApplyButton = () => {
    triggerGAEvent(GA_LABEL_ENUMS.booking_property_apply_click);
    setBookingDetails({
      ...bookingDetails,
      filters: {
        ...bookingDetails.filters,
        starRating: selectedValues,
      },
    });
  };

  useEffect(() => {
    setSelectedValues(bookingDetails.filters.starRating);
  }, [isPopoverOpen]);
  return (
    <div>
      <PropertyPopoverPopover
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        handleApplyButton={handleApplyButton}
        popoverTrigger={
          <div className={styles.main}>
            <p className={styles.label}>Property Type</p>
            <div
              className={`${isPopoverOpen ? styles.activeTravelBtn : styles.btn}`}
              onClick={() => {
                triggerGAEvent(GA_LABEL_ENUMS.booking_property_type_click);
              }}
            >
              <p className={styles.btnName}>
                {bookingDetails?.filters?.starRating.length > 0
                  ? bookingDetails.filters?.starRating.length === 1
                    ? bookingDetails.filters?.starRating[0] + " STAR"
                    : bookingDetails.filters?.starRating
                        ?.sort((a, b) => a - b)
                        .join(", ") + " STARS"
                  : "ANY"}
              </p>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default PropertyType;
