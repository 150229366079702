import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function planTripDetails(
  controller: any,
  // fromCity: string,
  // noOfDays: string,
  // places: string[],
  messages: any[],
  iteneryInput: any,
  userInput?: string,
) {
  // const URL = `${ENDPOINT}/itenryDetails`;
  const URL = `${ENDPOINT}/app/v1/itineraries/search`
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    signal: controller.signal,
  };
  // let body = {
  //   from: fromCity,
  //   noOfDays,
  //   places,
  // };
  let body = {
    userInput: null,
    messages,
    iteneryInput,
}
  try {
    const response = await axios.post(URL, body, config);
    // console.log(response);
    return response?.data;
  } catch (error) {
    // console.log(error);
  }
}
