import jwt from "jwt-decode";
import { DialogSelected } from "../constant/common";
import jwtDecode from "jwt-decode";
import { UserInfo } from "../types";
import { toastMessage } from "../helpers/toast/toastMessage";
import Cookies from "js-cookie";
import { environment } from "../constant/environment";
import { login } from "../api/authentication";
import { LoginResponse } from "../interface";

// Get JWT from local storage
export function checkIfUserIsLoggedIn(
  setUserInfo: (userInfo: UserInfo) => void
): void {
  const userJWT = window.localStorage.getItem("JWT");
  if (userJWT) {
    const decodedToken: any = jwtDecode(userJWT);
    // console.log("decodedToken:-", decodedToken);

    setUserInfo({
      id: decodedToken.id,
      name: decodedToken.name,
      email: decodedToken.email,
      phone: "",
      profile_pic: "",
      loggedIn: true,
      role: decodedToken?.role || 0,
    });
    /**
     * post message data
     */
    if (window && window.parent) {
      // console.log("Window.parent", window.parent);
      const message = {
        action: "CheckIfUserIsLoggedIn",
        data: decodedToken,
      };
      // console.log("MESSAGE", message);
      window.parent.postMessage(JSON.stringify(message)); // or specify a specific origin instead of "*"
    }
  } else {
    setUserInfo({
      id: "",
      name: "",
      email: "",
      phone: "",
      profile_pic: "",
      loggedIn: false,
      role: 0,
    });
  }
}

//  check log in and making API call
export async function checkLogin(
  email: string,
  loginType: string,
  name: string,
  password: string,
  setUserDetails: any,
  setIsLoggedIn: any,
  signInClick: any,
  setLoginCreateAccountState: any,
  loginCreateAccountState: any,
  setSignInClick: any,
  loginFailureMessage: string
) {
  const payLoad = {
    email: email.trim(),
    loginType: loginType,
    name: name.trim(),
    password: password.trim(),
  };
  const response: LoginResponse = await login(payLoad);
  // // console.log(response, userDetails)
  if (response?.message === "Login successfull") {
    const decodedToken: any = jwt(response?.data.jwtToken);
    const userDetailsData = {
      id: decodedToken.id,
      name: decodedToken.name,
      email: decodedToken.email,
      loggedIn: true,
    };
    setUserDetails((prev: any) => ({
      ...prev,
      ...userDetailsData,
    }));
    setIsLoggedIn(true);

    /**
     * post message data
     */
    if (window && window.parent) {
      // console.log("Window.parent", window.parent);
      const message = {
        action: "LogIn",
        data: userDetailsData,
      };
      // console.log("MESSAGE", message);
      const iframe: HTMLIFrameElement | null =
        document.querySelector("#app_domain");
      const wind = iframe?.contentWindow;
      const data = {
        ...userDetailsData,
        jwt: response?.data.jwtToken,
      };
      // testing cookies
      Cookies.set(`${environment}userData`, JSON.stringify(data), {
        path: "/",
        expires: 7,
        domain: ".zenvoya.ai",
      });
      wind?.postMessage(JSON.stringify(data), "*");
      window.parent.postMessage(JSON.stringify(message)); // or specify a specific origin instead of "*"
    }

    // store the JWT to local storage
    window.localStorage.setItem("JWT", JSON.stringify(response?.data.jwtToken));

    if (signInClick) {
      setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
      setSignInClick(false);
    } else
      setLoginCreateAccountState({
        ...loginCreateAccountState,
        page: DialogSelected.SAVE_ITINERARY,
      });
    return decodedToken.id;
  } else {
    toastMessage.error("OOPS!", loginFailureMessage);
    return "";
  }
}

// Get Local Storage Data
export function getLocalStorageData(localItem: string, setItem?: any) {
  const localStorageData = window.localStorage.getItem(localItem);
  if (localStorageData && setItem) {
    setItem(JSON.parse(localStorageData));
  }

  return localStorageData;
}

// Get Local Storage Data
export function getSessionStorage(localItem: string, setItem: any) {
  const localSessionData = window.sessionStorage.getItem(localItem);
  if (localSessionData) {
    setItem(JSON.parse(localSessionData));
  }

  return localSessionData;
}
