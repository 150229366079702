export const sideBar_navBar_events = {
    side_menu_about_us_click: {
      category: "New tab will open which contains about us",
      action: "User clicks on About us text",
      label: "side-menu-about-us-click",
    },
side_menu_x_close_button_click: {
        category: "Side nav bar will close",
      action: "User clicks on X close button",
      label: "side-menu-x-close-button-click",
    }
}