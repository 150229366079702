import React, { useContext, useEffect, useState } from "react";
import styles from "./exploreTripAndAssistance.module.scss";
import InputWithMic from "../reusableComponent/inputWithMic/InputWithMic";
import { iAppConfig, iChatData } from "../../types";
import ChatContent from "../../routes/topDestination/chatContent/ChatContent";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { v4 } from "uuid";
import { getConfigMessage, scrollToBottom } from "../../utils";
import { exploreCityLogo, zenvoyaChatIcon } from "../../constant";
import { APP_CONFIG } from "../../constant/common";
import Assistance from "../reusableComponent/assistance/Assistance";
import { faqAssistanceAPI } from "../../api/llm/faqAssistanceAPI";
import { toastMessage } from "../../helpers/toast/toastMessage";
let abortChatController = new AbortController();

export default function FaqAssistance() {
  const [assistancChatData, setAssistanceChatData] = useState(
    [] as iChatData[]
  );
  const globalContext = useContext(StateContext);
  const { userDetails, appConfig, scrollContainerRef }: iGlobalContext =
    globalContext;
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [chatInputText, setChatInputText] = useState("");

  async function handleSendMessage(message: string) {
    // console.log(message);
    if (message === "")
      return;
    setAssistanceChatData((prev: iChatData[]) => [
      ...prev,
      {
        id: v4(),
        message: chatInputText,
        isUserMessage: true,
        prompt: [],
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "",
        prompt: [],
        isUserMessage: false,
        isLoading: true,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);
    setChatInputText("");
    setDisableInput(true);

    abortChatController.abort();
    abortChatController = new AbortController();
    scrollToBottom(scrollContainerRef);
    const response = await faqAssistanceAPI(
      userDetails?.id || "",
      //   assistancChatData.length === 0 ? "In Barcelona, " + message : message,
      message,
      assistancChatData.length === 0
        ? []
        : assistancChatData[assistancChatData.length - 1].prompt,
      abortChatController.signal
    );

    if (response) {
      setAssistanceChatData((prev: iChatData[]) => [
        ...prev.splice(0, prev.length - 1),
        {
          id: prev[prev.length - 1].id,
          // message: response?.data?.result || "",
          message: response?.data || "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: false,
          destinationList: [],
        },
      ]);
    } else {
      // console.log(abortChatController.signal.aborted);
      if (!abortChatController.signal.aborted) {
        setAssistanceChatData((prev: iChatData[]) => [
          ...prev.splice(0, prev.length - 1),
        ]);
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
    }

    setDisableInput(false);
    // console.log(response);
    scrollToBottom(scrollContainerRef);
  }

  useEffect(() => {
    if (chatInputText === "") scrollToBottom();
    // console.log(assistancChatData, assistancChatData?.length - 1);
  }, [assistancChatData]);

  function handleCancel() {
    const tempassistancChatData = [...assistancChatData];
    setAssistanceChatData(tempassistancChatData.slice(0, -2));
    abortChatController.abort();
  }

  return (
    <Assistance
      placeholder="Get help from zenvoya"
      headingText="How can we help you?"
      title="ZENVOYA ASSISTANCE"
      chatData={assistancChatData}
      appConfig={appConfig}
      appConfigKey={APP_CONFIG.ASSISTANCE_INFO_TEXT}
      disableInput={disableInput}
      handleSendMessage={handleSendMessage}
      chatInputText={chatInputText}
      setChatInputText={setChatInputText}
      handleCancel={handleCancel}
    />
  );
}
