import React from "react";
import styles from "./tripDetailsHeader.module.scss";
import { getDDYYYY, getMonthDate, getMonthDateUTC, getYear } from "../../../utils/dateUtils";
import { getPassengerCount } from "../../../utils";

export default function TripDetailsHeader({
  travellersInfo = {},
  tripName = "2024 Europe Trip",
  startDate = "DEC 19 - 24, 2024",
  endDate = "DEC 19 - 24, 2024",
  adults = "2",
  children = "2",
  itineraryNumber = "SDF-154-S85",
  bookedDate = "",
}: {
  travellersInfo?: any;
  tripName?: string;
  startDate?: string;
  endDate?: string;
  adults?: string;
  children?: string;
  itineraryNumber?: string;
  bookedDate?: string;
}) {
  return (
    <div className={styles.mainContainer}>
      <h1 className={styles.headingText}>{tripName}</h1>
      <div className={styles.descriptionContainer}>
        {startDate && endDate && (
          <h1>
            {getMonthDateUTC(startDate)} - {getDDYYYY(endDate)}
          </h1>
        )}
        <p>
          {getPassengerCount(
            travellersInfo?.adultsCount
              ? Number(travellersInfo?.adultsCount)
              : 0,
            travellersInfo?.childrenCount
              ? Number(travellersInfo?.childrenCount)
              : 0,
            travellersInfo?.seniorsCount
              ? Number(travellersInfo?.seniorsCount)
              : 0
          )}
          {/* {adults} adults, {children} children */}
        </p>
        <div className={styles.itineraryNoAndBookedDate}>
          <p>Itinerary Number: {itineraryNumber}</p>
          {bookedDate !== "" && (
            <h1>
              Booked on: {getMonthDate(bookedDate)}, {getYear(bookedDate)}
            </h1>
          )}
        </div>
      </div>
    </div>
  );
}
