export const weather_advisory_events = {
  weather_advisory_page_load: {
    category: "User loads weather advisory page",
    action: "User navigates to weather advisory page",
    label: "weather-advisory-page-load",
  },
  weather_advisory_back_click: {
    category: "User navigates back to today page",
    action: "User clicks on back button",
    label: "weather-advisory-back-click",
  },
  weather_advisory_suggested_changes_your_plan_accordion_click: {
    category: "The suggestions options data section will open ",
    action: "User clicks on accordion button",
    label: "weather-advisory-suggested-changes-your-plan-accordion-click",
  },
  weather_advisory_suggested_changes_your_plan_checkbox_click: {
    category: "User can select the option that he would like to visit",
    action: "User clicks on check box",
    label: "weather-advisory-suggested-changes-your-plan-checkbox-click",
  },
  weather_advisory_apply_changes_click: {
    category:
      "By clicking user can apply and save the desired changes in the trip ",
    action: "User clicks on apply changes button",
    label: "weather-advisory-apply-changes-click",
  },
};
