import React from "react";
import BackButtonHandler from "../BackButtonHandler";
import { StateContext } from "../../context/globalContext/context";
import styles from "./notfound.module.scss";

export default function NotFound():JSX.Element {
  const state = React.useContext(StateContext);
  const { setNav }: any = state;

  function handleMobileBack() {
    setNav("STARTING_DESTINATION");
  }
  return (
    <div className={styles.main}>
      <BackButtonHandler onBack={handleMobileBack} />
      <h1>404 Page not found!</h1>
    </div>
  );
}
