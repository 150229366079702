import axios from "axios";
import { ApiConfig } from "../constant/api_constants/url";
import { API_METHODS } from "../enums";
import { toastMessage } from "../helpers/toast/toastMessage";
import { TOAST_MESSAGES } from "../constant/toastMessages";

const handleError = (statusCode: number) => {
  if (!statusCode) {
    return;
  }

  let message = "";
  let title = "Error";

  switch (statusCode) {
    // Client-Side
    case 400:
      message = TOAST_MESSAGES.ClientSide401.message;
      title = TOAST_MESSAGES.ClientSide401.messageTitle;
      break;
    case 401:
      message = TOAST_MESSAGES.ClientSide402.message;
      title = TOAST_MESSAGES.ClientSide402.messageTitle;
      break;
    case 403:
      message = TOAST_MESSAGES.ClientSide403.message;
      title = TOAST_MESSAGES.ClientSide403.messageTitle;
      break;
    case 404:
      message = TOAST_MESSAGES.ClientSide404.message;
      title = TOAST_MESSAGES.ClientSide404.messageTitle;
      break;
    case 405:
      message = TOAST_MESSAGES.ClientSide405.message;
      title = TOAST_MESSAGES.ClientSide405.messageTitle;
      break;

    // Server-Side
    case 500:
      message = TOAST_MESSAGES.ServerSide500.message;
      title = TOAST_MESSAGES.ServerSide500.messageTitle;
      break;
    case 501:
      message = TOAST_MESSAGES.ServerSide501.message;
      title = TOAST_MESSAGES.ServerSide501.messageTitle;
      break;
    case 502:
      message = TOAST_MESSAGES.ServerSide502.message;
      title = TOAST_MESSAGES.ServerSide502.messageTitle;
      break;
    case 503:
      message = TOAST_MESSAGES.ServerSide503.message;
      title = TOAST_MESSAGES.ServerSide503.messageTitle;
      break;
    case 504:
      message = TOAST_MESSAGES.ServerSide504.message;
      title = TOAST_MESSAGES.ServerSide504.messageTitle;
      break;

    // Default case for unknown errors
    default:
      message = TOAST_MESSAGES.ApiBreakdown.message;
      title = TOAST_MESSAGES.ApiBreakdown.messageTitle;
  }

  // Log
  console.error(`Error (${statusCode}): ${title} - ${message}`);
  // toastMessage.error(title, message);
};

// Generic API request function
export const fetchApi = async (
  endpoint: string,
  method:
    | API_METHODS.GET
    | API_METHODS.POST
    | API_METHODS.PUT
    | API_METHODS.DELETE,
  body?: any,
  params?: Record<string, string | number> | null,
  controller?: AbortController
) => {
  const url = ApiConfig.getFullUrl(endpoint, params || {});
  try {
    const response = await axios({
      url,
      method,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      data: body,
      signal: controller?.signal,
    });

    if (response?.status !== 200) {
      handleError(response?.status);
      return null;
    }
    return response?.data;
  } catch (error: unknown) {
    if (axios.isCancel(error)) {
      console.error("API call was canceled");
      return null;
    }
    console.error("Error in API call:", error);
    toastMessage.error("OOPS!", "An error occurred while fetching data.");
    throw error;
  }
};
