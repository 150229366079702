import React, { useContext } from "react";
import NewLoader from "../newLoader/NewLoader";
import { bedFontLight, planeEnginesLight, ROUTES } from "../../../constant";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { ScreenTypes } from "../../../enums";
import { useLocation } from "react-router-dom";
import BookingPreferencesModal from "../../booking/bookingPreferencesModal/bookingPreferencesModal";

function BookingModals(): JSX.Element {
  const globalContext = useContext(StateContext);
  const location = useLocation();
  const {
    isFetchingFlights = false,
    isFetchingHotels = false,
    // flightData,
    // hotelData,
    setIsFetchingFlights,
    setIsFetchingHotels,
    selectedFlightOptions,
    isCheckingFlight = false,
    setIsCheckingFlight,
    checkFlightDetails = false,
    setCheckFlightDetails,
    isFetchingHotelDetails,
    setIsFetchingHotelDetails,
    isMobile,
    setScreen,
    setIsFetchingAirports,
    isFetchingAirports,
    setAirPorts,
    shouldUserNavigateBacktoTripDetailsPage,
    showBookingPreferences,
    setShowBookingPreferences,
    setShouldUserNavigateBackTotripDetailsPage,
  }: iGlobalContext = globalContext || {};
  return (
    <div>
      {" "}
      <BookingPreferencesModal
        isOpen={showBookingPreferences}
        onClose={() => {
          setShowBookingPreferences(false);
        }}
      />
      {selectedFlightOptions.flights && selectedFlightOptions.hotels && (
        <NewLoader
          loaderIcon1={planeEnginesLight}
          loaderIcon2={bedFontLight}
          text="Searching for flights and hotels"
          isOpen={isFetchingFlights || isFetchingHotels}
          onClose={() => {
            setIsFetchingFlights(false);
            setIsFetchingHotels(false);
          }}
          loadFullScreen
        />
      )}
      {isFetchingAirports && (
        <NewLoader
          text="Please wait"
          // className={styles.loaderMain}
          // cancelButtonClassName={styles.cancelBtn}
          isOpen={true}
          onClose={() => {
            setIsFetchingAirports(false);
            setAirPorts([]);
          }}
          loadFullScreen
        />
      )}
      {isFetchingHotelDetails && (
        <NewLoader
          loaderIcon1={bedFontLight}
          // loaderIcon2={bedFontLight}
          text="Fetching hotel details"
          isOpen={isFetchingHotelDetails}
          onClose={() => {
            if (!isMobile) {
              if (shouldUserNavigateBacktoTripDetailsPage) {
                setScreen(ScreenTypes.TripDetails);
              }
              if (!shouldUserNavigateBacktoTripDetailsPage) {
                setScreen(ScreenTypes.Booking);
              }
            }

            if (isMobile) window.history.back();
            setIsFetchingHotelDetails(false);
          }}
          loadFullScreen
        />
      )}
      {selectedFlightOptions.hotels && !selectedFlightOptions.flights && (
        <NewLoader
          loaderIcon1={bedFontLight}
          // loaderIcon2={bedFontLight}
          text="Searching for hotels"
          isOpen={isFetchingHotels}
          onClose={() => {
            setIsFetchingHotels(false);
          }}
          loadFullScreen
        />
      )}
      {selectedFlightOptions.flights && !selectedFlightOptions.hotels && (
        <NewLoader
          loaderIcon1={planeEnginesLight}
          // loaderIcon2={bedFontLight}
          text="Searching for flights"
          isOpen={isFetchingFlights}
          onClose={() => {
            setIsFetchingFlights(false);
          }}
          loadFullScreen
        />
      )}
      <NewLoader
        loaderIcon1={planeEnginesLight}
        // loaderIcon2={bedFontLight}
        text="Checking flight availability"
        isOpen={isCheckingFlight}
        onClose={() => {
          setIsCheckingFlight(false);
        }}
        loadFullScreen
      />
      <NewLoader
        loaderIcon1={planeEnginesLight}
        // loaderIcon2={bedFontLight}
        text="Checking flight details"
        isOpen={checkFlightDetails}
        onClose={() => {
          setCheckFlightDetails(false);
        }}
        loadFullScreen
      />
    </div>
  );
}

export default BookingModals;
