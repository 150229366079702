import React, { ReactNode } from "react";
import { StateProvider } from "./globalContext/context";
import { TopDestinationContextProvider } from "./topDestinationContext/TopDestinationContext";
import { RecommendedTripContextProvider } from "./recommendedTripContext/recommendedTripContext";
import { HistoryContextProvider } from "./historyContext/historyContext";
import { CurrentTripContextProvider } from "./currentTripContext/currentTripContext";

export default function ContextProvider({ children }: { children: ReactNode }) {
  return (
    <StateProvider>
      <CurrentTripContextProvider>
        <HistoryContextProvider>
          <TopDestinationContextProvider>
            <RecommendedTripContextProvider>
              {children}
            </RecommendedTripContextProvider>
          </TopDestinationContextProvider>
        </HistoryContextProvider>
      </CurrentTripContextProvider>
    </StateProvider>
  );
}
