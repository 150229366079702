import { Chip, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./act.module.scss";
import { FoodPreferData } from "../../../constant";
import { ButtonSizes, ButtonVariants } from "../../../enums";

function FoodPreferences(): JSX.Element {
  const [foodPreferences, setFoodPreferences] = useState(FoodPreferData);
  const [selectedActivities, setSelectedActivities] = useState<
    { id: number; data: string }[]
  >([]);

  const handleChipClick = (activity: { id: number; data: string }) => {
    if (
      selectedActivities.some((selected) => selected.data === activity.data)
    ) {
      setSelectedActivities(
        selectedActivities.filter((selected) => selected.data !== activity.data)
      );
    } else {
      setSelectedActivities([...selectedActivities, activity]);
    }
  };

  useEffect(() => {
    // console.log(selectedActivities);
  }, [selectedActivities]);

  return (
    <div className={styles.main}>
      <span className={styles.title}>Food Preferences</span>
      <div className={styles.chipCard}>
        {foodPreferences.map((activity, index) => (
          <IconButton
            onClick={() => handleChipClick(activity)}
            className={
              selectedActivities.some((selected) => selected.id === activity.id)
                ? styles.activeChip
                : styles.chip
            }
          >
            {activity.data}
          </IconButton>
          // <Chip
          //   label={activity.data}
          //   sx={{
          //     height: "30px",
          //   }}
          //   variant={ButtonVariants.Outlined}
          //   onClick={() => handleChipClick(activity)}
          //   key={index}
          //   className={
          //     selectedActivities.some((selected) => selected.id === activity.id)
          //       ? styles.activeChip
          //       : styles.chip
          //   }
          // />
        ))}
      </div>
    </div>
  );
}

export default FoodPreferences;
