import React, { ChangeEvent, useEffect } from "react";
import { Rifm } from "rifm";
import { AsYouType } from "libphonenumber-js";
import styles from "./phoneNumberValidationInputField.module.scss";
import StarRateIcon from "@mui/icons-material/StarRate";
import { countryCode } from "../../../constant/dummyData";

interface iProps {
  countryCode?: any;
  inputName?: string;
  mandate: boolean;
  placeHolder?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  error?: any;
  name?: any;
  showRequired?: boolean;
  showOverlayInput?: boolean;
  overlayValue?: string;
  formDataLocal?: any;
  setFormDataLocal?: any;
}

export default function PhoneNumberValidationInputField({
  countryCode,
  inputName,
  mandate,
  placeHolder,
  value,
  onChange,
  width,
  error = false,
  name,
  showRequired = true,
  showOverlayInput = false,
  overlayValue = "",
  formDataLocal,
  setFormDataLocal,
}: iProps) {
  const parseDigits = (string: string) => (string.match(/\d+/g) || []).join("");
  const formatPhone = (string: string) => {
    const digits = parseDigits(string).substr(0, 10);
    return new AsYouType(countryCode).input(digits);
  };

  // const [phone, setPhone] = React.useState(formDataLocal.phoneNumber);

  // useEffect(() => {
  //   console.log(phone);
  // setFormDataLocal({
  //   ...formDataLocal,
  //   phoneNumber: phone,
  // });
  // }, [phone]);

  return (
    <div className={styles.main}>
      <p className={styles.inputCard}>
        {inputName} {mandate && <StarRateIcon className={styles.mandate} />}
      </p>
      <div className={styles.inputContainer}>
        <Rifm
          accept={/\d+/g}
          // do not jump after ) until see number before
          mask={
            formDataLocal.phoneNumber.length < 6 &&
            /[^\d]+/.test(formDataLocal.phoneNumber[3])
              ? undefined
              : formDataLocal.phoneNumber.length >= 14
          }
          format={formatPhone}
          value={formDataLocal.phoneNumber}
          onChange={(str) => {
            // console.log(str, "str");
            // setPhone(str);
            setFormDataLocal({
              ...formDataLocal,
              phoneNumber: str,
            });
          }}
        >
          {renderInput}
        </Rifm>
      </div>
      <p className={`${error ? styles.errorMessage : styles.hideError}`}>
        {/* {error} */}
        {showRequired ? "Required" : ""}
      </p>
    </div>
  );
}

const renderInput = ({ value, onChange }: { value: any; onChange: any }) => (
  <input
    className={`${styles.inputField} ${styles.phoneNumberOverlay}`}
    // style={{ width: `${width}` }}
    style={{ width: "120px" }}
    value={value}
    onChange={onChange}
  />
);
