import React from "react";
import { Stack } from "@mui/material";
import { StateContext } from "../../context/globalContext/context";
import LoginDialog from "./loginDialog/LoginDialog";
import CreateAccountDialog from "./createAccountDialog/CreateAccountDialog";
import SaveItineraryDialog from "./saveItineraryDialog/SaveItineraryDialog";
import { DialogSelected } from "../../constant/common";
import EditPromptDialog from "./editPromptDialog/EditPromptDialog";
import { iGlobalContext } from "../../context/globalContext/interface";
import EditAccountDialog from "./EditAccountDialog/editAccountDialog";
import EditPreferenceDialog from "./editPreferenceDialog/editPreferenceDialog";
import ForgetPasswordDialog from "./forgetPasswordDialog/forgetPasswordDialog";
import EditActivityDialog from "./editAcitvityDialog/EditActivityDialog";

export default function Dialog() {
  const state = React.useContext(StateContext);
  const {
    loginCreateAccountState,
    setLoginCreateAccountState,
    setIsFetchingAirports,
  }: iGlobalContext = state;

  // Cancel Save
  function handleCancel() {
    // setSaveScreenState(false)
    setIsFetchingAirports(false);
    setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
  }

  const renderDialog = () => {
    switch (loginCreateAccountState.page) {
      case DialogSelected.LOGIN:
        return <LoginDialog />;
      case DialogSelected.CREATE_ACCOUNT:
        return <CreateAccountDialog />;
      case DialogSelected.SAVE_ITINERARY:
        return <SaveItineraryDialog props={{ handleCancel }} />;
      case DialogSelected.EDIT_PROMPT:
        return <EditPromptDialog handleCancel={handleCancel} />;
      case DialogSelected.EDIT_ACCOUNT:
        return <EditAccountDialog />;
      case DialogSelected.EDIT_PREFER:
        return <EditPreferenceDialog />;
      case DialogSelected.ADD_PREFER:
        return <EditPreferenceDialog />;
      case DialogSelected.FORGET_PASS:
        return <ForgetPasswordDialog />;
      // case DialogSelected.EDIT_ACTIVITY:
      //   return <EditActivityDialog handleCancel={handleCancel}/>
      default:
        return <></>;
    }
  };
  if (loginCreateAccountState.flag) return <Stack>{renderDialog()}</Stack>;
  return <></>;
}
