import React, { useContext } from "react";
import styles from "./side.module.scss";
import Profile from "../profile/profile";
import BasicTabs from "../sideBar/tabs/tabs";
import MenuIcon from "../../assets/MenuIcon.svg";
import {
  arrowIcon,
  ROUTES,
  updatedCloseIcon,
  whiteMenuIcon,
  zenvolyaNewLogo,
  zenvolyaNewWhiteLogo,
} from "../../constant";
import { useLocation, useNavigate } from "react-router-dom";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import updatedNewTrip from "../../assets/updatedNewTrip.png";
import { Button } from "@mui/material";
import SideBarOptions from "./sideBarOptions/sideBarOptions";
import { iHistoryContext } from "../../context/historyContext/interface";
import { useHistoryContext } from "../../context/historyContext/historyContext";
import { TERMS_AND_CONTACT_ARR } from "../../constant/dummyData";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import { DialogSelected } from "../../constant/common";
import { ScreenTypes } from "../../enums";

const ROUTE_COLORS = {
  [ROUTES.Home]: "#FAFAFA",
  [ROUTES.Booking]: "#FAFAFA",
  [ROUTES.NewTrip]: "#FAFAFA",
  [ROUTES.Today]: "#FAFAFA",
  [ROUTES.TopDestination]: "#FAFAFA",
};

const BOX_SHADOW_DEFAULT = "0px 3px 8px rgba(0, 0, 0, 0.24)";
const BOX_SHADOW_NONE = "none";

function CustomSideBar(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isOpenMobileSidebar,
    setIsOpenMobileSidebar,
    setenableMultiSelect,
    userInfo,
    setSignInClick,
    setMenuOpenState,
    setLoginCreateAccountState,
    setScreen,
    setFirstScreen,
    isMobile,
  }: iGlobalContext = useContext(StateContext);
  const { getHistory }: iHistoryContext = useHistoryContext();
  const toggleSidebar = () => {
    if (isMobile) setIsOpenMobileSidebar((prev) => !prev);
    if (!isOpenMobileSidebar) {
      triggerGAEvent(GA_LABEL_ENUMS.home_side_bar_open_click);
      getHistory(true, true);
    }
    if (isOpenMobileSidebar)
      triggerGAEvent(GA_LABEL_ENUMS.home_side_bar_close_click);
    if (!isMobile) {
      navigate(ROUTES.Home);
      return;
    }
  };

  // useEffect(() => {
  //   if (!isMobile) setIsOpenMobileSidebar(false);
  // }, [isMobile]);

  const handleLogoClick = () => {
    triggerGAEvent(GA_LABEL_ENUMS.home_zenvoya_logo_click);
    navigate(ROUTES.NewTrip);
    setIsOpenMobileSidebar(false);
    setFirstScreen(ScreenTypes.NewTrip);
  };

  const backgroundColor = ROUTE_COLORS[location.pathname] || "#ffff";
  const boxShadow = ROUTE_COLORS[location.pathname]
    ? BOX_SHADOW_NONE
    : BOX_SHADOW_DEFAULT;
  const showNewTripButton =
    location.pathname !== ROUTES.Home &&
    location.pathname !== ROUTES.Booking &&
    location.pathname !== ROUTES.Today &&
    location.pathname !== ROUTES.NewTrip;
  const weatherAdvisoryRoutes = [
    ROUTES.weatherAdvisory,
    ROUTES.FlightDelay,
    ROUTES.FlightSelect,
  ];
  const isWeatherAdvisory = weatherAdvisoryRoutes.includes(location.pathname);
  const navBarStyles = isWeatherAdvisory ? {} : { backgroundColor, boxShadow };

  // Sign in
  function handleSignIn() {
    setSignInClick(true);
    setMenuOpenState(false);
    setLoginCreateAccountState({ flag: true, page: DialogSelected.LOGIN });
    // closeDrawer();
    toggleSidebar();
  }

  const handleNav = () => {
    triggerGAEvent(GA_LABEL_ENUMS.New_Trip_Click);
    navigate(ROUTES.Home);
    setScreen("");
    setFirstScreen(ScreenTypes.NewTrip);
  };

  const handleMobNav = () => {
    setenableMultiSelect(false);
    setTimeout(() => navigate(ROUTES.NewTrip), 100);
    triggerGAEvent(GA_LABEL_ENUMS.New_Trip_Click);
  };

  return (
    <div className={styles.mainContainer}>
      <div
        className={`${styles.container} ${isOpenMobileSidebar ? styles.open : ""}`}>
        <div className={styles.navBar} style={navBarStyles}>
          <div className={styles.navBox}>
            <div className={styles.menuCard}>
              <img
                src={isWeatherAdvisory ? whiteMenuIcon : MenuIcon}
                alt="Menu"
                className={`${isWeatherAdvisory ? styles.whiteMenuIcon : styles.menuIcon}`}
                onClick={toggleSidebar}
              />
              <img
                src={isWeatherAdvisory ? zenvolyaNewWhiteLogo : zenvolyaNewLogo}
                alt="Logo"
                className={styles.logo}
                onClick={handleLogoClick}
              />
            </div>
            {showNewTripButton && (
              <Button
                onClick={() => (isMobile ? handleMobNav() : handleNav())}
                className={`${isWeatherAdvisory ? styles.hideButton : styles.button}`}>
                <img
                  src={updatedNewTrip}
                  alt="New Trip"
                  className={styles.buttonIcon}
                />
                NEW TRIP
              </Button>
            )}
          </div>
        </div>
        <div
          className={`${styles.sidebar} ${isOpenMobileSidebar ? styles.sidebarOpen : styles.sideBarClose}`}>
          <div className={styles.subSideBar}>
            {!userInfo.loggedIn ? (
              <div className={styles.closeCard}>
                <Button
                  className={styles.closeButton}
                  endIcon={
                    <img
                      src={updatedCloseIcon}
                      alt="Close"
                      className={styles.closeIcon}
                    />
                  }
                  onClick={toggleSidebar}>
                  Close
                </Button>
              </div>
            ) : (
              <div className={styles.box}>
                <Profile onClick={toggleSidebar} />
                <SideBarOptions onClick={toggleSidebar} />

                <BasicTabs
                  setIsOpenMobileSidebar={setIsOpenMobileSidebar}
                  isOpenMobileSidebar={isOpenMobileSidebar}
                />
                <div className={styles.termArrContainer}>
                  {" "}
                  {TERMS_AND_CONTACT_ARR.filter((item) => {
                    if (userInfo.loggedIn) {
                      return true;
                    }
                    if (!userInfo.loggedIn && item.id === 1) return false;
                    return true;
                  }).map((i: any) => {
                    return (
                      <div
                        className={`${styles.sideContainer} ${!userInfo.loggedIn && styles.terms_contact} ${i.id === 4 && styles.aboutUs}`}
                        key={i.id}>
                        {/* <img src={i.icon} alt="" className={styles.optionIcon} /> */}
                        <a
                          className={styles.optionsCard}
                          href={i.link}
                          rel="noreferrer"
                          target={i.id !== 0 ? "_blank" : "_self"}
                          onClick={() => {
                            toggleSidebar();
                            triggerGAEvent(
                              i.title === "Terms of Service"
                                ? GA_LABEL_ENUMS.Terms_of_service_click
                                : GA_LABEL_ENUMS.Contact_us_click
                            );
                          }}>
                          <div className={styles.descCard}>
                            {i.id === 4 && (
                              <img
                                src={i.icon}
                                alt="info icon"
                                className={styles.infoIcon}
                              />
                            )}
                            <p className={styles.tripTitle}>{i.title}</p>
                            {/* <p className={styles.tripDesc}>{i.desc}</p> */}
                          </div>
                          {i.id !== 4 && (
                            <div className={styles.arrowCard}>
                              <img
                                src={arrowIcon}
                                alt=""
                                className={styles.arrowIcon}
                                // onClick={() => handleNavigate(i.routInfo)}
                              />
                            </div>
                          )}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {!userInfo.loggedIn && (
              <div
                className={`${styles.sideTermsContainer} ${!userInfo.loggedIn && styles.loggedOutUser}`}>
                {TERMS_AND_CONTACT_ARR.filter((item) => {
                  if (userInfo.loggedIn) {
                    return true;
                  }

                  if (!userInfo.loggedIn && item.id === 1) return false;
                  return true;
                }).map((i: any) => {
                  return (
                    <div
                      className={`${styles.sideContainer} ${!userInfo.loggedIn && styles.terms_contact} ${i.id === 4 && styles.aboutUs}`}
                      key={i.id}>
                      {/* <img src={i.icon} alt="" className={styles.optionIcon} /> */}
                      <a
                        className={styles.optionsCard}
                        href={i.link}
                        rel="noreferrer"
                        // target={i.id !== 0 ? "_blank" : "_self"}
                        onClick={() => {
                          console.log("HERE");
                          triggerGAEvent(
                            GA_LABEL_ENUMS.side_menu_about_us_click
                          );

                          window.history.pushState({}, "", location?.pathname);
                          toggleSidebar();
                          navigate(ROUTES.Faqs);
                          triggerGAEvent(
                            i.title === "Terms of Service"
                              ? GA_LABEL_ENUMS.Terms_of_service_click
                              : GA_LABEL_ENUMS.Contact_us_click
                          );
                        }}>
                        <div className={styles.descCard}>
                          {i.id === 4 && (
                            <img
                              src={i.icon}
                              alt="info icon"
                              className={styles.infoIcon}
                            />
                          )}
                          <p className={styles.tripTitle}>{i.title}</p>
                          {/* <p className={styles.tripDesc}>{i.desc}</p> */}
                        </div>
                        {i.id !== 4 && (
                          <div className={styles.arrowCard}>
                            <img
                              src={arrowIcon}
                              alt=""
                              className={styles.arrowIcon}
                              // onClick={() => handleNavigate(i.routInfo)}
                            />
                          </div>
                        )}
                      </a>
                    </div>
                  );
                })}
                <div className={styles.loginCard}>
                  <Button
                    onClickCapture={() => {
                      triggerGAEvent(GA_LABEL_ENUMS.Login_in_click);
                      handleSignIn();
                    }}
                    size="small"
                    sx={{
                      // mb: { xs: "80px", sm: 4, md: 0 },
                      bgcolor: "#BF150D",
                      color: "#fff",
                      borderRadius: "46.5px",
                      boxShadow: "none",
                      textTransform: "none",
                      fontFamily: "Lato",
                      pt: 0.5,
                      pb: 0.5,
                      pl: { xs: 2, sm: 2, md: 2 },
                      pr: { xs: 2, sm: 2, md: 2 },
                      "&:hover": {
                        bgcolor: "#BF150D",
                        color: "#fff",
                      },
                      width: "max-content",
                      alignSelf: "center",
                    }}
                    variant="text">
                    Log in
                  </Button>
                </div>
              </div>
            )}

            {/* {!userInfo.loggedIn && (
              <div className={styles.loginCard}>
                <Login closeDrawer={toggleSidebar} />
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomSideBar;
