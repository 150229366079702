import { arrowRight, calendarRangeDark } from "../../../../constant";
import FormatHotelListingDateFormat from "../../../../utils/formatHotelListingDateFormat";
import styles from "./check.module.scss";

interface iProps {
  className?: string;
  checkIn: string;
  checkOut: string;
}

const CheckInCheckOut: React.FC<iProps> = ({
  className = "",
  checkIn,
  checkOut,
}) => {
  return (
    <div className={`${className} ${styles.main}`}>
      <div className={styles.btn}>
        <img
          src={calendarRangeDark}
          alt="icon"
          className={styles.calendarIcon}
        />
        <p className={styles.btnName}>
          {checkIn ? FormatHotelListingDateFormat(checkIn) : "Fri DEC 18"}
        </p>
        <img src={arrowRight} className={styles.arrowRightIcon} alt="" />
        <p className={styles.btnName}>
          {checkOut ? FormatHotelListingDateFormat(checkOut) : "Fri DEC 18"}
        </p>
      </div>
    </div>
  );
};

export default CheckInCheckOut;
