export const explore_city_events = {
  explore_city_page_load: {
    category: "User loads explore city page",
    action: "User navigates to explore city page",
    label: "explore-city-page-load",
  },
  explore_city_back_click: {
    category: "User navigates back to today page",
    action: "User clicks on back button",
    label: "explore-city-back-click ",
  },
  explore_city_input_click: {
    category: "User sends the search prompt",
    action: "User clicks on prompt input send button",
    label: "explore-city-input-click ",
  },
  explore_city_stop_click: {
    category: "User stops the prompt request",
    action: "User clicks on stop button",
    label: "explore-city-stop-click ",
  },
  explore_city_mic_send_click: {
    category: "User record the prompt by using voice recording",
    action: "User clicks on mic button",
    label: "explore-city-mic-send-click ",
  },
  explore_city_mic_stop_click: {
    category: "User can stop the voice recording",
    action: "User clicks on mic button to stop the search",
    label: "explore-city-mic-stop-click",
  },
  explore_city_new_trip_click: {
    category: "User navigates to new trip page",
    action: "User clicks on new trip button",
    label: "explore-city-new-trip-click ",
  },
};
